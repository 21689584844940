import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

interface ErrorDisplayModalProps {
  heading:string,
  message:React.ReactNode,
  drawToActionComponent:(onClick: () => void) => JSX.Element,
}

const ErrorDisplayModal = ({
  heading,
  message,
  drawToActionComponent,
}:ErrorDisplayModalProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const actions = [
    {
      text: 'Close',
      onClick: () => setIsOpen(false),
    },
  ];

  return (
    <>
      {drawToActionComponent(() => setIsOpen(true))}
      <ModalTransition>
        {isOpen && (
          <Modal
            actions={actions}
            onClose={() => setIsOpen(false)}
            heading={heading}
            width="medium"
            appearance="danger"
          >
            {message}
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default ErrorDisplayModal;
