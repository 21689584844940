import React, { useState } from 'react';
import Popup from '@atlaskit/popup';
import { AppSwitcher } from '@atlaskit/atlassian-navigation';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import EditIcon from '@atlaskit/icon/glyph/edit';
import { colors } from '@atlaskit/theme';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../utils/useAuth';

const SwitchContainer = styled.div`
  width:300px;
  max-height:calc(100vh - 100px);
  padding: 24px;
  ul {
    list-style:none;
    margin:0;
    padding:0;
    margin:10px 0px 10px 0px;
  }
`;

const TeamSwitcher = styled.div`
 width:100%;
 display:flex;
 align-items:center;
`;

const SwitcherPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { state: { teams, currentTeamId }, dispatch } = useAuth();

  const history = useHistory();

  const onClick = () => {
    setIsOpen(!isOpen);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const Team = (
    { id, name, selected }: { id: string, name: string, selected: boolean },
  ) => {
    const handleTeamSwitch = (teamId: string) => {
      dispatch({
        type: 'switchTeam',
        switchTeamPayLoad: teamId,
      });
      onClose();
      history.push('/');
    };

    return (
      <TeamSwitcher>
        <Button
          appearance="subtle"
          iconBefore={<CheckCircleIcon label="selected" primaryColor={selected ? colors.green() : colors.N40} />}
          shouldFitContainer
          onClick={() => handleTeamSwitch(id)}
        >
          <div
            data-testid={`team-switcher-team-${name.replace(' ', '-')}`}
            style={{ textAlign: 'left' }}
          >
            {name}
          </div>
        </Button>
        {selected
          && (
          <Button
            appearance="subtle"
            testId={`edit-team-profile-button-${name.replace(' ', '-')}`}
            onClick={() => {
              history.push('/team/profile');
              onClose();
            }}
          >
            <EditIcon label="edit" primaryColor={colors.primary()} />
          </Button>
          )}
      </TeamSwitcher>
    );
  };
  const SwitcherContent = () => (
    <SwitchContainer>
      <h3>Switch to team...</h3>
      <ul>
        {teams.map((team) => (
          <li key={team.id}>
            <Team
              id={team.id}
              name={team.name}
              selected={currentTeamId === team.id}
            />
          </li>
        ))}
      </ul>
    </SwitchContainer>
  );

  return (
    <Popup
      placement="bottom-start"
      content={() => (
        <SwitcherContent />
      )}
      isOpen={isOpen}
      onClose={onClose}
      trigger={(triggerProps) => (
        <AppSwitcher
          onClick={onClick}
          tooltip="Switch to..."
          {...triggerProps}
          testId="team-switcher"
        />
      )}
    />
  );
};

export default SwitcherPopup;
