import React from 'react';
import { useParams } from 'react-router-dom';
import MarketPlaceConnectionError from '../../components/Errors/MarketPlaceConnectionError/MarketPlaceConnectionError';

export default () => {
  const { teamChannelId } = useParams();

  return (
    <MarketPlaceConnectionError teamChannelId={teamChannelId} />
  );
};
