import React from 'react';
import { Control } from 'react-hook-form';
import { ToggleStateless } from '@atlaskit/toggle';
import { TeamChannelProduct, TeamChannel } from '../ProductDetailsContainer/type';
import TextFieldWithLabelAndError from './TextFieldWithLabelAndError';
import { ErrorType } from './types';
import { Label } from '../ProductCreationFormContainer/ProductCreationForm.styles';
import { useSubscription } from '../../utils/useAuth';

const ChannelPricingInputs = ({
  teamChannelProducts, // for update pass teamChanelProcuts
  teamChannels, // for new creation pass teamChannels
  enabled,
  control,
  errors,
  setValue,
  enabledTable,
  handleChannelEnableStatus,
}: {
  // eslint-disable-next-line react/require-default-props
  teamChannelProducts?:TeamChannelProduct[]
  // eslint-disable-next-line react/require-default-props
  teamChannels?:TeamChannel[],
  enabled:Boolean,
  control: Control<Record<string, string>>,
  errors: ErrorType,
  setValue: Function,
  enabledTable:{[k:string]:boolean},
  handleChannelEnableStatus:(teamChannelId:string, enabledStatus:boolean)=>void;
}) => {
  const freeExpired = useSubscription();

  if (!enabled) {
    return <></>;
  }

  if (teamChannels && teamChannelProducts) {
    const priceInputUIs = teamChannels
      .filter((e) => (e.active && e.onBoarded))
      .sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      }).map((tc) => {
        const tcp = teamChannelProducts;
        const curTcp = tcp.filter((obj) => obj.teamChannel.id === tc.id)[0];
        const labelText = `${tc.name} (AUD Inc.)`;
        const label = (
          <Label>
            <div className="labelText">{labelText}</div>
            <ToggleStateless
              key={tc.id}
              isChecked={enabledTable[tc.id]}
              size="regular"
              onChange={() => handleChannelEnableStatus(
                tc.id,
                !enabledTable[tc.id],
              )}
              isDisabled={freeExpired}
            />
          </Label>
        );

        return (
          <TextFieldWithLabelAndError
            key={tc.id}
            testId={`unitPrice-${tc.id}`}
            name={tc.id}
            label={label}
            isRequired
            control={control}
            errors={errors}
            defaultValue={curTcp?.unitPrice ? curTcp?.unitPrice / 100 : ''}
            setValue={setValue}
            helpMessage=""
            maxLength={-1}
            type="number"
            minVal={0.01}
            validateOnMinVal={!!enabledTable[tc.id]}
            isDisable={freeExpired}
          />
        );
      });

    return <>{priceInputUIs}</>;
  }

  return <></>;
};

export default ChannelPricingInputs;
