import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

interface Props {
  isOpen:boolean,
  heading:string,
  // eslint-disable-next-line react/require-default-props
  width?:string,
  children:React.ReactNode,
  onClose:()=>void,
  onConfirm:()=>void,
}

export default ({
  isOpen,
  heading,
  width = 'small',
  children,
  onClose,
  onConfirm,
}:Props) => (
  <ModalTransition>
    {isOpen && (
      <Modal
        appearance="danger"
        onClose={onClose}
        heading={heading}
        width={width}
        actions={[
          { text: 'Cancel', onClick: onClose, appearance: 'default' },
          {
            text: 'Delete', appearance: 'danger', onClick: () => { onConfirm(); onClose(); }, testId: 'deleteConfirmButton',
          },
        ]}
      >
        {children}
      </Modal>
    )}
  </ModalTransition>
);
