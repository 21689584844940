import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import { useHistory } from 'react-router-dom';
import { HelperMessage } from '@atlaskit/form';
import { store } from 'react-notifications-component';
import { ToggleStateless } from '@atlaskit/toggle';
import { isNaN, isUndefined } from 'lodash';
import {
  CardContainer,
  ErrorMsg,
  FloatStickyFooter,
  Label,
  PageMargin,
} from '../ProductCreationFormContainer/ProductCreationForm.styles';
import {
  useQueryEbayCategoryAspectsQuery, useCreateProductMutation,
  useUpdateProductMutation, QueryCategorySubtreeDocument,
  useGetTeamChannelsByTeamIdQuery,
  useQueryEbayCategoryFeaturesQuery,
  useQueryProductTypeDefineQuery,
} from '../../graphql/types';
import client from '../../graphql/client';
import 'react-quill/dist/quill.snow.css';
import AspectFields from './AspectFields';
import TextFieldWithLabelAndError from './TextFieldWithLabelAndError';
import ImageUploadWithLabelAndError from './ImageUploadWithLabelAndError';
import RTEWithLabelAndError from './RTEWithLabelAndError';
import { useAuth, useSubscription } from '../../utils/useAuth';
import {
  InitProductType, Aspect, SubmitProductType, AspectOption,
  AspectOneOption, SingleOption, AspectString, TeamChannelPolicySubmitMap,
} from './types';
import AspectContainer from './AspectContainer';
import ProductTaxClassSelector from './ProductTaxClassSelector';
import { useProducts } from '../ProductsListing/ProductsListing';
import SuccessFlag from './SuccessFlag';
import unescapeHtml from '../../utils/unescapeHtml';
import TeamChannelsPoliciesSelector from './TeamChannelsPoliciesSelector';
import normaliseArrOrObj from '../../helpers/formHelper';
import ChannelPricingInputs from './ChannelPricingInputs';
import { getTeamChannelLists } from '../../utils/reducer';
import MerpLogs from '../Logs/MerpLogs';
import formatMoneyWithPrefix from '../../helpers/formatMoney';
import { TeamChannel } from '../ProductDetailsContainer/type';
import ProductIsForSaleSwitch from './ProductIsForSaleSwitch';
import WooCommerceTypeError from './WooCommerceTypeError';
import SupplierInventory from './SupplierInventory';
import ErrorFlag from './ErrorFlag';
import EBayCategoryTypeError from './eBayCategoryTypeError';
import PlanUpgrade from '../Billing/PlanUpgrade';

const EBAY_CHANNEL_ID = process.env.REACT_APP_EBAY_CHANNEL_ID || '1';

const ProductEditByCategoryId = ({ categoryId, productData }: {
  categoryId: string, productData: InitProductType
}) => {
  const filteredTcPs = productData.teamChannelProducts.filter(
    (cur) => cur.teamChannel.active,
  );
  const { dispatch } = useProducts();
  const history = useHistory();
  const cancelEditHandler = () => {
    // Close any product split view
    if (typeof dispatch === 'function') {
      dispatch({
        type: 'setCurrentProductID',
        payload: undefined,
      });
    } else {
      // Redirect to product listing
      history.push('/products');
    }
  };
  const { state: { currentTeamId, teams } } = useAuth();

  const freeExpired = useSubscription();
  const [disable, setDisable] = React.useState<boolean>(false);
  const closeUpgrade = () => {
    setDisable(false);
  };
  const { data: teamChannelsData } = useGetTeamChannelsByTeamIdQuery({
    variables: {
      teamId: currentTeamId!,
    },
    fetchPolicy: 'network-only',
  });

  /**
   * initially give the teamChannels from local state.
   */
  let eBayCheck = false;
  let teamChannels = getTeamChannelLists(
    currentTeamId!,
    teams,
  ) as TeamChannel[];
  const ebayChannelProducts = productData.teamChannelProducts
    .filter((tcP) => (tcP.externalListingId.trim() !== '' && tcP.teamChannel.active && tcP.teamChannel.channelID === EBAY_CHANNEL_ID));

  if (ebayChannelProducts.length !== 0) {
    eBayCheck = true;
  }
  if (teamChannelsData) {
    /**
    * because ebay onboarding moved to async,local state will not known if onboarded sucess
    * we need to fetch it live to override it.
    * channelPricing componenct need teamChannel.active = true and onboarded = true .
    */
    teamChannels = (
      teamChannelsData
        .GetTeamChannelsByTeamId
        .teamChannels as unknown as TeamChannel[]
    );
  }

  const { data, loading } = useQueryEbayCategoryAspectsQuery({
    variables: {
      categoryId,
    },
  });

  const { data: categoryFeatures, loading: loadingFeatures } = useQueryEbayCategoryFeaturesQuery({
    variables: {
      categoryId,
    },
  });

  const { data: wooCommerceProductData } = useQueryProductTypeDefineQuery({
    variables: {
      productType: productData.wooCommerceProductType,
      teamId: currentTeamId as string,
    },
  });

  // hanlde extra fields eg UPC
  const isExtraRequiredFields = !loadingFeatures && categoryFeatures && Array.isArray(categoryFeatures?.QueryEbay.response.fields) && categoryFeatures?.QueryEbay.response.fields.length > 0;

  const convertFeaturesToAspecs = (features: string[]) => {
    if (!features || !isExtraRequiredFields) return [];
    return features.map((f: string) => ({
      localizedAspectName: f,
      aspectConstraint: {
        aspectRequired: true,
        aspectMode: 'FREE_TEXT',
        itemToAspectCardinality: 'SINGLE',
      },
      aspectValues: [{
        localizedValue: 'Does not apply',
      }],
    }));
  };

  const wooCommerceProductTypeDef = useMemo(() => wooCommerceProductData?.QueryProductTypeDefine.response, [wooCommerceProductData]);

  const converWooCommerceRequiredToAspecs = (attributeNames: string[]) => {
    if (!Array.isArray(attributeNames) || attributeNames.length === 0) return [];
    return attributeNames.map((attr: string) => ({
      localizedAspectName: attr,
      aspectConstraint: {
        aspectRequired: true,
        aspectMode: 'FREE_TEXT',
        itemToAspectCardinality: 'SINGLE',
      },
      aspectValues: [{
        localizedValue: 'Does not apply',
      }],
    }));
  };

  const requiredAspects = data && data.QueryEbay.response.aspects !== undefined ? data.QueryEbay.response.aspects
    .filter((aspect: Aspect) => aspect
    && aspect.aspectConstraint && aspect.aspectConstraint.aspectUsage === 'RECOMMENDED' && aspect.aspectConstraint.aspectRequired === true) : [];

  const recommendAspects = data && data.QueryEbay.response.aspects !== undefined ? data.QueryEbay.response.aspects
    .filter((aspect: Aspect) => aspect
      && aspect.aspectConstraint && aspect.aspectConstraint.aspectUsage === 'RECOMMENDED' && aspect.aspectConstraint.aspectRequired === false) : [];

  const optionalAspects = data && data.QueryEbay.response.aspects !== undefined ? data.QueryEbay.response.aspects
    .filter((aspect: Aspect) => !(aspect && aspect.aspectConstraint
      && aspect.aspectConstraint.aspectUsage === 'RECOMMENDED')) : [];

  const {
    control, handleSubmit, errors, setValue, watch,
  } = useForm({});
  const currentInputSOH = watch('stockOnHand', true);
  const currentInputTotalIventory = watch('totalInventoryCost', true);
  const unitCost = (function getUnitCost() {
    if (Number(currentInputSOH) === 0) return 0;
    const costPerItem = (
      Number(currentInputTotalIventory) / Number(currentInputSOH)
    );
    if (isNaN(costPerItem)) return 0;
    return costPerItem * 100;
  }());

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMsg] = useState<string>();

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => setIsOpen(false);

  const [createProduct] = useCreateProductMutation();
  const [updateProduct] = useUpdateProductMutation();

  const getInitialIsMultiplePricing = () => {
    const isAllPriceSame = (filteredTcPs
      .map((tcp) => tcp.unitPrice)
      .every((v) => v === productData.unitPrice));

    const isAllTeamChannelProductsEnabled = filteredTcPs
      .map((tcp) => tcp.active)
      .every((v) => v === true);
    if (isAllPriceSame && isAllTeamChannelProductsEnabled) {
      return false;
    }

    return true;
  };

  const [
    isMultiplePricing,
    setIsMultiplePricing,
  ] = useState(getInitialIsMultiplePricing());
  const getSpecValues = (specs: AspectOption | AspectOneOption
  | AspectString) => Object.keys(specs)
    .map((key) => {
      let specValues: Array<string> = [];
      if (Array.isArray(specs[key])) {
        specValues = (specs[key] as Array<SingleOption | string>).map((op) => (
          (op as SingleOption).value
            ? (op as SingleOption).value : (op as string)
        ));
      } else if (specs[key] && (specs[key] as SingleOption).value) {
        specValues = [(specs[key] as SingleOption).value];
      } else {
        specValues = [specs[key] as string];
      }
      return {
        name: key,
        values: specValues,
      };
    }).filter((mappedSpec) => mappedSpec.values.length > 0);

  const mapPoliciesToSettings = (
    policies: TeamChannelPolicySubmitMap,
  ) => (Object
    .keys(policies).map((tcId) => ({
      teamChannelId: tcId,
      fulfillmentPolicyId: policies[tcId] && policies[tcId].fullfilment
      && policies[tcId].fullfilment.value && policies[tcId].fullfilment.value.trim() !== ''
        ? policies[tcId].fullfilment.value.trim() : undefined,
      paymentPolicyId: policies[tcId] && policies[tcId].payment
    && policies[tcId].payment.value && policies[tcId].payment.value.trim() !== ''
        ? policies[tcId].payment.value.trim() : undefined,
      returnPolicyId: policies[tcId] && policies[tcId].return
    && policies[tcId].return.value && policies[tcId].return.value.trim() !== ''
        ? policies[tcId].return.value.trim() : undefined,
      merchantLocationKey: policies[tcId] && policies[tcId].location
    && policies[tcId].location.value && policies[tcId].location.value.trim() !== ''
        ? policies[tcId].location.value.trim() : undefined,
    })));

  const normalisePolicies = (policies: TeamChannelPolicySubmitMap) => Object
    .keys(policies)
    .reduce((carry, tcId) => ({
      ...carry,
      [tcId]: {
        fullfilment: normaliseArrOrObj(policies[tcId].fullfilment),
        location: normaliseArrOrObj(policies[tcId].location),
        payment: normaliseArrOrObj(policies[tcId].payment),
        return: normaliseArrOrObj(policies[tcId].return),
      },
    }), {});

  /**
   * some helper to handle teamChannelProduct enable or disable.
   */
  const [
    enabledTable,
    setEnabledTable] = useState<{[k:string]:boolean}>((() => {
    const table:{[k:string]:boolean} = {};
    // if update a product
    if (filteredTcPs.length > 0) {
      filteredTcPs.forEach((tcp) => {
        table[tcp.teamChannel.id] = tcp.active;
      });
      return table;
    }
    // if create a new product
    if (teamChannels) {
      teamChannels.forEach((tc) => {
        table[tc.id] = false;
      });
      return table;
    }
    return table;
  })());
  const handleChannelEnableStatus = (
    teamChannelId:string,
    enabledStatus:boolean,
  ) => {
    setEnabledTable({
      ...enabledTable,
      [teamChannelId]: enabledStatus,
    });
  };
  const getMultiplePrices = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submitValues:{[k:string]:any},
  ) => {
    // teamChannelIds were used as name of channel price input
    // teamChannels is taken from upper scope.
    const possiblePriceInputNames = teamChannels.map((tc) => tc.id);
    const result:Array<{
      teamChannelId:string,
      active:boolean,
      unitPrice:number,
    }> = [];

    possiblePriceInputNames.forEach((teamChannelId) => {
      if (!isUndefined(submitValues[teamChannelId])) {
        /**
         * if channel price is invalid (disabled channel dont have input validation),
         * then use the RRP.
         */

        const unitPrice = parseFloat(submitValues[teamChannelId]) * 100;
        const rrp = parseFloat(submitValues.unitPrice) * 100;

        result.push({
          teamChannelId,
          active: enabledTable[teamChannelId],
          unitPrice: isNaN(unitPrice) ? rrp : unitPrice,
        });
      }
    });
    return result;
  };

  const wooCommerceValidate = () => {
    if (productData.wooCommerceProductType === process.env.REACT_APP_ChannelAttributeSetId) {
      return false;
    }
    return true;
  };

  const eBayValidate = () => {
    if (categoryId === 'NO_EBAY_CATEGORY_ID') {
      return false;
    }
    return true;
  };

  const categoryErrorPopUp = () => {
    if (!eBayValidate()) {
      return <EBayCategoryTypeError isOpen={isOpen} closeModal={closeModal} />;
    }

    if (!wooCommerceValidate()) {
      return <WooCommerceTypeError isOpen={isOpen} closeModal={closeModal} />;
    }

    return null;
  };

  const onSubmit = (submitValues: SubmitProductType) => {
    if (!eBayValidate() || !wooCommerceValidate()) {
      return;
    }

    setSubmitting(true);
    const {
      name,
      sku,
      fullDescription,
      images,
      unitPrice,
      stockOnHand,
      specs,
      productTaxClassSelection,
      policies,
      totalInventoryCost,
      isForSale,
      // isOnEbay,
    } = submitValues;
    const channelPrices = getMultiplePrices(submitValues);

    // Copy short desc the same as name
    const shortDescription = name;

    const teamID = currentTeamId!;
    const attributeSetId = categoryId;

    const parsedUnitPrice = parseFloat(unitPrice) * 100;
    const parsedStockOnHand = parseInt(stockOnHand, 10);
    const parsedTotalInventoryCostValue = parseFloat(totalInventoryCost) * 100;

    const parsedSpecs = getSpecValues(specs);

    const productTaxClassId = normaliseArrOrObj(productTaxClassSelection).value;

    const normalisedPolicies = policies ? normalisePolicies(policies) : {};
    const settings = mapPoliciesToSettings(normalisedPolicies);

    const normalisedImages: string[] = images.map((i) => (i.url ? i.url : '')).filter((url) => url !== '');

    client.query({
      query: QueryCategorySubtreeDocument,
      variables: {
        categoryId,
      },
    }).then((resp) => {
      const attributeSetName = resp.data.QueryEbay.response
        .categorySubtreeNode.category.categoryName;
      const product = {
        teamID,
        attributeSetId,
        attributeSetName,
        name,
        sku,
        shortDescription,
        fullDescription,
        isForSale,
        images: normalisedImages,
        prices: [
          {
            teamChannelId: '',
            unitPrice: parsedUnitPrice,
          },
          ...channelPrices,
        ],
        totalInventoryCost: {
          value: parsedTotalInventoryCostValue,
          isTaxIncluded: false,
        },
        stockOnHand: parsedStockOnHand,
        specs: parsedSpecs,
        productTaxClassId,
        settings,
      };
      if (!productData.id) {
        createProduct({ variables: { product } })
          .then(() => {
            setSubmitting(false);
            window.location.assign('/products');
            store.addNotification({
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 3000,
              },
              content: <SuccessFlag title="Product creation success!" />,
            });
          })
          .catch((err) => {
            setSubmitting(false);
            const msg = err?.graphQLErrors[0]?.message || 'Server internal error';
            setErrorMsg(msg);
            store.addNotification({
              id: 'error',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 6000,
                click: false,
                touch: false,
              },
              content: <ErrorFlag title={msg} />,
            });
          });
      } else {
        updateProduct({ variables: { id: productData.id, product } })
          .then(() => {
            setSubmitting(false);
            // history.push('/products');
            store.addNotification({
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 3000,
              },
              content: <SuccessFlag title="Product update success!" />,
            });
          })
          .catch((err) => {
            setSubmitting(false);
            console.log(err.graphQLErrors);
            const msg = err?.graphQLErrors[0]?.message || 'Server internal error';
            setErrorMsg(msg);
            store.addNotification({
              id: 'error',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 6000,
                click: false,
                touch: false,
              },
              content: <ErrorFlag title={msg} />,
            });
          });
      }
    });
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContainer data-testid="listing-details-card-container">
          <TextFieldWithLabelAndError
            testId="name"
            name="name"
            label="Title"
            isRequired
            control={control}
            errors={errors}
            defaultValue={productData.name ? unescapeHtml(productData.name) : ''}
            setValue={setValue}
            helpMessage="Use words people would search for when looking for your item."
            maxLength={80}
            isDisable={freeExpired}
          />
          <TextFieldWithLabelAndError
            testId="sku"
            name="sku"
            label="SKU"
            isRequired
            control={control}
            errors={errors}
            defaultValue={productData.sku ? productData.sku : ''}
            setValue={setValue}
            helpMessage=""
            maxLength={-1}
            isDisable={freeExpired}
          />
        </CardContainer>
        <>
          <h3>Required</h3>
          <AspectContainer>
            <AspectFields
              setValue={setValue}
              control={control}
              aspects={[...requiredAspects, ...convertFeaturesToAspecs(categoryFeatures?.QueryEbay.response.fields), ...converWooCommerceRequiredToAspecs(wooCommerceProductTypeDef?.attributeRequired),
              ]}
              errors={errors}
              defaultValue={productData.aspects}
              isDisable={freeExpired}
            />
          </AspectContainer>
          <h3>Recommended</h3>
          <HelperMessage>
            Buyers frequently search for these details
          </HelperMessage>
          <AspectContainer>
            <AspectFields
              setValue={setValue}
              control={control}
              aspects={recommendAspects}
              errors={errors}
              defaultValue={productData.aspects}
              isDisable={freeExpired}
            />
          </AspectContainer>
          <h3>Additional</h3>
          <HelperMessage>Buyers also search for these details</HelperMessage>
          <AspectContainer>
            <AspectFields
              setValue={setValue}
              control={control}
              aspects={optionalAspects}
              errors={errors}
              defaultValue={productData.aspects}
              isDisable={freeExpired}
            />
          </AspectContainer>
        </>

        <h3>Add photos</h3>
        <HelperMessage>
          Improve your buyer&apos;s confidence by including as many as possible
        </HelperMessage>
        <ImageUploadWithLabelAndError
          name="images"
          label="Additional photos"
          isRequired
          control={control}
          errors={errors}
          defaultValue={productData.images ? productData.images : []}
          setValue={setValue}
          maxLimit={12}
          isDisable={freeExpired}
        />

        <h3>
          Description
          <span aria-hidden="true" className="sc-fzoYkl jXqVjz">*</span>
        </h3>
        <HelperMessage>
          Tell buyers about unique features, flaws, or why are you selling it!
        </HelperMessage>
        <RTEWithLabelAndError
          name="fullDescription"
          label="Description"
          isRequired
          control={control}
          errors={errors}
          defaultValue={productData.fullDescription ? productData.fullDescription : ''}
          setValue={setValue}
          isDisable={freeExpired}
        />
        <h3>Inventory</h3>
        <CardContainer>
          <TextFieldWithLabelAndError
            testId="stockOnHand"
            name="stockOnHand"
            label="Stock on hand"
            isRequired
            control={control}
            errors={errors}
            defaultValue={productData.stockOnHand ? productData.stockOnHand : 0}
            setValue={setValue}
            helpMessage=""
            maxLength={-1}
            type="number"
            minVal={0}
            isDisable={freeExpired}
          />
          <TextFieldWithLabelAndError
            testId="totalInventoryCost"
            name="totalInventoryCost"
            label="Total inventory cost (AUD Excl.)"
            isRequired
            control={control}
            errors={errors}
            defaultValue={
              productData.totalInventoryCost
                ? productData.totalInventoryCost / 100
                : 0
              }
            setValue={setValue}
            helpMessage=""
            maxLength={-1}
            type="number"
            minVal={0}
            isDisable={freeExpired}
          />
          <div>
            Unit cost (AUD Excl.):
            <span style={{ fontWeight: 'bold' }}>{formatMoneyWithPrefix(unitCost)}</span>
          </div>
        </CardContainer>

        <h3>Supplier Inventory</h3>
        <SupplierInventory sku={productData.sku} />

        <h3>Tax Settings</h3>
        <CardContainer>
          { currentTeamId && (
          <ProductTaxClassSelector
            teamId={currentTeamId}
            control={control}
            errors={errors}
            setValue={setValue}
            defaultValue={productData.productTaxClass
              ? productData.productTaxClass : undefined}
            isDisable={freeExpired}
          />
          )}
        </CardContainer>
        {currentTeamId && eBayCheck && (
          <>
            <h3>Policies</h3>
            <CardContainer>
              <TeamChannelsPoliciesSelector
                teamId={currentTeamId}
                teamChannelProducts={filteredTcPs}
                control={control}
                errors={errors}
                setValue={setValue}
                isDisable={freeExpired}
              />
            </CardContainer>
          </>
        )}

        <PageMargin />

        {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
        <FloatStickyFooter>
          <div className="heading">
            <h3>Multiple Pricing</h3>
            <ToggleStateless
              id="multiplePricing"
              onChange={() => setIsMultiplePricing(!isMultiplePricing)}
              isChecked={isMultiplePricing}
              size="regular"
              isDisabled={freeExpired}
            />
            <ProductIsForSaleSwitch
              testId="isForSale"
              name="isForSale"
              control={control}
              defaultValue={productData.isForSale || false}
              setValue={setValue}
              isDisable={freeExpired}
            />
          </div>
          <div className="pricesGroup">
            <TextFieldWithLabelAndError
              testId="unitPrice"
              name="unitPrice"
              label={<Label>RRP (AUD Inc.)</Label>}
              isRequired
              control={control}
              errors={errors}
              defaultValue={productData.unitPrice ? productData.unitPrice / 100 : ''}
              setValue={setValue}
              helpMessage=""
              maxLength={-1}
              type="number"
              minVal={0.01}
              isDisable={freeExpired}
            />
            <ChannelPricingInputs
              teamChannelProducts={filteredTcPs}
              teamChannels={teamChannels}
              enabled={isMultiplePricing}
              control={control}
              errors={errors}
              setValue={setValue}
              enabledTable={enabledTable}
              handleChannelEnableStatus={handleChannelEnableStatus}
            />
          </div>
          <div className="buttons">
            <Button
              type="submit"
              appearance="primary"
              testId="submitBtn"
              isLoading={submitting}
              isDisabled={Object.keys(errors).length > 0}
              onClick={() => (freeExpired === true ? setDisable(true) : openModal())}
            >
              {productData.id ? 'Revise' : 'List it'}
            </Button>
            <Button
              type="cancel"
              appearance="link"
              testId="cancelBtn"
              onClick={cancelEditHandler}
            >
              Cancel
            </Button>
          </div>
        </FloatStickyFooter>
      </form>
      {productData.id
        && (
        <CardContainer>
          <MerpLogs
            teamId={currentTeamId!}
            tableId={productData.id!}
            title="Activity"
          />
        </CardContainer>
        )}
      {categoryErrorPopUp()}
      <PlanUpgrade isOpen={disable} close={closeUpgrade} />
    </>
  );
};

export default ProductEditByCategoryId;
