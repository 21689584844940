import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { G300 } from '@atlaskit/theme/colors';
import Flag from '@atlaskit/flag';
import { useHistory } from 'react-router-dom';
import Logo from '../Logo';
import Box from '../Box';
import {
  Wrapper,
  FormHeader,
  ButtonContainer,
  ErrorMsg,
  MessageWrapper,
} from './ResendSignupActivation.styles';

import { useResendSignupActivationMutation } from '../../graphql/types';

interface ResendSignupActivationForm {
  email: string;
}

export default function ResendSignupActivation(props: {email: string}) {
  const { email } = props;
  const {
    control, handleSubmit, errors, getValues,
  } = useForm();
  const [showSpinner, setSpinner] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMsg] = useState<string>();
  const history = useHistory();
  const [resendSignupActivation] = useResendSignupActivationMutation();

  const onSubmit = async (data: { resendEmail: string }) => {
    const { resendEmail } = data;
    setSpinner(true);

    resendSignupActivation({ variables: { userEmail: email, resendEmail } })
      .then(() => {
        setSpinner(false);
        setShowMessage(true);
        setTimeout(
          () => { setShowMessage(false); history.push('/signin'); },
          3000,
        );
      })
      .catch((err) => {
        const msg = err.graphQLErrors[0]?.message || 'server internal error';
        setErrorMsg(msg);
        setSpinner(false);
      });
  };

  return (
    <Wrapper>
      <Logo />
      <Box style={{ padding: '30px 40px 30px 40px' }}>
        <FormHeader>Resend confirmation email</FormHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            as={Textfield}
            name="resendEmail"
            placeholder="Enter your activation email"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Entered value does not match email format',
              },
            }}
            defaultValue={email}
            testId="signup-resend-email"
          />
          {errors.resendEmail && <ErrorMsg>{errors.resendEmail.message || 'Email required'}</ErrorMsg>}
          {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
          <ButtonContainer>
            <Button
              data-testid="submit-button"
              type="submit"
              appearance="primary"
              iconAfter={showSpinner ? <Spinner appearance="invert" /> : <></>}
              shouldFitContainer
              testId="resend-submit-btn"
            >
              Submit
            </Button>
          </ButtonContainer>
        </form>
      </Box>
      {
        showMessage && (
          <MessageWrapper>
            <Flag
              icon={<SuccessIcon primaryColor={G300} label="Info" />}
              title="Sent successfully"
              description={`Please check your email (${getValues('resendEmail')})`}
              id="1"
              key="1"
              testId="activation-resend-success-message"
            />
          </MessageWrapper>
        )
      }
    </Wrapper>
  );
}
