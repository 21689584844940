import React, { useState, useCallback } from 'react';
import Button, { ButtonAppearances } from '@atlaskit/button';
import OrderCreationModal from './OrderCreationModal';
import OrderCreationProvider from './OrderCreationProvider';
import { Order } from '../OrdersListing/types';
import { useGetTeamChannelsByTeamIdQuery, GetTeamChannelsByTeamIdQuery } from '../../graphql/types';
import { useAuth, useSubscription } from '../../utils/useAuth';
import PlanUpgrade from '../Billing/PlanUpgrade';

type TeamChannel =GetTeamChannelsByTeamIdQuery['GetTeamChannelsByTeamId']['teamChannels'][number];

interface Props {
  // eslint-disable-next-line react/require-default-props
  order?:Order,
  // eslint-disable-next-line react/require-default-props
  text?:string,
  // eslint-disable-next-line react/require-default-props
  icon?:React.ReactElement,
  // eslint-disable-next-line react/require-default-props
  appearance?:ButtonAppearances,
}

export default ({
  order,
  text = 'New Order',
  icon,
  appearance = 'primary',
}:Props) => {
  const { state: { currentTeamId: teamID } } = useAuth();

  const { data } = useGetTeamChannelsByTeamIdQuery({
    variables: {
      teamId: teamID || '',
    },
  });

  const freeExpired = useSubscription();
  const [disable, setDisable] = React.useState<boolean>(false);
  let defaultInStoreTeamChannel:TeamChannel|undefined;

  try {
    defaultInStoreTeamChannel = data
    ?.GetTeamChannelsByTeamId
    .teamChannels
    ?.filter((tc) => tc.channel.name === 'in-store')[0];
  } catch (error) {
    defaultInStoreTeamChannel = undefined;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [zoom, setZoom] = useState(false);
  const close = useCallback(() => {
    setIsOpen(false);
    setZoom(false);
  }, []);
  const open = () => setIsOpen(true);
  const closeUpgrade = () => {
    setDisable(false);
  };
  const modalHeading = 'Create order';
  const onModalClose = () => close();

  const zoomIt = useCallback(
    () => setZoom(true),
    [setZoom],
  );

  // to avoid use too many props. make code simpler
  // and to avoid use provider
  React.useEffect(() => {
    document.addEventListener('closeNewOrderModal', close);
    return () => {
      document.removeEventListener('closeNewOrderModal', close);
    };
  }, [close]);

  React.useEffect(() => {
    document.addEventListener('zoomOrderCreateView', zoomIt);
    return () => {
      document.removeEventListener('zoomOrderCreateView', zoomIt);
    };
  }, [zoomIt]);

  return (
    <>
      <Button
        appearance={freeExpired === true ? 'default' : appearance}
        onClick={() => (freeExpired === true ? setDisable(true) : open())}
        iconBefore={icon}
      >
        {text}
      </Button>

      <OrderCreationModal
        isOpen={isOpen}
        heading={modalHeading}
        onClose={onModalClose}
        width={zoom ? 1240 : 400}
      >
        <OrderCreationProvider
          order={order}
          preSelectedTeamChannel={defaultInStoreTeamChannel}
        />
      </OrderCreationModal>
      <PlanUpgrade isOpen={disable} close={closeUpgrade} />
    </>

  );
};
