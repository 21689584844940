import React from 'react';
import { isUndefined, get } from 'lodash';
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active';
import moment from 'moment';
import Button from '@atlaskit/button';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';
import UnlinkIcon from '@atlaskit/icon/glyph/unlink';
import { useHistory } from 'react-router-dom';
import { Table } from './styles';
import { GetUsersByTeamQuery, User } from '../../graphql/types';
import { useAuth, useSubscription } from '../../utils/useAuth';
import MerpAvatar from '../MerpAvatar/MerpAvatar';

export default (
  data: GetUsersByTeamQuery | undefined,
  handleUnlinkTeam: (userIdToBeUnlink: string)=>void,
) => {
  if (isUndefined(data)
    || isUndefined(data.GetUsersByTeam)
    || isUndefined(data.GetUsersByTeam.users)
  ) return [];

  const { users } = data.GetUsersByTeam;

  return users!.map((user) => ({
    cells: [
      { content: <ShowUserDetails user={user as User} /> },
      { content: user.email },
      { content: <Activated user={user as User} /> },
      { content: moment(user.createdAt).fromNow() },
      { content: moment(user.updatedAt).fromNow() },
      {
        content: <Unlink
          user={user as User}
          handleUnlinkTeam={handleUnlinkTeam}
        />,
      },
    ],
    key: user.id,
  }));
};

const ResendInvitation = (
  { user }:{user:User},
) => {
  const history = useHistory();
  const freeExpired = useSubscription();

  return (
    <Button
      appearance="link"
      spacing="none"
      onClick={() => (history.push(`users/user-invite?email=${encodeURIComponent(user.email)}`))}
      isDisabled={freeExpired}
    >
      Resend invitation
    </Button>
  );
};

const Activated = ({ user }: { user:User}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div>
      <PresenceActiveIcon label="status" primaryColor={user.activated ? 'green' : 'red'} />
    </div>
    <div>
      {user.activated
        && 'Active'}
      {!user.activated
        && <ResendInvitation user={user} />}
    </div>
  </div>
);

const ShowUserDetails = ({ user }:{user:User}) => {
  const [isOpen, setIsopen] = React.useState(false);
  const actions = [
    { text: 'Close', onClick: () => (setIsopen(false)) },
  ];
  return (
    <>
      <Button
        appearance="link"
        onClick={() => (setIsopen(true))}
        iconBefore={(
          <MerpAvatar
            firstName={user.firstName}
            lastName={user.lastName}
          />
      )}
      >
        {`${user.firstName} ${user.lastName}`}
      </Button>
      <ModalTransition>
        {isOpen
          && (
          <ModalDialog actions={actions} heading="User Details" onClose={() => setIsopen(false)}>
            <Table>
              <tbody>
                <tr>
                  <td>First Name:</td>
                  <td>{user.firstName}</td>
                </tr>
                <tr>
                  <td>Last Name:</td>
                  <td>{user.lastName}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <td>Activated:</td>
                  <td><PresenceActiveIcon label="status" primaryColor={user.activated ? 'green' : 'red'} /></td>
                </tr>
                <tr>
                  <td>Date Created:</td>
                  <td>{moment(user.createdAt).format('DD MMM YYYY [at] LT')}</td>
                </tr>
                <tr>
                  <td>Date Updated:</td>
                  <td>{moment(user.updatedAt).format('DD MMM YYYY [at] LT')}</td>
                </tr>
              </tbody>
            </Table>
          </ModalDialog>
          )}
      </ModalTransition>
    </>
  );
};

const Unlink = (
  {
    user,
    handleUnlinkTeam,
  }
  : {
    user: User,
    handleUnlinkTeam: (userIdToBeUnlink:string)=>void
  },
) => {
  const { state: { user: currentUser } } = useAuth();
  const currentUserId = get(currentUser, 'id', '');
  const userId = user.id;
  const [isOpen, setIsopen] = React.useState(false);
  const handleConfirm = () => {
    setIsopen(false);
    handleUnlinkTeam(userId);
  };
  const actions = [
    { text: 'Unlink', onClick: handleConfirm, testId: 'unlinkButton' },
    { text: 'Cancel', onClick: () => (setIsopen(false)) },
  ];
  const freeExpired = useSubscription();
  return (
    <div style={{ display: 'flex', alignContent: 'center' }}>
      <Button
        isDisabled={(userId === currentUserId) || freeExpired}
        appearance="link"
        onClick={() => (setIsopen(true))}
        iconBefore={<UnlinkIcon label="unlink" primaryColor="red" />}
      >
        Unlink
      </Button>

      <ModalTransition>
        {isOpen
          && (
          <ModalDialog
            appearance="danger"
            actions={actions}
            heading="Unlink a user"
            onClose={() => setIsopen(false)}
          >
            <p>
              You are removing
              {' '}
              <b>
                {user.firstName}
                {' '}
                {user.lastName}
              </b>
              {' '}
              from the team, the action is
              {' '}
              <b> NOT invertible</b>
              .
            </p>
            <p>
              Do you want to continue?
            </p>

          </ModalDialog>
          )}
      </ModalTransition>
    </div>
  );
};
