import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { gridSize } from '@atlaskit/theme';
import {
  Content,
  PageLayout,
  TopNavigation,
  RightSidebar,
} from '@atlaskit/page-layout';

import queryString from 'query-string';
import { useAuth } from '../utils/useAuth';
import { getQuickstartCancelled, setQuickstartCancelled } from '../utils/storages';
import Nav from './Nav';
import QuickStarter from './QuickStarter';

const Padding = styled.div`
  margin: ${gridSize() * 3}px;
  padding-bottom: ${gridSize() * 3}px;
  width:100%;
  @media only screen and (max-width: 600px) {
    margin: ${gridSize() * 1}px;
  }
`;

type Props = {
  children: ReactNode,
};

export default function ({ children }: Props) {
  const { state: { user } } = useAuth();
  const requireSignIn = () => {
    const { pathname } = window.location;
    return !user
      && !pathname.includes('/signin')
      && !pathname.includes('/signup')
      && !pathname.includes('/invitation')
      && !pathname.includes('/forgot-password');
  };
  const requirePath = () => {
    if (window.location.search === '?shopify_connect=true&create_new_user=true') {
      return `/signin/${window.location.search}`;
    }

    if (window.location.search.indexOf('pendingId') > -1) {
      return `/signin/${window.location.search}`;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { xero_auth } = queryString.parse(window.location.search);
    if (xero_auth === 'true') {
      return `/xeroLogin${window.location.search}`;
    }

    return '/signin';
  };
  const [showQuickstart, setShowQuickstart] = useState(
    !getQuickstartCancelled(),
  );
  const onCancel = () => {
    setQuickstartCancelled(true);
    setShowQuickstart(false);
  };

  return (
    <div style={{ height: '100%' }}>
      <PageLayout testId="pageLayout">
        { requireSignIn() && <Redirect push to={requirePath()} />}
        <TopNavigation isFixed id="navigation">
          { user && <Nav userId={user.id} /> }
        </TopNavigation>
        <Content testId="content">
          <Padding>
            { children }
          </Padding>
          {
            user && showQuickstart && (
              <RightSidebar
                testId="rightPanel"
                id="help-panel"
                skipLinkTitle="Help Panel"
                width={350}
              >
                <QuickStarter onCancel={onCancel} />
              </RightSidebar>
            )
          }
        </Content>
      </PageLayout>
    </div>
  );
}
