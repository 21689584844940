import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, GridColumn } from '@atlaskit/page';
import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import validator from 'validator';
import ErrorSection from '../ErrorSection/ErrorSection';
import { useCreateTeamCustomerMutation } from '../../graphql/types';
import { TeamCustomer, TeamChannel } from '../CreateOrder/types';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';

/**
 * a fast and minimum version of customer creator ui designed to be used
 * as a small viewpoint.
 *
 * may NOT suitable for a standard along page
 */

const Footer = styled.div`
    margin-top:20px;
    display:flex;
    justify-content:flex-end;
    >*{
      margin-left:10px;
    }
`;

const CreateCustomerMinContainer = styled.div`
  h5 {
    margin-top:10px;
  }
`;

export interface FormData {
  name: string,
  email: string,
  bStreet:string,
  bSuburb:string,
  bState:string,
  bPostcode:string,
  bCountry:string,
  sStreet:string,
  sSuburb:string,
  sState:string,
  sPostcode:string,
  sCountry:string,
}

interface Props {
  teamChannel:TeamChannel,
  onSave:(teamCustomer:TeamCustomer | undefined) => void,
  onCancel:()=>void,
}

export default ({ onSave, onCancel, teamChannel }:Props) => {
  const [
    createTeamCustomerMutation,
    { loading, error },
  ] = useCreateTeamCustomerMutation();
  const [hideShipping, setHideShipping] = useState(true);
  const [hideOptionButton, sethideOptionButton] = useState(false);

  const handleAddDeliveryAddress = () => {
    setHideShipping(false);
    sethideOptionButton(true);
  };

  const handleCancel = () => {
    onCancel();
  };

  const renderBasicInfo = (submiting:boolean) => (
    <>
      <GridColumn medium={12}>
        <Field
          name="name"
          label="Contact"
          isRequired
          isDisabled={submiting}
        >
          {({ fieldProps }) => <TextField isCompact {...fieldProps} />}
        </Field>
      </GridColumn>
      <GridColumn medium={12}>
        <Field
          name="email"
          label="Email"
          isRequired
          isDisabled={submiting}
        >
          {({ fieldProps }) => <TextField type="email" isCompact {...fieldProps} />}
        </Field>
      </GridColumn>
    </>
  );

  const renderBillingAddress = (submiting:boolean) => (
    <>
      <GridColumn medium={12}>
        <Field
          name="bStreet"
          label="Billing Address"
          isDisabled={submiting}
        >
          {({ fieldProps }) => (
            <TextField
              isCompact
              placeholder="Line 1"
              {...fieldProps}
            />
          )}
        </Field>
      </GridColumn>
      <GridColumn medium={12}>
        <Field
          name="bSuburb"
          isDisabled={submiting}
        >
          {({ fieldProps }) => (
            <TextField
              testId="bSuburb"
              isCompact
              placeholder="Suburb"
              {...fieldProps}
            />
          )}
        </Field>
      </GridColumn>
      <GridColumn medium={6}>
        <Field
          name="bState"
          isDisabled={submiting}
        >
          {({ fieldProps }) => (
            <TextField
              testId="bState"
              isCompact
              placeholder="State"
              {...fieldProps}
            />
          )}
        </Field>
      </GridColumn>
      <GridColumn medium={6}>
        <Field
          name="bPostcode"
          isDisabled={submiting}
        >
          {({ fieldProps }) => (
            <TextField
              testId="bPostcode"
              isCompact
              placeholder="Postcode"
              {...fieldProps}
            />
          )}
        </Field>
      </GridColumn>
      <GridColumn medium={12}>
        <Field
          name="bCountry"
          isDisabled={submiting}
        >
          {({ fieldProps }) => (
            <TextField
              testId="bCountry"
              isCompact
              placeholder="Country"
              {...fieldProps}
            />
          )}
        </Field>
      </GridColumn>
    </>
  );

  const renderShippingAddress = (submiting:boolean) => (
    <>
      <GridColumn medium={12}>
        <Field
          name="sStreet"
          label="Shipping Address"
          isDisabled={submiting}
        >
          {({ fieldProps }) => (
            <TextField
              isCompact
              placeholder="Line 1"
              {...fieldProps}
            />
          )}
        </Field>
      </GridColumn>
      <GridColumn medium={12}>
        <Field
          name="sSuburb"
          isDisabled={submiting}
        >
          {({ fieldProps }) => (
            <TextField
              testId="sSuburb"
              isCompact
              placeholder="Suburb"
              {...fieldProps}
            />
          )}
        </Field>
      </GridColumn>
      <GridColumn medium={6}>
        <Field
          name="sState"
          isDisabled={submiting}
        >
          {({ fieldProps }) => (
            <TextField
              testId="sState"
              isCompact
              placeholder="State"
              {...fieldProps}
            />
          )}
        </Field>
      </GridColumn>
      <GridColumn medium={6}>
        <Field
          name="sPostcode"
          isDisabled={submiting}
        >
          {({ fieldProps }) => (
            <TextField
              testId="sPostcode"
              isCompact
              placeholder="Postcode"
              {...fieldProps}
            />
          )}
        </Field>
      </GridColumn>
      <GridColumn medium={12}>
        <Field
          name="sCountry"
          isDisabled={submiting}
        >
          {({ fieldProps }) => (
            <TextField
              testId="sCountry"
              isCompact
              placeholder="Country"
              {...fieldProps}
            />
          )}
        </Field>
      </GridColumn>
    </>
  );

  const onSubmit = async (data: FormData) => {
    try {
      const res = await createTeamCustomerMutation({
        variables: {
          teamChannelId: teamChannel.id,
          teamCustomer: {
            name: data.name,
            email: data.email || '',
            shippingAddress: {
              name: data.name,
              street: data.sStreet,
              suburb: data.sSuburb,
              state: data.sState,
              country: data.sCountry,
              postcode: data.sPostcode,
            },
            billingAddress: {
              name: data.name,
              street: data?.bStreet,
              suburb: data?.bSuburb,
              state: data?.bState,
              country: data?.bCountry,
              postcode: data?.bPostcode,
            },
          },
        },
      });

      const teamCustomer = res.data?.CreateTeamCustomer?.teamCustomer;
      let pass = 0;
      if (data.bPostcode != null) {
        if (!validator.isPostalCode(data.bPostcode, 'any')) {
          pass = 1;
        }
      }
      if (data.sPostcode != null) {
        if (!validator.isPostalCode(data.sPostcode, 'any')) {
          pass = 1;
        }
      }
      if (pass !== 1) {
        onSave(teamCustomer);
      }
    } catch (err) {
      // leave blank intentional
      // upper scope hook is able to handle the error.
    }
  };

  return (
    <CreateCustomerMinContainer>
      {error
      && (
      <ErrorSection>
        {graphQLErrorsReader(error!)}
      </ErrorSection>
      )}

      <h5>Add a customer</h5>
      <Form<FormData>
        onSubmit={onSubmit}
      >
        {({
          formProps, submitting, getValues, setFieldValue,
        }) => (
          <form {...formProps}>
            <Grid spacing="compact">
              {renderBasicInfo(submitting)}
              {renderBillingAddress(submitting)}
              {!hideOptionButton && (
              <Button
                shouldFitContainer
                appearance="subtle-link"
                onClick={handleAddDeliveryAddress}
              >
                + Add Delivery Address
                {' '}
              </Button>
              )}

              {hideOptionButton && (
                <GridColumn medium={12}>
                  <Checkbox
                    value="sameAsBilling"
                    label="Same as billing"
                    onChange={() => {
                      const data = getValues();
                      setFieldValue('sStreet', data.bStreet);
                      setFieldValue('sSuburb', data.bSuburb);
                      setFieldValue('sState', data.bState);
                      setFieldValue('sPostcode', data.bPostcode);
                      setFieldValue('sCountry', data.bCountry);
                    }}
                    name="sameAsBilling"
                    testId="sameAsBilling"
                  />
                </GridColumn>
              )}
              {!hideShipping && renderShippingAddress(submitting)}
            </Grid>
            <Grid>
              <GridColumn medium={12}>
                <Footer>
                  <Button
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    isDisabled={loading}
                    isLoading={loading}
                    appearance="primary"
                  >
                    Save
                  </Button>
                </Footer>
              </GridColumn>
            </Grid>
          </form>
        )}
      </Form>

    </CreateCustomerMinContainer>
  );
};
