import React from 'react';
import { store } from 'react-notifications-component';
import Button from '@atlaskit/button';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import { usePushProductsToTeamChannelMutation } from '../../graphql/types';
import addNotification from '../../utils/addNotification';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import SuccessFlag from '../ProductEdit/SuccessFlag';

const PushProductsToTeamChannelBtn = ({
  children,
  teamChannelId,
  teamChannelName,
}: {
  children: string;
  teamChannelId: string;
  teamChannelName: string;
}) => {
  const [
    pushProductsToTeamChannel,
    pushProductsToTeamChannelState,
  ] = usePushProductsToTeamChannelMutation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isConfirmWordEntered, setIsConfirmWordEntered] = React.useState(false);
  const handleCleanTeamChannel = async () => {
    await pushProductsToTeamChannel({
      variables: {
        teamChannelId,
      },
    })
      .then(() => {
        store.addNotification({
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
          },
          content: <SuccessFlag title={`Pushing products to ${teamChannelName}`} />,
        });
        setIsOpen(false);
      })
      .catch((err) => {
        addNotification(graphQLErrorsReader(err), 'danger');
      });
  };

  const actions = [
    {
      text: 'Confirm',
      isDisabled: isConfirmWordEntered === false,
      onClick: handleCleanTeamChannel,
      isLoading: pushProductsToTeamChannelState.loading,
    },
    {
      text: 'Cancel',
      onClick: () => setIsOpen(false),
    },
  ];

  const channelNameInputHandler = (evt: React.FormEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    if (value.trim().toLowerCase() === teamChannelName.toLowerCase()) {
      setIsConfirmWordEntered(true);
    } else {
      setIsConfirmWordEntered(false);
    }
  };

  return (
    <>
      {isOpen}
      <ModalTransition>
        {isOpen && (
          <Modal
            actions={actions}
            onClose={() => setIsOpen(false)}
            heading="Confirm to push products?"
            width="medium"
            shouldCloseOnEscapePress={false}
            shouldCloseOnOverlayClick={false}
            appearance="danger"
          >
            <p>
              {/* eslint-disable-next-line max-len */}
              This action cannot be undone. It would push all products to this marketplace. Please enter the word
              {' "'}
              {teamChannelName}
              {'" '}
              to continue
            </p>
            <div>
              <Textfield
                testId="pushProductChannelName"
                placeholder={`Enter your market place name "${teamChannelName}"`}
                onChange={channelNameInputHandler}
              />
            </div>
          </Modal>
        )}
      </ModalTransition>
      <Button appearance="danger" onClick={() => setIsOpen(true)}>
        {children}
      </Button>
    </>
  );
};

export default PushProductsToTeamChannelBtn;
