export default {
  cells: [
    {
      key: 'fullName',
      content: 'Full Name',
      testId: 'fullName',
    },
    {
      key: 'email',
      content: 'Email',
      testId: 'email',
    },
    {
      key: 'activated',
      content: 'Activated',
      testId: 'activated',
    },
    {
      key: 'createdAt',
      content: 'Created Date',
      testId: 'createdAt',
    },
    {
      key: 'updatedAt',
      content: 'Updated Date',
      testId: 'updatedAt',
    },
    {
      key: 'action',
      content: 'Action',
      testId: 'action',
    },
  ],
  testId: 'head',
};
