import React, { useState } from 'react';
import Button from '@atlaskit/button';
import axios from 'axios';
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active';
import Modal, { ModalTransition, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import { useHistory } from 'react-router-dom';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import xeroConnectButton from '../../assets/svg/xeroConnectButton.svg';
import xeroDisconnectButton from '../../assets/svg/xeroDisconnectButton.svg';
import { Card } from '../TeamChannelListing/TeamChannelListing.style';
import { useDisconnectXeroMutation, useGetTeamByIdQuery } from '../../graphql/types';
import { useAuth, useSubscription } from '../../utils/useAuth';
import addNotification from '../../utils/addNotification';
import SetXeroAccountCode from './SetXeroAccountCode';

const REACT_APP_GRAPHQL_SERVER_HOST = process.env.REACT_APP_GRAPHQL_SERVER_HOST
  || 'http://localhost:4000';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const XeroConnector = (isXeroConnect: any) => {
  const { state: { currentTeamId } } = useAuth();
  const freeExpired = useSubscription();
  const { data } = useGetTeamByIdQuery({
    variables: { teamId: currentTeamId || '' },
    // to avoid still show cached data after update when switching team
    fetchPolicy: 'network-only',
  });
  const [disconnectXero] = useDisconnectXeroMutation();
  // const history = useHistory();
  const xeroState = {
    teamId: currentTeamId,
    redirectUrl: '/',
  };
  const history = useHistory();
  const showXeroConnectionPopUp = isXeroConnect.isXeroConnect;
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
    history.push('/marketplace');
    window.location.reload();
  };
  const open = () => {
    setIsOpen(true);
  };
  const [isOpenXero, setIsOpenXero] = useState(
    showXeroConnectionPopUp !== null && showXeroConnectionPopUp !== undefined,
  );

  const close = () => {
    setIsOpenXero(false);
    setIsOpen(true);
    if (showXeroConnectionPopUp !== 'true') {
      history.push('/marketplace');
      window.location.reload();
    }
  };
  const xeroConnection = data?.GetTeamById?.team?.xeroConnected;
  const tenantName = data?.GetTeamById?.team?.tenantName;

  const xeroMessage = showXeroConnectionPopUp === 'true' ? 'You have successfully connect with your Xero Account Code. Please set your Xero account code after close this window. Or you can set the code later at tany time from edit button on the Xero card in the market place. Otherwise, Sync order function can not work properly.' : 'Connect failed, please try again';
  const xeroHeader = showXeroConnectionPopUp === 'true' ? 'Successfully' : 'Fail';
  const xeroConnect = async () => {
    const res = await axios.get(
      `${REACT_APP_GRAPHQL_SERVER_HOST}/oauth/xero/url?state=${JSON.stringify(xeroState)}`,
    );
    const { url } = res.data;
    if (url) {
      window.location.assign(url);
    }
  };

  const xeroDisconnect = async () => {
    // const teamId = state?.currentTeamId;
    const xeroVariables = {
      teamId: currentTeamId!,
    };
    await disconnectXero({
      variables: xeroVariables,
    }).then(() => {
      history.push('/marketplace');
      window.location.reload();
      addNotification('Xero disconnect success', 'success');
    })
      .catch(() => {
        addNotification('Xero disconnect failed', 'danger');
      });
  };

  return (
    <>
      {xeroConnection && (
      <Card>
        <div className="xero">
          <div className="tenantName">
            <b>Tenant Name:</b>
            {' '}
            {tenantName}
          </div>
          <div className="xeroEdit">
            <Button
              appearance="link"
              onClick={open}
              isDisabled={freeExpired}
            >
              <EditFilledIcon label="edit" />
            </Button>
          </div>
          <SetXeroAccountCode isOpen={isOpen} close={closeModal} teamId={currentTeamId} />
          <Button
            appearance="link"
            style={{
              height: '50px', width: '270px',
            }}
            iconAfter={<img src={xeroDisconnectButton} alt="in-store" data-testid="xeroDisconnet" height="50px" width="250px" />}
            onClick={xeroDisconnect}
            testId="connectXeroButton"
            isDisabled={freeExpired}
          />
          <div className="state-item-name" style={{ marginTop: 15 }}>Connected</div>
          <PresenceActiveIcon label="status" primaryColor="green" />
        </div>
      </Card>
      )}

      {!xeroConnection && (
        <Card>
          <div className="xero">
            <Button
              appearance="link"
              style={{
                height: '50px', width: '270px',
              }}
              iconAfter={<img src={xeroConnectButton} alt="in-store" data-testid="synnex" height="50px" width="250px" />}
              onClick={xeroConnect}
              testId="connectXeroButton"
              isDisabled={freeExpired}
            />
            <div className="state-item-name" style={{ marginTop: 30 }}>Disconnected</div>
            <PresenceActiveIcon label="status" primaryColor="grey" />
          </div>
        </Card>
      )}

      <ModalTransition>
        {isOpenXero && (
        <Modal
          actions={[{ text: 'Close', onClick: close }]}
        >
          <ModalHeader style={{ paddingLeft: 0 }}>
            <ModalTitle>
              Xero Connect
              {' '}
              {xeroHeader}
            </ModalTitle>
          </ModalHeader>
          <p>{xeroMessage}</p>
        </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default XeroConnector;
