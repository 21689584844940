import React from 'react';
import { useParams } from 'react-router-dom';
import ResendSignupActivation from '../components/ResendSignupActivation/ResendSignupActivation';

export default function ResendSingnupActivationPage() {
  const { email } = useParams();
  return (
    <ResendSignupActivation email={email} />
  );
}
