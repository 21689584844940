import React, { SyntheticEvent } from 'react';
import Textfield from '@atlaskit/textfield';
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search';
import { useProducts } from '../ProductsListing';

const GlobalSearchBox = () => {
  const { state, dispatch } = useProducts();

  const [searchBarWidth, setSearchBarWidth] = React.useState(105);
  const [searchBarPlaceholder, setSearchBarPlaceholder] = React.useState('Search');
  const expandSearchBar = () => {
    setSearchBarWidth(300);
    setSearchBarPlaceholder('Search item name and sku');
  };
  const shrinkSearchBar = () => {
    setSearchBarWidth(105);
    setSearchBarPlaceholder('Search');
  };
  const searchNow = () => {
    clearTimeout(state.ui.debonced);
    dispatch({
      type: 'setKeyword',
      payload: state.ui.globalSearchBoxInput,
    });
  };

  const onSearchTextChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const inputs = e.currentTarget.value;
    dispatch({
      type: 'setGlobalSearchBoxInput',
      payload: inputs,
    });
    clearTimeout(state.ui.debonced);
    const timmerId = setTimeout(() => {
      dispatch({
        type: 'setKeyword',
        payload: inputs,
      });
    }, 500);
    dispatch({
      type: 'setDebonced',
      payload: timmerId,
    });
  };

  const searchBarOnBlur = () => {
    shrinkSearchBar();
    searchNow();
  };

  const handleUserEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && state.ui.globalSearchBoxInput.trim().length > 0) {
      // triger immediently
      searchNow();
    }
  };

  return (
    <Textfield
      testId="product-global-search-box"
      key="search"
      style={{ width: searchBarWidth }}
      elemBeforeInput={<EditorSearchIcon size="medium" label="search" />}
      isDisabled={state.display.isAdvancedFilterOpen}
      placeholder={searchBarPlaceholder}
      onChange={onSearchTextChange}
      onBlur={searchBarOnBlur}
      onKeyPress={handleUserEnterKey}
      onFocus={expandSearchBar}
      value={state.ui.globalSearchBoxInput}
    />
  );
};

export default GlobalSearchBox;
