import React, { useState, SyntheticEvent } from 'react';
import Select, { OptionType, ValueType } from '@atlaskit/select';
import Button from '@atlaskit/button';
import { isArray } from 'lodash';
import ClickAwayListener from 'react-click-away-listener';
import FilterWrapper from '../styles/FilterWrapper';
import { OrderFiltersProps } from '../types';
import { getTeamChannelLists } from '../../../utils/reducer';
import { useAuth } from '../../../utils/useAuth';
import SearchBox from './SearchBox';
import {
  useTcAutoCompleteLazyQuery,
  useOrderAutoCompleteLazyQuery,
  useProductsAutoCompleteLazyQuery,
  ProductsAutoCompleteQueryVariables,
  ProductFilterKey,
  ProductFilterCondition,
} from '../../../graphql/types';
import ExportReport from '../../ExportReports/ExportReport';
import CreateXeroInvoices from '../../Xero/createXeroInvoices';

export const DATES_DEFAULT = { label: 'All dates', value: '' };
// export const XERO_CONNECTION_DEFAULT = { label: 'Xero Sync Status', value: '' };

export const SEARCH_CONFIG = [
  {
    label: 'Customer name', // optionType - label
    value: 'customerName', // optionType - value, also use this value as key to pass order fetching query
    table: 'customer',
    variables: (v:string) => ({ name: v }), // query variable
  },
  {
    label: 'Email',
    value: 'customerEmail',
    table: 'customer',
    variables: (v: string) => ({ email: v }),
  },
  {
    label: 'Username',
    value: 'username',
    table: 'customer',
    variables: (v: string) => ({ username: v }),
  },
  {
    label: 'Order number',
    value: 'orderNumber',
    table: 'order',
    variables: (v: string) => ({ orderNumber: v }),
  },
  {
    label: 'Item title',
    value: 'productName',
    table: 'product',
    variables: (v: string): Pick<ProductsAutoCompleteQueryVariables, 'filters'> => ({
      filters: [{
        filterKey: ProductFilterKey.Name,
        filterCondition: ProductFilterCondition.Contains,
        filterValue: v,
      }],
    }),
  },
  {
    label: 'SKU',
    value: 'sku',
    table: 'product',
    variables: (v: string) => ({
      filter: {
        filterKey: ProductFilterKey.Sku,
        filterCondition: ProductFilterCondition.Contains,
        filterValue: v,
      },
    }),
  },
];

// advanced search options
export const SEARCH_TYPE = SEARCH_CONFIG.map((cur) => ({
  label: cur.label, value: cur.value,
}));

export default (props: OrderFiltersProps) => {
  const {
    teamID, values, actions,
  } = props;
  const { state: { teams } } = useAuth();
  const teamChannels = getTeamChannelLists(teamID, teams);
  const teamChannelOptions = teamChannels.map((cur) => ({
    label: cur.name,
    value: cur.id,
  }));
  const {
    dateSelectedValue,
    xeroSyncedValue,
    teamChannelInput,
    advancedFilterType,
    advancedFilterInputs,
  } = values;

  const {
    handleDatesChange,
    handleXeroSyncedChange,
    handleTeamChannelSelect,
    handleAdvancedFilterTypeChange,
    handleAdvancedFilterTextChange,
    reset,
  } = actions;

  const [debonced, setDebonced] = useState<number>();
  const [loadCustomers, loadCustomersState] = useTcAutoCompleteLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [loadOrders, loadOrdersState] = useOrderAutoCompleteLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [loadProducts, loadProductsState] = useProductsAutoCompleteLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [autoComplete, setAutoComplete] = useState<string[]>([]);

  const isIdenticalResult = React.useCallback(
    (data: string[]) => {
      if (data.length === 0) return true;
      if (data.length === 1 && data[0] === advancedFilterInputs) return true;
      return false;
    },
    [advancedFilterInputs],
  );

  // process customer related autocomplete when data changes
  React.useEffect(() => {
    const data = loadCustomersState?.data?.TeamCustomers?.teamCustomers;
    if (isArray(data)) {
      if ((advancedFilterType as OptionType)?.value === 'customerName') {
        const newData = data.map((cur) => cur.name)
          .filter((cur) => cur.length !== 0);
        if (!isIdenticalResult(newData)) {
          setAutoComplete(newData);
        }
      }
      if ((advancedFilterType as OptionType)?.value === 'customerEmail') {
        const newData = data.map((cur) => cur.email)
          .filter((cur) => cur.length !== 0);
        if (!isIdenticalResult(newData)) {
          setAutoComplete(newData);
        }
      }
      if ((advancedFilterType as OptionType)?.value === 'username') {
        const newData = data.map((cur) => cur.externalCustomerID)
          .filter((cur) => cur.length !== 0);
        if (!isIdenticalResult(newData)) {
          setAutoComplete(newData);
        }
      }
    }
  }, [
    loadCustomersState.data,
    advancedFilterType,
    isIdenticalResult,
    loadCustomersState,
  ]);

  // process order related autocomplete when data changes
  React.useEffect(() => {
    const data = loadOrdersState?.data?.Orders?.orders;
    if (isArray(data)) {
      if ((advancedFilterType as OptionType)?.value === 'orderNumber') {
        const newData = data.map((cur) => cur.orderNumber)
          .filter((cur) => cur.length !== 0);
        if (!isIdenticalResult(newData)) {
          setAutoComplete(newData);
        }
      }
    }
  }, [
    loadOrdersState.data,
    advancedFilterType,
    isIdenticalResult,
    loadOrdersState,
  ]);

  // process product related autocomplete when data changes
  React.useEffect(() => {
    const data = loadProductsState?.data?.Products?.products;
    if (isArray(data)) {
      if ((advancedFilterType as OptionType)?.value === 'sku') {
        const newData = data.map((cur) => cur.sku)
          .filter((cur) => cur.length !== 0);
        if (!isIdenticalResult(newData)) {
          setAutoComplete(newData);
        }
      }
      if ((advancedFilterType as OptionType)?.value === 'productName') {
        const newData = data.map((cur) => cur.name)
          .filter((cur) => cur.length !== 0);
        if (!isIdenticalResult(newData)) {
          setAutoComplete(newData);
        }
      }
    }
  }, [
    loadProductsState.data,
    advancedFilterType,
    isIdenticalResult,
    loadProductsState,
  ]);

  const autoCompleteHelper = SEARCH_CONFIG.map((cur) => {
    if (cur.table === 'customer') {
      return {
        ...cur,
        query: loadCustomers,
      };
    }
    if (cur.table === 'product') {
      return {
        ...cur,
        query: loadProducts,
      };
    }
    if (cur.table === 'order') {
      return {
        ...cur,
        query: loadOrders,
      };
    }
    return {
      ...cur,
      query: loadCustomers,
    };
  });

  const clearAutoCompleteCache = () => {
    setAutoComplete([]);
    clearTimeout(debonced);
  };

  const handleAutoComplete = (e: SyntheticEvent<HTMLInputElement>) => {
    // get the input value
    const inputs = e.currentTarget.value;
    // update testInput state
    handleAdvancedFilterTextChange(inputs);
    if (inputs.length === 0) {
      clearAutoCompleteCache();
    }
    if (inputs.length > 0) {
      try {
        /**
         * try to perform fetch for autoComplete
         */
        clearTimeout(debonced);
        // find the correct query to use
        const search = autoCompleteHelper
          .find(
            (cur) => cur.value === (advancedFilterType as OptionType)?.value,
          );
        // default query
        const { query, variables } = search!;
        setDebonced(setTimeout(() => {
          query({
            variables: {
              teamID,
              ...variables(inputs),
            },
          });
        }, 500));
      } catch (error) {
        // do nothing
      }
    }
  };

  const searchNow = () => {
    clearTimeout(debonced);
    setAutoComplete([]);
    handleAdvancedFilterTextChange(advancedFilterInputs);
  };

  const handleFilterTypeChangeLocal = (option: ValueType<OptionType>) => {
    handleAdvancedFilterTypeChange(option);
    // reset the autoComplte cache
    clearAutoCompleteCache();
  };

  const handleAutoCompleteItemClick = (val:string) => {
    handleAdvancedFilterTextChange(val);
    clearAutoCompleteCache();
  };

  return (
    <FilterWrapper>
      <div className="dateSelector" data-testid="datesFilter">
        <Select<OptionType>
          options={[
            DATES_DEFAULT,
            { label: 'Last 90 days', value: '90' },
            { label: 'Since today', value: 'today' },
            { label: 'Since yesterday', value: 'yesterday' },
            { label: 'Since this week', value: 'thisWeek' },
            { label: 'Since last week', value: 'lastWeek' },
            { label: 'Since this month', value: 'thisMonth' },
            { label: 'Since last month', value: 'lastMonth' },
          ]}
          value={dateSelectedValue}
          defaultValue={DATES_DEFAULT}
          onChange={handleDatesChange}
        />
      </div>

      <div className="teamChannelsSelector" data-testid="teamChannelsFilter">
        <Select
          options={teamChannelOptions}
          isMulti
          placeholder="All Channels"
          value={teamChannelInput}
          onChange={handleTeamChannelSelect}
        />
      </div>

      <div className="xeroConnectSelector" data-testid="datesFilter">
        <Select<OptionType>
          options={[
            { label: 'Xero Synced', value: 'Synced' },
            { label: 'Not synced to Xero', value: 'Not synced' },
          ]}
          placeholder="Xero Sync Status"
          value={xeroSyncedValue}
          onChange={handleXeroSyncedChange}
        />
      </div>

      <div className="filterTypeSelector" data-testid="filterType">
        <Select<OptionType>
          options={SEARCH_TYPE}
          value={advancedFilterType}
          defaultValue={advancedFilterType}
          onChange={handleFilterTypeChangeLocal}
        />
      </div>
      <div className="productFilter">
        <SearchBox
          value={advancedFilterInputs}
          searchNow={searchNow}
          onSearchTextChange={handleAutoComplete}
          onBlur={searchNow}
          handleUserEnterKey={searchNow}
          placeholder="Keywords"
          width={200}
          testId="advancedSearchBar"
        />
        {autoComplete.length > 0
          && (
          <ul className="autoComplete" data-testid="autoCompleteContainer">
            <ClickAwayListener onClickAway={() => setAutoComplete([])}>
              {autoComplete?.map(
                (cur, index) => (
                  <li // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
                    key={cur + index}
                    onMouseDown={() => handleAutoCompleteItemClick(cur)}
                    data-testid={`${cur}`}
                  >
                    {cur}
                  </li>
                )
              )}
            </ClickAwayListener>
          </ul>
          )}

      </div>

      <div className="reset">
        <Button
          appearance="link"
          onClick={reset}
          testId="resetButton"
        >
          Reset
        </Button>
      </div>

      <div>
        <ExportReport values={values} teamId={teamID} />
      </div>
      <div>
        <CreateXeroInvoices teamId={teamID} />
      </div>

    </FilterWrapper>

  );
};
