import React, { useState } from 'react';
import Button from '@atlaskit/button';
import EmailIcon from '@atlaskit/icon/glyph/email';
import SendInvoiceEmailModal from '../../SendInvoiceEmailModal/SendInvoiceEmailModal';

interface Props {
  orderId:string,
}

export default ({ orderId }:Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => {
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Button
        iconBefore={<EmailIcon label="email" />}
        onClick={open}
      >
        Email
      </Button>
      <SendInvoiceEmailModal isOpen={isOpen} close={close} orderId={orderId} />
    </>
  );
};
