import React, { useState } from 'react';
import Button from '@atlaskit/button';
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import Popup from '@atlaskit/popup';
import { Checkbox } from '@atlaskit/checkbox';
import {
  ColumnsContentContainer,
  CheckboxItem,
} from './teamCustomers.styles';
import {
  ColumnsInputProps,
  ColumnProps,
} from './types';

export default (props: ColumnsInputProps) => {
  const { columns, selectedColumnsChange } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom-start"
      content={() => (
        <ColumnsContentContainer>
          {
            columns.map((col: ColumnProps) => (
              <CheckboxItem key={`checkbox-item-${col.key}`}>
                <Checkbox
                  key={col.key}
                  value={col.key}
                  label={col.label}
                  onChange={(evt) => {
                    selectedColumnsChange({
                      ...col,
                      isChecked: evt.target.checked,
                    });
                  }}
                  name={`checkbox-${col.key}`}
                  isChecked={col.isChecked}
                  testId={`checkbox-${col.key}`}
                />
              </CheckboxItem>
            ))
          }
        </ColumnsContentContainer>
      )}
      trigger={(triggerProps) => (
        <Button
          {...triggerProps}
          isSelected={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          iconAfter={<HipchatChevronDownIcon label="Add" />}
        >
          Columns
        </Button>
      )}
    />
  );
};
