import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import jwtDecode from 'jwt-decode';
// `import { RetryLink } from 'apollo-link-retry';`
import { clientSideLogoutTask } from '../utils/useAuth';
import { getAccessToken, setAccessToken, getStorageUser } from '../utils/storages';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_SERVER_HOST}/graphql`,
  credentials: 'include',
});

// const retryLink = new RetryLink({
//   delay: {
//     initial: 300,
//     max: Infinity,
//     jitter: true,
//   },
//   attempts: {
//     max: 5,
//     retryIf: (error, operation) => (
//       !!error
//       && (operation.operationName === 'QueryFulfilmentPolicies'
//       || operation.operationName === 'QueryPaymentPolicies'
//       || operation.operationName === 'QueryReturnPolicies'
//       || operation.operationName === 'findOrCreateLocation'
//       )
//     ),
//   },
// });

const refreshTokenLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  isTokenValidOrUndefined: () => {
    /**
     * to tell if user has login in or not by cookie 'merp-user-session'
     */
    if (!getStorageUser()) {
      return true;
    }
    /**
     * parse the access token to see if expired
     */
    try {
      const { exp } = jwtDecode(getAccessToken()!);
      if (Date.now() >= exp * 1000) {
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  },
  fetchAccessToken: () => fetch(`${process.env.REACT_APP_GRAPHQL_SERVER_HOST}/refresh_token`, {
    method: 'POST',
    credentials: 'include',
  }),
  handleFetch: (accessToken) => {
    setAccessToken(accessToken);
  },
  handleResponse: (_, __) => async (response:Response) => {
    /**
     * if expericing 401 error on refresh_token
     * this can happen if user use another broswer(maybe in office computer)
     * to logout (which will update the tokenVersion in db (so invalidate all refrshtoken issued before)
     * therefore the existing token in home computer's borwser will be invalid(will get 401 error)
     * we need to force user to logout and re login in this senario
     * on re-login,user will get a new valid refresh token so can auto refresh accesstoken
     */
    if (response && response.status === 401) {
      clientSideLogoutTask(true, window.location.pathname);
    }
    // console.log('401', response.text, response.status);
    return response.json();
  },
  handleError: (
    err,
  ) => {
    /**
     * 401 error is handled above in handleResponse() (only happen when query refresh token)
     * invalid access token error is handled in errorLogger() below
     * all the rest of auth related error handled below
     */
    console.log("error", err); // eslint-disable-line
    setAccessToken('');
    clientSideLogoutTask();
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  if (getAccessToken()) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  }
  return forward(operation);
});

const errorLogger = onError(({
  graphQLErrors,
  networkError,
}) => {
  let errorMessages = '';
  if (graphQLErrors) {
    graphQLErrors.forEach(({
      message,
      locations, path,
    }) => {
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      errorMessages += `;${message}`;
    });
  }
  /**
   * To handle the graphQl accessToken verification error.
   */
  if (errorMessages.includes('access token invalid')) {
    /**
     * force user to loginout and re-login
     */
    console.log('access token invalid');
    clientSideLogoutTask(true, window.location.pathname);
    /**
     * please check graphQl server for permission config
     */
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(`
      if this query is intended to open to non-login user,
       please check if you have give the permision in api,
       src/authentication/exceptions.ts.       
       `);
    }
  }

  // eslint-disable-next-line no-console
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: ApolloLink.from([
    // retryLink,
    refreshTokenLink,
    authMiddleware,
    errorLogger,
    httpLink,
  ]),
  cache: new InMemoryCache(),
});

export default client;
