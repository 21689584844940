import React from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import axios from 'axios';
import amazon from '../../../assets/svg/amazon.svg';
import { EbayOauthParam } from '../../../pages/Home';

const REACT_APP_GRAPHQL_SERVER_HOST = process.env.REACT_APP_GRAPHQL_SERVER_HOST
  || 'http://localhost:4000';

const AmazonConnectorContainier = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;

  img {
    display: block;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }

  button {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

interface AmazonConnectorProps {
  teamChannelId: string;
  redirectPath:string,
}

const AmazonConnector = (props: AmazonConnectorProps) => {
  const [isLoading, setIsloading] = React.useState(false);
  const { teamChannelId, redirectPath } = props;
  const state: EbayOauthParam = {
    teamChannelId,
    redirectPath, // /marketplace/asdasdasd
  };

  const storeConnect = async () => {
    setIsloading(true);
    const res = await axios.get(
      `${REACT_APP_GRAPHQL_SERVER_HOST}/oauth/amazon/url?state=${JSON.stringify(state)}`,
    );
    const { url } = res.data;
    if (url) {
      window.location.assign(url);
    }
  };

  return (
    <AmazonConnectorContainier>
      <div>
        <img src={amazon} alt="amazon" data-testid="amazonLogo" />
        <Button
          appearance="primary"
          onClick={storeConnect}
          isLoading={isLoading}
          testId="connectAmazonButton"
        >
          Connect
        </Button>
      </div>
    </AmazonConnectorContainier>
  );
};

export default AmazonConnector;
