import React, { useState } from 'react';
import Popup from '@atlaskit/popup';
import { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Settings } from '@atlaskit/atlassian-navigation';
import Button from '@atlaskit/button';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import GraphBarIcon from '@atlaskit/icon/glyph/graph-bar';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import EmailIcon from '@atlaskit/icon/glyph/email';
import QueuesIcon from '@atlaskit/icon/glyph/queues';

const SettingsContainer = styled.div`
  min-width: 243px;
  padding-top:10px;
  padding-bottom:10px;
  font-size:0.825rem;
  font-weight:normal;
  line-height:20px;
`;

const DashboardButton = () => {
  const history = useHistory();
  return (
    <Button
      iconBefore={<GraphBarIcon label="dashboard" />}
      appearance="subtle"
      shouldFitContainer
      style={{ textAlign: 'left' }}
      onClick={() => (history.push('/'))}
      testId="dashboard-button"
    >
      Dashboard
    </Button>
  );
};

const TeamProfileButton = () => {
  const history = useHistory();
  return (
    <Button
      iconBefore={<PeopleGroupIcon label="company profile" />}
      appearance="subtle"
      shouldFitContainer
      style={{ textAlign: 'left' }}
      onClick={() => (history.push('/team/profile'))}
      testId="company-profile-button"
    >
      Company Profile
    </Button>
  );
};

const SMTPButton = () => {
  const history = useHistory();
  return (
    <Button
      iconBefore={<EmailIcon label="SMTP" />}
      appearance="subtle"
      shouldFitContainer
      style={{ textAlign: 'left' }}
      onClick={() => (history.push('/team/SMTP'))}
      testId="company-profile-button"
    >
      SMTP
    </Button>
  );
};

const DocsButton = () => (
  <Button
    iconBefore={<QueuesIcon label="docs" />}
    appearance="subtle"
    shouldFitContainer
    style={{ textAlign: 'left' }}
    testId="company-profile-button"
    onClick={() => {
      window.open('https://www.merp.com.au/docs/getting-started/');
    }}
  >
    Docs
  </Button>
);
export default () => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => {
    setIsOpen(!isOpen);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const SettingsContent = () => (
    <SettingsContainer>
      <DropdownItemGroup>
        <DropdownItem onClick={onClose}><TeamProfileButton /></DropdownItem>
        <DropdownItem onClick={onClose}><DashboardButton /></DropdownItem>
        <DropdownItem onClick={onClose}><SMTPButton /></DropdownItem>
        <DropdownItem onClick={onClose}><DocsButton /></DropdownItem>
      </DropdownItemGroup>
    </SettingsContainer>
  );

  return (
    <Popup
      placement="bottom-end"
      content={SettingsContent}
      isOpen={isOpen}
      onClose={onClose}
      testId="settings-popup"
      trigger={(triggerProps) => (
        <Settings
          testId="nav-settings-button"
          isSelected={isOpen}
          onClick={onClick}
          tooltip="Settings"
          {...triggerProps}
        />
      )}
    />
  );
};
