import React, {
  useEffect, useContext, createContext, useReducer,
} from 'react';
import {
  ProductsProps, ProductsContextType, State, Action,
} from './types';
import { defaultColumns } from './helper/getHeadAndRow';
import ProductsListView from './views/ListView/ListView';
import SplitView from '../SplitView/SplitView';
import ProductView from './views/DetailedView/ProductView';
import { useAuth } from '../../utils/useAuth';
import { getTeamChannelLists } from '../../utils/reducer';

const ProductsContext = createContext<ProductsContextType>(
  {} as ProductsContextType,
);

export default (props: ProductsProps) => {
  // props
  const { teamID, itemsPerPage = 20 } = props;
  // app state
  const { state: { teams } } = useAuth();

  // teamChannelFilters
  const defaultMarketPlaces = getTeamChannelLists(teamID, teams)
    .map((teamChannel) => ({
      name: teamChannel.name,
      id: teamChannel.id,
      isSelected: false,
    }));
  defaultMarketPlaces.unshift({
    name: 'All',
    id: '',
    isSelected: true,
  });
  // default state
  const initialState:State = {
    pagination: {
      currentPage: 0,
    },
    display: {
      columns: defaultColumns,
      isAdvancedFilterOpen: false,
      highlightedRowIndex: undefined,
      currentProductId: undefined,
      marketplaces: defaultMarketPlaces,
    },
    query: {
      skip: 0,
      take: itemsPerPage,
      sortKey: undefined,
      sortOrder: 'DESC',
      keyword: '',
      teamChannelIDs: [],
      advancedFilter: [],
    },
    ui: {
      globalSearchBoxInput: '',
      debonced: undefined,
    },
    utils: {
      lastDeletedId: '',
    },
  };

  // reducer
  const reducer = (
    state:State,
    action:Action,
  ):typeof initialState => {
    switch (action.type) {
      case 'setCurrentProductID': {
        return {
          ...state,
          display: {
            ...state.display,
            currentProductId: action.payload,
          },
        };
      }
      case 'setSkip': {
        return {
          ...state,
          query: {
            ...state.query,
            skip: action.payload,
          },
        };
      }
      case 'setCurrentPage': {
        return {
          ...state,
          pagination: {
            ...state.pagination,
            currentPage: action.payload,
          },
        };
      }
      case 'setSortKey': {
        return {
          ...state,
          query: {
            ...state.query,
            sortKey: action.payload,
          },
        };
      }
      case 'setSortOrder': {
        return {
          ...state,
          query: {
            ...state.query,
            sortOrder: action.payload,
          },
        };
      }
      case 'setColumns': {
        return {
          ...state,
          display: {
            ...state.display,
            columns: action.payload,
          },
        };
      }
      case 'setKeyword': {
        return {
          ...state,
          query: {
            ...state.query,
            keyword: action.payload,
            skip: 0,
          },
          pagination: {
            ...state.pagination,
            currentPage: 0,
          },
        };
      }
      case 'setIsAdvancedFilterOpen': {
        return {
          ...state,
          display: {
            ...state.display,
            isAdvancedFilterOpen: action.payload,
          },
        };
      }
      case 'setHighlightedRowIndex': {
        return {
          ...state,
          display: {
            ...state.display,
            highlightedRowIndex: action.payload,
          },
        };
      }
      case 'setTeamChannelIDs': {
        return {
          ...state,
          query: {
            ...state.query,
            teamChannelIDs: action.payload,
          },
        };
      }
      case 'setGlobalSearchBoxInput': {
        return {
          ...state,
          ui: {
            ...state.ui,
            globalSearchBoxInput: action.payload,
          },
        };
      }
      case 'setDebonced': {
        return {
          ...state,
          ui: {
            ...state.ui,
            debonced: action.payload,
          },
        };
      }
      case 'setMarketplaces': {
        return {
          ...state,
          display: {
            ...state.display,
            marketplaces: action.payload,
          },
        };
      }
      case 'setAdvancedFilter': {
        return {
          ...state,
          query: {
            ...state.query,
            advancedFilter: action.payload,
            skip: 0,
          },
          pagination: {
            ...state.pagination,
            currentPage: 0,
          },
        };
      }
      case 'setLastDeletedId': {
        return {
          ...state,
          utils: {
            ...state.utils,
            lastDeletedId: action.payload,
          },
        };
      }
      case 'reset': {
        return {
          ...initialState,
          display: {
            ...initialState.display,
            isAdvancedFilterOpen: state.display.isAdvancedFilterOpen,
            currentProductId: state.display.currentProductId,
            columns: state.display.columns,
          },
        };
      }
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * GIVEN: isAdvancedFilterOpen changes
   * THEN: reset data
   */
  useEffect(() => {
    dispatch({
      type: 'reset',
    });
  }, [state.display.isAdvancedFilterOpen]);

  /**
   * GIVEN: on mount component
   * THEN: set event lisener for esc key
   */
  useEffect(() => {
    const onESC = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        dispatch({
          type: 'setCurrentProductID',
          payload: undefined,
        });
      }
    };
    window.addEventListener('keydown', onESC);
    return () => {
      window.removeEventListener('keydown', onESC);
    };
  }, []);

  return (
    <ProductsContext.Provider value={{ state, dispatch }}>
      <SplitView
        left={<ProductsListView teamId={teamID} />}
        right={
          state.display.currentProductId
            ? <ProductView teamId={teamID} />
            : null
        }
      />
    </ProductsContext.Provider>
  );
};

export const useProducts = () => useContext(ProductsContext);
