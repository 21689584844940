/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  AtlassianNavigation,
  Help,
  PrimaryButton,
} from '@atlaskit/atlassian-navigation';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import { SettingsMenu, Logo } from '.';
import ProfilePopup from './ProfilePopup/ProfilePopup';
import SwitcherPopup from './TeamSwitcherPopup/TeamSwitcherPopup';
import NotificationPopup from './NotificationPopup/NotificationPopup';
import { useGetTeamByIdQuery } from '../graphql/types';
import { useAuth } from '../utils/useAuth';
import PlanUpgrade from './Billing/PlanUpgrade';
import CheckPlan from './Billing/CheckPlan';

const Card = styled.div`
  border: 0;
  flex-grow: 1;
  box-shadow: 0 1px 1px 0 rgba(60,75,100,.14), 0 2px 1px -1px rgba(60,75,100,.12), 0 1px 3px 0 rgba(60,75,100,.2);

  color: #000!important;

  position: relative;
  display: flex;
  flex-direction: column;
  height: 2rem;
  margin-left: 14.7rem;
  border-radius: 5px;
  text-align: center;
  background: #ffd700!important;
  font-weight: 600;
  background: ${(props) => props.theme.bgGradient}
  border-color: ${(props) => props.theme.bgColor}

`;

const CardBody = styled.div`
  padding: 0.5rem;
`;

const CustomButton = styled(Button)`
  background-color: black;
  height: 2.3rem;
  color: white !important;
  border-radius: 5px;
  color: white;
  height: 2rem;
  margin-left: 1rem;
`;

const FreeGet = (teamId: string) => {
  const {
    data,
  } = useGetTeamByIdQuery({
    variables: { teamId },
    // to avoid still show cached data after update when switching team
    fetchPolicy: 'network-only',
  });
  const teamSettingsObj = data?.GetTeamById.team?.settings ? JSON.parse(data?.GetTeamById.team?.settings) : {};

  const current = new Date(Date.now()).getTime();
  const create = teamSettingsObj.createdTime;
  const diff = current - create;
  const freeDay = Math.ceil(30 - diff / (1000 * 3600 * 24));

  return freeDay;
};
const MERPProductHome = () => <Logo width={120} />;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (userId: any) => {
  const location = useLocation();
  const history = useHistory();
  const plan = CheckPlan();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const closeUpgrade = () => {
    setIsOpen(false);
  };

  const isHighlighted = (pathname:string) => location.pathname === pathname;

  const link = (path:string) => (
    <PrimaryButton
      isHighlighted={isHighlighted(`/${path}`)}
      testId={`${path}-link`}
      onClick={() => history.push(`/${path}`)}
    >
      { _.capitalize(path) }
    </PrimaryButton>
  );
  const { state: { currentTeamId: teamID } } = useAuth();
  const freeDay = FreeGet(teamID || '');
  const renderFree = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {
        (plan.title === 'Free Trial' || plan.title === 'Plan Expired')
        && (
          <>
            <Card theme={{
              bgColor: '#f6960b',
              bgGradient: '#f6960b',
            }}
            >
              <CardBody>
                <div>
                  <div>
                    {plan.title === 'Plan Expired' ? 'Your plan has expired' : `Free trial: ${freeDay} day left`}
                  </div>
                </div>
              </CardBody>
            </Card>
            <PlanUpgrade isOpen={isOpen} close={closeUpgrade} />
            <CustomButton
              onClick={() => setIsOpen(true)}
              spacing="compact"
              testId="delete-payment-btn"
            >
              Plan Upgrade
            </CustomButton>
          </>
        )
      }
    </div>
  );

  // eslint-disable-next-line no-nested-ternary
  const primaryItems = userId.id === 'ckhfrt0ji0053wwwpkhaaln7g' ? [
    link('home'),
    link('products'),
    link('orders'),
    link('customers'),
    link('tickets'),
    link('report'),
    link('marketplace'),
    link('users'),
    renderFree(),
  ] : [
    link('home'),
    link('products'),
    link('orders'),
    link('customers'),
    link('report'),
    link('marketplace'),
    link('users'),
    renderFree(),
  ];

  return (
    <>
      <AtlassianNavigation
        label="site"
        primaryItems={primaryItems}
        renderProductHome={MERPProductHome}
        renderProfile={ProfilePopup}
        renderSettings={SettingsMenu}
        renderAppSwitcher={SwitcherPopup}
        renderNotifications={NotificationPopup}
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
        renderHelp={() => <div><a href="mailto:support@merp.com.au" target="_blank" rel="noopener noreferrer"><Help tooltip="Get help" /></a></div>}
      />

    </>

  );
};
