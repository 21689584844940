import React, { useState, useEffect } from 'react';
import Select, { ValueType, OptionType } from '@atlaskit/select';
import { useTeamCustomersLazyQuery } from '../../../../graphql/types';
import { useAuth } from '../../../../utils/useAuth';
import { TeamCustomer } from '../../types';

interface Props {
  onSelect:(teamCustomer:TeamCustomer | undefined)=>void;
}

export default ({ onSelect }:Props) => {
  const { state: { currentTeamId: teamID } } = useAuth();
  const [debonced, setDebonced] = useState<number>();
  const [keyword, setKeyword] = useState('');
  const [getCustomers, {
    data, loading, client,
  }] = useTeamCustomersLazyQuery();

  const parseDataToOptions = (payload:typeof data) => {
    try {
      const options = payload?.TeamCustomers.teamCustomers?.map(
        (teamCustomer) => ({
          label: teamCustomer.name,
          value: teamCustomer.id,
        }));

      if (options) {
        return options;
      }
      return undefined;
    } catch (error) {
      return undefined;
    }
  };

  const handleInputChange = (inputValue:string) => {
    clearTimeout(debonced);
    client.stop();
    setDebonced(setTimeout(() => {
      setKeyword(inputValue);
    }, 500));
  };

  useEffect(() => {
    getCustomers({
      variables: {
        teamID: teamID || '',
        searchTxt: keyword,
        skip: 0,
        take: 5,
      },
    });
  }, [keyword, teamID, getCustomers]);

  const handleOnChange = (selection:ValueType<OptionType>) => {
    try {
      const teamCustomerID = (selection as OptionType).value as string;

      const selectedCustomer = data?.TeamCustomers.teamCustomers?.filter(
        (tc) => tc.id === teamCustomerID,
      );

      onSelect(selectedCustomer![0]);
    } catch (error) {
      onSelect(undefined);
    }
  };

  return (
    <Select<OptionType>
      isLoading={loading}
      placeholder="Search by name"
      options={parseDataToOptions(data)}
      isClearable
      onInputChange={handleInputChange}
      onChange={handleOnChange}
    />
  );
};
