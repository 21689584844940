import React, { useState, useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import request from 'superagent';
import UploadButton from './UploadButton';
import Thumbnail from './Thumbnail';
import FlexContainer from './FlexContainer';
import DottedBoarderContainer from './DottedBoarderContainer';
import CenteredContainer from './CenteredContainer';
import { IImage } from './types';

export const supportedImageFormatArr = [
  'jpg',
  'jpeg',
  'png',
  'webp',
  'avif',
];

export default ({
  initImages,
  onUpdate,
  maxLimit,
  isDisable,
}: {
  initImages: string[];
  onUpdate: (images: IImage[]) => void;
  maxLimit: number;
  isDisable: boolean;
}) => {
  // map ebay image to uploading arr
  const ebayImgPrefix = 'https://i.ebayimg.com';
  const ebayImages = initImages.filter((img) => img.startsWith(ebayImgPrefix));

  // map non ebay image to init images
  const nonEbayImages = initImages
    .filter((img) => !img.startsWith(ebayImgPrefix));

  const initImageObjArr = nonEbayImages.map(
    (img: string): IImage => ({ id: uuidv4(), url: img, loading: false }),
  );

  const [images, setImages] = useState(initImageObjArr);

  const mapToImagesArr = (imageObjArr: IImage[]) => {
    onUpdate(imageObjArr);
  };
  mapToImagesArr(images);

  const getNewImgArr = (imgs: IImage[], image: IImage) => {
    const isContainImage = imgs.find((item: IImage) => item.id === image.id);
    const findMatchedImgFunc = (img: IImage) => (img.id === image.id
      ? { ...img, ...image } : img);
    const replacedImages = imgs.map(findMatchedImgFunc);
    const newImgObjArr = isContainImage ? replacedImages : [...imgs, image];
    return newImgObjArr;
  };

  const updateImage = (image: IImage) => {
    setImages((imgs: IImage[]) => {
      const newImgObjArr = getNewImgArr(imgs, image);
      mapToImagesArr(newImgObjArr);
      return newImgObjArr;
    });
  };

  const removeImage = (image: IImage) => {
    const newImgObjArr = images.filter((img: IImage) => img.id !== image.id);
    setImages(() => newImgObjArr);
    mapToImagesArr(newImgObjArr);
  };

  const imageCols = images.map((img: IImage) => {
    const imgCol = (
      <Thumbnail
        image={img}
        onRemove={() => {
          removeImage(img);
        }}
        isDisable={isDisable}
      />
    );
    return {
      id: img.id,
      component: imgCol,
      url: img.url ? img.url : '',
      img,
    };
  });

  interface ImageColType {
    id: string,
    component: JSX.Element,
    url: string,
    img: IImage
  }

  const SortableItem = SortableElement(({ value }:
  // eslint-disable-next-line react/no-unused-prop-types
  {value: ImageColType}) => (
    <DottedBoarderContainer
      key={value.id}
      className={(value.img.isLowRes && value.img.isLowRes === true)
        || (value.img.isUnSupportedFormat
          && value.img.isUnSupportedFormat === true)
        ? 'highlighted' : ''}
    >
      <CenteredContainer>
        {value.component}
      </CenteredContainer>
    </DottedBoarderContainer>
  ));

  const SortableList = SortableContainer(({ items }:
  // eslint-disable-next-line react/no-unused-prop-types
  {items: ImageColType[]}) => (
    <ul style={{ padding: 0, display: 'flex', flexWrap: 'wrap' }}>
      {items.map((value, index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value} />
      ))}
      {images.length < maxLimit && isDisable === false && (
      <DottedBoarderContainer>
        <CenteredContainer>
          <UploadButton
            onUpdateImage={updateImage}
          />
        </CenteredContainer>
      </DottedBoarderContainer>
      )}
    </ul>
  ));

  const shiftArr = (
    arr: ImageColType[],
    oldIndex: number,
    newIndex: number,
  ) => {
    const arrWithoutTarget = arr
      .filter((img, currentIndex) => currentIndex !== oldIndex);
    const targetImg = arr[oldIndex];
    return [
      ...arrWithoutTarget.slice(0, newIndex),
      targetImg,
      ...arrWithoutTarget.slice(newIndex),
    ];
  };

  const onSortEnd = ({ oldIndex, newIndex }:
  { oldIndex: number, newIndex: number }) => {
    const swappedArr = shiftArr(imageCols, oldIndex, newIndex);
    setImages(() => swappedArr.map((arr) => arr.img));
    const updatedImgs = swappedArr.map((arr) => arr.img);
    onUpdate(updatedImgs);
  };

  // Run only once for uploading ebay images
  useEffect(() => {
    ebayImages.forEach((imgUrl) => {
      const title = 'merp';
      const cloudName = process.env.REACT_APP_CLOUDINARY_NAME || '';
      const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
      const uploadPreset = process.env.REACT_APP_CLOUDINARY_PRESET || '';
      const id = uuidv4();
      updateImage({
        id,
        loading: true,
        url: imgUrl,
      });
      request
        .post(url)
        .field('upload_preset', uploadPreset)
        .field('file', imgUrl)
        .field('multiple', true)
        .field('tags', title ? `myphotoalbum,${title}` : 'myphotoalbum')
        .field('context', title ? `photo=${title}` : '')
        .end((error, response) => {
          updateImage({
            id,
            loading: false,
            url: response.body.secure_url,
            isLowRes: response.body.width < 500 && response.body.height < 500,
            isUnSupportedFormat: !supportedImageFormatArr
              .includes(response.body.format),
          });
        });
    });
    // eslint-disable-next-line
  }, []);

  return (
    <FlexContainer>
      <SortableList
        items={imageCols}
        onSortEnd={onSortEnd}
        axis="xy"
        helperClass="somecontainerclass"
        useDragHandle
      />
    </FlexContainer>
  );
};
