import React from 'react';
import Button from '@atlaskit/button';
import { OptionType } from '@atlaskit/select';
import { isNull, get } from 'lodash';
import { useShipping } from '../../Shipping';
import graphQLErrorsReader from '../../../../utils/graphQLErrorsReader';
import { useAuth } from '../../../../utils/useAuth';
import {
  Quote, PricesShipmentsPostResponse,
} from '../../types';
import { ARAMEX_AUTOPILOT, AUSPOST_AUTOPILOT } from '../ShippingMethod';
import getFastwayQuotes from './helpers/getFastwayQuotes';
import getAuspostQuote from './helpers/getAuspostQuote';
import { ItemInput } from '../../../../graphql/types';

const GetIntegratedQuoteButton = () => {
  const { state: providerState, dispatch } = useShipping();
  const { state: { currentTeamId: teamId } } = useAuth();
  const { order, shippingMethod } = providerState;
  const { boxes } = providerState.shipment;

  const items: Array<ItemInput> = boxes.map((cur) => ({
    length: Number(cur.length),
    width: Number(cur.width),
    height: Number(cur.height),
    weight: Number(cur.weight),
  }));

  const {
    name, street, suburb, state, postcode, email: shippingEmail,
  } = order.shippingAddress;

  const { email } = order.teamCustomer;
  const phone = order.shippingAddress.mobile || order.shippingAddress.phone;
  const { config } = providerState.integration.auspost;

  const isFastwayRequired = (() => {
    if (!shippingMethod) {
      return false;
    }
    return (shippingMethod
      .map((cur:OptionType) => cur.label) as Array<string>)
      .some((cur) => cur === ARAMEX_AUTOPILOT);
  })();

  const isAuspostRequired = (() => {
    if (!shippingMethod) {
      return false;
    }
    return (shippingMethod
      .map((cur:OptionType) => cur.label) as Array<string>)
      .some((cur) => cur === AUSPOST_AUTOPILOT);
  })();

  let isDisabled = true;
  items.forEach((key) => {
    isDisabled = (() => !![
      Number(key.length) > 0,
      Number(key.height) > 0,
      Number(key.width) > 0,
      Number(key.weight) > 0,
    ].includes(false))();
  });

  const handleGetQuote = async () => {
    let quotes:Quote[] = [];
    // init ui state
    dispatch({ type: 'setIsSubmiting', payload: true });
    dispatch({ type: 'setIsPrimaryButtonDisabled', payload: true });
    dispatch({ type: 'setError', payload: '' });
    dispatch({ type: 'setQuotes', payload: undefined });
    dispatch({ type: 'setIsQuoteFetching', payload: true });
    dispatch({ type: 'setQuoteId', payload: '' });
    // some ui trick
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    const getAllQuotes = async () => {
      if (isFastwayRequired) {
        try {
          const responses = await getFastwayQuotes({
            name,
            phone,
            email: shippingEmail || email,
            street,
            suburb,
            state,
            postcode,
            items,
            teamId: teamId!,
          });

          const fastwayQuotes = responses
            .map((response) => response.data.FastwayGetQuote.quote)
            .filter((quote) => !isNull(quote));

          const normolizedQuotes = fastwayQuotes.map((quote) => ({
            product_id: `${quote!.packageType}-${quote!.satchelSize || ''}`,
            productName: quote!.description,
            calculatedPrice: quote!.priceInc,
            courierId: quote!.courierId,
            courierName: ARAMEX_AUTOPILOT,
            satchelSize: quote!.satchelSize,
          }));

          quotes = [
            ...quotes,
            ...normolizedQuotes,
          ];
        } catch (error) {
          dispatch({ type: 'setError', payload: graphQLErrorsReader(error) });
        }
      }

      if (isAuspostRequired) {
        try {
          const response = await getAuspostQuote({
            itemsInput: {
              teamId: teamId!,
              fromName: get(config, 'name', ''),
              toName: name,
              fromLine: get(config, 'lines', '')[0] ? get(config, 'lines', '')[0] : [''],
              toLine: street ? [street] : [''],
              fromSuburb: get(config, 'suburb', ''),
              toSuburb: suburb,
              fromState: get(config, 'state', ''),
              toState: state,
              fromPostCode: get(config, 'postcode', ''),
              toPostCode: postcode,
              items,
            },
          });
          // eslint-disable-next-line max-len
          const auspostQuote: PricesShipmentsPostResponse = response.data.AuspostGetQuote.response;
          // eslint-disable-next-line max-len
          const normolizedQuotes:Array<Quote> = auspostQuote.shipments.map((cur) => ({
            product_id: `${cur.items[0].product_id}-${''}`,
            productName: cur.items[0].productName,
            calculatedPrice: cur.shipment_summary.total_cost,
            courierId: auspostQuote.courierId,
            courierName: AUSPOST_AUTOPILOT,
          }));

          quotes = [
            ...quotes,
            ...normolizedQuotes,
          ];
        } catch (error) {
          dispatch({ type: 'setError', payload: graphQLErrorsReader(error) });
        }
      }
    };

    await getAllQuotes()
      .then(() => {
        dispatch({
          type: 'setQuotes',
          payload: quotes,
        });

        if (quotes.length > 0) {
          dispatch({
            type: 'setStage',
            payload: 'quote',
          });
        }
      })
      .catch((err) => {
        dispatch({ type: 'setError', payload: graphQLErrorsReader(err) });
      })
      .finally(() => {
        dispatch({ type: 'setIsSubmiting', payload: false });
        dispatch({ type: 'setIsPrimaryButtonDisabled', payload: false });
        dispatch({ type: 'setIsQuoteFetching', payload: false });
      });
  };

  return (
    <Button
      appearance="primary"
      isDisabled={isDisabled || providerState.ui.isQuoteFetching}
      onClick={handleGetQuote}
      isLoading={providerState.ui.isQuoteFetching}
    >
      Quote
    </Button>
  );
};

export default GetIntegratedQuoteButton;
