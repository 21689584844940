import React from 'react';
import Button from '@atlaskit/button';
import { useProducts } from '../ProductsListing';

const MarketplaceFilter = () => {
  const { state, dispatch } = useProducts();

  const handleMarketplaceClick = (name: string) => {
    const newMarketplaces = [...state.display.marketplaces];
    // if 'All'
    if (name === 'All') {
      newMarketplaces.forEach((_, i) => {
        newMarketplaces[i].isSelected = false;
      });
      newMarketplaces[0].isSelected = true;
    } else {
      // if NOT 'All'
      newMarketplaces.forEach((e, i) => {
        if (e.name === name) {
          newMarketplaces[i].isSelected = !newMarketplaces[i].isSelected;
        }
      });
    }
    // to check when all other marketplaces is off
    // the 'All' stat should be on and vice versa
    if (!(newMarketplaces.map((e) => e.isSelected).includes(true, 1))) {
      newMarketplaces[0].isSelected = true;
    } else {
      newMarketplaces[0].isSelected = false;
    }
    dispatch({
      type: 'setMarketplaces',
      payload: newMarketplaces,
    });
    dispatch({
      type: 'setTeamChannelIDs',
      payload: newMarketplaces.map((cur) => (cur.isSelected ? cur.id : '')).filter((cur) => cur.length > 0),
    });
  };

  return (
    <div>
      {state.display.marketplaces.map((e) => (
        <Button
          key={e.name}
          appearance="subtle"
          isSelected={e.isSelected}
          onClick={() => handleMarketplaceClick(e.name)}
          style={{ marginRight: 10 }}
          testId={`tc-filter-${e.name.toLowerCase()}`}
          spacing="compact"
        >
          {e.name}
        </Button>
      ))}
    </div>
  );
};

export default MarketplaceFilter;
