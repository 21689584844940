import React, { useState, useEffect } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import { isInteger, isNaN } from 'lodash';
import Button, { ButtonGroup } from '@atlaskit/button';
import Form, { Field, FormFooter, FormSection } from '@atlaskit/form';
import formatMoneyWithPrefix from '../../helpers/formatMoney';
import { useAdjustInventoryMutation } from '../../graphql/types';
import {
  PlainText, FieldGroup, FieldItem, HighlightedText,
} from './styles';
import { Props, StockInFormData } from './types';
import { FormTitle } from '../Signin/ForgotPassword.styles';
import ErrorSection from '../ErrorSection/ErrorSection';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import { getCompoundedTaxRateMutiplier } from '../../utils/taxHelper';

export default ({
  product,
  taxClasses,
  isOpen,
  onClose,
}:Props) => {
  const [calculatedNewSOH, setCalculatedNewSOH] = useState(product.stockOnHand);
  const [
    calculatedNewInventoryValue,
    setCalculatedNewInventoryValue,
  ] = useState(product.totalInventoryCost);
  const [purchaseUnitCostEx, setPurchaseUnitCostEx] = useState(0);
  const [purchaseQty, setPurchaseQty] = useState(0);
  const taxRate = getCompoundedTaxRateMutiplier(product.productTaxClass?.id || '', taxClasses);
  const totalPurchaseEx = formatMoneyWithPrefix(
    purchaseQty * purchaseUnitCostEx * 100,
  );
  const totalPurchaseInc = formatMoneyWithPrefix(
    (purchaseQty * purchaseUnitCostEx * 100 * taxRate),
  );

  const [adjustInventoryMutation, { error }] = useAdjustInventoryMutation();
  const onPurchaseCostChange = (
    e:React.FormEvent<HTMLInputElement>,
  ) => {
    const unitCost = Number(e.currentTarget.value);
    if (!isNaN(unitCost)) {
      setPurchaseUnitCostEx(unitCost);
    }
  };
  const onPurchaseQtyChange = (
    e:React.FormEvent<HTMLInputElement>,
  ) => {
    const increaseQty = Number(e.currentTarget.value);
    if (isInteger(increaseQty)) {
      setPurchaseQty(increaseQty);
    }
  };
  useEffect(() => {
    const newPurchaseValueInCents = purchaseQty * purchaseUnitCostEx * 100;
    const existingInventoryValueInCents = product.totalInventoryCost;
    setCalculatedNewInventoryValue(
      newPurchaseValueInCents + existingInventoryValueInCents,
    );
    const existingSOH = product.stockOnHand;
    setCalculatedNewSOH(existingSOH + purchaseQty);
  }, [
    purchaseUnitCostEx,
    purchaseQty,
    product.stockOnHand,
    product.totalInventoryCost,
  ]);

  const onSubmit = async () => {
    try {
      await adjustInventoryMutation({
        variables: {
          productId: product.id,
          changeInQty: purchaseQty,
          costPerUnit: purchaseUnitCostEx,
          isTaxIncluded: false,
        },
      });

      onClose();
    } catch (err) {
      // left intentional blank to aovid unhandled promise rejection error
      // error handlered in above 'error' variable passed from custom hook
    }
  };

  return (
    <div
      role="presentation"
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <ModalTransition>
        {isOpen && (
        <Modal
          onClose={onClose}
          heading="Stock in"
          scrollBehavior="outside"
          width={350}
          shouldCloseOnOverlayClick={false}
        >
          <Form<StockInFormData>
            onSubmit={onSubmit}
          >
            {({ formProps, submitting }) => (
              <form {...formProps} style={{ paddingBottom: 20 }}>
                <FormTitle>
                  <PlainText>{product.name}</PlainText>
                </FormTitle>

                <FormSection>
                  {error
                    && (
                    <ErrorSection>
                      {graphQLErrorsReader(error)}
                    </ErrorSection>
                    )}
                  <FieldGroup>
                    <FieldItem>
                      <Field
                        name="currentSOH"
                        label="Current SOH"
                      >
                        {() => (
                          <PlainText>
                            {product.stockOnHand}
                          </PlainText>
                        )}
                      </Field>
                    </FieldItem>
                    <FieldItem>
                      <Field
                        name="newSOH"
                        label="New SOH"
                      >
                        {() => (
                          <HighlightedText data-testid="newSOH">
                            {calculatedNewSOH}
                          </HighlightedText>
                        )}
                      </Field>
                    </FieldItem>
                  </FieldGroup>
                  <FieldGroup>
                    <FieldItem>
                      <Field
                        name="currentInventoryValue"
                        label="Current inventory value (AUD Excl.)"
                      >
                        {() => (
                          <PlainText>
                            {formatMoneyWithPrefix(product.totalInventoryCost)}
                          </PlainText>
                        )}
                      </Field>
                    </FieldItem>
                    <FieldItem>
                      <Field
                        name="currentInventoryValue"
                        label="New inventory value (AUD Excl.)"
                      >
                        {() => (
                          <HighlightedText data-testid="currentInventoryValue">
                            {formatMoneyWithPrefix(calculatedNewInventoryValue)}
                          </HighlightedText>
                        )}
                      </Field>
                    </FieldItem>
                  </FieldGroup>
                  <Field
                    name="totalPurchaseEx"
                    label="Total purchase (AUD Excl.)"
                  >
                    {() => (
                      <HighlightedText data-testid="totalPurchaseEx">
                        {totalPurchaseEx}
                      </HighlightedText>
                    )}
                  </Field>
                  <Field
                    name="totalPurchaseInc"
                    label="Total purchase (AUD Inc.)"
                  >
                    {() => (
                      <HighlightedText data-testid="totalPurchaseInc">
                        {totalPurchaseInc}
                      </HighlightedText>
                    )}
                  </Field>
                  <Field
                    name="qtyPurchased"
                    label="Purchased quantity"
                    isRequired
                  >
                    {({ fieldProps }) => (
                      <>
                        <Textfield
                          {...fieldProps}
                          placeholder="Enter the qty purchased"
                          type="number"
                          min="1"
                          onChange={onPurchaseQtyChange}
                        />
                      </>
                    )}
                  </Field>
                  <Field
                    name="unitCost"
                    label="Purchased unit cost (AUD Excl.)"
                    isRequired
                  >
                    {({ fieldProps }) => (
                      <>
                        <Textfield
                          {...fieldProps}
                          placeholder="Enter unit price"
                          type="number"
                          min="0"
                          onChange={onPurchaseCostChange}
                        />
                      </>
                    )}
                  </Field>
                </FormSection>

                <FormFooter>
                  <ButtonGroup>
                    <Button
                      type="submit"
                      appearance="primary"
                      isDisabled={submitting}
                      isLoading={submitting}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={onClose}
                      appearance="subtle"
                      isDisabled={submitting}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </FormFooter>
              </form>
            )}
          </Form>
        </Modal>
        )}
      </ModalTransition>
    </div>
  );
};
