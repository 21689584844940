import React from 'react';
import { useParams } from 'react-router-dom';
import EbayImporter from '../components/EbayImporter/EbayImporter';

export default () => {
  const { teamChannelID } = useParams();

  return (
    <EbayImporter teamChannelID={teamChannelID} />
  );
};
