import React from 'react';
import Textfield from '@atlaskit/textfield';
import { Field, ErrorMessage } from '@atlaskit/form';
import AddIcon from '@atlaskit/icon/glyph/add';
import Button from '@atlaskit/button';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import styled from 'styled-components';
import { useShipping } from '../Shipping';
import { PreviewShipmentContainer, Dimensions } from '../Shipping.style';
import { Box, Shipment } from '../types';

const CustomButton = styled(Button)`
  background-color: #36b37e
`;

const ShippingdDimensions = () => {
  const { state, dispatch } = useShipping();

  const isDisabled = (state.ui.stage !== 'preview') && (state.ui.stage !== 'quote');

  const handleShipmentChange = (value:Shipment) => dispatch({ type: 'setShipment', payload: value });

  const resetQuote = () => {
    // if change demetion,
    // update state to preview
    // so the button will change to 'quote'
    dispatch({
      type: 'setStage',
      payload: 'preview',
    });

    // if change demetion,
    // clear previous quote
    // so don't confuse people

    dispatch({
      type: 'setQuotes',
      payload: [],
    });
  };

  const handleValueChange = (
    index: number,
    dimension: 'width' | 'length' | 'height',
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    const newBoxes = Array.from(state.shipment.boxes);
    const { value } = e.currentTarget;

    newBoxes[index][dimension] = value;
    let upperDimension: 'isWidthInvalid' | 'isLengthInvalid' | 'isHeightInvalid' = 'isWidthInvalid';

    switch (dimension) {
      case 'width': {
        upperDimension = 'isWidthInvalid';
        break;
      }
      case 'length': {
        upperDimension = 'isLengthInvalid';
        break;
      }
      case 'height': {
        upperDimension = 'isHeightInvalid';
        break;
      }
      default:
        upperDimension = 'isWidthInvalid';
    }

    newBoxes[index][upperDimension] = (Number.isNaN(Number(value))) || Number(value) <= 0 || value.length === 0;

    handleShipmentChange(
      {
        ...state.shipment,
        boxes: newBoxes,
      },
    );
    resetQuote();
  };

  // add new Dimension into the dimension array
  const addNewDimension = () => {
    const newBoxes = Array.from(state.shipment.boxes);
    // create a new box object
    const newBox: Box = {
      weight: '',
      height: '',
      length: '',
      width: '',
      isHeightInvalid: true,
      isWidthInvalid: true,
      isLengthInvalid: true,
      isWeightInvalid: true,
    };
    // add a new element
    newBoxes.push(newBox);
    // set the dimensionArr
    dispatch({
      type: 'setBoxes',
      payload: newBoxes,
    });
    resetQuote();
  };

  // delete Dimension, given the dimension array index
  const deleteDimension = (index: number) => {
    // create a new arr
    const newBoxes = Array.from(state.shipment.boxes);
    // delete a element according to the index
    newBoxes.splice(index, 1);
    //
    dispatch({
      type: 'setBoxes',
      payload: newBoxes,
    });
    resetQuote();
  };

  React.useEffect(() => {
    const newBoxes = Array.from(state.shipment.boxes);
    // eslint-disable-next-line no-restricted-syntax
    for (const box of newBoxes) {
      if (!(Number(box.length) > 0)) {
        box.isLengthInvalid = true;
      }
      if (!(Number(box.width) > 0)) {
        box.isWidthInvalid = true;
      }
      if (!(Number(box.height) > 0)) {
        box.isHeightInvalid = true;
      }
    }
    dispatch({
      type: 'setBoxes',
      payload: newBoxes,
    });
  }, []); //eslint-disable-line

  return (
    <PreviewShipmentContainer>
      <div className="section summary">
        <div className="block weight">
          <Field
            name="Dimension"
            label="Dimension(cm)"
            isRequired
          >
            {() => (
              <>
                {state.shipment.boxes.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div style={{ marginTop: '10px' }} key={`item_${index}`}>
                    <Dimensions>
                      <Textfield
                        value={item.length}
                        isInvalid={item.isLengthInvalid}
                        onChange={(e) => handleValueChange(index, 'length', e)}
                        isDisabled={isDisabled || state.ui.isSubmiting}
                        isCompact
                        width={80}
                        testId="length"
                      />
                      <span> X </span>
                      <Textfield
                        value={item.width}
                        isInvalid={item.isWidthInvalid}
                        onChange={(e) => handleValueChange(index, 'width', e)}
                        isDisabled={isDisabled || state.ui.isSubmiting}
                        isCompact
                        width={80}
                        testId="width"
                      />
                      <span> X </span>
                      <Textfield
                        value={item.height}
                        isInvalid={item.isHeightInvalid}
                        onChange={(e) => handleValueChange(index, 'height', e)}
                        isDisabled={isDisabled || state.ui.isSubmiting}
                        isCompact
                        width={80}
                        testId="height"
                      />
                      {(index !== 0 || state.shipment.boxes.length > 1)
                          && (
                            <Button onClick={() => deleteDimension(index)} appearance="danger">
                              <TrashIcon label="Delete" size="small" />
                            </Button>
                          )}
                      {index === state.shipment.boxes.length - 1
                          && (
                            <Button onClick={addNewDimension} component={CustomButton}>
                              <AddIcon primaryColor="white" label="Add" size="small" />
                            </Button>
                          )}

                    </Dimensions>
                    {
                      (!item.height || !item.length || !item.width
                       || item.height <= 0 || item.length <= 0 || item.width <= 0)
                        && (
                        <ErrorMessage>
                          Dimension should be greater than 0 cm.
                        </ErrorMessage>
                        )
                    }
                    {
                       (item.height && item.length && item.width
                        && item.height > 0 && item.length > 0 && item.width > 0)
                         && (
                         <div style={{ marginTop: '30px' }} />
                         )
                    }
                  </div>
                ))}
              </>
            )}
          </Field>
        </div>
      </div>
    </PreviewShipmentContainer>
  );
};

export default ShippingdDimensions;
