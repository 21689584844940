import React, { useState } from 'react';
import Modal, { ModalBody, ModalTransition } from '@atlaskit/modal-dialog';
import { useForm } from 'react-hook-form';
import Button from '@atlaskit/button';
import styled from 'styled-components';
import Select, { ValueType, OptionType } from '@atlaskit/select';
import { useGetTeamXeroAccountCodeQuery, useGetTeamByIdQuery, useSetTeamXeroAccountCodeMutation } from '../../graphql/types';
import addNotification from '../../utils/addNotification';

export const LabelContainer = styled.div`
  color: #6B778C;
  margin-top: 20px;
  font-size: 0.85em;
`;

export const ButtonContainer = styled.div`
  margin-top: 70px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ErrorMsg = styled.p`
  margin-top:10px;
  color: #bf1650;
  &:before {
    content: "⚠ "
  }
`;

interface AppInquiryModalInputProps {
  isOpen: boolean,
  close: any, //eslint-disable-line
  teamId: string | undefined,
}

interface FormValuesProps {
  courierName: string,
}

const SetXeroAccountCode = (props: AppInquiryModalInputProps) => {
  const { isOpen, close, teamId } = props;

  const { handleSubmit, errors } = useForm();
  const [setTeamXeroAccountCode] = useSetTeamXeroAccountCodeMutation();

  const [errorMessage, setErrorMsg] = useState<string>();
  const [value, setValue] = useState<OptionType|undefined>(undefined);
  const { data: accountCodes, loading } = useGetTeamXeroAccountCodeQuery(
    {
      variables: {
        teamId: teamId || '',
      },
      // to avoid still show cached data after update when switching team
      fetchPolicy: 'network-only',
    },
  );

  const {
    data,
  } = useGetTeamByIdQuery({
    variables: { teamId: teamId || '' },
    // to avoid still show cached data after update when switching team
    fetchPolicy: 'network-only',
  });
  const currentCode = data?.GetTeamById.team ? data?.GetTeamById.team.xeroAccountCode : '';

  const codes = accountCodes?.GetTeamXeroAccountCode.response.accounts;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const optionsCode = codes && codes.map((cur: { code: any; name: any; }) => (
    {
      label: `${cur.code}-${cur.name}`, value: cur.code,
    }
  ));
  let current = '';

  if (currentCode !== '') {
    const list = optionsCode && optionsCode.filter(
      // eslint-disable-next-line eqeqeq
      (element: { value: string; }) => element.value == currentCode,
    );
    current = list && list[0].label;
  }

  const options = currentCode !== '' ? [
    {
      label: 'Current Setting',
      options: [
        { label: current, value: currentCode },
      ],
    },
    {
      label: 'Other Options',
      options: optionsCode,
    },
  ] : [
    {
      label: 'Options',
      options: optionsCode,
    },
  ];

  const handleChange = (option:ValueType<OptionType>) => {
    setValue(option as OptionType);
  };

  const onSubmit = () => { //eslint-disable-line
    const xeroAccountCode = value?.value.toString();
    if (!teamId) return;
    setTeamXeroAccountCode({
      variables: {
        xeroAccountCode: xeroAccountCode || '200',
        teamId,
      },
    })
      .then(() => {
        close();
        addNotification('Xero account code update success', 'success');
      })
      .catch((err: { graphQLErrors: { message: string; }[]; }) => {
        const msg = err?.graphQLErrors[0]?.message || 'set account code failed';
        setErrorMsg(msg);
      });
  };

  return (
    <ModalTransition>
      {isOpen && (
      <Modal
        onClose={close}
        heading="Set Your Xero Account Code (Default Currency: AUD)"
        width="medium"
        shouldCloseOnEscapePress={false}
        shouldCloseOnOverlayClick={false}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="subtitle">
              Please choose a revenue account code from your chart of accounts list
            </div>
            <ModalBody>
              <Select<OptionType>
                isLoading={loading}
                options={options}
                onChange={handleChange}
                placeholder="Please select your account code"
                menuPortalTarget={document.body}
                value={value}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
              />
            </ModalBody>
            {errors.courierName
              && <ErrorMsg data-testid="courierName-error-message">Account Code is required</ErrorMsg>}
            {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
            <ButtonContainer>
              <Button
                testId="submit-button"
                type="submit"
                appearance="primary"
              >
                Submit
              </Button>
              <Button
                testId="cancel-button"
                appearance="link"
                onClick={() => close()}
              >
                Cancel
              </Button>
            </ButtonContainer>
          </div>
        </form>
      </Modal>
      )}
    </ModalTransition>
  );
};

export default SetXeroAccountCode;
