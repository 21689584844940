/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Form, {
  Field, FormSection, FormFooter, HelperMessage,
} from '@atlaskit/form';
import { useHistory } from 'react-router-dom';
import TextField from '@atlaskit/textfield';
import { Grid, GridColumn } from '@atlaskit/page';
import Button, { ButtonGroup } from '@atlaskit/button';
import { useSmtpSendCheckMutation } from '../../graphql/types';

export interface SMTPEditorFormData {
  host: string,
  port: string,
  secure: boolean,
  username: string,
  password: string,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (props: any) => {
  const { teamId, values, actions } = props;
  const history = useHistory();
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [sendTo, setSendTo] = useState('');
  const [smtpSendCheckMutation] = useSmtpSendCheckMutation();

  const onSubmit = (data: SMTPEditorFormData) => {
    const mergedData = {
      ...data,
    };
    actions.handleSubmit(mergedData);
    // console.log(mergedData);
  };

  const testSmtpButton = () => {
    smtpSendCheckMutation({
      variables: {
        teamId,
        sendTo,
      },
    });
    return undefined;
  };

  const renderFooterButton = (submitting: boolean) => (
    <FormFooter>
      <ButtonGroup>
        <Button
          appearance="default"
          isLoading={submitting}
          onClick={() => history.push('/')}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          appearance="primary"
          isLoading={submitting}
          ref={buttonRef}
        >
          Save
        </Button>
      </ButtonGroup>
    </FormFooter>
  );

  return (
    <>
      <Form<SMTPEditorFormData>
        onSubmit={onSubmit}
      >
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <Grid>
              <GridColumn medium={3} />
              <GridColumn medium={6}>
                <FormSection title="SMTP Configuration Option">
                  <Field
                    name="host"
                    label="Host"
                    isRequired
                    defaultValue={values.host}
                  >
                    {({ fieldProps }) => (
                      <>
                        <TextField {...fieldProps} testId="hostInput" />
                        <HelperMessage>
                          Support Host Name and IP Address
                        </HelperMessage>
                      </>
                    )}
                  </Field>
                  <Field
                    name="port"
                    label="Port"
                    isRequired
                    defaultValue={values.port}
                  >
                    {({ fieldProps }) => (
                      <>
                        <TextField {...fieldProps} testId="portInput" />
                        <HelperMessage>
                          Default ports: 25, 465 or 587. Port 465(SSL Required), Port 587(TLS Required)
                        </HelperMessage>
                      </>
                    )}
                  </Field>
                  <Field
                    name="username"
                    label="Username"
                    isRequired
                    defaultValue={values.username}
                  >
                    {({ fieldProps }) => (
                      <>
                        <TextField {...fieldProps} testId="usernameInput" />
                        <HelperMessage>
                          This is your email account which sending email to customer.
                        </HelperMessage>
                      </>
                    )}
                  </Field>
                  <Field
                    name="password"
                    label="Password"
                    isRequired
                    defaultValue={values.password}
                  >
                    {({ fieldProps }) => (
                      <>
                        <TextField {...fieldProps} testId="passwordInput" />
                        <HelperMessage>
                          This is your email password which sending email to customer.
                        </HelperMessage>
                      </>
                    )}
                  </Field>
                </FormSection>
                <GridColumn>
                  {renderFooterButton(submitting)}
                </GridColumn>
              </GridColumn>

              <GridColumn medium={3} />
            </Grid>
          </form>
        )}
      </Form>
      <Grid>
        <GridColumn medium={3} />
        <GridColumn medium={6}>
          <FormSection title="Send Test Email">
            <Field
              name="Send To"
              label="Send To"
            >
              {({ fieldProps }) => (
                <>
                  <TextField
                    {...fieldProps}
                    testId="sendToInput"
                    onChange={(event) => setSendTo(event.currentTarget.value)}
                  />
                  <HelperMessage>
                    Enter your Email and Click
                    <strong>&nbsp;Test Now&nbsp;</strong>
                    button to test the configuration.
                  </HelperMessage>
                </>
              )}
            </Field>
            <FormFooter>
              <Button
                type="submit"
                appearance="primary"
                // isLoading={submitting}
                ref={buttonRef}
                onClick={testSmtpButton}
              >
                Test Now
              </Button>
            </FormFooter>
          </FormSection>
        </GridColumn>
        <GridColumn medium={3} />
      </Grid>
    </>
  );
};
