import React from 'react';
import Button from '@atlaskit/button';
import { ButtonAppearances, Spacing } from '@atlaskit/button/types';
import WatchFilledIcon from '@atlaskit/icon/glyph/watch-filled';

const openNewTab = (itemId: string) => {
  const finalUrl = `https://www.ebay.com.au/itm/${itemId}`;
  window.open(finalUrl, '_blank');
};

const ViewEbayListingBtn = ({
  ebayItemId, children, isCompactSize, isDisabled,
}: {
  ebayItemId: string,
  children: React.ReactNode,
  isCompactSize: boolean,
  isDisabled: boolean,
}) => {
  const subtle = 'subtle' as ButtonAppearances;
  const none = 'none' as Spacing;
  const extraProps = isCompactSize !== true ? {
    iconBefore: <WatchFilledIcon size="medium" label="view ebay listing" />,
  } : {
    appearance: subtle,
    spacing: none,
  };
  return (
    <Button
      {...extraProps}
      onClick={() => openNewTab(ebayItemId)}
      isDisabled={isDisabled}
    >
      {children}
    </Button>
  );
};

export default ViewEbayListingBtn;
