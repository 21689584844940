import React from 'react';
import { useParams } from 'react-router-dom';
import * as QueryString from 'query-string';
// import axios from 'axios';
import TeamChannelViewer from '../components/TeamChannelListing/TeamChannelViewer';

// const REACT_APP_GRAPHQL_SERVER_HOST = process.env.REACT_APP_GRAPHQL_SERVER_HOST|| 'http://localhost:4000';

// const pendingHandle = async (pengingId: string) => {
//   const res = await axios.get(
//     `${REACT_APP_GRAPHQL_SERVER_HOST}/oauth/shopify/pending_handle?pendingId=${pengingId}`,
//   );
//   const { url } = res.data;
//   if (url) {
//     window.location.assign(url);
//   }
// };

export default (props: { location: { search: string } }) => {
  const { location } = props;
  const { search } = location;
  const params = QueryString.parse(search);

  console.log(params);

  let { teamChannelId } = useParams<{ teamChannelId: string }>();

  if (!teamChannelId) {
    teamChannelId = params.teamChannelId ? params.teamChannelId as string : '';
  }

  return (
    <TeamChannelViewer teamChannelId={teamChannelId} linkState={params?.link_state} errCode={params?.errCode} />
  );
};
