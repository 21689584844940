import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { NavigationHeader } from '@atlaskit/side-navigation';
import { SuccessProgressBar } from '@atlaskit/progress-bar';
import Button from '@atlaskit/button';

import { H4 } from '../Typography';
import Step from './Step';
import Stepper from './Stepper';
import { useGetTeamChannelByIdQuery } from '../../graphql/types';
import { TeamChannelSettingsData } from '../../utils/types';
import { useAuth } from '../../utils/useAuth';

const OnboardingNav = styled.div`
  height: 100%;
  border-left: 1px solid #eee;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Header = styled.div`
  text-align: center;
  padding-bottom: 10px;
`;

const Footer = styled.div`
  margin-top: 12px;
  align-self: center;
  a {
    text-decoration: none;
  }
`;

const TitleWrapper = styled.div`
  margin-top: -12px;
  margin-bottom: 10px;
`;

interface Props {
  onCancel: Function
}

export default ({ onCancel }:Props):JSX.Element => {
  const { state: { teams } } = useAuth();

  const [teamChannelId, setTeamChannelId] = useState(teams[0].teamChannels[0].id);
  const { data: teamChannelData } = useGetTeamChannelByIdQuery({
    variables: { teamChannelId },
    fetchPolicy: 'network-only', // to avoid cached data not refresh after make changes
  });
  const teamChannel = teamChannelData?.GetTeamChannelById.teamChannel;
  const [settings, setSettings] = useState<TeamChannelSettingsData>({});
  const [selectedKey, SetSelectedKey] = useState<string>();

  const stepper = useMemo(
    () => Stepper(settings, teamChannelId, selectedKey!),
    [settings, teamChannelId, selectedKey],
  );

  // Parse and set settings when the team channel data is fetched
  useEffect(() => {
    const tch = teams[0].teamChannels[0];
    if (tch) {
      setTeamChannelId(tch.id);
    }
    // Parse json to our typed settings
    if (teamChannelData && teamChannel) {
      let parsedSettings: TeamChannelSettingsData;
      try {
        parsedSettings = JSON.parse(teamChannel?.settings);
      } catch (error) {
        parsedSettings = JSON.parse('{}');
      }
      setSettings(parsedSettings);
    }
  }, [teams, teamChannelData, teamChannel]);

  return (
    <OnboardingNav>
      <Header>
        <NavigationHeader>
          <TitleWrapper>
            <H4>Quickstart</H4>
          </TitleWrapper>
        </NavigationHeader>
        <SuccessProgressBar value={stepper.completionRate} />
      </Header>
      {
        stepper.steps.map((step) => (
          <Step key={step.key} step={step} onSelectStep={SetSelectedKey} />
        ))
      }
      <Footer>
        <Button appearance="link" onClick={() => onCancel()} testId="cancel-btn">
          <small>Cancel Quickstart</small>
        </Button>
      </Footer>
    </OnboardingNav>
  );
};
