import React, { useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield/dist/cjs/components/Textfield';
import { Controller, useForm } from 'react-hook-form';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import { useCreateCourierInquiryMutation } from '../../graphql/types';
import { ButtonContainer, ErrorMsg } from './AppInquiryModal.styles';

interface AppInquiryModalInputProps {
  isOpen: boolean,
  close: any, //eslint-disable-line
  teamID: string | undefined,
}

interface FormValuesProps {
  courierName: string,
}

const AppInquiryModal = (props: AppInquiryModalInputProps) => {
  const { isOpen, close, teamID } = props;

  const { control, handleSubmit, errors } = useForm();
  const [showSpinner, setSpinner] = useState<boolean>(false);
  const [createCourierInquiryMutation] = useCreateCourierInquiryMutation();

  const [errorMessage, setErrorMsg] = useState<string>();

  const onSubmit = (data: any) => { //eslint-disable-line
    setSpinner(true);
    const { courierName } = data;
    if (!teamID) return;
    createCourierInquiryMutation({
      variables: {
        courierName,
        teamID,
      },
    })
      .then(() => {
        setSpinner(false);
        close();
      })
      .catch((err: { graphQLErrors: { message: string; }[]; }) => {
        const msg = err?.graphQLErrors[0]?.message || 'send email failed';
        setErrorMsg(msg);
        setSpinner(false);
      });
  };

  return (
    <ModalTransition>
      {isOpen && (
      <Modal
        onClose={close}
        heading="App Inquiry"
        width="medium"
        shouldCloseOnEscapePress={false}
        shouldCloseOnOverlayClick={false}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="subtitle">
              Which new App would you like to integrate to MERP?
            </div>
            <br />
            <Controller
              as={Textfield}
              name="courierName"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              testId="courierName"
            />
            {errors.courierName
              && <ErrorMsg data-testid="courierName-error-message">email body is required</ErrorMsg>}
            {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
            <ButtonContainer>
              <Button
                testId="submit-button"
                type="submit"
                appearance="primary"
                iconAfter={
                  showSpinner ? <Spinner appearance="invert" /> : <></>
                }
              >
                Submit
              </Button>
              <Button
                testId="cancel-button"
                appearance="link"
                onClick={() => close()}
              >
                Cancel
              </Button>
            </ButtonContainer>
          </div>
        </form>
      </Modal>
      )}
    </ModalTransition>
  );
};

export default AppInquiryModal;
