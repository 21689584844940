import React, { useState } from 'react';
import { InlineEditableTextfield } from '@atlaskit/inline-edit';
import Button from '@atlaskit/button';
import {
  useUpdateAddressMutation,
  AddressParams,
} from '../../graphql/types';
import { TeamCustomer } from '../CreateOrder/types';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import {
  ElementContainer,
  TeamCustomerEditorContainer,
  Block,
  BlockItems,
  VerticalToolBar,
} from './CustomerInlineEditMini.style';
import addNotification from '../../utils/addNotification';

/**
 * A simple but elegant version of customer detail editor that
 * allow to be fitted on a small viewpoint height.
 * Primarly desinged to be used as an inline edit on mannual order creation.
 */

interface Props {
  teamCustomer:TeamCustomer,
}

const Element = (
  { label, content }:{label:string, content:React.ReactNode},
) => (
  <ElementContainer>
    <span>{label}</span>
    <div>{content}</div>
  </ElementContainer>
);

const AddressBlock = (
  { label, children }:{label:string, children:React.ReactNode},
) => (
  <Block>
    <h5>{label}</h5>
    <BlockItems>{children}</BlockItems>
  </Block>
);

const TeamCustomerEditMini = ({ teamCustomer }:Props) => {
  const [updateAddress] = useUpdateAddressMutation();
  const { shippingAddresses, billingAddresses } = teamCustomer;
  const [{ address: sAddress }] = shippingAddresses;
  const [{ address: bAddress }] = billingAddresses;
  const sId = sAddress.id;
  const bId = bAddress.id;
  const [s, setS] = useState<typeof sAddress>(sAddress);
  const [b, setB] = useState<typeof bAddress>(bAddress);

  const edit = (addressId:string, value:AddressParams) => {
    // will be used to perform failover recovery
    const deepCopyS = JSON.parse(JSON.stringify(s));
    const deepCopyB = JSON.parse(JSON.stringify(b));

    // local state update
    switch (addressId) {
      case (sId): {
        const newS = {
          ...s,
          ...value,
        };
        setS(newS as typeof sAddress);
        break;
      }
      case (bId): {
        const newB = {
          ...b,
          ...value,
        };
        setB(newB as typeof bAddress);
        break;
      }
      default:
        break;
    }

    // call api async
    updateAddress({
      variables: {
        addressId,
        addressParams: value,
      },
    })
      .catch((error) => {
      // failover recovery local state and show eror msg
        addNotification(graphQLErrorsReader(error), 'danger');
        // restore
        setS((prev) => ({
          ...prev,
          ...deepCopyS,
        }));
        setB((prev) => ({
          ...prev,
          ...deepCopyB,
        }));
      });
  };

  const bulkUpdate = (
    addressId:string,
    data:typeof sAddress | typeof bAddress,
  ) => {
    // will be used to perform failover recovery
    const deepCopyS = JSON.parse(JSON.stringify(s));
    const deepCopyB = JSON.parse(JSON.stringify(b));

    // local state update
    switch (addressId) {
      case (sId): {
        const newS = {
          ...s,
          ...data,
        };
        setS(newS as typeof sAddress);
        break;
      }
      case (bId): {
        const newB = {
          ...b,
          ...data,
        };
        setB(newB as typeof bAddress);
        break;
      }
      default:
        break;
    }

    updateAddress({
      variables: {
        addressId,
        addressParams: {
          company: data.company,
          name: data.name,
          street: data.street,
          suburb: data.suburb,
          state: data.state,
          postcode: data.postcode,
          country: data.country,
        },
      },
    })
      .catch((error) => {
      // failover recovery local state and show eror msg
        addNotification(graphQLErrorsReader(error), 'danger');
        // restore
        setS((prev) => ({
          ...prev,
          ...deepCopyS,
        }));
        setB((prev) => ({
          ...prev,
          ...deepCopyB,
        }));
      });
  };

  return (
    <>
      <h4>{teamCustomer.name}</h4>
      <TeamCustomerEditorContainer>
        <AddressBlock label="Shipping address">
          <Element
            label="Company Name"
            content={(
              <InlineEditableTextfield
                defaultValue={s.company}
                readViewFitContainerWidth
                onConfirm={(value) => (edit(sId, { company: value }))}
                placeholder=""
                isCompact
              />
          )}
          />
          <Element
            label="Contact Name"
            content={(
              <InlineEditableTextfield
                defaultValue={s.name}
                readViewFitContainerWidth
                onConfirm={(value) => (edit(sId, { name: value }))}
                placeholder={' '}
                isCompact
              />
          )}
          />
          <Element
            label="Street Address"
            content={(
              <InlineEditableTextfield
                defaultValue={s.street}
                readViewFitContainerWidth
                onConfirm={(value) => (edit(sId, { street: value }))}
                placeholder={' '}
                isCompact
              />
          )}
          />
          <Element
            label="Suburb"
            content={(
              <InlineEditableTextfield
                defaultValue={s.suburb}
                readViewFitContainerWidth
                onConfirm={(value) => (edit(sId, { suburb: value }))}
                placeholder={' '}
                isCompact
              />
          )}
          />
          <Element
            label="State"
            content={(
              <InlineEditableTextfield
                defaultValue={s.state}
                readViewFitContainerWidth
                onConfirm={(value) => (edit(sId, { state: value }))}
                placeholder={' '}
                isCompact
              />
          )}
          />
          <Element
            label="Postcode"
            content={(
              <InlineEditableTextfield
                defaultValue={s.postcode}
                readViewFitContainerWidth
                onConfirm={(value) => (edit(sId, { postcode: value }))}
                placeholder={' '}
                isCompact
              />
          )}
          />
          <Element
            label="Country"
            content={(
              <InlineEditableTextfield
                defaultValue={s.country}
                readViewFitContainerWidth
                onConfirm={(value) => (edit(sId, { country: value }))}
                placeholder={' '}
                isCompact
              />
          )}
          />
        </AddressBlock>
        <VerticalToolBar>
          <Button
            spacing="compact"
            appearance="default"
            width={10}
            onClick={() => bulkUpdate(bId, s)}
          >
            {'>'}
          </Button>
          <Button
            spacing="compact"
            appearance="default"
            width={10}
            onClick={() => bulkUpdate(sId, b)}
          >
            {'<'}
          </Button>
        </VerticalToolBar>
        <AddressBlock label="Billing address">
          <Element
            label="Company Name"
            content={(
              <InlineEditableTextfield
                defaultValue={b.company}
                readViewFitContainerWidth
                onConfirm={(value) => (edit(bId, { company: value }))}
                placeholder=""
                isCompact
              />
          )}
          />
          <Element
            label="Contact Name"
            content={(
              <InlineEditableTextfield
                defaultValue={b.name}
                readViewFitContainerWidth
                onConfirm={(value) => (edit(bId, { name: value }))}
                placeholder={' '}
                isCompact
              />
          )}
          />
          <Element
            label="Street Address"
            content={(
              <InlineEditableTextfield
                defaultValue={b.street}
                readViewFitContainerWidth
                onConfirm={(value) => (edit(bId, { street: value }))}
                placeholder={' '}
                isCompact
              />
          )}
          />
          <Element
            label="Suburb"
            content={(
              <InlineEditableTextfield
                defaultValue={b.suburb}
                readViewFitContainerWidth
                onConfirm={(value) => (edit(bId, { suburb: value }))}
                placeholder={' '}
                isCompact
              />
          )}
          />
          <Element
            label="State"
            content={(
              <InlineEditableTextfield
                defaultValue={b.state}
                readViewFitContainerWidth
                onConfirm={(value) => (edit(bId, { state: value }))}
                placeholder={' '}
                isCompact
              />
          )}
          />
          <Element
            label="Postcode"
            content={(
              <InlineEditableTextfield
                defaultValue={b.postcode}
                readViewFitContainerWidth
                onConfirm={(value) => (edit(bId, { postcode: value }))}
                placeholder={' '}
                isCompact
              />
          )}
          />
          <Element
            label="Country"
            content={(
              <InlineEditableTextfield
                defaultValue={b.country}
                readViewFitContainerWidth
                onConfirm={(value) => (edit(bId, { country: value }))}
                placeholder={' '}
                isCompact
              />
          )}
          />
        </AddressBlock>

      </TeamCustomerEditorContainer>
    </>
  );
};

export default TeamCustomerEditMini;
