import React from 'react';
import { RowType, HeadType, RowCellType } from '@atlaskit/dynamic-table/types';
import { get, isArray } from 'lodash';
import { OrdersQuery } from '../../../graphql/types';
import { Order } from '../types';
import { columnConfigs } from '../config/config';
import isChecked from './isChecked';

/**
 * extend localstorage settings with columns configs
 * also do some extra calculation
 */
const withStorages = (
  c: typeof columnConfigs,
) => c.map((cur) => {
  const key = cur.dataSource.toString().replace(',', '-');
  let group = '';
  if (cur.dataSource.length === 0) {
    group = '';
  } else if (cur.dataSource.length === 1) {
    group = 'Order';
  } else if (cur.dataSource.length === 2) {
    if (cur.dataSource[0] === 'teamChannel' || cur.dataSource[0] === 'channel') {
      group = 'Channel';
    } else if (cur.dataSource[0] === 'teamCustomer') {
      group = 'Customer';
    } else if (cur.dataSource[0] === 'status') {
      group = 'Order';
    }
  }
  return ({
    ...cur,
    key,
    group,
    isChecked: isChecked(key) ?? cur.defaultChecked,
  });
});

export const defaultColumns = withStorages(columnConfigs);

export const getHeadAndRow = (
  d: OrdersQuery | undefined,
  c: typeof defaultColumns,
  handleTableRowClick: (order: Order) => void,
) => {
  const head: HeadType = {
    cells: c.filter((cur) => cur.isChecked).map((column) => {
      const {
        name, key, isSortable, width,
      } = column;
      return {
        key,
        testId: `ts-${key}`,
        content: name,
        isSortable,
        width,
      };
    }),
  };
  const rows: RowType[] = [];

  const orders = d?.Orders?.orders;

  if (isArray(orders)) {
    orders.forEach((order) => {
      const cells: Array<RowCellType> = [];
      // generate row cells
      c.filter((cur) => cur.isChecked).forEach((column) => {
        const {
          key, dataSource, defaultValue, transformData, render,
        } = column;
        const content = get(order, dataSource, defaultValue);
        const transformedContent = (
          transformData as (v: string | number, order: Order) => string | number
        )(content, order);
        if (key === 'orderNumber') {
          const herf = `/order/${order.id}`;
          cells.push({
            key,
            testId: 'cell',
            // eslint-disable-next-line react/jsx-one-expression-per-line
            content: <a href={herf}> {order.orderNumber} </a>,
          });
        } else {
          cells.push({
            key,
            testId: 'cell',
            content: (render as Function)(
              transformedContent,
              key === 'status-name'
                ? `${order.orderNumber}-lv-status`
                : () => handleTableRowClick(order),
            ),
          });
        }
      });
      // push to the rows
      rows.push({
        cells,
        testId: 'row',
        key: order.id,
        onClick: () => {
          handleTableRowClick(order);
        },
      });
    });
  }
  return {
    head,
    rows,
  };
};
