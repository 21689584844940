import gql from 'graphql-tag';
import sharedTeamCustomerFragment from '../../customer/fragments/sharedTeamCustomerFragment';
import fullAddressFragment from '../../address/fragments/fullAddressFragment';
import orderStatusFragment from '../../orderStatus/fragments/orderStatusFragment';
import orderItemFragment from './orderItemFragment';
import shipmentFragment from './shipmentFragment';
import teamChannelFragment from '../../channel/fragments/teamChannelFragment';

const orderFragment = gql`
fragment sharedOrderFragment on Order {
      id,
      amountBase,
      teamChannelID,
      teamID,
      channelID,
      teamCustomerID,
      shippingAddrID,
      billingAddrID,
      marketPlaceFee,
      shippingCarrierCode,
      shipByDate,
      shippingAddress {
        ...fullAddressFragment
      },
      billingAddress {
        ...fullAddressFragment
      },
      status {
        ...fullOrderStatusFragment,
      },
      payments{
        id,
        orderId,
        currency,
        paidAmount
        createdAt,
        paymentMethod {
          name,
        },
        paymentCreatedBy {
          firstName,
          lastName,
          email,
        },
        paidBy {
          name,
        },
      },
      paymentStatus,
      currency,
      orderNumber,
      legacyOrderId,
      totalAmount,
      totalAmtInclTax,
      paidAmount,
      totalPaidAmt,
      comments,
      totalTaxAmt,
      totalShippingAmt,
      shippingTaxAmt,
      shippingServiceCode,
      preferPaymentMethod,
      totalShippingAmtInclTax,
      shippingTaxRate,
      totalDiscountAmt,
      orderDiscountAmt,
      createdAt,
      updatedAt,
      lastModifiedDate, 
      orderItems {
        ...orderItemFragment,
      },
      shipments (orderBy:{createdAt:asc}) {
        ...shipmentFragment,
      },      
      teamChannel {
        ...teamChannelFragment,
      },
      channel{
        id,
        name,
      }
      teamCustomer {
        ...SharedTeamCustomerFragment
      },
}
  ${sharedTeamCustomerFragment}
  ${fullAddressFragment}
  ${orderStatusFragment}
  ${orderItemFragment}
  ${shipmentFragment}
  ${teamChannelFragment}
`;

export default orderFragment;
