import React, { useRef, useState } from 'react';
import Textfield from '@atlaskit/textfield';
import Spinner from '@atlaskit/spinner';
import { isArray, isUndefined } from 'lodash';
import { formatNumber } from 'accounting';
import ClickAwayListener from 'react-click-away-listener';
import {
  SearchResultContainer,
  SearchResultPopUp,
  SearchResultPopUpItem,
} from './ProductFinder.style';
import { useAuth } from '../../utils/useAuth';
import { useProductsLazyQuery } from '../../graphql/types';
import noimage from '../../assets/svg/noimage.svg';
import formatMoneyWithPrefix from '../../helpers/formatMoney';
import { Product } from '../ProductsListing/types';
import { TeamTaxClasses } from '../OrderItemEditor/types';

/**
 * a simple textField for search products of given keywords,
 * and display results in an elegent dropdown that allow for click
 * and will pass the selected product JSON to the callback
 */
interface Props {
  teamChannelId?:string,
  onSelect: (prodcut:Product, teamTaxClasses:TeamTaxClasses)=>void,
  isDisabled?:boolean,
}

const ProductFinder = ({
  teamChannelId, onSelect, isDisabled = false,
}:Props) => {
  const { state: { currentTeamId: teamID } } = useAuth();
  const [debonced, setDebonced] = useState<number>();
  const [isOpen, setIsOpen] = useState(false);
  const [searchProducts, { data, loading }] = useProductsLazyQuery({
    fetchPolicy: 'network-only',
  });
  const products = data?.Products?.products;
  const availableTeamTaxClasses = data?.Products?.teamTaxClasses;
  const keywordRef = useRef<HTMLInputElement>(null);
  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (!isOpen) setIsOpen(true);

    const input = event.currentTarget.value;

    clearTimeout(debonced);
    setDebonced(setTimeout(() => {
      searchProducts({
        variables: {
          teamID: teamID || '',
          skip: 0,
          take: 5,
          keyword: input,
          teamChannelIDs: teamChannelId ? [teamChannelId] : undefined,
        },
      });
    }, 500));
  };

  const handleItemClick = (product:Product, teamTaxClasses:TeamTaxClasses) => {
    onSelect(product, teamTaxClasses);
    setIsOpen(false);
    if (keywordRef.current) {
      keywordRef.current.value = '';
    }
  };
  return (
    <>
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <Textfield
          placeholder="Enter product name or sku"
          onChange={handleOnChange}
          isDisabled={isDisabled}
          ref={keywordRef}
        />
      </ClickAwayListener>
      {isOpen
        && (
        <SearchResultContainer>
          <SearchResultPopUp>
            {(loading || !isArray(products)) && <Spinner size="small" />}
            {
             isArray(products) && products.length === 0
              && !loading && <div>No product found</div>
            }
            {products?.map((p, i) => {
              const [img] = p.images;
              // take the current teamChannelProduct
              const [tcp] = p.teamChannelProducts.filter(
                (cur) => cur.teamChannel.id === teamChannelId,
              );

              if (isUndefined(tcp) && teamChannelId) {
                return <></>;
              }

              return (
                <SearchResultPopUpItem
                  onClick={() => handleItemClick(p, availableTeamTaxClasses!)}
                  key={`${p.id}-${i}`}
                >
                  <img src={(img?.url || noimage)} alt="" />
                  <div className="name">{p.name}</div>
                  <div className="sku">
                    <span>
                      SKU:
                      {p.sku}
                    </span>
                  </div>
                  <div className="priceInc">
                    <span>
                      Price Inc.:
                      {formatMoneyWithPrefix(
                        teamChannelId ? tcp.unitPrice : p.unitPrice,
                      )}
                    </span>
                  </div>
                  <div className="soh">
                    <span>
                      Stock:
                      {formatNumber(p.stockOnHand)}
                    </span>
                  </div>
                </SearchResultPopUpItem>
              );
            })}
          </SearchResultPopUp>
        </SearchResultContainer>
        )}
    </>
  );
};

ProductFinder.defaultProps = {
  teamChannelId: undefined,
  isDisabled: false,
};

export default ProductFinder;
