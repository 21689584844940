import React from 'react';
import Form, {
  Field,
  FormFooter,
  ErrorMessage,
  FormHeader,
  FormSection,
  HelperMessage,
} from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import Box from '../Box';

import {
  FormFooterChildContainer,
  FormTitle,
} from './ForgotPassword.styles';

export interface ForgotPasswordFormData {
  email: string;
  error: string;
}

interface ForgotPasswordFormProps{
  handleResetPassword: (formData: ForgotPasswordFormData) => Promise<{
    email: string | undefined;
  } | undefined>,
  error:string,
  message: string | string[] | null | undefined,
}

export default function (props:ForgotPasswordFormProps) {
  const { handleResetPassword, error, message } = props;
  return (
    <Form<ForgotPasswordFormData>
      onSubmit={handleResetPassword}
    >
      {({ formProps, submitting }) => (
        <form {...formProps}>
          <FormHeader>
            <FormTitle>Forgot Your Password?</FormTitle>
            {message
                && (
                <Box style={{ maxWidth: 300, marginTop: 20 }}>
                  <HelperMessage>
                    {message}
                  </HelperMessage>
                </Box>
                )}
          </FormHeader>
          <FormSection>
            <Field
              name="email"
              label="We'll send a recovery link to"
              isRequired
            >
              {({ fieldProps }) => (
                <>
                  <Textfield
                    testId="email"
                    {...fieldProps}
                    type="email"
                    placeholder="Enter email"
                  />
                </>
              )}
            </Field>
          </FormSection>
          {error
            && <ErrorMessage testId="serverError">{error}</ErrorMessage>}
          <FormFooter align="start">
            <FormFooterChildContainer>
              <Button
                type="submit"
                appearance="primary"
                isLoading={submitting}
                shouldFitContainer
              >
                Send recovery link
              </Button>
              <ul>
                <li>
                  <a href="/signin">Return to log in</a>
                </li>
              </ul>
            </FormFooterChildContainer>
          </FormFooter>
        </form>
      )}
    </Form>
  );
}
