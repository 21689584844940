import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import Button from '@atlaskit/button';
import addNotification from '../../utils/addNotification';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import {
  useDeletePaymentMutation,
} from '../../graphql/types';
import formatMoneyWithPrefix from '../../helpers/formatMoney';

const DeleteOrderPaymentModal = ({
  orderId,
  paidAmount,
  paymentId,
  isShipped,
  refetchOrder,
}: {
  orderId: string;
  paidAmount: number;
  paymentId: string;
  isShipped: boolean;
  refetchOrder: () => void;
}) => {
  const [deletePaymentMutation] = useDeletePaymentMutation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [comments, setComments] = React.useState('');
  const [isConfirmWordEntered, setIsConfirmWordEntered] = React.useState(false);
  const handleDelatePayment = () => {
    deletePaymentMutation({
      variables: {
        deletePayment: {
          orderId,
          paymentId,
          comments,
        },
      },
    }).then(() => {
      setIsOpen(false);
      refetchOrder();
    }).catch((err) => {
      addNotification(graphQLErrorsReader(err), 'danger');
    });
  };
  const actions = [
    {
      text: 'Confirm',
      isDisabled: isConfirmWordEntered === false,
      onClick: handleDelatePayment,
    },
    {
      text: 'Cancel',
      onClick: () => setIsOpen(false),
    },
  ];

  const paymentInputHandler = (evt: React.FormEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    if (value.trim() === formatMoneyWithPrefix(paidAmount)) {
      setIsConfirmWordEntered(true);
    } else {
      setIsConfirmWordEntered(false);
    }
  };

  const handleComments = (evt: React.FormEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    setComments(value);
  };

  return (
    <>
      {isOpen}
      <ModalTransition>
        {isOpen && (
          <Modal
            actions={actions}
            onClose={() => setIsOpen(false)}
            heading="Confirm to delete payment?"
            width="medium"
            shouldCloseOnEscapePress={false}
            shouldCloseOnOverlayClick={false}
            appearance="danger"
          >
            <p>
              This action cannot be undone. It would delete the payment.
              Please enter the
              {' "'}
              {formatMoneyWithPrefix(paidAmount)}
              {'" '}
              to continue
            </p>
            <p>
              <Textfield
                testId="test-payment-delete"
                placeholder="please input payment"
                onChange={paymentInputHandler}
              />
            </p>
            <p>Comments</p>
            <p>
              <Textfield
                name="basic"
                testId="test-delete-comments"
                placeholder="please input delete payment comments"
                onChange={handleComments}
              />
            </p>
          </Modal>
        )}
      </ModalTransition>
      <Button
        onClick={() => setIsOpen(true)}
        appearance="subtle-link"
        spacing="compact"
        testId="delete-payment-btn"
        isDisabled={isShipped}
      >
        <TrashIcon label="Delete" size="small" />
      </Button>
    </>
  );
};

export default DeleteOrderPaymentModal;
