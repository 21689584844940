import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { useHistory } from 'react-router';
import { useCancelTeamChargeMutation } from '../../graphql/types';
import { useAuth } from '../../utils/useAuth';
import addNotification from '../../utils/addNotification';

interface CancelChargeInputProps {
  isOpen: boolean,
  close: any, //eslint-disable-line
  chargeID: string
}

const CancelCharge = (props: CancelChargeInputProps) => {
  const { isOpen, close, chargeID } = props;
  const { state } = useAuth();
  const teamId = state.currentTeamId || '';
  const history = useHistory();
  const [cancelTeamChargeMutation] = useCancelTeamChargeMutation();

  const handleCancelCharge = async () => {
    await cancelTeamChargeMutation(
      {
        variables: {
          teamId,
          teamChargeId: chargeID,
        },
      },
    ).then(
      () => {
        close();
        history.push('/Billing');
        window.location.reload();
        addNotification('Your plan cancellation for Standard has been successfully processed', 'success');
      },
    ).catch(
      (err) => {
        addNotification(err.toString(), 'danger');
        close();
      },
    );
  };

  const action = [
    {
      text: 'Confirm',
      onClick: handleCancelCharge,
    },
    {
      text: 'Close',
      onClick: () => close(),
    },
  ];

  return (
    <ModalTransition>
      {
        isOpen
        && (
        <Modal
          actions={action}
          onClose={close}
          heading="Are you sure you want to cancel"
          width="medium"
          shouldCloseOnEscapePress={false}
          shouldCloseOnOverlayClick={false}
        >
          <div className="modal-container">
            <p>
              If you are sure about canceling, please click &quot;Confirm&quot; to proceed with the cancellation. If you have changed your mind or have any questions, please click &quot;Close&quot;.
            </p>
          </div>
        </Modal>
        )
      }
    </ModalTransition>
  );
};

export default CancelCharge;
