import React from 'react';
import Color from 'color';
import { colors } from '@atlaskit/theme';
import styled from 'styled-components';

interface TagProps {
  text: string,
  color: string,
  testId:string,
}

const TagContainer = styled.div`
    width:80px;
    font-size:11px;
    padding: 3px 0px 3px 0px;
    text-transform:uppercase;
    font-weight:bold;
    border-radius:5px;
    overflow:hidden;
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right:5px;
`;

const Tag = (props: TagProps) => {
  const { color, text, testId } = props;

  const style = {
    backgroundColor: color,
    color: Color(color).isDark() ? colors.N0 : colors.N900,
  };

  return (
    <TagContainer
      data-testid={testId}
      style={style}
    >

      {text}
    </TagContainer>
  );
};

export default Tag;
