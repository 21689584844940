import React from 'react';
import { RowType, HeadType } from '@atlaskit/dynamic-table/types';
import { formatNumber } from 'accounting';
import DynamicTable from '@atlaskit/dynamic-table';
import styled from 'styled-components';
import EditIcon from '@atlaskit/icon/glyph/edit';
import ProductThumbnail from '../../ProductThumbnail/ProductThumbnail';
import formatMoneyWithPrefix from '../../../helpers/formatMoney';
import { Order } from '../../OrdersListing/types';
import PaymentStatusTag from '../../Widgets/OrderPaymentStatusTag/OrderPaymentStatusTag';
import OrderTaxBaseSelector from '../OrderTaxBaseSelector';
import {
  useGetTeamTaxClassesQuery,
  useUpdateOrderMutationMutation,
  TaxAmountBaseEnum,
} from '../../../graphql/types';
import OrderItemTaxClassSelector from '../OrderItemTaxClassSelector';
import EditableShipingAmount from './EditableShipingAmount';
import EditDiscountBtn from './EditDiscountBtn';

const OrderItemsContainer = styled.div`
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .sku {
      color:rgb(107, 119, 140);
      font-size:11px;
      line-height:16px;
    }
    .bold {
      font-weight:500;
    }
    .right {
      text-align:right;
    }   
    .light {
      color:rgb(107, 119, 140);
    }    
    margin-bottom:20px; 
    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-bottom:10px;
    } 
    table {
      tbody {
        tr {
          td {
            color: rgb(23, 43, 77);
            line-height: 20px;
            vertical-align:middle;            
          }
        }
      }
    }
`;

interface Props {
  order:Order,
  refetch?:()=>void,//eslint-disable-line
  title:React.ReactNode,
}

export default ({ order, refetch, title }:Props) => {
  const {
    id, orderItems, teamID, amountBase, channel,
  } = order;
  const { name: channelName } = channel;

  const initOrderUpdatePrams = {
    orderId: id,
    taxBase: amountBase,
    orderItems: orderItems.map((oi) => ({
      orderItemId: oi.id,
      taxClassId: oi.productTaxClassId as string,
    })),
  };

  const [updateOrderMutationMutation] = useUpdateOrderMutationMutation();

  const {
    data: teamTaxClassData,
    loading: teamTaxClassLoading,
  } = useGetTeamTaxClassesQuery({
    variables: {
      teamId: teamID,
    },
  });

  const updateTaxClassCallback = (orderItemId: string, taxClassId: string) => {
    const tmpOrderItems = initOrderUpdatePrams.orderItems
      .map((oi) => (oi.orderItemId === orderItemId ? {
        ...oi,
        taxClassId,
      } : oi));
    updateOrderMutationMutation({
      variables: {
        order: {
          ...initOrderUpdatePrams,
          orderItems: [...tmpOrderItems],
        },
      },
    }).then(() => {
      if (refetch) {
        refetch();
      }
    });
  };

  const generateHeadCell = () => {
    const cells = [
      {
        content: '',
        shouldTruncate: false,
      },
      {
        content: 'Product',
        shouldTruncate: true,
      },
      {
        content: 'Unit Price (Inc.)',
      },
      {
        content: 'Qty',
      },
    ];

    if (channelName === 'eBay') {
      cells.push(
        {
          content: 'Tax Rate',
        },
      );
    }

    cells.push(
      {
        content: 'Total (Inc.)',
      },
    );

    return cells;
  };

  const orderItemHead: HeadType = {
    cells: generateHeadCell(),
  };

  const orderItemRows: Array<RowType> = orderItems.map((cur) => {
    const cells:RowType['cells'] = [
      {
        content: <ProductThumbnail
          teamID={teamID}
          productId={cur.productID}
          height={36}
          width={36}
        />,
      },
      {
        content:
  <div style={{ maxWidth: 350 }}>
    <div>{cur.name}</div>
    {cur.sku && (
    <div className="sku">
      <a href={`/product/${cur.productID}`} target="_blank" rel="noopener noreferrer" style={{ color: '#7d7d7d', fontWeight: 'bold' }}>{`SKU: ${cur.sku}`}</a>
    </div>
    )}
  </div>,
      },
      {
        content: formatMoneyWithPrefix(cur.unitPrice),
        testId: `${cur.sku}-unitPrice`,
      },
      {
        content: formatNumber(cur.qty),
        testId: `${cur.sku}-qty`,
      },
    ];

    if (channelName === 'eBay') {
      cells.push(
        {
          content: teamTaxClassLoading === false
          && (
          <OrderItemTaxClassSelector
            teamTaxClasses={teamTaxClassData
              && teamTaxClassData?.GetTeamTaxClasses
            && teamTaxClassData.GetTeamTaxClasses.taxClasses
              ? teamTaxClassData.GetTeamTaxClasses.taxClasses
                .filter((tc) => tc.isForShipping === false && tc.name !== 'GST Excluded Goods') : []}
            orderItemId={cur.id}
            taxClassId={cur.productTaxClassId}
            onChange={updateTaxClassCallback}
          />
          ),
        },
      );
    }
    cells.push({ content: formatMoneyWithPrefix(cur.totalAmtInclTax) });
    return ({ cells });
  });

  const withShippingAndTotal = (r:typeof orderItemRows) => [
    ...r,
    {
      cells: [
        {
          colSpan: orderItemHead.cells.length - 1,

          content: <div className="bold right">Shipping (Inc.)</div>,
        },
        {
          content: <EditableShipingAmount order={order} />,
        },
      ],
    },
    {
      cells: [
        {
          colSpan: orderItemHead.cells.length - 1,
          content: <div className="bold right">Include GST</div>,
        },
        {
          content: <div>{formatMoneyWithPrefix(order.totalTaxAmt)}</div>,
        },
      ],
    },
    {
      cells: [
        {
          colSpan: orderItemHead.cells.length - 1,
          content: <div className="bold right">Discount</div>,
        },
        {
          content:
  <>
    {formatMoneyWithPrefix(order.orderDiscountAmt)}
    {
    ['PAID', 'SHIPPED', 'CANCELLED', 'REFUNDED'].includes(order.status.code) ? null
      : (
        <EditDiscountBtn
          orderId={order.id}
          orderAmount={order.totalAmtInclTax}
          onFinish={() => {
            if (refetch) {
              refetch();
            }
          }}
        >
          <EditIcon label="edit" size="small" />

        </EditDiscountBtn>
      )
}
  </>,
        },
      ],
    },
    {
      cells: [
        {
          colSpan: orderItemHead.cells.length - 1,
          content:
  <div className="bold right">
    <PaymentStatusTag status={order.status.code} />
    {' '}
    Total (Inc.)
    {' '}
  </div>,
        },
        {
          content: <div className="bold">{formatMoneyWithPrefix(order.totalAmtInclTax)}</div>,
        },
      ],
    },
    {
      cells: [
        {
          colSpan: orderItemHead.cells.length - 1,

          content: <div className="bold right light">Transaction Fee </div>,
        },
        {
          content: <div className="light">{`(${formatMoneyWithPrefix(order.marketPlaceFee)})`}</div>,
        },
      ],
    },
  ];

  const updateTaxBaseCallback = (taxBase: string) => {
    updateOrderMutationMutation({
      variables: {
        order: {
          ...initOrderUpdatePrams,
          taxBase: taxBase as TaxAmountBaseEnum,
        },
      },
    }).then(() => {
      if (refetch) {
        refetch();
      }
    });
  };

  return (
    <OrderItemsContainer>
      <div className="flex">
        <h5>{title}</h5>
        {order.channel.name === 'eBay'
          && (
          <div style={{ width: 230 }}>
            <OrderTaxBaseSelector
              onChange={updateTaxBaseCallback}
              value={amountBase}
            />
          </div>
          )}
      </div>
      <DynamicTable
        head={orderItemHead}
        rows={withShippingAndTotal(orderItemRows)}
        testId="orderItemsDetailTable"
      />
    </OrderItemsContainer>
  );
};
