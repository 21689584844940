import React from 'react';
import JiraFailedBuildStatusIcon from '@atlaskit/icon/glyph/jira/failed-build-status';
import { isUndefined } from 'lodash';
import { CommonProps } from '../types';
import { FlexBox } from '../styles';

const QtyIncreasedBy = (
  { lineItem }:CommonProps,
) => {
  const newQty = lineItem.response
                    ?.AdjustInventory.product
                    ?.stockOnHand;
  const oldQty = lineItem.response
                    ?.AdjustInventory
                    .sohBeforeMutation;

  if (isUndefined(newQty) || isUndefined(oldQty)) {
    return (
      <FlexBox>
        <JiraFailedBuildStatusIcon
          label="error"
          primaryColor="red"
          testId="errorIcon"
        />
      </FlexBox>
    );
  }

  return (<>{newQty - oldQty}</>);
};
export default QtyIncreasedBy;
