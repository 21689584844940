import React, { useState, useCallback } from 'react';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import TextField from '@atlaskit/textfield';
import { Grid, GridColumn } from '@atlaskit/page';
import Form, {
  ErrorMessage,
  Field,
  FormFooter,
} from '@atlaskit/form';
import queryString from 'query-string';
import { isString } from 'lodash';
import axios from 'axios';
import Modal, {
  ModalBody, ModalHeader, ModalTitle, ModalTransition,
} from '@atlaskit/modal-dialog';
import { Box, Logo } from '../components';
import { MutationLoginArgs } from '../graphql/types';
import { useAuth } from '../utils/useAuth';
import graphQLErrorsReader from '../utils/graphQLErrorsReader';
import FriendlyErrorMessage from '../components/Signin/FriendlyErrorMessage';
import xeroSignIn from '../assets/png/xeroSignIn.png';

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

interface FormState {
  email: string;
  password: string;
}

const SignIn = () => {
  const [error, setError] = useState('');
  const { signIn, state: { user } } = useAuth();
  const [formState, setFormState] = useState<FormState>({ email: '', password: '' });

  const history = useHistory();
  let { msg } = queryString.parse(window.location.search);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { entry, shopify_connect, create_new_user } = queryString.parse(window.location.search);
  let showShopifyPop = false;
  if (isString(msg) && msg === 'relogin') {
    msg = 'Please login to verify your account.';
    showShopifyPop = shopify_connect === 'true' && create_new_user === 'true';
  } else {
    msg = '';
  }

  const [isOpenShopify, setIsOpenShopify] = useState(
    showShopifyPop === true,
  );

  const close = () => {
    setIsOpenShopify(false);
  };

  const handleSignIn = useCallback(async (data: MutationLoginArgs) => {
    setFormState(data);
    await signIn(data)
      .then((res) => {
        /**
         * on every login, reset the hasRefreshTokenAlertShown to false
         */
        window.localStorage.setItem('hasTokenAlertShown', 'false');
        /**
         * we can't mount the alert component in this page
         * because this page will unmount soon.
         * we mount alert component in 'Home page'
         * this alert component will check the flag to
         * tell if need to popup or not
         */
        let defaultTeamChannel = '';
        let onBoarded = false;
        const teamUser = res?.teams;

        if (teamUser && teamUser.length > 0) {
          try {
            // index 0 can not exist
            const teamChannel = teamUser[0]
              .team
              .teamChannels
              // attempted to filter out 'in-store' type channel
              // in order to avoid give incorrect onboarding queryString
              .filter((t) => t.channel.name === 'eBay')[0];

            defaultTeamChannel = teamChannel.id;
            onBoarded = teamChannel.onBoarded;
            if (!onBoarded) {
              history.push(`/connect/ebay/${defaultTeamChannel}?redirectPath=/config/ebay/${defaultTeamChannel}`);
            } else if (isString(entry)) {
              history.push(entry.includes('signin') ? '/' : entry);
            } else {
              history.push('/');
            }
          } catch (err) {
            // failover
            history.push('/');
          }
        }
      })
      .catch((apolloError: ApolloError) => {
        setError(graphQLErrorsReader(apolloError));
      });
  }, [signIn, history, entry]);

  const renderFooterLinks = () => (
    <div style={{ marginTop: '16px' }}>
      <Grid>
        <GridColumn>
          <div style={{ textAlign: 'left' }}>
            <a href="/signin/forgot/">Forgot password?</a>
          </div>
        </GridColumn>
        <GridColumn>
          <div style={{ textAlign: 'right' }}>
            <a href="/signup">Signup</a>
          </div>
        </GridColumn>
      </Grid>
    </div>
  );

  const xeroState = {
    xeroLogin: 'ok',
    redirectUrl: '/',
  };
  const REACT_APP_GRAPHQL_SERVER_HOST = process.env.REACT_APP_GRAPHQL_SERVER_HOST || 'http://localhost:4000';
  const xeroConnect = async () => {
    const res = await axios.get(
      `${REACT_APP_GRAPHQL_SERVER_HOST}/oauth/xero/url?state=${JSON.stringify(xeroState)}`,
    );

    const { url } = res.data;
    if (url) {
      window.location.assign(url);
    }
  };

  return (
    <>
      <Wrapper>
        {user && <Redirect push to="/home" />}
        <Logo />
        <Box style={{ padding: '30px 40px 40px 40px', width: '240px' }}>
          {msg && (
          <ErrorMessage>
            {msg}
            {' '}
          </ErrorMessage>
          )}
          <Form<FormState>
            onSubmit={async (data) => { await handleSignIn(data); }}
          >
            {({ formProps, submitting }) => (
              <form {...formProps}>
                <Field name="email" isRequired defaultValue="">
                  {({ fieldProps }) => (
                    <TextField testId="email" autoComplete="off" {...fieldProps} placeholder="Enter your email" />
                  )}
                </Field>
                <Field name="password" defaultValue="" isRequired>
                  {({ fieldProps }) => (
                    <div style={{ marginTop: '16px' }}>
                      <TextField testId="password" type="password" {...fieldProps} placeholder="Enter your password" />
                    </div>
                  )}
                </Field>
                {error && FriendlyErrorMessage(error, formState.email)}
                <FormFooter>
                  <Button type="submit" appearance="primary" isLoading={submitting} shouldFitContainer>
                    Login
                  </Button>
                </FormFooter>
                {renderFooterLinks()}
              </form>
            )}
          </Form>
          <div style={{ marginTop: '16px' }}>
            <Button
              iconBefore={<img src={xeroSignIn} alt="xeroLogin" data-testid="xeroLogin" width="100%" />}
              style={{ background: '#fff' }}
              onClick={xeroConnect}
              shouldFitContainer
            />
          </div>
        </Box>
      </Wrapper>
      <ModalTransition>
        {
          isOpenShopify && (
            <Modal
              actions={[{ text: 'Close', onClick: close }]}
              width="medium"
              shouldCloseOnEscapePress={false}
              shouldCloseOnOverlayClick={false}
            >
              <ModalHeader>
                <ModalTitle>Verify Your Account</ModalTitle>
              </ModalHeader>
              <ModalBody>
                We have already create the new MERP account for you, please check your email to verify the account to sign in
              </ModalBody>
            </Modal>
          )
        }
      </ModalTransition>

    </>
  );
};

export default SignIn;
