import React from 'react';
import { Controller, Control } from 'react-hook-form';
import ImageUploader, { supportedImageFormatArr } from '../ImageUploader/ImageUploader';
import { ErrorMsg, LabelContainer } from '../ProductCreationFormContainer/ProductCreationForm.styles';
import { ErrorType } from './types';

const ImageUploadWithLabelAndError = ({
  name, label, control, errors, defaultValue, setValue, isRequired, maxLimit, isDisable,
}: {
  name: string, label: string, control: Control<Record<string, string>>,
  errors: ErrorType, defaultValue: Array<string>, setValue: Function,
  isRequired: boolean, maxLimit: number, isDisable: boolean
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={{
      validate: {
        imageIsRequired: (imgArr) => (isRequired === true && !(imgArr && Array.isArray(imgArr) && imgArr.length > 0) ? 'At least one image is required.' : undefined),
        maxLimitReached: (imgArr) => (!(imgArr && Array.isArray(imgArr) && imgArr.length <= maxLimit) ? `Max ${maxLimit} of photos are supported.` : undefined),
        lowResolutionDetected: (imgArr) => (!(imgArr && Array.isArray(imgArr)
        && !imgArr.some((img) => img && img.isLowRes && img.isLowRes === true))
          ? 'Please upload high-resolution photos that are at least 500 pixels on the longest side.' : undefined),
        imageUploading: (imgArr) => (!(imgArr && Array.isArray(imgArr)
        && !imgArr.some((img) => img && img.loading && img.loading === true))
          ? 'Please wait all images uploaded.' : undefined),
        fileTypeNotSupported: (imgArr) => (!(imgArr && Array.isArray(imgArr)
        && !imgArr.some((img) => img && img.isUnSupportedFormat
        && img.isUnSupportedFormat === true))
          ? `Please upload make sure all images are in supported formats like ${supportedImageFormatArr.join(', ')}.` : undefined),
      },
    }}
    as={(
      <>
        <LabelContainer>{label}</LabelContainer>
        <ImageUploader
          initImages={defaultValue}
          maxLimit={maxLimit}
          onUpdate={(imgs) => {
            setTimeout(() => {
              if (imgs && imgs.length > 0) {
                setValue(name, imgs, {
                  shouldValidate: true,
                });
              } else {
                setValue(name, [], {
                  shouldValidate: true,
                });
              }
            }, 0);
          }}
          isDisable={isDisable}
        />
        {errors[name] && (
          <ErrorMsg>
            {errors[name].message}
          </ErrorMsg>
        )}
      </>
      )}
  />
);

export default ImageUploadWithLabelAndError;
