import React from 'react';
import ebay from '../../../assets/svg/ebay.svg';
import magento from '../../../assets/svg/magento.svg';
import amazon from '../../../assets/svg/amazon.svg';
import wooCommerce from '../../../assets/svg/wooCommerce.svg';
import shopify from '../../../assets/svg/shopify.svg';
import { TeamChannelProduct } from '../types';
import MarketplaceStatusContainer from '../styles/MarketplaceStatusContainer';
import shop from '../../../assets/svg/shop.svg';

export const ChannelLogo = (
  { name, status = 'published' }: { name: string, status?:string},
) => {
  const style = {
    filter: `grayscale(${status === 'published' ? '0' : '100'}%)`,
    fill: 'red',
    padding: '0 2px',
    width: '12px',
    height: 'auto',
  };
  switch (name) {
    case 'eBay': {
      return (
        <img className="logo" src={ebay} alt="ebay" style={{ ...style, width: '1.7rem' }} />
      );
    }
    case 'in-store': {
      return (
        <img className="logo" src={shop} alt="in-store" style={style} />
      );
    }
    case 'Magento': {
      return (
        <img className="logo" src={magento} alt="magento" style={style} />
      );
    }
    case 'Amazon': {
      return (
        <img className="logo" src={amazon} alt="amazon" style={style} />
      );
    }
    case 'WooCommerce': {
      return (
        <img className="logo" src={wooCommerce} alt="wooCommerce" style={style} />
      );
    }
    case 'Shopify': {
      return (
        <img className="logo" src={shopify} alt="shopify" style={style} />
      );
    }
    default: {
      return (
        <img className="logo" src={shop} alt="in-store" style={style} />
      );
    }
  }
};

const MarketplaceStatus = (
  { teamChannelProducts }:
  {teamChannelProducts:Array<TeamChannelProduct>},
) => (
  <MarketplaceStatusContainer>
    {teamChannelProducts.sort((a, b) => {
      // to ensure each channel logo displayed in a propery order
      // so will will have an unified looking
      const nameA = a.teamChannel.channel.name.toUpperCase();
      const nameB = b.teamChannel.channel.name.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    }).map((teamChannelProduct) => (
      <ChannelLogo
        key={teamChannelProduct.id}
        name={teamChannelProduct.teamChannel.channel.name}
        status={teamChannelProduct.status}
      />
    ))}
  </MarketplaceStatusContainer>
);

export default MarketplaceStatus;
