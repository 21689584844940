import React from 'react';
import OrderDetailContainer, { OrderInfoContainer } from './OrderDetails.style';
import { Order } from '../OrdersListing/types';
import MerpLogs from '../Logs/MerpLogs';
import OrderBreadcrumb from './widges/OrderBreadcrumb';
import Shipments from './widges/Shipments';
import OrderItems from './widges/OrderItems';
import OrderComment from './widges/OrderComment';
import OrderToolBar from './widges/OrderToolBar';
import OrderAddressDetails from './widges/OrderAddressDetails';
import OrderInfo from './widges/OrderInfo';
import UpdateAddressIconButton from './widges/UpdateAddressIconButton';
import UpdateOrderItemIconButton from './widges/UpdateOrderItemIconButton';
import OrderPaymentList from '../OrderPaymentList/OrderPaymentList';
import OrderTicketsList from '../OrderTicketsList/OrderTicketsList';

const event = new CustomEvent('refetchLogs');
interface OrderDetailsProps {
  order:Order,
  refetch?:()=>void,//eslint-disable-line
}
export default (props:OrderDetailsProps) => {
  const { order, refetch } = props;
  const {
    id,
    orderNumber,
    shippingAddress,
    billingAddress,
    teamCustomer,
    comments,
    teamID,
  } = order;

  const markOrderShipped = () => {
    // to update the data after shipped
    if (refetch) {
      refetch();
    }
    // tell the log component to refetch
    document.body.dispatchEvent(event);
  };

  const refetchOrder = () => {
    if (refetch) {
      refetch();
    }
    document.body.dispatchEvent(event);
  };

  const shipping = shippingAddress;
  const billing = billingAddress;

  const ShippingTitle = () => (
    <>
      <span>Ship to</span>
      {
        (order.status.code === 'UNPAID' || order.status.code === 'PAID')
        && order.channel.name === 'in-store'
        && <UpdateAddressIconButton address={shipping} />
}
    </>

  );

  const BillingTitle = () => (
    <>
      <span>Bill to</span>
      {
        (order.status.code === 'UNPAID' || order.status.code === 'PAID')
        && order.channel.name === 'in-store'
        && <UpdateAddressIconButton address={billing} />
}
    </>

  );

  const OrderItemsTitle = () => (
    <>
      <span>Products</span>
      {
        // maybe only unpiad order can update order item
        // once we have a solution to deal with overpayment or short payment
        // we may then allow to update paid order!
        // Otherwise maybe trouble maker...
        (order.status.code === 'UNPAID')
        && order.channel.name === 'in-store'
        && <UpdateOrderItemIconButton order={order} />
}
    </>

  );

  return (
    <OrderDetailContainer data-testid="orderDetail">
      {/* breadcrumbs */}
      <OrderBreadcrumb
        statusName={order.status.name}
        orderNumber={orderNumber}
        orderId={id}
      />
      {/* Toolbars */}
      <OrderToolBar
        order={order}
        markOrderShipped={markOrderShipped}
        refetchOrder={refetchOrder}
      />
      {/* order summary */}
      <OrderInfoContainer>
        <OrderInfo order={order} />
        <OrderAddressDetails
          address={shipping}
          teamCustomer={teamCustomer}
          title={<ShippingTitle />}
        />
        <OrderAddressDetails
          address={billing}
          teamCustomer={teamCustomer}
          title={<BillingTitle />}
        />
      </OrderInfoContainer>
      {/* delivery instruments */}
      <OrderComment comments={comments} />
      {/* Order items */}
      <OrderItems order={order} title={<OrderItemsTitle />} refetch={refetch} />
      {/* Shipments */}
      <Shipments order={order} markOrderShipped={markOrderShipped} />
      {/* Audit logs */}
      <OrderPaymentList order={order} refetchOrder={refetchOrder} />
      <OrderTicketsList teamID={teamID} orderId={orderNumber} />
      <MerpLogs
        teamId={order.teamID}
        tableId={order.id}
        title={<h5>Activity</h5>}
      />
    </OrderDetailContainer>
  );
};
