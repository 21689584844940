import React, {
  useContext, useEffect, createContext, useReducer,
} from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import { Order } from '../OrdersListing/types';
import { ShippingContextType } from './types';
import {
  isShippable, getButtonName, initialState, reducer, getOrderLineItemConfig,
} from './helper';
import ShippingHooks from './Views/ShippingHooks';
import Error from './Views/Error';

const ShippingContext = createContext<ShippingContextType>(
  {} as ShippingContextType,
);

interface Props {
  order: Order,
  onShipped:()=>void,
  // eslint-disable-next-line react/require-default-props
  isAlwaysClickable?:boolean,
  // eslint-disable-next-line react/require-default-props
  overrideText?:string,
  // eslint-disable-next-line react/require-default-props
  icon?:React.ReactElement,
}
const ShippingButton = (
  {
    order,
    onShipped,
    isAlwaysClickable = false,
    overrideText,
    icon,
  }:Props,
) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, order });
  const open = () => {
    // re init some core variables on modal mount every time.
    dispatch({ type: 'setStage', payload: 'preview' });
    dispatch({ type: 'setIsModalOpen', payload: true });
  };
  const reset = () => dispatch({ type: 'reset' });
  // re-initialize the state on mounting / and reset on unmount
  useEffect(() => {
    dispatch({ type: 'setIsShipButtonDisabled', payload: !isShippable(order.status.code, isAlwaysClickable) });
    dispatch({ type: 'setOrderId', payload: order.id });
    dispatch({ type: 'setLineItemsConfig', payload: getOrderLineItemConfig(order, []) });
    // on order props change
    dispatch({ type: 'setOrder', payload: order });
    dispatch({ type: 'setQuoteId', payload: '' });
    dispatch({ type: 'setBoxes', payload: getOrderLineItemConfig(order, []) });
    dispatch({ type: 'setQuotes', payload: undefined });
    dispatch({ type: 'setPostShipment', payload: undefined });

    return () => reset();
  }, [order, isAlwaysClickable]);

  // /**
  //  * auto calculate total weight on item weight input changes
  //  */
  // useEffect(() => {
  //   const totalWeights = () => {
  //     let total = 0;
  //     state.lineItemsConfig.forEach((cur) => {
  //       if (cur.weight && !cur.isWeightInvalid) {
  //         total += Number.isNaN(Number(cur.weight)) ? 0 : Number(cur.weight);
  //       }
  //     });
  //     return total;
  //   };
  //   dispatch({
  //     type: 'setTotalWeight',
  //     payload: totalWeights(),
  //   });
  // }, [state.lineItemsConfig]);

  /**
   * on shipping method change
   */
  useEffect(() => {
    dispatch({ type: 'setStage', payload: 'preview' });
    // update the primary button state to true
    dispatch({ type: 'setIsPrimaryButtonDisabled', payload: false });
    dispatch({ type: 'setQuotes', payload: undefined });
  }, [state.shippingMethod]); //eslint-disable-line

  return (
    <ShippingContext.Provider value={{ state, dispatch }}>
      <Button
        iconBefore={icon}
        onClick={open}
        isDisabled={state.ui.isShipButtonDisabled}
        testId="ship-button"
      >
        {getButtonName(order.status.code, overrideText)}
      </Button>

      <ModalTransition>
        {state.ui.isModalOpen && (
        <Modal
          heading="Shipping"
          shouldCloseOnEscapePress={false}
          shouldCloseOnOverlayClick
          width="large"
          scrollBehavior="outside"
          autoFocus={false}
          testId="shipping-modal"
        >
          <Error />
          <ShippingHooks onShipped={onShipped} />
        </Modal>
        )}
      </ModalTransition>
    </ShippingContext.Provider>
  );
};
export default ShippingButton;

export const useShipping = () => useContext(ShippingContext);
