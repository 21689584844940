import React from 'react';

type Props = {
  width?: number,
};

const Logo = ({ width }: Props) => (
  <img
    src={`${process.env.PUBLIC_URL}/img/logo.png`}
    style={{ width: width || 180, margin: '8px' }}
    alt="Logo"
  />
);

export default Logo;
