import React, { useState } from 'react';
import Modal, { ModalTransition, ActionProps } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import { Order } from '../OrdersListing/types';

/**
 * if order has not been shipped, enable cancel button
 * otherwise, disable cancel button
 */
const isCancellable = (statusCode:string) => {
  const shippableCodes = ['UNPAID', 'PAID', 'PACKED'];
  if (shippableCodes.includes(statusCode)) {
    return true;
  }
  return false;
};

const CancelOrderBtn = (
  { order }:{ order: Order},
) => {
  const [isOpen, setIsopen] = useState(false);
  const { legacyOrderId, orderNumber } = order;
  const [itemId, transId] = legacyOrderId.split('-');
  const cancelUrl = transId && itemId
    ? `https://www.ebay.com.au/Cancel/Start?transId=${transId}&itemId=${itemId}`
    : `https://www.ebay.com.au/sh/ord/details?orderid=${orderNumber}`;

  const [
    isCancelButtonDisabled,
    setIsCancelButtonDisabled,
  ] = useState(!isCancellable(order.status.code));

  const close = () => {
    setIsopen(false);
  };

  const open = () => {
    setIsopen(true);
  };

  const handleContinue = () => {
    window.open(cancelUrl);
    close();
  };

  const actions:Array<ActionProps> = [
    {
      text: 'Cancel',
      onClick: close,
      appearance: 'default',
    },
    {
      text: 'Continue',
      appearance: 'primary',
      onClick: handleContinue,
    },
  ];

  React.useEffect(() => {
    setIsCancelButtonDisabled(!isCancellable(order.status.code));
  }, [order])//eslint-disable-line
  if (
    !order.channel.name.includes('eBay')
    || (
      ['SHIPPED', 'CANCELLED', 'REFUNDED'].includes(order.status.code)
        && order.channel.name.includes('eBay')
    )
  ) {
    return <></>;
  }

  return (
    <>

      <Button
        iconBefore={<CrossCircleIcon label="cancel" />}
        isDisabled={isCancelButtonDisabled}
        onClick={open}
      >
        Cancel & Refund
      </Button>

      <ModalTransition>
        {isOpen && (
        <Modal
          heading="Order Cancellation"
          actions={actions}
          width="medium"
          appearance="danger"
          autoFocus={false}
        >
          <p>
            Upon clicking the
            {' '}
            <strong>Continue</strong>
            {' '}
            button, system will redirect you to your
            eEay store to cancel & refund this order.
          </p>
          <p>
            Once done, MERP will sync the
            order status within a few minutes.
          </p>
          <p>Do you wish to continue?</p>

        </Modal>
        )}
      </ModalTransition>
    </>
  );
};
export default CancelOrderBtn;
