import React from 'react';
import {
  useParams,
} from 'react-router-dom';
import ProductDetailsContainer from '../../components/ProductDetailsContainer/ProductDetailsContainer';
import { useAuth } from '../../utils/useAuth';

const ProductDetailsPage = () => {
  const { productId } = useParams();
  const { state: { currentTeamId: teamID } } = useAuth();
  if (!teamID) {
    return <p>Team ID not found</p>;
  }
  return <ProductDetailsContainer productId={productId} teamID={teamID!} />;
};

export default ProductDetailsPage;
