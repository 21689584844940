import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

interface PlanUpgradeInputProps {
  isOpen: boolean,
  close: any, //eslint-disable-line
}

const PlanUpgrade = (props: PlanUpgradeInputProps) => {
  const {
    isOpen, close,
  } = props;

  const action = [
    {
      text: 'Close',
      onClick: () => close(),
    },
  ];

  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          actions={action}
          onClose={close}
          heading="Want to continue use?"
          width="medium"
          shouldCloseOnEscapePress={false}
          shouldCloseOnOverlayClick={false}
        >
          <div className="modal-container">
            <p>
              Your current plan has expired, please visit the
              {' '}
              <a href="/Billing" rel="noopener noreferrer" style={{ color: '#00000', fontWeight: 'bold' }}>
                billing page
              </a>
              {' '}
              to reactivate your account.
            </p>
          </div>
        </Modal>

      )}
    </ModalTransition>

  );
};

export default PlanUpgrade;
