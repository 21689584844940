import React from 'react';
import { Link } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import { ErrorMessage } from '@atlaskit/form';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active';
import { useAuth, useSubscription } from '../../utils/useAuth';
import auspostLogo from '../../assets/svg/auspost.svg';
import fastwayLogo from '../../assets/png/fastway.png';
import { Card, Container } from './CourierIntegrationList.style';
import {
  useGetTeamCouriersAndSystemsCouriersQuery,
} from '../../graphql/types';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import noImage from '../../assets/svg/noimage.svg';

const SUPPORTED_COURIERS = [
  {
    name: 'Australia Post',
    code: 'AustraliaPost',
  },
  {
    name: 'Aramex Courier',
    code: 'FASTWAYCOURIERS',
  },
];

interface LogisticsCardProps {
  logo:string,
  name:string,
  code:string,
  active:boolean,
  configured:boolean,
  tested:boolean,
  courierId:string,
  isDisabled:boolean,
}

export const LogisticsCard = (props:LogisticsCardProps) => {
  const {
    logo,
    name,
    active,
    configured,
    tested,
    code,
    courierId,
    isDisabled,
  } = props;

  const paths:{[k:string]:string} = {
    AustraliaPost: 'auspost',
    FASTWAYCOURIERS: `fastway/${courierId}`,
  };
  return (
    <Card>
      <div className="head">
        <img className="logo" src={logo} alt={name} />
        <div className="name">
          <div className="value">{name}</div>
          <div className="subtitle">
            Integration with
            {' '}
            {name}
          </div>
        </div>
        <div className="edit">
          {
            isDisabled === false ? (
              <Link to={`/integration/${paths[code] || ''}`}>
                <EditFilledIcon
                  label="edit"
                  primaryColor="#0052CC"
                />
              </Link>
            )
              : (
                <EditFilledIcon
                  label="edit"
                  primaryColor="grey"
                />
              )
          }

        </div>
      </div>
      <div className="state">
        <div className="state-item feeedback">
          <div className="state-item-name">Configured</div>
          <div className="state-item-value">
            <PresenceActiveIcon label="status" primaryColor={configured ? 'green' : 'grey'} />
          </div>
        </div>
        <div className="state-item revenue">
          <div className="state-item-name">Tested</div>
          <div className="state-item-value">
            <PresenceActiveIcon label="status" primaryColor={tested ? 'green' : 'grey'} />
          </div>
        </div>
        <div className="state-item revenue">
          <div className="state-item-name">In use</div>
          <div className="state-item-value">
            <PresenceActiveIcon label="status" primaryColor={active ? 'green' : 'grey'} />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default () => {
  const { state: { currentTeamId: teamId } } = useAuth();
  const isDisabled = useSubscription();

  const { data, loading, error } = useGetTeamCouriersAndSystemsCouriersQuery({
    variables: {
      teamId: teamId || '',
    },
  });
  if (loading) {
    return (
      <Container>
        <Spinner size="medium" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <ErrorMessage>{graphQLErrorsReader(error)}</ErrorMessage>
      </Container>
    );
  }

  if (data) {
    return (
      <Container>
        {SUPPORTED_COURIERS.map((supportedCourier) => {
          const logos:{[k:string]:string} = {
            AustraliaPost: auspostLogo,
            FASTWAYCOURIERS: fastwayLogo,
          };
          const logo = logos[supportedCourier.code] || noImage;
          const {
            GetCouriers: { couriers },
            GetTeamCouriers: { teamCouriers },
          } = data;

          const currentCourier = couriers?.find(
            (cur) => cur.code === supportedCourier.code,
            );
          const currentTeamCourier = teamCouriers?.find(
            (cur) => cur.courierId === currentCourier?.id,
            );
          const active = currentTeamCourier ? currentTeamCourier.active : false;
          // eslint-disable-next-line max-len
          const configured = (currentTeamCourier?.configuration?.length || 0) > 2;
          const tested = (() => {
            // auspost save account details in info column
            // so check the length will know if it is sucess or not
            if (supportedCourier.code === 'AustraliaPost') {
              return (currentTeamCourier?.info?.length || 0) > 2;
            }
            // other courier will only be saved if coneection test pass
            // so if it is undefined, means it has not passed the test.
            return !!currentTeamCourier;
          })();

          return (
            <>
              <LogisticsCard
                key={supportedCourier.code}
                logo={logo}
                name={supportedCourier.name}
                code={supportedCourier.code}
                active={active}
                configured={configured}
                tested={tested}
                courierId={currentCourier?.id || ''}
                isDisabled={isDisabled}
              />
            </>
          );
        })}
      </Container>
    );
  }

  return <></>;
};
