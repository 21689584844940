import React, {
  ReactNode,
  useContext,
  createContext,
  useReducer,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  MutationLoginArgs,
  useLoginMutation,
  useSignUpMutation,
  useSignOutMutation,
  SignUpMutationVariables,
  useActivateUserMutation,
  ActivateUserMutationVariables,
  User,
  useRequestPasswordResetMutation,
  RequestPasswordResetMutationVariables,
  useResetPasswordMutation,
  ResetPasswordMutationVariables,
  useUpdateUserMutation,
  UpdateUserMutationVariables,
  useChangePasswordMutation,
  ChangePasswordMutationVariables,
  useXeroLoginMutation,
  Scalars,
} from '../graphql/types';
import { clearStorage } from './storages';
import { ContextType } from './types';
import { reducer, initialState } from './reducer';
import CheckFreeTrial from '../components/Billing/CheckFreeTrial';

type Props = {
  children: ReactNode,
};

type FreeProps = {
  children: ReactNode,
};

type PlanProps = {
  children: ReactNode,
};

type MutationAccessArgs = {
  accessToken: Scalars['String'];
};

export const clientSideLogoutTask = (forced:boolean = false, entry:string = '') => {
  let loginUrl = '/signin/';
  /**
   * allow user to redirect to the current page
   * after re-login
   */
  if (forced) {
    if (window.location.search === '?shopify_connect=true&create_new_user=true') {
      loginUrl = `${loginUrl}${window.location.search}&msg=relogin&entry=${encodeURIComponent(entry)}`;
    } else {
      loginUrl = `${loginUrl}?msg=relogin&entry=${encodeURIComponent(entry)}`;
    }
  }
  /**
   * clear localStorages
   */
  clearStorage();
  /**
   * finally send user to login page
   */
  window.location.assign(loginUrl);
};

export function useAuthProvider() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [signInMutation] = useLoginMutation();
  const [signUpMutation] = useSignUpMutation();
  const [signOutMutation] = useSignOutMutation();
  const [activateUserMutation] = useActivateUserMutation();
  const [sendPasswordResetMutation] = useRequestPasswordResetMutation();
  const [resetPasswordMutation] = useResetPasswordMutation();
  const [updateProfileMutation] = useUpdateUserMutation();
  const [changePasswordMutation] = useChangePasswordMutation();
  const [xeroSignInMutation] = useXeroLoginMutation();

  const signIn = (signInArgs: MutationLoginArgs) => signInMutation({
    variables: signInArgs,
  })
    .then(({ data }) => {
      if (data && data.Login && data.Login.user) {
        const loginUser = data.Login.user;
        dispatch({
          type: 'login',
          loginPayload: loginUser as User,
        });
        return loginUser;
      }
      return undefined;
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const xeroSignIn = (accessToken: MutationAccessArgs) => xeroSignInMutation({
    variables: accessToken,
  })
    .then(({ data }) => {
      if (data && data.XeroLogin && data.XeroLogin.user) {
        const xeroLoginUser = data.XeroLogin.user;
        dispatch({
          type: 'login',
          loginPayload: xeroLoginUser as User,
        });
        return xeroLoginUser;
      }
      return undefined;
    });

  const activateUser = (
    activateUserArgs: ActivateUserMutationVariables,
  ) => activateUserMutation({
    variables: activateUserArgs,
  })
    .then(({ data }) => {
      if (data && data.ActivateUser && data.ActivateUser.user) {
        const activatedUser = data.ActivateUser.user;
        dispatch({
          type: 'login',
          loginPayload: activatedUser as User,
        });
        return activatedUser;
      }
      return undefined;
    });

  const signUp = (signupArgs: SignUpMutationVariables) => signUpMutation({
    variables: signupArgs,
  })
    .then(({ data }) => {
      if (data && data.SignUp && data.SignUp.user) {
        const { user: signUpUser } = data.SignUp;
        return signUpUser;
      }
      return undefined;
    });

  const signOut = () => signOutMutation()
    .finally(() => {
      dispatch({
        type: 'logout',
      });
      clientSideLogoutTask();
      /**
       * to triger the syncLogout()
       */
      window.localStorage.setItem('logout', Date());
    });

  const sendPasswordResetEmail = (
    sendPasswordResetArgs: RequestPasswordResetMutationVariables,
  ) => sendPasswordResetMutation({
    variables: sendPasswordResetArgs,
  })
    .then(({ data }) => {
      if (data
        && data.RequestPasswordReset
        && data.RequestPasswordReset.email) {
        const { email } = data.RequestPasswordReset;
        return email;
      }
      return undefined;
    });

  const confirmPasswordReset = (
    resetPasswordArgs: ResetPasswordMutationVariables,
  ) => resetPasswordMutation({
    variables: resetPasswordArgs,
  })
    .then(({ data }) => {
      if (data && data.ResetPassword && data.ResetPassword.user) {
        const passwordResetUser = data.ResetPassword.user;
        dispatch({
          type: 'login',
          loginPayload: passwordResetUser as User,
        });
        return passwordResetUser;
      }
      return undefined;
    });

  const updateProfile = (
    updateProfileArgs: UpdateUserMutationVariables,
  ) => updateProfileMutation({
    variables: updateProfileArgs,
  })
    .then(({ data }) => {
      if (data && data.UpdateUser && data.UpdateUser.user) {
        // data only contains user id, firstname, and lastname,
        // only use these return value to update state
        const userAfterUpdateProfile = data.UpdateUser.user;
        // update user in state
        dispatch({
          type: 'updateUserProfile',
          updateUserPayload: userAfterUpdateProfile,
        });
        return userAfterUpdateProfile;
      }
      return undefined;
    });

  const changePassword = (
    changePasswordArgs: ChangePasswordMutationVariables,
  ) => changePasswordMutation({
    variables: changePasswordArgs,
  })
    .then(() => 'ok');

  return {
    state,
    dispatch,
    signIn,
    xeroSignIn,
    signUp,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    activateUser,
    updateProfile,
    changePassword,
  };
}

const authContext = createContext<ContextType>({} as ContextType);
const expiredContext = createContext(false);

export function AuthProvider({ children }: Props) {
  const auth = useAuthProvider();
  const history = useHistory();

  React.useEffect(() => {
    /**
    * force all tabs in the browers to logout
    * if user clicked sign out on one of these tabs
    */
    const syncLogout = (event: StorageEvent) => {
      if (event.key === 'logout') {
        history.push('/signin');
      }
    };
    /**
     * add event listener to triger foreced logout
     */
    window.addEventListener('storage', syncLogout);
    return () => {
      window.removeEventListener('storage', syncLogout);
    };
  }, [history]);

  return (
    <authContext.Provider value={auth as ContextType}>
      { children }
    </authContext.Provider>
  );
}

export const useAuth = () => useContext(authContext);

export function Subscription({ children }: FreeProps) {
  const { state } = useAuth();
  let subscriptionExpired = false;
  if (state.user) {
    subscriptionExpired = CheckFreeTrial();
  }
  return (
    <div>
      <expiredContext.Provider value={subscriptionExpired}>
        {children}
      </expiredContext.Provider>
    </div>
  );
}
export const useSubscription = () => useContext(expiredContext);
