/* eslint-disable max-len */
import React from 'react';
import Button from '@atlaskit/button';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import { ErrorMessage } from '@atlaskit/form';
import { useHistory } from 'react-router-dom';
import { Radio } from '@atlaskit/radio';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { B300 } from '@atlaskit/theme/dist/cjs/colors';
// import Flag from '@atlaskit/flag';
import axios from 'axios';
import { CreateTeamChannelMutation, useCreateTeamChannelMutation } from '../../graphql/types';
import { useAuth, clientSideLogoutTask } from '../../utils/useAuth';
import PlanUpgrade from '../Billing/PlanUpgrade';
import addNotification from '../../utils/addNotification';

export default ({ onComplete, freeExpired, config }: { onComplete: () => void, freeExpired: boolean, config: boolean }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [shopifyNotice, setShopifyNotice] = React.useState(false);
  const [
    createTeamChannel,
    createTeamChannelState,
  ] = useCreateTeamChannelMutation();
  const { state: { currentTeamId, user }, dispatch } = useAuth();
  const [errorMsg, setErrorMsg] = React.useState('');
  const [name, setName] = React.useState('');
  const [magentoUrl, setMagentoUrl] = React.useState('');
  const [magentoAccessToken, setMagentoAccessToken] = React.useState('');
  const [wooCommerceUrl, setWooCommerceUrl] = React.useState('');
  const [targetChannel, setTargetChannel] = React.useState('EBAY');
  const [shopifyUrl, setShopifyUrl] = React.useState('');
  const history = useHistory();

  const [disable, setDisable] = React.useState(false);
  const closeUpgrade = () => {
    setDisable(false);
  };

  // const getTargetChannelId = (channelName: string) => (channelName === 'MAGENTO' ? (process.env.REACT_APP_MAGENTO_CHANNEL_ID || '3') : undefined);

  const REACT_APP_GRAPHQL_SERVER_HOST = process.env.REACT_APP_GRAPHQL_SERVER_HOST
    || 'http://localhost:4000';

  const installMerpApp = async () => {
    // setIsloading(true);
    const userId = user ? user.id : '';
    const res = await axios.get(
      `${REACT_APP_GRAPHQL_SERVER_HOST}/oauth/shopify/installUrl?teamId=${currentTeamId}&userId=${userId}`,
    );
    const { url } = res.data;
    if (url) {
      window.location.assign(url);
    }
  };

  const getTargetChannelId = (channelName: string) => {
    if (channelName.toUpperCase() === 'MAGENTO') return process.env.REACT_APP_MAGENTO_CHANNEL_ID || '3';

    if (channelName.toUpperCase() === 'AMAZON') return '5';

    if (channelName.toUpperCase() === 'WOOCOMMERCE') return process.env.REACT_APP_WOOCOMMERCE_CHANNEL_ID || '7';

    if (channelName.toUpperCase() === 'SHOPIFY') return process.env.REACT_APP_SHOPIFY_CHANNEL_ID || '8';

    return '1';
  };
  const handleLocalStateUpdate = (
    data: CreateTeamChannelMutation | undefined,
  ) => {
    try {
      const teamChannel = data?.CreateTeamChannel.teamChannel;
      const settings = JSON.parse(teamChannel!.settings);
      // update local state
      dispatch({
        type: 'addTeamChannel',
        addTeamChannelPayload: {
          id: teamChannel!.id,
          channelID: teamChannel!.channel.id,
          channelName: teamChannel!.channel.name,
          name: teamChannel!.name,
          active: teamChannel!.active ?? true,
          externalId: teamChannel!.externalId,
          onBoarded: teamChannel!.onBoarded,
          settings: {
            pushStockQty: settings?.pushStockQty ?? false,
            pushPriceUpdates: settings?.pushPriceUpdates
              ?? false,
            refresh_token_expires_at: settings?.access_token?.refresh_token_expires_at ?? '',
            token: settings?.access_token?.access_token ?? '',
            country: settings?.country ?? 'AU',
            currency: settings?.currency ?? 'AUD',
            fulfillmentPolicy: {
              id: settings?.fulfillmentPolicyId ?? '',
              name: settings?.fulfillmentPolicyName ?? '',
            },
            paymentPolicy: {
              id: settings?.paymentPolicyId ?? '',
              name: settings?.paymentPolicyName ?? '',
            },
            returnPolicy: {
              id: settings?.returnPolicyId ?? '',
              name: settings?.returnPolicyId ?? '',
            },
            merchantLocation: {
              id: settings?.merchantLocationKey ?? '',
              name: settings?.merchantLocationName ?? '',
            },
          },
          createdAt: teamChannel!.createdAt,
          updatedAt: teamChannel!.updatedAt,
          createdBy: `${teamChannel?.createdBy?.firstName ?? ''} ${teamChannel?.createdBy?.lastName}`,
          updatedBy: `${teamChannel?.updatedBy?.firstName ?? ''} ${teamChannel?.updatedBy?.lastName}`,
        },
      });
    } catch (error) {
      // if encouter unexpected err,
      // we force user to relogin so can retrive the local state again
      // therefore, the frontend can have consistent data with backend.
      clientSideLogoutTask();
    }
  };

  const handleOnClose = () => {
    // clear up the data to allow next time open with a fresh look
    setName('');
    setErrorMsg('');
    setMagentoUrl('');
    setMagentoAccessToken('');
    // close the modal
    setIsOpen(false);
    setShopifyNotice(false);
  };

  const onWarnning = () => {
    setShopifyNotice(!shopifyNotice);
  };

  const handleStoreName = (storeName: string) => {
    setName(storeName);
    if (targetChannel === 'Shopify') {
      setShopifyUrl(storeName);
    }
  };

  const handleNextButtonClick = async () => {
    if (freeExpired === true) {
      setDisable(true);
      return;
    }
    const channelId = getTargetChannelId(targetChannel);
    const magentoChannelVariables = channelId ? {
      teamId: currentTeamId!,
      name,
      channelId,
      channelSettings: JSON.stringify({
        token: magentoAccessToken,
        url: magentoUrl,
      }),
    } : {
      teamId: currentTeamId!,
      name,
      channelId,
    };
    const wooCommerceChannelVariables = channelId ? {
      teamId: currentTeamId!,
      name,
      channelId,
      channelSettings: JSON.stringify({
        url: wooCommerceUrl,
      }),
    } : {
      teamId: currentTeamId!,
      name,
      channelId,
    };
    const shopifyChannelVariables = channelId ? {
      teamId: currentTeamId!,
      name,
      channelId,
      channelSettings: JSON.stringify({
        url: shopifyUrl,
      }),
    } : {
      teamId: currentTeamId!,
      name,
      channelId,
    };

    // const teamChannelVariables = targetChannel === 'MAGENTO' ? magentoChannelVariables : wooCommerceChannelVariables;

    let teamChannelVariables;
    switch (targetChannel) {
      case 'MAGENTO':
        teamChannelVariables = magentoChannelVariables;
        break;
      case 'WooCommerce':
        teamChannelVariables = wooCommerceChannelVariables;
        break;
      case 'Shopify':
        teamChannelVariables = shopifyChannelVariables;
        break;
      default:
        teamChannelVariables = wooCommerceChannelVariables;
        break;
    }

    await createTeamChannel({
      variables: teamChannelVariables,
    }).then(({ data }) => {
      handleLocalStateUpdate(data);
      switch (data?.CreateTeamChannel.teamChannel.channel.name) {
        case 'eBay': {
          // close modal
          setIsOpen(false);
          const teamChannelId = data?.CreateTeamChannel.teamChannel.id;
          // push user to onboarding
          history.push(`/connect/ebay/${teamChannelId}?redirectPath=/config/ebay/${teamChannelId}`);
          break;
        }
        case 'Amazon': {
          setIsOpen(false);
          const teamChannelId = data?.CreateTeamChannel.teamChannel.id;
          history.push(`/connect/amazon/${teamChannelId}?redirectPath=/marketplace/${teamChannelId}`);
          break;
        }
        case 'WooCommerce': {
          // close modal
          setIsOpen(false);
          const teamChannelId = data?.CreateTeamChannel.teamChannel.id;
          // push user to onboarding
          history.push(`/connect/wooCommerce/${teamChannelId}?${wooCommerceUrl}`);
          break;
        }
        case 'Shopify': {
          // close modal
          setIsOpen(false);
          const teamChannelId = data?.CreateTeamChannel.teamChannel.id;
          // push user to onboarding
          history.push(`/connect/shopify/${teamChannelId}?${shopifyUrl}`);
          break;
        }
        default: {
          onComplete();
          handleOnClose();
        }
      }
    }).catch((err) => addNotification(err.toString(), 'danger'));
  };
  const actions = [
    {
      text: 'Next',
      onClick: handleNextButtonClick,
      isLoading: createTeamChannelState.loading,
      isDisabled: name.trim().length === 0,
    },
    {
      text: 'Cancel',
      onClick: handleOnClose,
    },
  ];
  return (
    <>
      {config && (
        <div>
          <Button
            onClick={() => {
              setIsOpen(true);
              setTargetChannel('EBAY');
            }}
            appearance="default"
          >
            Connect
          </Button>
        </div>
      )}
      {!config && (
        <>
          <Button
            onClick={() => (setIsOpen(true))}
            appearance="default"
            className="addChannelBtn"
          >
            +
          </Button>

        </>
      )}
      <PlanUpgrade isOpen={disable} close={closeUpgrade} />
      <ModalTransition>
        {isOpen && (
          <Modal
            actions={actions}
            onClose={handleOnClose}
            heading="Connect to a new store?"
            width="medium"
            shouldCloseOnEscapePress={false}
            shouldCloseOnOverlayClick={false}
          >
            <div>
              <p>
                Please select a store type:
                <Radio
                  value="eBay"
                  label="eBay"
                  name="eBay"
                  testId="eBay"
                  isChecked={targetChannel === 'EBAY'}
                  onChange={() => {
                    setTargetChannel('EBAY');
                  }}
                />
                <Radio
                  value="Magento"
                  label="Magento 2"
                  name="Magento"
                  testId="Magento"
                  isChecked={targetChannel === 'MAGENTO'}
                  onChange={() => {
                    setTargetChannel('MAGENTO');
                  }}
                />
                <Radio
                  value="WooCommerce"
                  label="Wordpress(WooCommerce)"
                  name="WooCommerce"
                  testId="WooCommerce"
                  isChecked={targetChannel === 'WooCommerce'}
                  onChange={() => {
                    setTargetChannel('WooCommerce');
                  }}
                />
                <Radio
                  value="Shopify"
                  label="Shopify"
                  name="Shopify"
                  testId="Shopify"
                  isChecked={targetChannel === 'Shopify'}
                  onChange={() => {
                    setTargetChannel('Shopify');
                  }}
                />
              </p>
              <br />
              {targetChannel !== 'Shopify' && (
                <Textfield
                  value={name}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                  ) => (handleStoreName(e.target.value))}
                  placeholder={targetChannel === 'Shopify' ? 'Enter your shopify URL' : 'Enter your store name'}
                  maxLength={25}
                  elemAfterInput={(targetChannel === 'Shopify'
                    && (
                      <>
                        <Button
                          style={{ marginLeft: 20, marginRight: 5 }}
                          iconBefore={<InfoIcon primaryColor={B300} label="Info" />}
                          appearance="subtle"
                          onClick={onWarnning}
                        />

                      </>
                    )
                  )}
                />
              )}
              {
                targetChannel === 'Shopify' && (
                  <div>
                    <Button
                      onClick={
                        installMerpApp
                      }
                      appearance="default"
                    >
                      Install MERP on Shopify
                    </Button>
                  </div>
                )
              }
              {targetChannel === 'MAGENTO' && (
                <>
                  <br />
                  <Textfield
                    value={magentoUrl}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ) => (setMagentoUrl(e.target.value))}
                    placeholder="Enter Magento URL"
                  />
                  <br />
                  <Textfield
                    value={magentoAccessToken}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ) => (setMagentoAccessToken(e.target.value))}
                    placeholder="Enter Magento Access Token"
                  />
                </>
              )}
              {targetChannel === 'WooCommerce' && (
                <>
                  <br />
                  <Textfield
                    value={wooCommerceUrl}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ) => (setWooCommerceUrl(e.target.value))}
                    placeholder="Enter WooCommerce URL"
                  />
                </>
              )}
              {errorMsg
                && <ErrorMessage>{errorMsg}</ErrorMessage>}
            </div>
          </Modal>
        )}
      </ModalTransition>

    </>
  );
};
