import React from 'react';
import JiraFailedBuildStatusIcon from '@atlaskit/icon/glyph/jira/failed-build-status';
import { isUndefined } from 'lodash';
import { CommonProps } from '../types';
import { FlexBox } from '../styles';
import formatMoneyWithPrefix from '../../../helpers/formatMoney';

const MovingAverage = (
  { lineItem }:CommonProps,
) => {
  const newQty = lineItem.response
                    ?.AdjustInventory.product
                    ?.stockOnHand;
  const newTotalInventoryValue = lineItem.response
                          ?.AdjustInventory.product
                          ?.totalInventoryCost;

  if (isUndefined(newQty) || isUndefined(newTotalInventoryValue)) {
    return (
      <FlexBox>
        <JiraFailedBuildStatusIcon
          label="error"
          primaryColor="red"
          testId="errorIcon"
        />
      </FlexBox>
    );
  }

  return (
    <div>
      {formatMoneyWithPrefix(newTotalInventoryValue / newQty)}
    </div>
  );
};

export default MovingAverage;
