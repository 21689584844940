import { OptionsType, ValueType, OptionType } from '@atlaskit/select';
import { isUndefined } from 'lodash';
import {
  State, Action, Shipment, ShippingQuoteUI,
} from './types';
import { Order } from '../OrdersListing/types';
import { AUSPOST_AUTOPILOT, ARAMEX_AUTOPILOT } from './Views/ShippingMethod';
import {
  GetCouriersQuery,
} from '../../graphql/types';

/**
 * helper function to tell if ship Button should enabled or disabled
 */
export const isShippable = (statusCode: string, isAlwaysClickable:boolean) => {
  const shippableCodes = ['PAID', 'PACKED'];
  if (isAlwaysClickable) {
    return true;
  }

  if (shippableCodes.includes(statusCode)) {
    return true;
  }
  return false;
};

export const getButtonName = (
  statusCode: string, overrideText:string|undefined,
) => {
  if (overrideText) return overrideText;
  if (['SHIPPED'].includes(statusCode)) {
    return 'Shipped';
  }
  return 'Ship';
};

export const initialState: Omit<State, 'order'> = {
  ui: {
    stage: 'preview',
    isModalOpen: false,
    isShipButtonDisabled: false,
    error: '',
    isSubmiting: false,
    isPrimaryButtonDisabled: false,
    isQuoteFetching: false,
  },
  shipment: {
    orderId: '',
    courierId: '',
    trackingNumber: '',
    totalWeight: '',
    isTotalWeightInvalid: false,
    totalCost: '',
    isTotalCostInvalid: false,
    selectedProductId: '',
    length: '',
    width: '',
    height: '',
    isLengthInvalid: false,
    isWidthInvalid: false,
    isHeightInvalid: false,
    // fastway will need this ,package/satchel
    // will get updated if selected a fastway quote
    type: undefined,
    // fastway will need this
    // will get updated if selected a fastway quote
    satchelSize: undefined,
    notes: '',
    // initialize boxes struct
    boxes: [{
      weight: '',
      length: '',
      width: '',
      height: '',
      isWeightInvalid: true,
      isLengthInvalid: true,
      isWidthInvalid: true,
      isHeightInvalid: true,
    }],
  },
  lineItemsConfig: [],
  shippingMethod: undefined,
  integration: {
    auspost: {
      enabled: false,
      config: {},
    },
    fastway: {
      enabled: false,
      config: {},
    },
  },
};

export const getOrderLineItemConfig = (
  order:Order,
  shippingMethod: ValueType<OptionType>,
) => order.orderItems.map((cur) => ({
  id: cur.id,
  weight: undefined,
  isWeightInvalid: false,
  isHideWeightInput: shippingMethod?.map((option: OptionType) => option.label).includes('Pickup') || isUndefined(shippingMethod),
}));

// reducer
export const reducer = (
  state: State,
  action: Action,
): State => {
  switch (action.type) {
    case 'setStage': {
      return {
        ...state,
        ui: {
          ...state.ui,
          stage: action.payload,
        },
      };
    }
    case 'setIsModalOpen': {
      return {
        ...state,
        ui: {
          ...state.ui,
          isModalOpen: action.payload,
        },
      };
    }
    case 'setIsShipButtonDisabled': {
      return {
        ...state,
        ui: {
          ...state.ui,
          isShipButtonDisabled: action.payload,
        },
      };
    }
    case 'setIsPrimaryButtonDisabled': {
      return {
        ...state,
        ui: {
          ...state.ui,
          isPrimaryButtonDisabled: action.payload,
        },
      };
    }
    case 'setError': {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: action.payload,
        },
      };
    }
    case 'setIsSubmiting': {
      return {
        ...state,
        ui: {
          ...state.ui,
          isSubmiting: action.payload,
        },
      };
    }
    case 'setShipment': {
      return {
        ...state,
        shipment: action.payload,
      };
    }
    case 'setCourierId': {
      return {
        ...state,
        shipment: {
          ...state.shipment,
          courierId: action.payload,
        },
      };
    }
    case 'setSelectedIntegratedCourier': {
      return {
        ...state,
        integration: {
          ...state.integration,
          selectedIntegration: action.payload,
        },
      };
    }
    case 'setLineItemsConfig': {
      return {
        ...state,
        lineItemsConfig: action.payload,
      };
    }
    case 'reset': {
      return {
        ...state,
        ...initialState,
      };
    }
    case 'setOrderId': {
      return {
        ...state,
        shipment: {
          ...state.shipment,
          orderId: action.payload,
        },
      };
    }
    case 'setTotalWeight': {
      return {
        ...state,
        shipment: {
          ...state.shipment,
          totalWeight: action.payload,
        },
      };
    }
    case 'setShippingMethod': {
      return {
        ...state,
        shippingMethod: action.payload,
      };
    }
    case 'setAuspostIntegration': {
      return {
        ...state,
        integration: {
          ...state.integration,
          auspost: {
            ...state.integration.auspost,
            enabled: action.payload,
          },
        },
      };
    }
    case 'setAuspostIntegrationConfig': {
      return {
        ...state,
        integration: {
          ...state.integration,
          auspost: {
            ...state.integration.auspost,
            config: action.payload,
          },
        },
      };
    }
    case 'setFastwayIntegration': {
      return {
        ...state,
        integration: {
          ...state.integration,
          fastway: {
            ...state.integration.fastway,
            enabled: action.payload,
          },
        },
      };
    }
    case 'setFastwayIntegrationConfig': {
      return {
        ...state,
        integration: {
          ...state.integration,
          auspost: {
            ...state.integration.fastway,
            config: action.payload,
          },
        },
      };
    }
    case 'setQuotes': {
      return {
        ...state,
        quotes: action.payload,
      };
    }
    case 'setIsQuoteFetching': {
      return {
        ...state,
        ui: {
          ...state.ui,
          isQuoteFetching: action.payload,
        },
      };
    }
    case 'setQuoteId': {
      return {
        ...state,
        shipment: {
          ...state.shipment,
          selectedProductId: action.payload,
        },
      };
    }
    case 'setCost': {
      return {
        ...state,
        shipment: {
          ...state.shipment,
          totalCost: action.payload,
        },
      };
    }
    case 'setOrder': {
      return {
        ...state,
        order: action.payload,
      };
    }
    case 'setPostShipment': {
      return {
        ...state,
        postShipment: action.payload,
      };
    }
    // update the box's value
    case 'setBoxes': {
      return {
        ...state,
        shipment: {
          ...state.shipment,
          boxes: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export const getHead = (
  // shippingServiceCode: string,
  // shippingMethod: ValueType<OptionType>,
) => {
  const cells = [
    {
      content: 'Product',
      shouldTruncate: true,
      width: 8,
    },
    {
      content: 'Qty',
      width: 1,
    },
  ];

  return { cells };
};

export const isShipmentValid = (shipment:Shipment) => shipment.courierId
        && shipment.trackingNumber
        && !Number.isNaN(Number(shipment.totalWeight))
        && Number(shipment.totalWeight) >= 0
        && !shipment.isTotalWeightInvalid
        && !Number.isNaN(Number(shipment.totalCost))
        && Number(shipment.totalCost) > 0
        && !shipment.isTotalCostInvalid
        && shipment.courierId.length > 0;

export const convertShippingMethodToUnifiedValue = (
  shippingMethod: ValueType<OptionType>,
): ShippingQuoteUI => {
  // eslint-disable-next-line max-len
  const selectedCourierIDsForQuote:Array<string>|undefined = shippingMethod?.map(
    (cur: OptionType) => cur.value,
  );
  // eslint-disable-next-line max-len
  const selectedCourierLabelsForQuote: Array<string> | undefined = shippingMethod?.map(
    (cur: OptionType) => cur.label,
  );

  if (selectedCourierIDsForQuote && selectedCourierIDsForQuote.some((cur) => cur.includes('-auto'))) {
    return 'integrated';
  }
  if (selectedCourierLabelsForQuote && selectedCourierLabelsForQuote.some((cur) => cur.includes('Pickup'))) {
    return 'pickup';
  }

  if (selectedCourierLabelsForQuote && selectedCourierLabelsForQuote.some((cur) => cur.includes('letter'))) {
    return 'pickup';
  }

  if ((!shippingMethod) || (shippingMethod?.length === 0)) {
    return 'pickup';
  }

  return 'default';
};

export const getCourierList = (
  couriers: GetCouriersQuery['GetCouriers']['couriers'],
): OptionsType => {
  if (!couriers) return [];

  return couriers.map((cur) => ({
    label: cur.name,
    value: cur.id,
  }));
};

export const getIntegratedOptions = (
  couriers: GetCouriersQuery['GetCouriers']['couriers'],
  config:{
    auspost:boolean,
    fastway:boolean,
  },
): OptionsType => {
  if (!couriers) return [];
  const { auspost, fastway } = config;
  let result: OptionsType = [];

  if (auspost) {
    const [auspostOption] = couriers.filter((courier) => courier.code === 'AustraliaPost').map((cur) => ({
      label: AUSPOST_AUTOPILOT,
      value: `${cur.id}-auto`,
    }));

    if (auspostOption) {
      result = [
        ...result,
        auspostOption,
      ];
    }
  }

  if (fastway) {
    const [fastwayOption] = couriers.filter((courier) => courier.code === 'FASTWAYCOURIERS').map((cur) => ({
      label: ARAMEX_AUTOPILOT,
      value: `${cur.id}-auto`,
    }));

    if (fastwayOption) {
      result = [
        ...result,
        fastwayOption,
      ];
    }
  }

  return result;
};
