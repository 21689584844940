import React from 'react';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Button from '@atlaskit/button';
import TeamCustomerDetail from '../../../TeamCustomerDetail/TeamCustomerDetail';
/**
  * render order details view
  */
const CustomerView = (
  {
    customerId, teamId, closeOrdeView,
  }: {
    customerId: string,
    teamId: string,
    closeOrdeView: () => void,
    // refetch:()=>void,
  },
) => (
  <div style={{ position: 'relative' }} data-testid="orderDetailView">
    <div
      style={{
        position: 'absolute',
        right: 20,
        top: 30,
      }}
    >
      <Button
        appearance="subtle-link"
        onClick={closeOrdeView}
        testId="close-button"
      >
        <CrossIcon label="close" size="large" />
      </Button>

    </div>
    <div>
      <TeamCustomerDetail
        customerId={customerId}
        teamId={teamId}
        // refetch={refetch}
      />
    </div>
  </div>
);

export default CustomerView;
