import gql from 'graphql-tag';

const productQuery = gql`
   query Product($teamID:String!,$productId:String!) {
    Product(teamID:$teamID,productId:$productId) {
      status,
      product{
        id,
        name,
        sku,
        unitPrice,
        isForSale,
        stockOnHand
        totalInventoryCost
        fullDescription
        shortDescription
        images (orderBy: {orderNum: asc} ) {
          url
        }
        productAttributeValues{
          id
          value
          productAttribute{
            id
            name
          }
        }
        teamChannelProducts(where:{active:{equals:true}}){
          id
          unitPrice
          active
          stockOnHand
          teamChannel{
            id
            active
            name
            channelID
          }
          externalListingId
          settings
        }
        attributeSet {
          id
          channelAttributeSetId
        },
        productTaxClass {
          id
          name
        },
        teamID
      }
    }
  }
`;
export default productQuery;
