import React from 'react';
import Spinner from '@atlaskit/spinner';
import { Control } from 'react-hook-form';
import { Grid, GridColumn } from '@atlaskit/page';
import {
  useQueryFulfilmentPoliciesQuery,
  useQueryPaymentPoliciesQuery,
  useQueryReturnPoliciesQuery,
  useFindOrCreateLocationQuery,
  QueryFulfilmentPoliciesQuery,
  QueryPaymentPoliciesQuery,
  QueryReturnPoliciesQuery,
  FindOrCreateLocationQuery,
} from '../../graphql/types';
import { ChannelProductSettingsType, ErrorType, TeamChannelSettingsType } from './types';
import SelectWithLabelAndError from './SelectWithLabelAndError';
import {
  mapFulfillmentsOptions, mapLocationssOptions,
  mapPaymentsOptions, mapReturnsOptions,
} from '../EbayConfigor/helpers';

const getInputProps = (
  control: Control<Record<string, string>>,
  errors: ErrorType,
  setValue: Function,
  name: string,
  label: string,
  teamChannelId: string,
  defaultValue: string | undefined,
) => ({
  key: `policies.${teamChannelId}.${name}`,
  name: `policies.${teamChannelId}.${name}`,
  label,
  isRequired: true,
  control,
  errors,
  defaultValue: defaultValue && typeof defaultValue === 'string' ? [defaultValue] : [],
  setValue,
  helpMessage: '',
});

const getFullfillmentSelectProps = (
  fulfilmentData: QueryFulfilmentPoliciesQuery | undefined,
) => {
  const fieldOptions = mapFulfillmentsOptions(fulfilmentData);
  return {
    isMulti: false,
    optionsArr: fieldOptions,
  };
};

const getPaymentSelectProps = (
  paymentData: QueryPaymentPoliciesQuery | undefined,
) => {
  const fieldOptions = mapPaymentsOptions(paymentData);
  return {
    isMulti: false,
    optionsArr: fieldOptions,
  };
};

const getReturnSelectProps = (
  returnData: QueryReturnPoliciesQuery | undefined,
) => {
  const fieldOptions = mapReturnsOptions(returnData);
  return {
    isMulti: false,
    optionsArr: fieldOptions,
  };
};

const getLocationSelectProps = (
  locationData: FindOrCreateLocationQuery | undefined,
) => {
  const fieldOptions = mapLocationssOptions(locationData);
  return {
    isMulti: false,
    optionsArr: fieldOptions,
  };
};

const TeamChannelPolicySelector = ({
  teamChannelId,
  teamChanelName,
  teamChannelSetttings,
  channelProductSettings,
  control,
  errors,
  setValue,
  isDisable,
}: {
  teamChannelId : string,
  teamChanelName: string,
  teamChannelSetttings: TeamChannelSettingsType,
  channelProductSettings: ChannelProductSettingsType,
  control: Control<Record<string, string>>,
  errors: ErrorType,
  setValue: Function,
  isDisable: boolean,
}) => {
  // fulfillments policies
  const {
    data: fulfilmentData,
    loading: fulfilmentLoading,
  } = useQueryFulfilmentPoliciesQuery({
    variables: {
      teamChannelId,
    },
  });
  // payments policies
  const {
    data: paymentData,
    loading: paymentLoading,
  } = useQueryPaymentPoliciesQuery({
    variables: {
      teamChannelId,
    },
  });
  // refund policies
  const {
    data: returnData,
    loading: returnLoading,
  } = useQueryReturnPoliciesQuery({
    variables: {
      teamChannelId,
    },
  });
  // locations
  const {
    data: locationData,
    loading: locationLoading,
  } = useFindOrCreateLocationQuery({
    variables: {
      teamChannelId,
    },
  });
  const defaultFulfillmentPolicyId = channelProductSettings
  && channelProductSettings.fulfillmentPolicyId
    ? channelProductSettings.fulfillmentPolicyId
    : teamChannelSetttings.fulfillmentPolicyId;
  const fullfillmentInputProps = getInputProps(
    control, errors, setValue, 'fullfilment', 'Fullfilment',
    teamChannelId, defaultFulfillmentPolicyId,
  );
  const fullfillmentSelectProps = getFullfillmentSelectProps(fulfilmentData);

  const defaultPaymentPolicyId = channelProductSettings
  && channelProductSettings.paymentPolicyId
    ? channelProductSettings.paymentPolicyId
    : teamChannelSetttings.paymentPolicyId;
  const paymentInputProps = getInputProps(
    control, errors, setValue, 'payment', 'Payment',
    teamChannelId, defaultPaymentPolicyId,
  );
  const paymentSelectProps = getPaymentSelectProps(paymentData);

  const defaultReturnPolicyId = channelProductSettings
  && channelProductSettings.returnPolicyId
    ? channelProductSettings.returnPolicyId
    : teamChannelSetttings.returnPolicyId;
  const returnInputProps = getInputProps(
    control, errors, setValue, 'return', 'Return',
    teamChannelId, defaultReturnPolicyId,
  );
  const returnSelectProps = getReturnSelectProps(returnData);

  const defaultMerchantLocationKey = channelProductSettings
  && channelProductSettings.merchantLocationKey
    ? channelProductSettings.merchantLocationKey
    : teamChannelSetttings.merchantLocationKey;
  const locationInputProps = getInputProps(
    control, errors, setValue, 'location', 'Location',
    teamChannelId, defaultMerchantLocationKey,
  );
  const locationSelectProps = getLocationSelectProps(locationData);

  return (
    <>
      <h4>{`Channel: ${teamChanelName}`}</h4>

      <Grid>
        <GridColumn medium={6}>
          {fulfilmentLoading ? <Spinner /> : (
            <div test-id="fulfillmentSelector">
              <SelectWithLabelAndError
                {...fullfillmentInputProps}
                {...fullfillmentSelectProps}
                isDisable={isDisable}
              />
            </div>
          )}
        </GridColumn>

        <GridColumn medium={6}>
          {paymentLoading ? <Spinner /> : (
            <div test-id="paymentSelector">
              <SelectWithLabelAndError
                {...paymentInputProps}
                {...paymentSelectProps}
                isDisable={isDisable}
              />
            </div>
          )}
        </GridColumn>

        <GridColumn medium={6}>

          {returnLoading ? <Spinner /> : (
            <div test-id="returnSelector">
              <SelectWithLabelAndError
                {...returnInputProps}
                {...returnSelectProps}
                isDisable={isDisable}
              />
            </div>
          )}
        </GridColumn>

        <GridColumn medium={6}>
          {locationLoading ? <Spinner /> : (
            <div test-id="locationSelector">
              <SelectWithLabelAndError
                {...locationInputProps}
                {...locationSelectProps}
                isDisable={isDisable}
              />
            </div>
          )}
        </GridColumn>
      </Grid>

    </>
  );
};

export default TeamChannelPolicySelector;
