import React from 'react';
import { useInvoiceChargePdfCreationMutation } from '../../graphql/types';
import addNotification from '../../utils/addNotification';
import { PDFButton } from './ListBilling.style';

interface InvoicePDFProps {
  invoiceId: string
}
export default function BillingPDF(prop: InvoicePDFProps) {
  const [invoiceChargePdfCreation] = useInvoiceChargePdfCreationMutation();
  const pdfPrint = ((teamChargeBillingId: string) => {
    invoiceChargePdfCreation(
      {
        variables: {
          teamChargeBillingId,
        },
      },
    ).then(
      (res) => {
        const url = res.data?.InvoiceChargePdfCreation.url || '';
        if (url === '') {
          addNotification('url is empty', 'danger');
        } else {
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.target = '_blank';
          anchor.click();
        }
      },
    ).catch((err) => {
      addNotification(err.toString(), 'danger');
    });
  });
  return (
    <PDFButton
      onClick={() => pdfPrint(prop.invoiceId)}
    >
      Download
    </PDFButton>
  );
}
