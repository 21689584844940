import React from 'react';
import { ButtonAppearances, ButtonProps, Spacing } from '@atlaskit/button/types';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
// load types
import { ApolloError } from 'apollo-client/errors/ApolloError';
import Button from '@atlaskit/button';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { useDeleteProductMutation, DeleteProductMutation } from '../graphql/types';
import { useSubscription } from '../utils/useAuth';

export type DeleteButtonResponse = {
  data: DeleteProductMutation | undefined,
  error: ApolloError | undefined
};

export interface DeleteButtonProps extends ButtonProps {
  id: string,
  sku: string,
  getResponse: (response: DeleteButtonResponse) => void,
  children?: React.ReactNode,
  isCompactSize?: boolean,
}

export default function (props: DeleteButtonProps) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const isDisabled = useSubscription();
  const {
    id, sku, getResponse, children, isCompactSize,
  } = props;
  const [deleteProduct, { loading }] = useDeleteProductMutation();

  const open = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(true);
  };
  const close = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const handleResponse = (res: DeleteButtonResponse) => {
    getResponse(res);
  };

  const handleDelete = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    deleteProduct({ variables: { id } })
      .then((res) => handleResponse({ data: res.data, error: undefined }))
      .catch((err: ApolloError) => handleResponse({
        data: undefined,
        error: err,
      }))
      .finally(() => (setIsOpen(false)));
  };

  const actions = [
    {
      text: 'DELETE', onClick: handleDelete, isLoading: loading, testId: 'product-del-confirm',
    },
    {
      text: 'CANCEL', onClick: close, isDisabled: loading,
    },
  ];

  const extraProps = isCompactSize === true ? {
    appearance: 'subtle' as ButtonAppearances,
    spacing: 'none' as Spacing,
    shouldFitContainer: true,
  } : {
    appearance: 'danger' as ButtonAppearances,
    iconBefore: <TrashIcon size="medium" label="delete a product" />,
  };

  return (
    <>
      {}
      <Button
        {...extraProps}
        onClick={open}
        testId={`${sku}-delete-btn`}
        style={{ textAlign: 'left' }}
        isDisabled={isDisabled}
      >
        {children}
      </Button>
      <ModalTransition>
        {isOpen && (
          <Modal
            actions={actions}
            onClose={() => setIsOpen(false)}
            heading={`Delete SKU:${sku}?`}
            appearance="danger"
            width="small"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
          >
            This action cannot be undone. The eBay listing would be unlisted.
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
