import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

interface Props {
  isOpen:boolean,
  heading:string,
  children:React.ReactNode,
  width:number | string,
  onClose:()=>void,
}

export default ({
  heading, children, isOpen, onClose, width,
}:Props) => (
  <ModalTransition>
    {isOpen && (
      <Modal
        onClose={onClose}
        heading={heading}
        scrollBehavior="outside"
        width={width}
        shouldCloseOnOverlayClick={false}
      >
        {children}
      </Modal>
    )}
  </ModalTransition>
);
