import React, { useState, useCallback } from 'react';
import Textfield from '@atlaskit/textfield';
import Form, {
  Field,
  FormFooter,
  ErrorMessage,
  FormSection,
  ValidMessage,
} from '@atlaskit/form';
import Button from '@atlaskit/button';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import { useAuth } from '../../utils/useAuth';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import Box from '../Box';

interface ChangePasswordFormData {
  currentPassword: string,
  newPassword: string,
}
interface ChangePasswordFormProps {
  onSubmit: (formData: ChangePasswordFormData) => {
    newPassword: string | undefined;
  } | undefined,
  error:string,
}
const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const { onSubmit, error } = props;
  return (
    <>
      <Form<ChangePasswordFormData>
        onSubmit={onSubmit}
      >
        {({ formProps, dirty, submitting }) => (
          <form {...formProps}>
            <FormSection>
              <Field
                name="currentPassword"
                label="Current password"
                defaultValue=""
                isRequired
              >
                {({ fieldProps }) => (
                  <Textfield
                    testId="currentPassword"
                    type="password"
                    {...fieldProps}
                    placeholder="Enter your current password"
                  />
                )}
              </Field>
              <Field
                name="newPassword"
                label="New password"
                defaultValue=""
                isRequired
              >
                {({ fieldProps, error: formError }) => (
                  <>
                    <Textfield
                      testId="newPassword"
                      type="password"
                      {...fieldProps}
                      placeholder="Enter your new password"
                    />
                    {formError && <ErrorMessage testId="currentPasswordErr">{formError}</ErrorMessage>}
                  </>
                )}
              </Field>
            </FormSection>
            {error
              && (
              <ErrorMessage testId="serverError">
                {error}
              </ErrorMessage>
              )}
            <FormFooter>
              <Button
                type="submit"
                appearance="primary"
                isDisabled={!dirty || submitting}
                isLoading={submitting}
                shouldFitContainer
              >
                Save changes
              </Button>
            </FormFooter>
          </form>
        )}
      </Form>
    </>
  );
};

const ChangePasswordSucess = () => (
  <Box>
    <ValidMessage>
      Success! Your Password has been changed!
    </ValidMessage>
  </Box>
);

export default () => {
  const [
    isPasswordChangeSuccess,
    setIsPasswordChangeSuccess,
  ] = useState(false);

  const [serverResponseError, setServerResponseError] = useState('');
  const { changePassword } = useAuth();

  const handleChangePassword = useCallback(
    (formData: ChangePasswordFormData) => {
      const err = {
        newPassword:
          formData.newPassword.length < 6
            ? 'New password must at least 6 characters'
            : undefined,
      };
      if (err.newPassword) {
        return err;
      }
      changePassword({
        ...formData,
      })
        .then(() => setIsPasswordChangeSuccess(true))
        .catch((errors: ApolloError) => {
          setServerResponseError(graphQLErrorsReader(errors));
        });
      return undefined;
    }, [changePassword, setIsPasswordChangeSuccess],
  );

  if (isPasswordChangeSuccess) {
    return <ChangePasswordSucess />;
  }
  return (
    <ChangePasswordForm
      onSubmit={handleChangePassword}
      error={serverResponseError}
    />
  );
};
