import React from 'react';
import { SimpleTag, TagColor } from '@atlaskit/tag';
import { get } from 'lodash';

/**
 * define the color scheme for different status
 */
const PAYMENT_STATUS_TAG_COLOR_SCHEME = {
  FAILED: 'red' as TagColor,
  FULLY_REFUNDED: 'greyLight' as TagColor,
  PAID: 'green' as TagColor,
  PARTIALLY_REFUNDED: 'greyLight' as TagColor,
  PENDING: 'yellow' as TagColor,
};

const DEFAULT_COLOR = 'greyLight';

interface Props {
  status:string,
}
export default ({ status }:Props) => {
  if (status) {
    return (
      <SimpleTag
        appearance="default"
        color={(get(PAYMENT_STATUS_TAG_COLOR_SCHEME, status, DEFAULT_COLOR))}
        text={status}
      />
    );
  }
  // if no payment status, display nothing
  return <></>;
};
