import { colors } from '@atlaskit/theme';
import { DEFAULT_STAT } from '../config/config';

const getColor = (
  v: string,
) => {
  const index = DEFAULT_STAT.findIndex((e) => e.name.toUpperCase() === v);
  if (index === -1) {
    return colors.Y300;
  }
  return DEFAULT_STAT[index].color;
};

export default getColor;
