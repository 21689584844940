import React, { useEffect, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import { ToggleStateless } from '@atlaskit/toggle';

type TextFieldInputProps = {
  name: string;
  control: Control<Record<string, string>>;
  defaultValue: boolean;
  setValue: Function;
  testId: string;
  isDisable: boolean;
};

const ProductIsForSaleSwitch = ({
  name,
  control,
  defaultValue,
  setValue,
  isDisable,
}: TextFieldInputProps) => {
  const [isChecked, setIsChecked] = useState(defaultValue);

  useEffect(() => {
    setValue(name, isChecked);
  }, [isChecked, name, setValue]);
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={() => (
          <>
            <div className="isForSaleLabel">
              <h3>For Sale</h3>
            </div>
            <ToggleStateless
              name="isForSale"
              isChecked={isChecked}
              size="regular"
              onChange={() => {
                setIsChecked((prev) => !prev);
                setValue(name, !isChecked);
              }}
              isDisabled={isDisable}
            />
          </>
        )}
      />
    </>
  );
};

export default ProductIsForSaleSwitch;
