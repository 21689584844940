import React, { useState } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { RowType, HeadType } from '@atlaskit/dynamic-table/types';
import { useHistory } from 'react-router';
import CreateCharge from '../components/Billing/CreateCharge';
// eslint-disable-next-line import/no-named-as-default
import Wrapper, {
  DefaultButton, CurrenStatus, InvoiceHistory, LeftCard, RightCard, BillInfo, BillingB, LeftColumn, MidColumn, RightColumn, LeftWrap, Paid, UnPaid,
} from '../components/Billing/ListBilling.style';
import CheckChargeType from '../components/Billing/CheckChargeType';
import { useAuth } from '../utils/useAuth';
import { useGetTeamByIdQuery, useGetTeamChargeBillingsByIdQuery } from '../graphql/types';
import BillingPDF from '../components/Billing/BillingPDF';
import CancelCharge from '../components/Billing/CancelCharge';
import CheckPlan from '../components/Billing/CheckPlan';

export default function Billing() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const history = useHistory();
  const close = () => {
    setIsOpen(false);
  };
  const closeCancel = () => {
    setIsCancel(false);
  };
  const { state } = useAuth();

  const teamId = state.currentTeamId || '';
  const { data: billingData } = useGetTeamChargeBillingsByIdQuery(
    {
      variables: {
        teamId,
      },
      // to avoid still show cached data after update when switching team
      fetchPolicy: 'network-only',
    },
  );

  const {
    data: teamProfileData,
  } = useGetTeamByIdQuery({
    variables: { teamId },
    // to avoid still show cached data after update when switching team
    fetchPolicy: 'network-only',
  });

  const name = teamProfileData?.GetTeamById.team?.name;
  const address = teamProfileData?.GetTeamById.team?.addresses[0]?.address;
  const payments = billingData?.GetTeamChargeBillingsById.billings || [];
  // eslint-disable-next-line max-len
  // const teamSettingsObj = teamProfileData?.GetTeamById.team?.settings ? JSON.parse(teamProfileData?.GetTeamById.team?.settings) : {};
  let {
    // eslint-disable-next-line prefer-const
    title, amount, id, planType,
  } = CheckPlan();
  const DaysLeftDescribe = 'Days Left';
  const teamCharge = CheckChargeType(); // 0=shopify, 1=cash, 2=other
  // console.log(teamCharge);
  let daysLeft = 0;
  const current = new Date(Date.now()).getTime();

  if (planType === 0) {
    const createdAt = teamProfileData?.GetTeamById.team?.createdAt;
    // console.log(createdAt);
    const diff = current - new Date(createdAt).getTime();
    daysLeft = Math.ceil(30 - diff / (1000 * 3600 * 24));
    if (daysLeft < 0) {
      title = 'Plan Expired';
      daysLeft = 0;
    }
  } if (planType === 2) {
    if (teamCharge === null || (teamCharge !== null && teamCharge.teamChargeStatus !== 'alive')) {
      title = 'Plan Expired';
      daysLeft = 0;
    }

    const activeEnd = teamCharge?.activeEnd;
    const diff = new Date(activeEnd).getTime() - current;
    // console.log(diff);
    if (diff > 0) {
      daysLeft = Math.ceil(diff / (1000 * 3600 * 24));
    } else {
      daysLeft = 0;
    }
  }

  const chargeID = teamCharge?.chargeID ? teamCharge.chargeID : '';
  const nextBillingDay = () => {
    const nextBill = teamCharge?.activeEnd;
    const AUday = new Date(nextBill);
    return AUday.toLocaleDateString('en-au', { timeZone: 'Australia/Melbourne' });
  };

  // const remainDay = () => {
  //   const current = new Date(Date.now()).getTime();
  //   if (plan.title === 'MERP Standard') {
  //     const day = teamCharge?.activeEnd;
  //     const newDate = new Date(day);
  //     const nextDate = new Date(newDate.setDate(newDate.getDate())).getTime();
  //     const diffTime = Math.abs(nextDate - current);
  //     const planDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //     return planDays;
  //   }
  //   if (plan.title === 'Free Trial') {
  //     const create = teamSettingsObj.createdTime;
  //     const diff = current - create;
  //     const freeDays = Math.ceil(30 - diff / (1000 * 3600 * 24));
  //     return freeDays;
  //   }

  //   return null;
  // };

  const paymentsHead: HeadType = {
    cells: [
      {
        content: 'Date',
      },
      {
        content: 'Invoice Number',
      },
      {
        content: 'Status',
      },
      {
        content: '',
      },
    ],
  };

  const paymentsRows: Array<RowType> = payments.map((payment) => ({
    cells: [
      { content: new Date(payment.invDate).toLocaleDateString('en-au', { timeZone: 'Australia/Melbourne' }) },
      { content: payment.invNumber },
      { content: payment.paid ? <Paid>PAID</Paid> : <UnPaid>UNPAID</UnPaid> },
      {
        content: <BillingPDF
          invoiceId={payment.id}
        />,
      },
    ],
  }));

  return (
    <Wrapper>
      <BillingB>
        <LeftCard>
          <h2>
            Current Plan
          </h2>
          <p>30-DAY FREE TRIAL</p>
          <CurrenStatus>
            <div>
              <LeftWrap>
                <LeftColumn>
                  {title === 'Plan Expired' ? (
                    <h3 style={{ marginTop: 17 }}>
                      {title}
                    </h3>
                  ) : (
                    <>
                      <h1 style={{ fontSize: '1.8em' }}>
                        {title}
                      </h1>
                      <p>
                        {daysLeft}
                        {' '}
                        {DaysLeftDescribe}
                      </p>

                    </>
                  )}

                </LeftColumn>
                <MidColumn>
                  {planType === 2
                  && (
                  <p>
                    <span style={{ fontSize: 32 }}>
                      $
                      {amount / 100}
                      {' '}
                      USD
                    </span>
                    /Mo.
                  </p>
                  )}

                </MidColumn>
                <RightColumn>
                  {planType === 0 && (
                    <DefaultButton
                      onClick={() => setIsOpen(true)}
                    >
                      UPGRADE

                    </DefaultButton>
                  ) }
                  {
                  planType === 2 && teamCharge?.monthPlan === true
                  && (
                    <DefaultButton
                      onClick={() => setIsCancel(true)}
                    >
                      Cancel Renewal

                    </DefaultButton>
                  )
                  }

                  { title === 'Plan Expired' && (
                  <DefaultButton
                    onClick={() => setIsOpen(true)}
                  >
                    Renew Subscription

                  </DefaultButton>
                  )}
                  {
                  title === 'MERP Standard' && teamCharge?.monthPlan === false
                  && (
                  <DefaultButton
                    onClick={() => setIsOpen(true)}
                  >
                    Renew Subscription

                  </DefaultButton>
                  )
}
                </RightColumn>
              </LeftWrap>
              <div style={{ color: '#808080' }}>
                <hr style={{ color: '#808080' }} />
                {
                  title === 'MERP Standard' && teamCharge?.monthPlan === true && (
                    <>
                      <p>
                        Your next payment is
                        {' '}
                        $
                        {amount / 100}
                        {' '}
                        USD
                        /Mo.
                        {' '}
                        on
                        {' '}
                        {nextBillingDay()}
                      </p>
                      <p>
                        Your payment will be automatically renewal each month
                      </p>
                      <p>
                        *VAT &amp; local taxes may apply
                      </p>
                    </>
                  )
                }
                {
                  title === 'MERP Standard' && teamCharge?.monthPlan
                  === false && (
                    <>
                      <p>
                        %0 Transactions Fees
                      </p>
                      <p>
                        All Supported Sales Channel
                      </p>
                      <p>
                        Unlimited Products
                      </p>
                      <p>
                        Unlimited User
                      </p>
                    </>
                  )
                }
                <p>
                  All charges are billed in USD. Recurring and usage-based charges are billed every 30 days.
                </p>
              </div>
            </div>

          </CurrenStatus>
          <h2>Billing Information</h2>
          <BillInfo>
            <LeftWrap>
              <LeftColumn>
                <h3>
                  {name}
                </h3>
                <p style={{ color: ' #000' }}>
                  {address?.fax === '' ? address?.email : address?.fax}
                </p>
              </LeftColumn>
              <RightColumn>
                <DefaultButton
                  onClick={() => history.push('/team/profile')}
                >
                  Modify Billling Information

                </DefaultButton>
              </RightColumn>
            </LeftWrap>

          </BillInfo>
        </LeftCard>
        <RightCard>

          <h2>Billing History</h2>
          <InvoiceHistory>
            <DynamicTable
              head={paymentsHead}
              rows={paymentsRows}
              rowsPerPage={8}
              defaultPage={1}
              loadingSpinnerSize="large"
              emptyView={<div>No Charge Invoice</div>}
            />
          </InvoiceHistory>
        </RightCard>

      </BillingB>

      <CreateCharge isOpen={isOpen} close={close} planID={id} />
      <CancelCharge isOpen={isCancel} close={closeCancel} chargeID={chargeID} />

    </Wrapper>
  );
}
