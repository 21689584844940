import gql, { disableFragmentWarnings } from 'graphql-tag';

disableFragmentWarnings();
const teamChannelFragment = gql`
fragment teamChannelFragment on TeamChannel {
  id,
  name,
  externalId,
  channel {
    id,
    name,
  },
  onBoarded,
  active,
  lastOrderSync,
  settings,
  createdAt,
  updatedAt,
  createdBy{
    firstName,
    lastName,
  },
  updatedBy{
    firstName,
    lastName,
  }
}
`;

export default teamChannelFragment;
