import React from 'react';
import { RowType, HeadType } from '@atlaskit/dynamic-table/types';
import DynamicTable from '@atlaskit/dynamic-table';
import styled from 'styled-components';
import { sumBy } from 'lodash';
import MerpDateTime from '../MerpDateTime/MerpDateTime';
import formatMoneyWithPrefix from '../../helpers/formatMoney';
import { Order } from '../OrdersListing/types';
import DeleteOrderPaymentModal from '../DeleteOrderPaymentModal/DeleteOrderPaymentModal';

const PaymentsContainer = styled.div`
    margin-bottom:20px; 
    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    } 
`;
const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: stretch;
    p {
      margin-left: 20px;
      margin-top: 0px;
    }
`;

interface PaymentProps {
  order: Order,
  refetchOrder: () =>void,
}

export default ({ order, refetchOrder }:PaymentProps) => {
  const {
    payments,
    totalAmtInclTax,
    status,
    channel,
  } = order;
  const shouldDeleteButtonBeDisabled = (status.code === 'CANCELLED' || channel.name === 'eBay' || status.code === 'SHIPPED');
  const paymentsHead: HeadType = {
    cells: [
      {
        content: 'Date',
      },
      {
        content: 'Paid By',
      },
      {
        content: 'Payment Method',
      },
      {
        content: 'Amount',
      },
      {
        content: 'Action',
      },
    ],
  };

  const paymentsRows: Array<RowType> = payments.map((payment) => ({
    cells: [
      { content: <MerpDateTime>{payment.createdAt}</MerpDateTime> },
      { content: payment.paidBy.name },
      { content: payment.paymentMethod.name },
      { content: formatMoneyWithPrefix(payment.paidAmount) },
      {
        content: <DeleteOrderPaymentModal
          orderId={payment.orderId}
          paidAmount={payment.paidAmount}
          paymentId={payment.id}
          isShipped={shouldDeleteButtonBeDisabled}
          refetchOrder={refetchOrder}
        />,
      },
    ],
  }));

  if (payments.length > 0) {
    const totalPaid = sumBy(payments, (payment) => payment.paidAmount);
    const amountDue = totalAmtInclTax - totalPaid;
    return (
      <PaymentsContainer>
        <h5>Payments</h5>
        <DynamicTable
          head={paymentsHead}
          rows={paymentsRows}
        />
        <RowContainer>
          <h5>
            Amount Due:
          </h5>
          <p>{formatMoneyWithPrefix(amountDue)}</p>
        </RowContainer>
      </PaymentsContainer>
    );
  }
  return <></>;
};
