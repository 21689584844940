import React from 'react';
import { Control } from 'react-hook-form';
import { Grid, GridColumn } from '@atlaskit/page';
import {
  Aspect, AspectMap, ErrorType,
} from './types';
import TextFieldWithLabelAndError from './TextFieldWithLabelAndError';
import CreatableSelectWithLabelAndError from './CreatableSelectWithLabelAndError';
import SelectWithLabelAndError from './SelectWithLabelAndError';

const AspectFields = ({
  aspects, control, setValue, errors, defaultValue, isDisable,
}: {
  aspects: Array<Aspect>, control: Control<Record<string, string>>,
  setValue: Function, errors: ErrorType, defaultValue: AspectMap, isDisable: boolean
}) => (aspects && Array.isArray(aspects)
  ? (
    <Grid>
      {aspects.map((aspect) => {
        let targetInput;
        let title = aspect.localizedAspectName;
        if (title.includes('Height')) {
          title = 'Item Height (cm)';
        } else if (title.includes('Weight')) {
          title = 'Item Weight (kg)';
        } else if (title.includes('Width')) {
          title = 'Item Width (cm)';
        } else if (title.includes('Length')) {
          title = 'Item Length (cm)';
        } else if (title.includes('Depth')) {
          title = 'Item Depth (cm)';
        }
        const defaultOptions = defaultValue[aspect.localizedAspectName]
          ? defaultValue[aspect.localizedAspectName] : [];
        const isSelection = (aspect.aspectValues
          && aspect.aspectValues.length > 0);
        const isSelectOnly = isSelection
        && (aspect.aspectConstraint.aspectMode === 'SELECTION_ONLY');
        const fieldOptions = aspect.aspectValues
        && Array.isArray(aspect.aspectValues)
          ? aspect.aspectValues.map((option) => ({
            label: option.localizedValue, value: option.localizedValue,
          })) : [];

        const inputProps = {
          key: aspect.localizedAspectName.toLowerCase(),
          name: `specs.${aspect.localizedAspectName}`,
          label: title,
          isRequired: aspect.aspectConstraint.aspectRequired,
          control,
          errors,
          defaultValue: defaultOptions,
          setValue,
          helpMessage: '',
          maxLength: -1,
        };
        const selectProps = {
          isMulti: aspect.aspectConstraint.itemToAspectCardinality !== 'SINGLE',
          optionsArr: fieldOptions,
        };
        if (isSelection === true) {
          if (isSelectOnly === true) {
            targetInput = (
              <SelectWithLabelAndError
                {...inputProps}
                {...selectProps}
                isDisable={isDisable}
              />
            );
          } else {
            targetInput = (
              <CreatableSelectWithLabelAndError
                {...inputProps}
                {...selectProps}
                isDisable={isDisable}
              />
            );
          }
        } else {
          targetInput = (
            <TextFieldWithLabelAndError
              testId={aspect.localizedAspectName}
              {...inputProps}
              isDisable={isDisable}
            />
          );
        }
        return (
          <GridColumn medium={6} key={aspect.localizedAspectName}>
            {targetInput}
          </GridColumn>
        );
      })}
    </Grid>
  ) : <></>);

export default AspectFields;
