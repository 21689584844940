import React, { useEffect, useState, useRef } from 'react';
import Flag from '@atlaskit/flag';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { G300 } from '@atlaskit/theme/colors';
import Modal, { ModalTransition, ModalFooter, FooterComponentProps } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import TextField from '@atlaskit/textfield';
import { debounce } from 'lodash';
import SearchProduct from '../../components/SearchProduct/SearchProduct';
// import ProductCreationFormContainer from '../../components/ProductCreationFormContainer/ProductCreationFormContainer';
import ProductEditByCategoryId from '../../components/ProductEdit/ProductEditByCategoryId';
import { useQueryEbayProductLazyQuery } from '../../graphql/types';
import { InitProductType, InitImageType } from '../../components/ProductEdit/types';
import { Aspect } from '../../components/SearchProduct/types';
import SuggestCategoriesBtn from '../../components/ProductEdit/SuggestCategoriesBtn';
import DisplayCategoryById from '../../components/ProductEdit/DisplayCategoryById';
import WithOnboardedBoundary from '../../components/WithOnboardedBoundary/WithOnboardedCheck';
import ProductFormContainer from '../../components/ProductEdit/ProductFormContainer';
import CategoryEditContainer from '../../components/ProductEdit/styles/CategoryEditContainer';
import FlexSpaceBetweenContainer from '../../components/ProductEdit/styles/FlexSpaceBetweenContainer';
import SearchCategoryContainer from '../../components/ProductEdit/styles/SearchCategoryContainer';
import SuggestCategoriesByKeyword from '../../components/ProductEdit/SuggestCategoriesByKeyword';
import { useAuth } from '../../utils/useAuth';
import Wrapper from '../../components/ListView/Wrapper';

const getAspects = (aspects: Array<Aspect>) => aspects
  .reduce((obj, aspect) => ({
    ...obj,
    [aspect.localizedName]: aspect.localizedValues,
  }), {});

const CreateProduct = () => {
  const { state: { currentTeamId, teams } } = useAuth();
  const currentTeam = teams.filter((t) => t.id === currentTeamId)[0];

  const [selectedEpid, setSelectedEpid] = React.useState('');
  const [selectCategoryId, setSelectCategoryId] = React.useState('');
  const initProductValues:InitProductType = {
    name: '',
    sku: '',
    images: [],
    shortDescription: '',
    fullDescription: '',
    isForSale: false,
    unitPrice: 100,
    stockOnHand: 1,
    aspects: {},
    productTaxClass: undefined,
    teamChannelProducts: [],
    totalInventoryCost: 0,
    wooCommerceProductType: '',
  };
  const [selectProductValues,
    setSelectProductValues] = React.useState(initProductValues);
  const [isSearchComplete, setIsSearchComplete] = useState(false);

  const [loadProduct, productState] = useQueryEbayProductLazyQuery({
    variables: {
      epid: selectedEpid,
    },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const getImages = (image: InitImageType | Array<InitImageType>) => {
    if (image && Array.isArray(image) && image.length > 0) {
      return image.map((img) => img.imageUrl);
    }
    return image && (image as InitImageType).imageUrl
      ? [(image as InitImageType).imageUrl] : [];
  };

  // Prepare category id & prefill values for product edit
  useEffect(() => {
    if (productState.data) {
      const productResp = productState.data.QueryEbay.response;
      // TODO remove default ebay images for now
      const convertedImgs: string[] = getImages(productResp.image);

      const productValues: InitProductType = {
        name: productResp.title,
        sku: productResp.epid,
        shortDescription: productResp.title,
        fullDescription: productResp.title,
        aspects: getAspects(productResp.aspects),
        isForSale: false,
        images: convertedImgs,
        productTaxClass: { id: currentTeam.settings.defaultProductTaxClassId, name: '' },
        teamChannelProducts: [],
        unitPrice: 100,
        stockOnHand: 1,
        totalInventoryCost: 0,
        wooCommerceProductType: '',
      };
      setSelectProductValues(productValues);
      setSelectCategoryId(productResp.primaryCategoryId);
    }
  }, [currentTeam.settings.defaultProductTaxClassId, productState.data]);

  // Get product details via epid
  const onProductSearchComplete = (tmpEpid: string) => {
    setSelectedEpid(tmpEpid);
    loadProduct({
      variables: {
        epid: tmpEpid,
      },
    });
  };

  const CustomFooter = (props: FooterComponentProps) => (
    <ModalFooter {...props}>
      <span />
      <Button onClick={() => { setIsModalOpen(false); }}>
        Close
      </Button>
    </ModalFooter>
  );

  const debounceHandleSearch = debounce((val: string) => {
    setKeyword(val);
  }, 500);

  const chosenCategoryCallback = (chosenCategoryId: string) => {
    setSelectCategoryId(chosenCategoryId);
    setIsModalOpen(false);
  };

  return selectCategoryId ? (
    <ProductFormContainer>
      <h1>New Product</h1>
      <CategoryEditContainer>
        <SuggestCategoriesBtn
          appearance="subtle-link"
          text={<DisplayCategoryById categoryId={selectCategoryId} />}
          onCategoryChosen={(cateId: string) => {
            setSelectCategoryId(cateId);
          }}
        />
      </CategoryEditContainer>
      <ProductEditByCategoryId
        categoryId={selectCategoryId}
        productData={selectProductValues}
      />

    </ProductFormContainer>
  ) : (
    <div className="create-product">
      <SearchProduct
        className={isSearchComplete ? 'searchComplated' : ''}
        onComplete={onProductSearchComplete}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSelectProductCategory={(product: any) => {
          setSelectCategoryId(product.categoryName);
          setSelectProductValues({
            sku: product.sku,
            name: product.shortDescription,
            shortDescription: '',
            fullDescription: '',
            aspects: getAspects([]),
            isForSale: false,
            images: [],
            productTaxClass: { id: '', name: '' },
            teamChannelProducts: [],
            unitPrice: 100,
            stockOnHand: 1,
            totalInventoryCost: 0,
            wooCommerceProductType: '',
          });
        }}
        onSearchComplete={() => {
          setIsSearchComplete(true);
        }}
      />
      {
       isSearchComplete ? (
         <Wrapper>
           <div className="productSearch">
             <Flag
               icon={<SuccessIcon primaryColor={G300} label="Info" />}
               description={(
                 <>
                   <p>You can create a product by your own:</p>
                 </>
             )}
               id="1"
               key="1"
               title="Still not find the product?"
               actions={[{ content: 'Suggest a category', onClick: () => { setIsModalOpen(true); } }]}
             />
           </div>
         </Wrapper>
       ) : <></>
      }
      <ModalTransition>
        {isModalOpen && (
          <Modal
            onClose={() => setIsModalOpen(false)}
            heading="Suggest a category"
            width="large"
            components={{
              Footer: CustomFooter,
            }}
          >
            <form onSubmit={(e) => {
              e.preventDefault();
              setKeyword(inputRef.current.value);
            }}
            >
              <FlexSpaceBetweenContainer>
                <TextField
                  ref={inputRef}
                  name="keyword"
                  placeholder="Please enter a keyword to start"
                  onChange={
                    (evt) => debounceHandleSearch(evt.currentTarget.value)
                  }
                />
                <Button
                  style={{ height: 40 }}
                  appearance="primary"
                  type="submit"
                >
                  Search
                </Button>
              </FlexSpaceBetweenContainer>
            </form>
            <SearchCategoryContainer>
              {keyword.trim() !== '' && (
                <SuggestCategoriesByKeyword
                  onCategoryChosen={chosenCategoryCallback}
                  keyword={keyword}
                />
              )}
            </SearchCategoryContainer>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default () => (
  <WithOnboardedBoundary>
    <CreateProduct />
  </WithOnboardedBoundary>
);
