import React from 'react';
import { store } from 'react-notifications-component';
import Button from '@atlaskit/button';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import addNotification from '../../utils/addNotification';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import SuccessFlag from '../ProductEdit/SuccessFlag';
import { useSyncEbayProductsViaQueueMutation } from '../../graphql/types';

const SyncProductsFromEbayBtn = ({
  children,
  teamChannelId,
  teamChannelName,
}: {
  children: string;
  teamChannelId: string;
  teamChannelName: string;
}) => {
  const [
    syncEbayProductsViaQueue,
    syncEbayProductsViaQueueState,
  ] = useSyncEbayProductsViaQueueMutation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isConfirmWordEntered, setIsConfirmWordEntered] = React.useState(false);
  const handleCleanTeamChannel = async () => {
    await syncEbayProductsViaQueue({
      variables: {
        teamChannelId,
      },
    })
      .then(() => {
        store.addNotification({
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
          },
          content: <SuccessFlag title={`Pulling products from ${teamChannelName}`} />,
        });
        setIsOpen(false);
      })
      .catch((err) => {
        addNotification(graphQLErrorsReader(err), 'danger');
      });
  };

  const actions = [
    {
      text: 'Confirm',
      isDisabled: isConfirmWordEntered === false,
      onClick: handleCleanTeamChannel,
      isLoading: syncEbayProductsViaQueueState.loading,
    },
    {
      text: 'Cancel',
      onClick: () => setIsOpen(false),
    },
  ];

  const channelNameInputHandler = (evt: React.FormEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    if (value.trim().toLowerCase() === teamChannelName.toLowerCase()) {
      setIsConfirmWordEntered(true);
    } else {
      setIsConfirmWordEntered(false);
    }
  };

  return (
    <>
      {isOpen}
      <ModalTransition>
        {isOpen && (
          <Modal
            actions={actions}
            onClose={() => setIsOpen(false)}
            heading="Confirm to pull products from eBay?"
            width="medium"
            shouldCloseOnEscapePress={false}
            shouldCloseOnOverlayClick={false}
            appearance="danger"
          >
            <p>
              {/* eslint-disable-next-line max-len */}
              This action cannot be undone. It would download all eBay listing and merge into products. Please enter the word
              {' "'}
              {teamChannelName}
              {'" '}
              to continue
            </p>
            <p>
              <Textfield
                testId="pushProductChannelName"
                placeholder={`Enter your market place name "${teamChannelName}"`}
                onChange={channelNameInputHandler}
              />
            </p>
          </Modal>
        )}
      </ModalTransition>
      <Button appearance="danger" onClick={() => setIsOpen(true)}>
        {children}
      </Button>
    </>
  );
};

export default SyncProductsFromEbayBtn;
