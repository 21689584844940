import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

interface CallToConfirmModalProps {
  heading:string,
  message:React.ReactNode,
  onConfirm:Function,
  drawToActionComponent:(onClick: () => void) => JSX.Element,
}

const SimpleConfirmModal = ({
  heading,
  message,
  onConfirm,
  drawToActionComponent,
}:CallToConfirmModalProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleConfirm = () => {
    onConfirm();
    setIsOpen(false);
  };

  const actions = [
    {
      text: <>Confirm</>,
      onClick: handleConfirm,
      testId: 'confirmButton',
    },
    {
      text: <>Cancel</>,
      onClick: () => setIsOpen(false),
    },
  ];

  return (
    <>
      {drawToActionComponent(() => setIsOpen(true))}
      {isOpen}
      <ModalTransition>
        {isOpen && (
          <Modal
            actions={actions}
            onClose={() => setIsOpen(false)}
            heading={heading}
            width="medium"
            appearance="danger"
          >
            {message}
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default SimpleConfirmModal;
