import React, { useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { Radio } from '@atlaskit/radio';
import Button from '@atlaskit/button';
import { useHistory } from 'react-router';
import axios from 'axios';
import { useAuth, clientSideLogoutTask } from '../../utils/useAuth';
import {
  CreateTeamChannelMutation, useCreateTeamChannelMutation, useCreateTeamChargeMutation, useGetTeamChannelsByTeamIdQuery,
} from '../../graphql/types';
import addNotification from '../../utils/addNotification';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';

interface CreateChargeInputProps {
  isOpen: boolean,
  close: any, //eslint-disable-line
  planID: string
}

const CreateCharge = (props: CreateChargeInputProps) => {
  const {
    isOpen, close, planID,
  } = props;
  const [isShopify, setIsShopify] = useState<boolean>(false);
  const { state: { currentTeamId: teamId, user }, dispatch } = useAuth();
  const [shopifyStore, setShopifyStore] = useState('');
  const [shopifyStoreName] = useState('');
  const [createTeamCharge] = useCreateTeamChargeMutation();
  const history = useHistory();

  const [
    createTeamChannel,
  ] = useCreateTeamChannelMutation();
  const {
    data: teamChannelData,
  } = useGetTeamChannelsByTeamIdQuery({
    variables: {
      teamId: teamId!,
    },
    fetchPolicy: 'network-only', // to avoid cached data not refresh after delete teamChannel
  });
  const teamChannels = teamChannelData?.GetTeamChannelsByTeamId.teamChannels || [];
  let empty = true;

  teamChannels.forEach((teamChannel) => {
    if (teamChannel.channel.name === 'Shopify') {
      empty = false;
    }
  });

  const REACT_APP_GRAPHQL_SERVER_HOST = process.env.REACT_APP_GRAPHQL_SERVER_HOST
    || 'http://localhost:4000';

  const installMerpApp = async () => {
    // setIsloading(true);
    const userId = user ? user.id : '';
    const res = await axios.get(
      `${REACT_APP_GRAPHQL_SERVER_HOST}/oauth/shopify/installUrl?teamId=${teamId}&userId=${userId}`,
    );
    const { url } = res.data;
    if (url) {
      window.location.assign(url);
    }
  };

  // const {
  //   data: teamPlan,
  // } = useGetTeamChargePlansQuery(
  //   {
  //     variables: {},
  //     // to avoid still show cached data after update when switching team
  //     fetchPolicy: 'network-only',
  //   },
  // );
  // const plans = teamPlan?.GetTeamChargePlans.teamChargePlans || [];
  // const { id } = plans[0] || '';

  const handleCreateCharge = async () => {
    if (shopifyStore === 'merp.com.au') {
      window.open('https://www.merp.com.au/pricing/');
      return;
    }

    if (shopifyStore === 'add new shopify store') {
      const channelId = process.env.REACT_APP_SHOPIFY_CHANNEL_ID || '8';

      const shopifyChannelVariables = channelId ? {
        teamId: teamId!,
        name: shopifyStoreName,
        channelId,
        channelSettings: JSON.stringify({
          url: shopifyStoreName,
        }),
      } : {
        teamId: teamId!,
        name: shopifyStoreName,
        channelId,
      };

      const handleLocalStateUpdate = (
        data: CreateTeamChannelMutation | undefined,
      ) => {
        try {
          const teamChannel = data?.CreateTeamChannel.teamChannel;
          const settings = JSON.parse(teamChannel!.settings);
          // update local state
          dispatch({
            type: 'addTeamChannel',
            addTeamChannelPayload: {
              id: teamChannel!.id,
              channelID: teamChannel!.channel.id,
              channelName: teamChannel!.channel.name,
              name: teamChannel!.name,
              active: teamChannel!.active ?? true,
              externalId: teamChannel!.externalId,
              onBoarded: teamChannel!.onBoarded,
              settings: {
                pushStockQty: settings?.pushStockQty ?? false,
                pushPriceUpdates: settings?.pushPriceUpdates
                  ?? false,
                refresh_token_expires_at: settings?.access_token?.refresh_token_expires_at ?? '',
                token: settings?.access_token?.access_token ?? '',
                country: settings?.country ?? 'AU',
                currency: settings?.currency ?? 'AUD',
                fulfillmentPolicy: {
                  id: settings?.fulfillmentPolicyId ?? '',
                  name: settings?.fulfillmentPolicyName ?? '',
                },
                paymentPolicy: {
                  id: settings?.paymentPolicyId ?? '',
                  name: settings?.paymentPolicyName ?? '',
                },
                returnPolicy: {
                  id: settings?.returnPolicyId ?? '',
                  name: settings?.returnPolicyId ?? '',
                },
                merchantLocation: {
                  id: settings?.merchantLocationKey ?? '',
                  name: settings?.merchantLocationName ?? '',
                },
              },
              createdAt: teamChannel!.createdAt,
              updatedAt: teamChannel!.updatedAt,
              createdBy: `${teamChannel?.createdBy?.firstName ?? ''} ${teamChannel?.createdBy?.lastName}`,
              updatedBy: `${teamChannel?.updatedBy?.firstName ?? ''} ${teamChannel?.updatedBy?.lastName}`,
            },
          });
        } catch (error) {
          // if encouter unexpected err,
          // we force user to relogin so can retrive the local state again
          // therefore, the frontend can have consistent data with backend.
          clientSideLogoutTask();
        }
      };

      await createTeamChannel({
        variables: shopifyChannelVariables,
      }).then(({ data: createData }) => {
        handleLocalStateUpdate(createData);
        const teamChannelId = createData?.CreateTeamChannel.teamChannel.id;
        // push user to onboarding
        history.push(`/connect/shopify/${teamChannelId}?${shopifyStoreName}`);
      }).catch((err) => addNotification(err.toString(), 'danger'));

      return;
    }

    await createTeamCharge({
      variables: {
        teamId: teamId!,
        teamChannelId: shopifyStore,
        teamChargePlanId: planID,
      },
    })
      .then((res) => {
        window.location.assign(res?.data?.CreateTeamCharge.confirmationUrl || '');
      })
      .catch((err) => {
        addNotification(graphQLErrorsReader(err), 'danger');
      });
  };

  const action = [
    {
      text: 'Next Step',
      onClick: () => handleCreateCharge(),
      isDisabled: shopifyStore === '',
    },
    {
      text: 'Close',
      onClick: () => {
        close();
        setIsShopify(false);
      },
    },
  ];

  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          actions={action}
          onClose={close}
          heading="Subscribe to our Plan and Choose your Store"
          width="medium"
          shouldCloseOnEscapePress={false}
          shouldCloseOnOverlayClick={false}
        >
          <div className="modal-container">

            <p style={{ padding: 10 }}>
              {empty !== true ? ' To get started, please select the Shopify store that you want to upgrade from the list below' : 'If you did add the shopify store in MERP, please choose to contact us to active or add new shopify store'}
            </p>
            {teamChannels?.filter(
                (teamChannel) => teamChannel.channel.name === 'Shopify',
              )
                ?.map(
                  (teamChannel) => (
                    <Radio
                      value={teamChannel.name}
                      label={teamChannel.name}
                      name={teamChannel.name}
                      testId={teamChannel.name}
                      isChecked={shopifyStore === teamChannel.id}
                      onChange={() => {
                        setShopifyStore(teamChannel.id);
                      }}
                    />
                  ),
                )}
            {
                  empty === true
                  && (
                  <>
                    {/* <Radio
                      value="merp.com.au"
                      label="merp.com.au"
                      name="merp.com.au"
                      testId="merp.com.au"
                      isChecked={shopifyStore === 'merp.com.au'}
                      onChange={() => {
                        setShopifyStore('merp.com.au');
                      }}
                    /> */}
                    <Radio
                      value="add new shopify store"
                      label="add new shopify store"
                      name="add new shopify store"
                      testId="add new shopify store"
                      isChecked={shopifyStore === 'add new shopify store'}
                      onChange={() => {
                        setIsShopify(true);
                        setShopifyStore('add new shopify store');
                      }}
                    />
                    {isShopify && (
                    <Button
                      onClick={
                      installMerpApp
                    }
                      appearance="default"
                    >
                      Install MERP on Shopify
                    </Button>
                    )}
                  </>
                  )
                }
          </div>
        </Modal>

      )}
    </ModalTransition>

  );
};

export default CreateCharge;
