import Button from '@atlaskit/button';
import Textfield from '@atlaskit/textfield/dist/cjs/components/Textfield';
import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import addNotification from '../../../utils/addNotification';
import graphQLErrorsReader from '../../../utils/graphQLErrorsReader';
import { useUpdateOrderDiscountMutation } from '../../../graphql/types';

export default ({
  orderId,
  orderAmount,
  children,
  onFinish,
}: {
  orderId: string,
  orderAmount: number,
  children: React.ReactNode
  onFinish: () => void
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isDiscountEntered, setIsDiscountEntered] = React.useState(false);
  const [discountAmt, setDiscountAmt] = React.useState(0);
  const [
    updateOrderDiscount,
    updateOrderDiscountState,
  ] = useUpdateOrderDiscountMutation();

  const handleApplyDiscount = async () => {
    await updateOrderDiscount({
      variables: {
        discount: {
          orderId,
          discountAmt,
        },
      },
    })
      .then(() => {
        onFinish();
        setIsOpen(false);
      })
      .catch((err) => {
        addNotification(graphQLErrorsReader(err), 'danger');
      });
  };

  const actions = [
    {
      text: 'Confirm',
      isDisabled: isDiscountEntered === false,
      onClick: handleApplyDiscount,
      isLoading: updateOrderDiscountState.loading,
    },
    {
      text: 'Cancel',
      onClick: () => setIsOpen(false),
    },
  ];

  const discountAmountHandler = (evt: React.FormEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    const floatVal = parseFloat(value) * 100;
    if (floatVal >= 0 && floatVal <= orderAmount) {
      setIsDiscountEntered(true);
      setDiscountAmt(floatVal);
    } else {
      setIsDiscountEntered(false);
      setDiscountAmt(0);
    }
  };

  return (
    <>
      <ModalTransition>
        {isOpen && (
        <Modal
          actions={actions}
          onClose={() => setIsOpen(false)}
          heading="Please enter discount amount:"
          width="medium"
          shouldCloseOnEscapePress={false}
          shouldCloseOnOverlayClick={false}
        >
          <p>
            <Textfield
              placeholder="Enter discount amount"
              onChange={discountAmountHandler}
            />
          </p>
        </Modal>
        )}
      </ModalTransition>
      <Button
        appearance="subtle-link"
        spacing="compact"
        onClick={() => setIsOpen(true)}
      >
        {children}
      </Button>
    </>
  );
};
