import React, { useState, SyntheticEvent } from 'react';
import Button from '@atlaskit/button';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import Pagination from '@atlaskit/pagination';
import { ErrorMessage } from '@atlaskit/form';
import { useHistory } from 'react-router-dom';
import { get, min } from 'lodash';
import RefreshIcon from '@atlaskit/icon/glyph/refresh';
import graphQLErrorsReader from '../../../../utils/graphQLErrorsReader';
import PaginationWrapper from '../../styles/PaginationWrapper';
import Wrapper from '../../../ListView/Wrapper';
import AdvancedFilter from '../../widget/AdvancedFilter';
import ColumnOptions from '../../widget/ColumnOptions';
import GlobalSearchBox from '../../widget/GlobalSearchBox';
import { useProducts } from '../../ProductsListing';
import { useProductsQuery, useExportProductsMutation } from '../../../../graphql/types';
import { getHeadAndRow, withStorages } from '../../helper/getHeadAndRow';
import withMultiplePrices from '../../helper/withMultiplePrices';
import getPages from '../../helper/getPages';
import { Product } from '../../types';
import MarketplaceFilter from '../../widget/MarketplaceFilter';
import BulkProductsUpdateModal from '../../../BulkProductsUpdateModal/BulkProductsUpdateModal';
import addNotification from '../../../../utils/addNotification';
import PlanUpgrade from '../../../Billing/PlanUpgrade';
import { useSubscription } from '../../../../utils/useAuth';

interface ListViewProps {
  teamId: string,
}

const ProductsListView = (props: ListViewProps) => {
  const { teamId } = props;
  const { state, dispatch } = useProducts();
  const {
    data, loading, error, refetch,
  } = useProductsQuery({
    variables: {
      teamID: teamId,
      skip: state.query.skip,
      take: state.query.take,
      sortKey: state.query.sortKey,
      sortOrder: state.query.sortOrder,
      keyword: state.query.keyword,
      teamChannelIDs: state.query.teamChannelIDs,
      filters: state.query.advancedFilter.map((filter) => ({
        filterKey: filter.filterKey,
        filterCondition: filter.filterCondition,
        filterValue: filter.filterValue,
      })),
    },
  });

  const freeExpired = useSubscription();
  const [disable, setDisable] = React.useState<boolean>(false);

  const [isRefetching, setIsRefetching] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [exportProductsMutation] = useExportProductsMutation();
  const [downloading, setDownloading] = useState(false);
  const downloadCSV = () => {
    setDownloading(true);
    exportProductsMutation({
      variables: { teamId },
    })
      .then((response) => {
        setDownloading(false);
        const url = response?.data?.ExportProducts?.downloadUrl;
        if (!url) {
          addNotification('Download product csv failed', 'danger');
        } else {
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.click();
        }
      })
      .catch(() => {
        setDownloading(false);
        addNotification('Download product csv failed', 'danger');
      });
  };
  const close = () => {
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };

  const closeUpgrade = () => {
    setDisable(false);
  };

  const handleRefetch = async () => {
    setIsRefetching(true);
    await refetch()
      .finally(() => setIsRefetching(false));
  };

  const isLoading = loading || isRefetching;

  const onRowClick = (product:Product) => {
    dispatch({
      type: 'setCurrentProductID',
      payload: product.id,
    });
  };

  const { head, rows } = getHeadAndRow(
    data,
    withStorages(state.display.columns),
    onRowClick,
    freeExpired,
  );

  const highlightedRowIndex = rows.findIndex(
    (row) => row.key === state.display.currentProductId,
  );

  const onClickAdvancedSearch = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    clearTimeout(state.ui.debonced);
    dispatch({
      type: 'setIsAdvancedFilterOpen',
      payload: !state.display.isAdvancedFilterOpen,
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSort = (d: any) => {
    dispatch({
      type: 'setSortKey',
      payload: d.key,
    });
    dispatch({
      type: 'setSortOrder',
      payload: d.sortOrder,
    });
  };
  const pages = getPages(data, state.query.take);

  const handlePageChange = async (event: SyntheticEvent, newPage: number) => {
    dispatch({
      type: 'setSkip',
      payload: (newPage - 1) * state.query.take,
    });
    dispatch({
      type: 'setCurrentPage',
      payload: (newPage - 1),
    });
  };

  React.useEffect(() => {
    if (state.utils.lastDeletedId) {
      refetch();
    }
  }, [state.utils.lastDeletedId, refetch]);

  /**
   * GIVEN:has product selected to display on right pane
   * THEN: show only columns which compactMode = true
   */
  React.useEffect(() => {
    if (state.display.currentProductId) {
      // set compacted columns option
      const compactedColumns = state.display.columns.filter(
        ((cur) => (cur.compactMode)),
      );

      dispatch({
        type: 'setColumns',
        payload: compactedColumns,
      });
    } else {
      dispatch({
        type: 'setColumns',
        payload: withStorages(withMultiplePrices(data)),
      });
    }
  }, [state.display.currentProductId,data]); //eslint-disable-line

  /**
   * calculation for total products
   */
  const productsTotal = get(data, 'Products.total', 0);
  const cursorFrom = data?.Products && data?.Products?.total > 0
    ? state.query.skip + 1
    : 0;
  const cursorTo = min([(cursorFrom + state.query.take - 1), productsTotal]);

  const renderPagination = () => (
    <div className="count">
      {productsTotal > 0
        && (
          <div>
            {cursorFrom}
            -
            {cursorTo}
            {' '}
            <span>of</span>
            {' '}
            {productsTotal}
          </div>
        )}
      <Button
        appearance="subtle-link"
        iconBefore={<RefreshIcon label="refresh" size="small" />}
        spacing="none"
        onClick={handleRefetch}
      />
    </div>
  );

  const renderSearchBox = () => (
    <>
      <div className="filters">
        <MarketplaceFilter />
      </div>
      <div className="search-box">
        <GlobalSearchBox />
        <Button
          appearance="link"
          onClick={onClickAdvancedSearch}
          testId="advanced-search-toggle-button"
        >
          Advanced
        </Button>
      </div>
    </>
  );

  const history = useHistory();

  return (
    <>
      <Wrapper data-testid="productsListView" style={{ backgroundColor: '#ffffff' }}>
        <div className="heading">
          <h2>Products</h2>
          <div className="heading-btns">
            <Button appearance={freeExpired === true ? 'default' : 'primary'} onClick={() => (freeExpired === true ? setDisable(true) : history.push('/create-product'))}>Add Product</Button>
            <Button appearance={freeExpired === true ? 'default' : 'primary'} onClick={() => (freeExpired === true ? setDisable(true) : open())}>Bulk Update</Button>
            <Button
              appearance={freeExpired === true ? 'default' : 'primary'}
              onClick={() => (freeExpired === true ? setDisable(true) : history.push('/receiving'))}
            >
              Receiving
            </Button>
            <Button
              appearance="primary"
              onClick={downloadCSV}
              isLoading={downloading}
            >
              Export Products Data
            </Button>

            <BulkProductsUpdateModal isOpen={isOpen} close={close} />
          </div>
        </div>
        <div className="search-filter-wrapper">
          {renderSearchBox()}
        </div>
        {state.display.isAdvancedFilterOpen && (
        <div className="advanced-filter">
          <AdvancedFilter />
        </div>
        )}
        <div className="table-controls">
          <div className="pagination">
            {renderPagination()}
          </div>
          <div className="column-options">
            {!state.display.currentProductId && <ColumnOptions />}
          </div>
        </div>
        <DynamicTableStateless
        // isFixedSize
          head={head}
          rows={rows}
          loadingSpinnerSize="large"
          isLoading={isLoading}
          testId="products-listing-table"
          onSort={onSort}
          sortKey={state.query.sortKey}
          sortOrder={state.query.sortOrder}
          emptyView={<div>No Match</div>}
          highlightedRowIndex={highlightedRowIndex}
        />
        {error
        && (
          <ErrorMessage>
            {graphQLErrorsReader(error)}
          </ErrorMessage>
        )}

        <PaginationWrapper data-testid="pagination">
          {(!loading && rows.length > 0)
          && (
            <Pagination
              pages={pages}
              onChange={handlePageChange}
              selectedIndex={state.pagination.currentPage}
            />
          )}
        </PaginationWrapper>
      </Wrapper>
      <PlanUpgrade isOpen={disable} close={closeUpgrade} />
    </>
  );
};

export default ProductsListView;
