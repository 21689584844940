import React from 'react';
import queryString from 'query-string';
import Modal, {
  ModalHeader, ModalTitle, ModalTransition,
} from '@atlaskit/modal-dialog';

export default function TeamCharge() {
  const { team_charge_success: success } = queryString.parse(window.location.search);

  const action = [
    {
      text: 'Done',
      onClick: () => window.location.assign('/Billing'),
    },
  ];

  return (
    <>
      <ModalTransition>
        <Modal
          actions={action}
        >
          <ModalHeader>
            <ModalTitle>
              Your subscriptions for SAAS Standard is
              {' '}
              {success === 'true' ? 'Successful' : 'Failed'}
            </ModalTitle>
          </ModalHeader>
        </Modal>
      </ModalTransition>

    </>
  );
}
