import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import addNotification from '../../utils/addNotification';
import { useAuth } from '../../utils/useAuth';
import { Scalars } from '../../graphql/types';
import { setAccessToken } from '../../utils/storages';

type MutationAccessArgs = {
  accessToken: Scalars['String'];
};
export default (props: { location: { search: string } }) => {
  const history = useHistory();
  const { location } = props;
  const { search } = location;
  const accessToken = queryString.parse(search);
  const accessTokenForSet = accessToken?.access_token as string;
  const { xeroSignIn } = useAuth();
  useEffect(() => {
    const xeroHandleAccessToken = async () => {
      setAccessToken(accessTokenForSet as string);
      await xeroSignIn({ accessToken: accessTokenForSet })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          window.localStorage.setItem('hasTokenAlertShown', 'false');
          const teamUser = res?.teams;
          if (teamUser && teamUser.length > 0) {
            try {
              history.push('/home');
            } catch (err) {
              // failover
              history.push('/');
            }
          }
        })
        .catch(
          () => {
            addNotification('XeroLogin Fail', 'danger');
            history.push('/signin');
          },
        );
    };
    xeroHandleAccessToken();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <p>
      Loading...
    </p>
  );
};
