import Button from '@atlaskit/button';
import CopyIcon from '@atlaskit/icon/glyph/copy';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { Radio } from '@atlaskit/radio';
import Spinner from '@atlaskit/spinner';
import TextField from '@atlaskit/textfield';
import { debounce } from 'lodash';
import React, { useRef, useState } from 'react';
import { store } from 'react-notifications-component';
import styled from 'styled-components';
import noImage from '../../../assets/svg/noimage.svg';
import { Product, useProductsQuery, useSwapEBayProductMutation } from '../../../graphql/types';
import CenteredContainer from '../../ImageUploader/CenteredContainer';
import FlexSpaceBetweenContainer from '../../ProductEdit/styles/FlexSpaceBetweenContainer';
import OptionContainer from '../../ProductEdit/styles/OptionContainer';
import SearchCategoryContainer from '../../ProductEdit/styles/SearchCategoryContainer';
import SuccessFlag from '../../ProductEdit/SuccessFlag';
import Option from '../../SearchProduct/styles/Option';
import ItemTitleCard from './ItemTitleCard';
import swap from '../../../assets/svg/swap.svg';
import leftArrow from '../../../assets/svg/left-arrow.svg';
import { ProductProps } from '../../ProductDetailsContainer/type';
import { useSubscription } from '../../../utils/useAuth';

const SearchProductsByKeyword = ({ onProductChosen, keyword, teamID }: {
  onProductChosen: Function,
  keyword: string,
  teamID: string
}) => {
  const { data, error, loading } = useProductsQuery({
    variables: {
      teamID,
      skip: 0,
      take: 10,
      keyword,
    },
  });

  if (error) {
    return <p>Something wrong when getting suggestions</p>;
  }

  if (keyword === '') {
    return <></>;
  }
  return loading === true ? (
    <OptionContainer>
      <CenteredContainer>
        <Spinner />
      </CenteredContainer>
    </OptionContainer>
  ) : (
    <div data-testid="search-products-result">
      <OptionContainer>
        {
          (data?.Products.products
            && Array.isArray(data?.Products.products))
            ? data?.Products.products
            .map((prod) => (
              <Option
                key={prod.id}
                onClick={() => onProductChosen(prod)}
              >
                <ItemTitleCard
                  title={prod.name}
                  sku={prod.sku}
                  image={(prod.images && Array.isArray(prod.images)
                    && prod.images.length > 0)
                    ? prod.images[0].url : noImage}
                />
              </Option>
            )) : (
              <p>
                No relevant product was found. Please try another keyword.
              </p>
            )
          }
      </OptionContainer>
    </div>
  );
};

const TargetProductList = ({ onSelect, teamID }:
{ onSelect: (productId: Product, teamID: string) => void, teamID: string }) => {
  const [keyword, setKeyword] = useState('');
  const debounceHandleSearch = debounce((val: string) => {
    setKeyword(val);
  }, 500);
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const chosenProductCallback = (product: Product) => {
    onSelect(product, teamID);
  };

  return (
    <>
      <p>Please search and select an existing product</p>
      <form onSubmit={(e) => {
        e.preventDefault();
        setKeyword(inputRef.current.value);
      }}
      >
        <FlexSpaceBetweenContainer>
          <TextField
            ref={inputRef}
            name="keyword"
            placeholder="Please enter a keyword to start"
            onChange={
              (evt) => debounceHandleSearch(evt.currentTarget.value)
            }
          />
          <Button
            style={{ height: 40 }}
            appearance="primary"
            type="submit"
          >
            Search
          </Button>
        </FlexSpaceBetweenContainer>
        <SearchCategoryContainer>
          {keyword.trim() !== '' && (
          <SearchProductsByKeyword
            onProductChosen={chosenProductCallback}
            keyword={keyword}
            teamID={teamID}
          />
          )}
        </SearchCategoryContainer>
      </form>
    </>
  );
};

const ReplaceEbayProductBtn = ({
  children, teamID, sourceProduct,
}: {
  children: React.ReactNode,
  teamID: string,
  sourceProduct: ProductProps,
}) => {
  const isDisabled = useSubscription();
  const [isOpen, setIsOpen] = React.useState(false);
  const [targetProduct, setTargetProduct] = React.useState<Product>();

  const [mode, setMode] = React.useState('SWAP');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [swapProduct] = useSwapEBayProductMutation();

  const handleSwapEbayProduct = () => {
    if (!targetProduct) {
      return;
    }
    const mutationVar = {
      sourceProductId: sourceProduct.id,
      targetProductId: targetProduct.id,
      mode,
    };
    setSubmitting(true);
    swapProduct({
      variables: mutationVar,
    }).then(() => {
      setSubmitting(false);
      store.addNotification({
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
        content: <SuccessFlag title={`eBay product ${mode} success!`} />,
      });
      setIsOpen(false);
      setTargetProduct(undefined);
    });
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleProductChosen = (product: Product) => {
    setTargetProduct(product);
  };

  const actions = [
    {
      text: 'Confirm',
      onClick: handleSwapEbayProduct,
      isLoading: submitting,
      isDisabled: !targetProduct,
    },
    {
      text: 'Close', onClick: handleClose,
    },
  ];

  const ProductSwapContainer = styled.div`
    display:flex;  
    .icon {
        max-width:30px;
        margin: 0 2rem;      
      };
  `;

  return (
    <>
      <Button
        iconBefore={<CopyIcon size="medium" label="Swap/Replace ebay product details from a target product" />}
        onClick={() => setIsOpen(true)}
        isDisabled={isDisabled}
      >
        {children}
      </Button>
      <ModalTransition>
        {isOpen && (
        <Modal
          actions={actions}
          onClose={() => setIsOpen(false)}
          heading="Swap/Replace eBay product(s)"
          width="x-large"
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEscapePress={false}
        >
          {!targetProduct && (
          <TargetProductList
            onSelect={handleProductChosen}
            teamID={teamID}
          />
          )}
          {targetProduct && (
          <>
            <h5>
              Please confirm the selected product and choose a target mode:
            </h5>
            <br />

            <ProductSwapContainer>
              <ItemTitleCard
                title={sourceProduct.name}
                sku={sourceProduct.sku}
                image={(sourceProduct.images
              && Array.isArray(sourceProduct.images)
              && sourceProduct.images.length > 0)
                  ? sourceProduct.images[0].url : noImage}
              />
              { mode === 'SWAP' && <img className="icon" src={swap} alt="swap" />}
              { mode === 'REPLACE' && <img className="icon" src={leftArrow} alt="left-arrow" />}
              <ItemTitleCard
                title={targetProduct.name}
                sku={targetProduct.sku}
                image={(targetProduct.images
              && Array.isArray(targetProduct.images)
              && targetProduct.images.length > 0)
                  ? targetProduct.images[0].url : noImage}
              />
            </ProductSwapContainer>

            <p>
              <Radio
                value="SWAP"
                label="Swap (This actions would swap product details between current product and selected product.)"
                name="swap"
                isChecked={mode === 'SWAP'}
                onChange={() => {
                  setMode('SWAP');
                }}
              />
              <Radio
                value="REPLACE"
                label="Replace (This action would replace eBay listing with  selected product info. And qty would be set to 0 on the eBay listing of the selected product.)"
                name="replace"
                isChecked={mode === 'REPLACE'}
                onChange={() => {
                  setMode('REPLACE');
                }}
              />
            </p>
          </>
          )}
        </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default ReplaceEbayProductBtn;
