import React, { useState } from 'react';
import _, { get } from 'lodash';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';

import {
  LabelContainer,
  ButtonContainer,
  ErrorMsg,
} from './CreateOrderPaymentModal.styles';
import {
  useCreatePaymentMutation,
  useGetPaymentMethodsQuery,
} from '../../graphql/types';
import { Order } from '../OrdersListing/types';
import { useAuth } from '../../utils/useAuth';

interface CreateOrderPaymemtModalInputProps {
  isOpen: boolean,
  order: Order,
  close: any, //eslint-disable-line
  refetchOrder: Function,
}

interface FormValuesProps {
  paymentMethod: {
    label: string,
    value: string,
  }
  amount: string,
}
interface MethodProps {
  id: string,
  name: string,
}

const CreateOrderPaymentModal = (props: CreateOrderPaymemtModalInputProps) => {
  const {
    isOpen, close, order, refetchOrder,
  } = props;
  const { control, handleSubmit, errors } = useForm();
  const [createPaymentMutation] = useCreatePaymentMutation();
  const [showSpinner, setSpinner] = useState<boolean>(false);
  const [errorMessage, setErrorMsg] = useState<string>();
  const { state: { user } } = useAuth();

  const {
    data, error, loading,
  } = useGetPaymentMethodsQuery();
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <h1>Fetching payment methods failed</h1>;
  }

  const methods = _.get(data, 'GetPaymentMethods.paymentMethods', []);
  const options = methods.map((method: MethodProps) => ({
    label: method.name,
    value: method.id,
  }));

  const onSubmit = (data: any) => { //eslint-disable-line
    setSpinner(true);
    const {
      id,
      currency,
      orderNumber,
      teamChannelID,
      teamCustomerID,
    } = order;
    const { paymentMethod, amount } = data;
    const args = {
      orderId: id,
      paymentMethodId: paymentMethod.value || '',
      teamChannelId: teamChannelID,
      orderNumber,
      currency,
      paidAmount: parseFloat(amount) * 100,
      paidById: teamCustomerID,
      createdById: user?.id || '',
    };

    createPaymentMutation({
      variables: {
        payment: args,
      },
    }).then(() => {
      setSpinner(false);
      close();
      refetchOrder();
    }).catch((err) => {
      const msg = err?.graphQLErrors[0]?.message || 'create payment failed';
      setErrorMsg(msg);
      setSpinner(false);
    });
  };
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={close} heading="Order Payment">
          <form onSubmit={handleSubmit(onSubmit)}>
            <LabelContainer>Payment Method *</LabelContainer>
            <Controller
              name="paymentMethod"
              control={control}
              options={options}
              defaultValue="Cash"
              rules={{ required: true }}
              as={Select}
              testId="paymentMethod"
            />
            {errors.paymentMethod
              && <ErrorMsg data-testid="paymentMethod-error-message">payment method is required</ErrorMsg>}
            <LabelContainer>Amount *</LabelContainer>
            <Controller
              as={Textfield}
              name="amount"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                min: 0,
                max: order.totalAmtInclTax / 100,
              }}
              placeholder="Please input paid amount"
              testId="amount"
            />
            {get(errors, 'amount.type') === 'required'
              && <ErrorMsg data-testid="amount-error-message">paid amount is required</ErrorMsg>}
            {get(errors, 'amount.type') === 'min'
              && <ErrorMsg data-testid="amount-error-message">amount should be larger than 0</ErrorMsg>}
            {get(errors, 'amount.type') === 'max'
              && <ErrorMsg data-testid="amount-error-message">amount should be less than order total amount</ErrorMsg>}
            {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
            <ButtonContainer>
              <Button
                testId="submit-button"
                type="submit"
                appearance="primary"
                iconAfter={
                  showSpinner ? <Spinner appearance="invert" testId="submit-spinner" /> : <></>
                }
              >
                Submit
              </Button>
              <Button
                testId="cacel-button"
                appearance="link"
                onClick={() => close()}
              >
                Cancel
              </Button>
            </ButtonContainer>
          </form>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default CreateOrderPaymentModal;
