import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import Spinner from '@atlaskit/spinner';
import { get } from 'lodash';
import { ErrorMessage } from '@atlaskit/form';
import axios from 'axios';
import addNotification from '../../utils/addNotification';
import Wrapper from './TeamProfileEditor.style';
import noimage from '../../assets/svg/noimage.svg';
import {
  useGetTeamByIdQuery,
  GetTeamByIdQuery,
  useUpdateTeamByIdMutation,
  UpdateTeamByIdMutationVariables,
  useGetTeamTaxClassesQuery,
} from '../../graphql/types';
import TeamProfileForm, { TeamProfileFormData } from './TeamProfileForm';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import { useAuth } from '../../utils/useAuth';

interface TeamProfileEditorProps {
  teamId:string,
}
// for upload logo
const cloudName = process.env.REACT_APP_CLOUDINARY_NAME || '';
const uploadPreset = process.env.REACT_APP_CLOUDINARY_PRESET || '';

const TeamProfileEditor = (props: TeamProfileEditorProps) => {
  const { teamId } = props;
  const fileInput = useRef<HTMLInputElement>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [logo, setLogo] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [contactId, setContactId] = useState('');
  const [updateTeamProfile] = useUpdateTeamByIdMutation();
  const {
    data, loading, error, refetch,
  } = useGetTeamByIdQuery({
    variables: { teamId },
    // to avoid still show cached data after update when switching team
    fetchPolicy: 'network-only',
  });
  const {
    data: teamTaxClassesData,
    loading: teamTaxClassesLoading,
  } = useGetTeamTaxClassesQuery({
    variables: { teamId },
  });
  const { dispatch } = useAuth();

  const uploadFile = useCallback((file: File) => {
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
    const fd = new FormData();
    fd.append('upload_preset', uploadPreset);
    fd.append('tags', 'browser_upload_logo');
    fd.append('file', file);
    setUploadProgress(0);
    const config = {
      onUploadProgress: (
        progressEvent: ProgressEvent<XMLHttpRequestEventTarget>,
      ) => {
        setUploadProgress(
          (Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          )
          ) / 100,
        );
      },
    };
    axios.post(url, fd, config)
      .then((res) => setLogo(res.data.secure_url))
      .catch((err) => addNotification(err.messages || 'upload failed', 'danger'));
  }, []);

  const handleFiles = useCallback(() => {
    const { files } = fileInput.current!;
    uploadFile(files![0]);
  }, [uploadFile]);

  const handleSubmit = useCallback(async (formData: TeamProfileFormData) => {
    const variables: UpdateTeamByIdMutationVariables = {
      teamId,
      team: {
        name: formData.teamName,
        abn: formData.abn,
        description: formData.description,
      },
      contact: {
        company: formData.company,
        email: formData.email,
        phone: formData.phone,
        fax: formData.fax,
        mobile: formData.mobile,
        street: formData.street,
        suburb: formData.suburb,
        state: formData.state,
        country: formData.country,
        postcode: formData.postcode,
      },
      defaultProductTaxClassId: formData.defaultProductTaxClassId,
      defaultShippingTaxClassId: formData.defaultShippingTaxClassId,
    };
    // if new logo uploaded
    if (logo) {
      variables.team.logo = logo;
    }
    // update an address or create a new
    // on default, team don't have a address row in db when init
    if (contactId) {
      variables.contact.id = contactId;
    }

    await updateTeamProfile({
      variables,
    })
      .then(({ data: resData }) => {
        // update the state so the team switcher can tell the changes in team Name
        // also update the session as well
        const newTeamName = resData?.UpdateTeamById?.team?.name;

        if (newTeamName) {
          // update state
          dispatch({
            type: 'changeTeamName',
            changeTeamNamePayLoad: {
              teamId,
              value: newTeamName,
            },
          });
        }

        addNotification('Company profile saved', 'success');
        // tell the form has updated
        setIsSaved(true);
      })
      .catch((err) => {
        addNotification(graphQLErrorsReader(err), 'danger');
      });
  }, [contactId, teamId, updateTeamProfile, logo]);// eslint-disable-line

  const getFormValues = useCallback((
    teamData: GetTeamByIdQuery | undefined,
  ) => {
    const team = teamData?.GetTeamById.team;
    const teamSettings = team?.settings ? JSON.parse(team?.settings) : {};
    const contact = teamData?.GetTeamById.team?.addresses[0]?.address;
    let companyLogo = '';
    if (logo) {
      companyLogo = logo;
    } else if (get(team, 'logo', '')) {
      companyLogo = get(team, 'logo', '');
    } else {
      companyLogo = noimage;
    }

    return {
      values: {
        fileInput,
        uploadProgress,
        isSaved,
        logo: companyLogo,
        teamName: get(team, 'name', ''),
        abn: get(team, 'abn', ''),
        description: get(team, 'description', ''),
        company: get(contact, 'company', ''),
        email: get(contact, 'email', ''),
        phone: get(contact, 'phone', ''),
        fax: get(contact, 'fax', ''),
        mobile: get(contact, 'mobile', ''),
        street: get(contact, 'street', ''),
        suburb: get(contact, 'suburb', ''),
        state: get(contact, 'state', ''),
        country: get(contact, 'country', ''),
        postcode: get(contact, 'postcode', ''),
        defaultProductTaxClassId: get(teamSettings, 'defaultProductTaxClassId', ''),
        defaultShippingTaxClassId: get(teamSettings, 'defaultShippingTaxClassId', ''),
        createdTime: get(teamSettings, 'createdTime', ''),
        free: get(teamSettings, 'free', ''),
      },
      actions: {
        handleFiles,
        handleSubmit,
        setIsSaved,
      },
    };
  }, [handleFiles, logo, uploadProgress, handleSubmit, isSaved]);

  const [formValues, setFormValues] = useState(getFormValues(data));

  useEffect(() => {
    setFormValues(getFormValues(data));
  }, [data, teamTaxClassesData, logo, uploadProgress, getFormValues]);

  useEffect(() => {
    const contact = data?.GetTeamById.team?.addresses[0]?.address;
    if (contact) {
      setContactId(contact.id);
    } else {
      // on switch teams need to refresh this value
      setContactId('');
    }
  }, [data]);

  useEffect(() => {
    // on swithcing team, reset core variables
    setIsSaved(false);
    setUploadProgress(0);
    setLogo('');
    refetch();
  }, [teamId, refetch]);

  return (
    <Wrapper>
      <div style={{ width: '100%' }}>
        {(loading || teamTaxClassesLoading) && <Spinner size="xlarge" testId="teamProfileFormLoading" />}
        {data && teamTaxClassesData
          && (
          <TeamProfileForm
            values={formValues.values}
            actions={formValues.actions}
            options={{
              teamTaxClasses: teamTaxClassesData.GetTeamTaxClasses.taxClasses
                ? teamTaxClassesData.GetTeamTaxClasses.taxClasses : [],
            }}
          />
          )}
        {error
          && <ErrorMessage>{graphQLErrorsReader(error)}</ErrorMessage>}
      </div>
    </Wrapper>
  );
};

export default TeamProfileEditor;
