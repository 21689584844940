import React from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import MarketplaceIcon from '@atlaskit/icon/glyph/marketplace';
import ShipIcon from '@atlaskit/icon/glyph/ship';
import CopyIcon from '@atlaskit/icon/glyph/copy';
import ShippingButton from '../../Shipping/Shipping';
import { Order } from '../../OrdersListing/types';
import OrderPrintButton from './OrderPrintButton';
import OrderEmailButton from './OrderEmailButton';
import OrderPaymentButton from './OrderPaymentButton';
import CancelEbayOrderBtn from '../../CancelOrder/CancelOrderBtn';
import CloneOrderButton from '../../CreateOrder/CreateOrderButton';
import MarkAsPaidButton from './MarkAsPaidButton';
import CancelOrderButton from './CancelOrderButton';
import GenerateXeroInvoice from '../../Xero/GenerateXeroInvoice';
import { useSubscription } from '../../../utils/useAuth';

const MANGENTO_CHANNEL_ID = process.env.MANGENTO_CHANNEL_ID || '3';
const INSTORE_CHANNEL_ID = process.env.INSTORE_CHANNEL_ID || '2';

const OrderToolBarContainer = styled.div`
    margin-bottom:20px;
    button {
      margin-right:10px;
    }    
    >* {
      margin:5px 5px 5px 0px;
    }

    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-bottom:10px;
    }
`;

interface Props {
  order:Order,
  refetch?:()=>void,//eslint-disable-line
  markOrderShipped:()=>void,
  refetchOrder: ()=>void,
}

export default ({ order, markOrderShipped, refetchOrder }:Props) => {
  const freeExpired = useSubscription();
  return (
    <OrderToolBarContainer>
      <ShippingButton order={order} onShipped={markOrderShipped} icon={<ShipIcon label="ship" />} />
      {/* eslint-disable-next-line max-len */}
      {(order.channelID === INSTORE_CHANNEL_ID || order.channelID === MANGENTO_CHANNEL_ID)
    && <CancelOrderButton order={order} refetchOrder={refetchOrder} /> }
      {order.channelID === MANGENTO_CHANNEL_ID
    && <MarkAsPaidButton order={order} refetchOrder={refetchOrder} />}
      <OrderPrintButton orderId={order.id} />
      <OrderEmailButton orderId={order.id} />
      {order.status.code === 'UNPAID' && <OrderPaymentButton order={order} refetchOrder={refetchOrder} />}
      {order.channel.name.includes('eBay')
      && (
      <Button
        iconBefore={<MarketplaceIcon label="eBay" />}
        onClick={() => window.open(`https://www.ebay.com.au/sh/ord/details?orderid=${order.orderNumber}`)}
      >
        Open on eBay
      </Button>
      )}

      <CancelEbayOrderBtn order={order} />
      {
        freeExpired === false && (<CloneOrderButton order={order} text="Clone" icon={<CopyIcon label="Clone" />} appearance="default" />)
      }

      {order.status.code === 'PAID' && <GenerateXeroInvoice order={order} />}
    </OrderToolBarContainer>
  );
};
