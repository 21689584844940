import React, { useState } from 'react';
import Button from '@atlaskit/button';
import CreditcardIcon from '@atlaskit/icon/glyph/creditcard';
import { Order } from '../../OrdersListing/types';
import CreateOrderPaymentModal from '../../CreateOrderPaymentModal/CreateOrderPaymentModal';

interface Props {
  order: Order,
  refetchOrder: ()=>void,
}

export default ({ order, refetchOrder }:Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => {
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };
  return (
    <>
      <Button
        iconBefore={<CreditcardIcon label="payment" />}
        onClick={open}
      >
        Pay
      </Button>
      <CreateOrderPaymentModal
        isOpen={isOpen}
        close={close}
        order={order}
        refetchOrder={refetchOrder}
      />
    </>
  );
};
