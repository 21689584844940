import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Button from '@atlaskit/button';
import TextArea from '@atlaskit/textarea';
import Form, {
  Field, FormFooter,
} from '@atlaskit/form';
import graphQLErrorsReader from '../../../utils/graphQLErrorsReader';
import { useEditLogMutation, useDeleteLogMutation } from '../../../graphql/types';
import addNotification from '../../../utils/addNotification';
import ConfirmDialog from '../../UnifiedDeleteConfirmationDialog/UnifiedDeleteConfirmationDialog';
import { Log } from '../MerpLogs';
import MerpAvatar from '../../MerpAvatar/MerpAvatar';
import { useAuth } from '../../../utils/useAuth';

export const CommentCardContainer = styled.li`
  list-style:none;
  padding:0;
  margin:0px 0px 10px 0px;    
  display:flex;
  align-items:flex-start;
  width:100%;
`;
export const CommentCardContainerColumn = styled.div`
  margin-right:10px;
`;

export interface EditCommentFormData {
  text:string,
}

interface CommentCardProps {
  log:Log,
}
const CommentCard = ({ log }:CommentCardProps) => {
  const { state: { user } } = useAuth();
  const {
    id, content, createdBy, createdAt,
  } = log;

  const firstName = createdBy?.firstName || '';
  const lastName = createdBy?.lastName || '';
  const fullName = `${firstName} ${lastName}`;

  const [editLogMutation] = useEditLogMutation();
  const [deleteLogMutation] = useDeleteLogMutation();
  const [mode, setMode] = useState<'view'|'edit'>('view');
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  let textareaElement: HTMLTextAreaElement | undefined;

  useEffect(() => {
    const focus = () => {
      if (textareaElement) {
        textareaElement.focus();
        try {
        // move cursor to the end of text
          const val = textareaElement.value;
          textareaElement.value = '';
          textareaElement.value = val;
        } catch (error) {
          textareaElement.value = content;
        }
      }
    };

    if (mode === 'edit') {
      focus();
    }
  }, [mode, content, textareaElement]);

  const onSubmit = async (data:EditCommentFormData) => {
    await editLogMutation({
      variables: {
        logId: id,
        content: data.text,
      },
    })
      .then(() => {
        setMode('view');
      })
      .catch((err) => addNotification(graphQLErrorsReader(err), 'danger'));
  };

  const handleDelete = async () => {
    await deleteLogMutation({
      variables: {
        logId: id,
      },
    })
      .then(() => {
        setMode('view');
      })
      .catch((err) => addNotification(graphQLErrorsReader(err), 'danger'));
  };

  return (
    <CommentCardContainer>
      <CommentCardContainerColumn>
        <MerpAvatar
          firstName={firstName}
          lastName={lastName}
          size={32}
        />
      </CommentCardContainerColumn>
      <CommentCardContainerColumn style={{ width: '100%' }}>
        <span style={{ fontWeight: 500, marginRight: 5 }}>{fullName}</span>
        <span>{moment.utc(createdAt).fromNow()}</span>
        {mode === 'view' && <div style={{ marginTop: 10 }}>{content}</div>}
        {mode === 'edit'
      && (
      <Form<EditCommentFormData>
        onSubmit={onSubmit}
      >
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <Field<string, HTMLTextAreaElement>
              name="text"
              defaultValue={content}
            >
              {({ fieldProps }) => (
                <TextArea
                  ref={(ref) => {
                    textareaElement = ref;
                  }}
                  minimumRows={2}
                  {...fieldProps}
                  testId="editCommentTextArea"
                />
              )}
            </Field>

            <FormFooter align="start">
              <Button
                appearance="primary"
                style={{ marginRight: 10 }}
                isDisabled={submitting}
                type="submit"
              >
                Save
              </Button>
              <Button
                appearance="default"
                onClick={() => setMode('view')}
              >
                Cancel
              </Button>
            </FormFooter>
          </form>
        )}
      </Form>
      )}
        {mode === 'view'
        && user // only the comment owner can edit
        && user.id === log.createdBy?.id
        && (
        <div style={{ marginTop: 10 }}>
          <Button
            appearance="subtle-link"
            spacing="none"
            style={{ marginRight: 10 }}
            onClick={() => setMode('edit')}
            testId="editCommentButton"
          >
            Edit
          </Button>
          <Button
            appearance="subtle-link"
            spacing="none"
            onClick={open}
            testId="deleteCommentButton"
          >
            Delete
          </Button>
          <ConfirmDialog
            isOpen={isOpen}
            heading="Delete a comment"
            onClose={close}
            onConfirm={handleDelete}
          >
            This action cannot be undone.
          </ConfirmDialog>
        </div>
        )}
      </CommentCardContainerColumn>
    </CommentCardContainer>
  );
};

export default CommentCard;
