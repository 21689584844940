import React from 'react';
import Button from '@atlaskit/button';
import { DEFAULT_STAT } from '../config/config';

const StatusFilter = (
  { status, onStatusClick }:
  { status: typeof DEFAULT_STAT,
    onStatusClick:(name:string)=>void, },
) => (
  <div>
    {status.map((e) => (
      <Button
        key={e.name}
        appearance="subtle"
        isSelected={e.isSelected}
        onClick={() => onStatusClick(e.name)}
        style={{ marginRight: 10 }}
        testId={`status-filter-${e.name.toLowerCase()}`}
        spacing="compact"
      >
        {e.name}
      </Button>
    ))}
  </div>
);

export default StatusFilter;
