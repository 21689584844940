import React from 'react';
import { colors } from '@atlaskit/theme';
import Color from 'color';
import { isUndefined } from 'lodash';
import { useAuth } from '../../utils/useAuth';

interface MerpAvatar {
  size?: number,
  firstName?:string,
  lastName?:string,
  color?:string,
}

/**
 * if don't pass first name and last name
 * will use current login info
 * otherwise will use passed value
 */
const MerpAvatar = (props: MerpAvatar) => {
  const {
    size = 24, firstName = undefined, lastName = undefined, color = undefined,
  } = props;
  const { state: { user } } = useAuth();

  let firstNameInitial = '';
  let lastNameInitial = '';

  /**
   * if firstName is undefined we use current login user
   * OTHERWISE use passed in name
   */
  if (isUndefined(firstName)) {
    firstNameInitial = (user && user.firstName && user.firstName[0] && user.firstName[0].toUpperCase()) || '';
  } else {
    firstNameInitial = firstName[0] ? firstName[0].toUpperCase() : '';
  }
  if (isUndefined(lastName)) {
    lastNameInitial = (user && user.lastName && user.lastName[0] && user.lastName[0].toUpperCase()) || '';
  } else {
    lastNameInitial = lastName[0] ? lastName[0].toUpperCase() : '';
  }

  /**
   * if at this moment, the firstName or lastName still empty,
   * more likely passed empty string as variable,
   * this is the senario when invite user and don't know his/her name yet.
   */

  let backgroundColor = ((initial:string) => {
    const unicode = initial.charCodeAt(0);
    if (unicode < 71) return colors.red();
    if (unicode < 76) return colors.green();
    if (unicode < 81) return colors.yellow();
    if (unicode < 86) return colors.blue();
    if (unicode < 91) return colors.purple();
    return colors.subtleText();
  })(lastNameInitial);

  if (color) backgroundColor = color;

  const fontColor = Color(backgroundColor).isDark() ? colors.N0 : colors.N900;
  const fontSize = size / 2 - 1;
  const avatarCSS = {
    borderRadius: '100%',
    height: size,
    width: size,
    backgroundColor,
    display: 'flex',
    color: fontColor,
    lineHeight: `${size}px !important`,
    fontSize,
    fontWeight: 600,
    alignItems: 'center',
    justifyContent: 'center',
  };
  return (
    <span style={avatarCSS} data-testid="merp-avatar">
      {firstNameInitial}
      {lastNameInitial}
    </span>
  );
};
MerpAvatar.defaultProps = {
  size: 24,
  firstName: undefined,
  lastName: undefined,
  color: undefined,
};

export default MerpAvatar;
