import React, { useState } from 'react';
import moment from 'moment';
import Button from '@atlaskit/button';
import { useHistory } from 'react-router-dom';
import Modal, { ModalTransition, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import { isUndefined } from 'lodash';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { TeamChannelSettingsData } from '../../utils/types';
import { ChannelLogo } from './TeamChannelListing';
import NotFound from '../../pages/NotFound';
import { useAuth } from '../../utils/useAuth';
import {
  useUpdateTeamChannelMutation,
  useDeactivateTeamChannelMutation,
  useGetTeamChannelByIdQuery,
  GetTeamChannelByIdQuery,
  useQueryMyEbaySellingQuery,
  useSyncEbayStoreInfoMutation,
} from '../../graphql/types';
import addNotification from '../../utils/addNotification';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import Container from './TeamChannelViewer.style';
import CleanTeamChannelBtn from './CleanTeamChannelBtn';
import PushProductsToTeamChannelBtn from './PushProductsToTeamChannelBtn';
import UploadButton from '../ImageUploader/UploadButton';
import { IImage } from '../ImageUploader/types';
import ThumbnailContainer from '../ImageUploader/ThumbnailContainer';
import ThumbnailItem from '../ImageUploader/ThumbnailItem';
import RemoveBtn from '../ImageUploader/RemoveBtn';
import { WatermarkImageContainer } from './WatermarkImageContainer.style';
import CenteredOverlay from '../ImageUploader/CenteredOverlay';
import { ErrorMsg } from '../ProductCreationFormContainer/ProductCreationForm.styles';
import SyncProductsFromShopifyBtn from './SyncProductsFromShopifyBtn';
import SyncProductsFromEbayBtn from './SyncProductsFromEbayBtn';

type TeamChannelProp =
  GetTeamChannelByIdQuery['GetTeamChannelById']['teamChannel'];

const TeamChannelDetails = ({
  teamChannel,
  refresh,
}: {
  teamChannel: TeamChannelProp;
  refresh: () => void;
}) => {
  const {
    id,
    channel,
    createdAt,
    name,
    updatedAt,
    createdBy,
    updatedBy,
    settings,
  } = teamChannel;
  let parsedSettings: TeamChannelSettingsData;
  try {
    parsedSettings = JSON.parse(settings);
  } catch (error) {
    parsedSettings = JSON.parse('{}');
  }
  const [saveConfig] = useUpdateTeamChannelMutation();
  const [deactivateTeamChannel, deactivateTeamChannelState] = useDeactivateTeamChannelMutation();
  const history = useHistory();
  const { dispatch } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);
  const [channelName] = React.useState(channel.name);
  // const [pushPriceUpdates, setPushPriceUpdates] = React.useState(
  //   parsedSettings?.pushPriceUpdates ?? false,
  // );
  // const [pushStockQty, setPushStockQty] = React.useState(
  //   parsedSettings?.pushStockQty ?? false,
  // );
  const [watermarkImage, setWatermarkImage] = React.useState<IImage>();

  // const handlePushPriceUpdatesToggle = async () => {
  //   await saveConfig({
  //     variables: {
  //       teamChannelID: id,
  //       pushPriceUpdates: !pushPriceUpdates,
  //     },
  //   })
  //     .then(() => {
  //       // toggle component level state
  //       setPushPriceUpdates(!pushPriceUpdates);
  //       // update app level state
  //       dispatch({
  //         type: 'togglePushStockQty',
  //         togglePushStockQtyPayload: {
  //           teamChannelId: teamChannel.id,
  //         },
  //       });
  //     })
  //     .catch((error) => {
  //       addNotification(graphQLErrorsReader(error), 'danger');
  //     });
  // };

  const clearImage = async () => {
    setWatermarkImage(undefined);
    await saveConfig({
      variables: {
        teamChannelID: id,
        watermarkUrl: '',
      },
    });
    await refresh();
  };

  const updateImage = async (image: IImage) => {
    setWatermarkImage(image);
    if (image.url) {
      await saveConfig({
        variables: {
          teamChannelID: id,
          watermarkUrl: image.url,
        },
      });
      await refresh();
    }
  };

  const handleDeactivateTeamChannel = async () => {
    await deactivateTeamChannel({
      variables: {
        teamChannelId: id,
      },
    })
      .then(() => {
        // update app sate to remove the deleted teamChannel
        dispatch({
          type: 'deleteTeamChannel',
          deleteTeamChannelPayload: {
            teamChannelId: id,
          },
        });
        // close modal
        setIsOpen(false);
        // push back to the listing
        history.push('/marketplace');
      })
      .catch((err) => {
        addNotification(graphQLErrorsReader(err), 'danger');
      });
  };

  // const handlePushStockQtyToggle = async () => {
  //   await saveConfig({
  //     variables: {
  //       teamChannelID: id,
  //       pushStockQty: !pushStockQty,
  //     },
  //   })
  //     .then(() => {
  //       // toggle component level state
  //       setPushStockQty(!pushStockQty);
  //       // update app level state
  //       dispatch({
  //         type: 'togglePushPriceUpdates',
  //         togglePushPriceUpdatesPayload: {
  //           teamChannelId: id,
  //         },
  //       });
  //     })
  //     .catch((error) => {
  //       addNotification(graphQLErrorsReader(error), 'danger');
  //     });
  // };

  const actions = [
    {
      text: 'Confirm',
      onClick: handleDeactivateTeamChannel,
      isLoading: deactivateTeamChannelState.loading,
    },
    {
      text: 'Cancel',
      onClick: () => setIsOpen(false),
    },
  ];

  // const renderSynchronisationSection = () => {
  //   if (teamChannel.channel.name !== 'eBay') return <></>;

  //   return (
  //     <div className="block">
  //       <div className="block-item heading">Synchronisation</div>
  //       <div className="block-item toggle">
  //         <Toggle
  //           size="large"
  //           id="push-stock-quantities"
  //           isDefaultChecked={pushStockQty}
  //           onChange={handlePushStockQtyToggle}
  //           testId="push-stock-toggler"
  //         />
  //         {/* jsx-a11y/label-has-associated-control */}
  //         {/* eslint-disable-next-line */}
  //         <label htmlFor="push-stock-quantities">Push Stock Quantities</label>
  //       </div>
  //       <div className="block-item toggle">
  //         <Toggle
  //           size="large"
  //           id="push-price-updates"
  //           isDefaultChecked={pushPriceUpdates}
  //           onChange={handlePushPriceUpdatesToggle}
  //           testId="push-price-updates-toggler"
  //         />
  //         {/* jsx-a11y/label-has-associated-control */}
  //         {/* eslint-disable-next-line */}
  //         <label htmlFor="push-price-updates">Push Price Updates</label>
  //       </div>
  //     </div>
  //   );
  // };

  const WatermarkSection = () => {
    if (channelName !== 'eBay' && channelName !== 'Amazon') return <></>;

    return (
      <div className="block">
        <div className="block-item heading">Watermark</div>
        <div className="field-name">Minimum size：800 * 800 px</div>
        <div className="block-item toggle">
          {((teamChannel.watermarkUrl
            && teamChannel.watermarkUrl.trim() !== '')
            || (watermarkImage && watermarkImage.loading === true)) && (
            <WatermarkImageContainer>
              <ThumbnailContainer>
                {watermarkImage && watermarkImage.loading === true && (
                  <CenteredOverlay>
                    <small>Uploading...</small>
                  </CenteredOverlay>
                )}
                <ThumbnailItem
                  src={teamChannel.watermarkUrl || watermarkImage?.rawSrc}
                />
                <RemoveBtn onClick={clearImage} data-testid="image-remove-btn">
                  <TrashIcon label="trash" />
                </RemoveBtn>
              </ThumbnailContainer>
            </WatermarkImageContainer>
          )}
          {(!teamChannel.watermarkUrl
            || teamChannel.watermarkUrl.trim() === '')
            && watermarkImage?.loading !== true && (
              <WatermarkImageContainer>
                <UploadButton onUpdateImage={updateImage} />
              </WatermarkImageContainer>
          )}
        </div>
        {watermarkImage?.isLowRes === true && (
        <ErrorMsg>
          The image size is not meet the minimum resolution of 800 * 800 px. Please upload a high-quality one.
        </ErrorMsg>
          )}
      </div>
    );
  };

  const renderDefaultStorePoliciesSection = () => {
    if (teamChannel.channel.name !== 'eBay') return <></>;
    return (
      <div className="block">
        <div className="block-item heading">Default store policies</div>
        <div className="block-item field">
          <div className="field-name">Fulfillment</div>
          <div className="field-value" data-testid="fulfillment-value">
            {parsedSettings?.fulfillmentPolicyName || 'Await setup'}
          </div>
        </div>
        <div className="block-item field">
          <div className="field-name">Payment</div>
          <div className="field-value" data-testid="payment-value">
            {parsedSettings?.paymentPolicyName || 'Await setup'}
          </div>
        </div>
        <div className="block-item field">
          <div className="field-name">Return</div>
          <div className="field-value" data-testid="return-value">
            {parsedSettings?.returnPolicyName || 'Await setup'}
          </div>
        </div>
        <div className="block-item field">
          <div className="field-name">Merchant Location</div>
          <div className="field-value" data-testid="merchant-value">
            {parsedSettings?.merchantLocationName || 'Await setup'}
          </div>
        </div>
        <div className="block-item field">
          <Button
            spacing="compact"
            isDisabled={isUndefined(parsedSettings?.access_token?.access_token)}
            onClick={() => history.push(
              `/config/ebay/${teamChannel.id}?redirectPath=/marketplace/${teamChannel.id}`,
            )}
          >
            Update store policies
          </Button>
        </div>
      </div>
    );
  };

  const renderConnectionSection = () => {
    if (channelName !== 'eBay' && channelName !== 'Amazon') return <></>;
    return (
      <div className="block">
        <div className="block-item heading">Connection</div>
        <div className="block-item field">
          <div className="field-name">Token</div>
          <div className="field-value">
            {parsedSettings?.access_token?.access_token
              ? `${parsedSettings?.access_token?.access_token.substr(
                  0,
                  20
                )}****`
              : 'Await setup'}
          </div>
        </div>
        <div className="block-item field">
          <div className="field-name">Token Expiry</div>
          <div className="field-value">
            {parsedSettings?.access_token?.refresh_token_expires_at
              ? moment(
                parsedSettings.access_token.refresh_token_expires_at,
              ).fromNow()
              : 'Await setup'}
          </div>
        </div>
        <div className="block-item field">
          <Button
            spacing="compact"
            onClick={() => history.push(
              `/connect/${channelName.toLowerCase()}/${teamChannel.id}?redirectPath=/marketplace/${teamChannel.id}`,
            )}
          >
            Update Connection
          </Button>
        </div>
      </div>
    );
  };

  const MonthlyLimitSection = () => {
    const { data, loading, error } = useQueryMyEbaySellingQuery({
      variables: {
        teamChannelId: id,
      },
    });
    const filterMap = {
      QuantityLimitRemaining: 'Quantity Limit Remaining',
      AmountLimitRemaining: 'Amount Limit Remaining (AUD)',
    };
    return channelName !== 'eBay' && channelName !== 'Amazon' ? (
      <></>
    ) : (
      <div className="block">
        <div className="block-item heading">Monthly Limits</div>
        {loading && (
          <div className="block">
            <Spinner />
          </div>
        )}
        {error && 'Something wrong when retrieving selling limit'}
        <div className="block-item field">
          {data?.QueryMyEbaySelling.response
            && Object.keys(filterMap).map((key) => {
              // @ts-ignore
              const title = filterMap[key];
              const val = data?.QueryMyEbaySelling.response[key];
              return (
                <div className="block-item field">
                  <div className="field-name">{title}</div>
                  <div className="field-value">{val.toLocaleString()}</div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };
  return (
    <>
      {/* delete teamChannel modal */}
      <ModalTransition>
        {isOpen && (
          <Modal
            actions={actions}
            onClose={() => setIsOpen(false)}
            heading={`move ${name} to trash?`}
            width="medium"
            shouldCloseOnEscapePress={false}
            shouldCloseOnOverlayClick={false}
            appearance="danger"
          >
            <div>
              <p>This action cannot be undone.</p>
            </div>
          </Modal>
        )}
      </ModalTransition>

      <Container data-testid="teamchannel-viewer-container">
        <div className="head">
          <div className="head-item logo">
            <ChannelLogo
              channelName={channel.name}
              testId={`${name.replace(' ', '-')}-logo`}
            />
          </div>
          <div className="head-item name" data-testid="marketplace-name">
            {name}
          </div>
        </div>
        <WatermarkSection />
        {/* {renderSynchronisationSection()} */}
        {renderDefaultStorePoliciesSection()}
        {renderConnectionSection()}
        {channelName === 'ebay' && <MonthlyLimitSection />}

        <div className="block">
          <div className="block-item heading">Logs</div>
          <div className="block-item field">
            <div className="field-name">Created date</div>
            <div className="field-value">{moment(createdAt).fromNow()}</div>
          </div>
          <div className="block-item field">
            <div className="field-name">Updated Date</div>
            <div className="field-value">{moment(updatedAt).fromNow()}</div>
          </div>
          <div className="block-item field">
            <div className="field-name">Created by</div>
            <div className="field-value" data-testid="created-by-value">
              {`${createdBy?.firstName ?? ''} ${createdBy?.lastName ?? ''}`}
            </div>
          </div>
          <div className="block-item field">
            <div className="field-name">Last update by</div>
            <div className="field-value" data-testid="updated-by-value">
              {`${updatedBy?.firstName ?? ''} ${updatedBy?.lastName ?? ''}`}
            </div>
          </div>
        </div>

        <div className="block">
          <div className="block-item heading danger">Danger Zone</div>
          <div className="block-item field danger-zone">
            <div className="field-name">Delete this marketplace</div>
            <div className="field-value button-container">
              <Button appearance="danger" onClick={() => setIsOpen(true)}>
                Move to trash
              </Button>
            </div>
          </div>
          <div className="block-item field danger-zone">
            <div className="field-name">
              Clean products, orders, customers under this this marketplace
            </div>
            <div className="field-value button-container">
              <CleanTeamChannelBtn
                teamChannelId={teamChannel.id}
                teamChannelName={teamChannel.name}
              >
                Clean data
              </CleanTeamChannelBtn>
            </div>
          </div>
          {/* Only non-eBay && non-instore channel allow push products */}
          {teamChannel.channel.name !== 'eBay'
            && teamChannel.channel.name !== 'in-store' && (
              <div className="block-item field danger-zone">
                <div className="field-name">
                  Push all products to this marketplace
                </div>
                <div className="field-value button-container">
                  <PushProductsToTeamChannelBtn
                    teamChannelId={teamChannel.id}
                    teamChannelName={teamChannel.name}
                  >
                    Push All Products
                  </PushProductsToTeamChannelBtn>
                </div>
              </div>
          )}
          {(teamChannel.channel.name === 'eBay' || 'shopify') && (
          <div className="block-item field danger-zone">
            <div className="field-name">
              Download and merge all
              {' '}
              {teamChannel.channel.name}
              {' '}
              listing items into products
            </div>
            <div className="field-value button-container">
              {teamChannel.channel.name === 'eBay' ? (
                <SyncProductsFromEbayBtn
                  teamChannelId={teamChannel.id}
                  teamChannelName={teamChannel.name}
                >
                  {`Sync products from ${teamChannel.channel.name}`}
                </SyncProductsFromEbayBtn>
              )
                : (
                  <SyncProductsFromShopifyBtn
                    teamChannelId={teamChannel.id}
                    teamChannelName={teamChannel.name}
                  >
                    {`Sync products from ${teamChannel.channel.name}`}
                  </SyncProductsFromShopifyBtn>
                )}

            </div>
          </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default ({
  teamChannelId,
  linkState,
  errCode,
}: {
  teamChannelId: string;
  linkState: string | string[] | null | undefined;
  errCode: string | string[] | null | undefined;
}) => {
  const {
    data, loading, error, refetch,
  } = useGetTeamChannelByIdQuery({
    variables: {
      teamChannelId,
    },
    fetchPolicy: 'network-only', // to avoid cached data not refresh after make changes
  });
  const teamChannel = data?.GetTeamChannelById.teamChannel;

  const { dispatch } = useAuth();
  const [isOpen, setIsOpen] = useState(
    linkState !== null && linkState !== undefined,
  );
  const [syncEbayStoreNameID] = useSyncEbayStoreInfoMutation();

  const close = () => {
    setIsOpen(false);
    if (teamChannel?.channel.name !== 'eBay') {
      try {
        dispatch({
          type: 'updateTeamChannel',
          updateTeamChannelPayload: {
            teamChannelId,
          },
        });
      } catch (e) {
        // do nothing if error
      }
    } else {
      /**
     * synchronous run is ok, since we don't need to give any feedback to the user
     * this mutation will silently sync ebay stores name and id.
     */
      syncEbayStoreNameID({
        variables: {
          teamChannelId,
        },
      })
        .then(({ data: ebayData }) => {
          // update app state
          try {
            const { id, name, externalId } = ebayData?.SyncEbayStoreInfo?.teamChannel!;
            dispatch({
              type: 'updateTeamChannel',
              updateTeamChannelPayload: {
                teamChannelId: id,
                name,
                externalId,
              },
            });
          } catch (e) {
            // do nothing if error
          }
        })
        .catch(() => {
          // don't need to handle errors.
          // but leave catch here to avoid unhandled promise rejection error.
        });
    }
  };

  if (error) {
    return <NotFound />;
  }

  if (loading) {
    return (
      <Container>
        <Spinner size="medium" />
      </Container>
    );
  }

  let errMsg = `Connect to your ${teamChannel?.channel.name} store failed, please try again`;
  if (errCode) {
    if (errCode as string === '1') {
      errMsg = 'Current shopify store has install on merp! no necessary add again';
    }
  }

  const message = linkState === 'true'
    ? `You have successfully connect with your ${teamChannel?.channel.name} Store, please allow a bit longer for the full onboarding processes to finish in the background, estimated around 10mins. That means your orders, products from your current ${teamChannel?.channel.name} Store will be pulled down to MERP. `
    : errMsg;
  const header = linkState === 'true'
    ? 'Successfully'
    : 'Fail';

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal
            actions={[{ text: 'Close', onClick: close }]}
          >
            <ModalHeader style={{ paddingLeft: 0 }}>
              <ModalTitle>
                Store Connect
                {' '}
                {header}
              </ModalTitle>
            </ModalHeader>
            <p>{message}</p>
          </Modal>
        )}
      </ModalTransition>
      <TeamChannelDetails
        teamChannel={teamChannel!}
        refresh={() => refetch()}
      />
    </>
  );
};
