import React from 'react';
import moment from 'moment';
import Spinner from '@atlaskit/spinner';
import LineChart, { LineChartDataType } from './LineChart';
import { useQueryTopCustomerSalesQuery } from '../../graphql/types';
import roundNumber from '../../utils/formatMoney';

const TopCustomerSalesChart = ({ teamId }: {
  teamId: string
}) => {
  const dateStart = moment().subtract(29, 'days').format('DD/MM/YYYY');
  const dateEnd = moment().format('DD/MM/YYYY');
  const { data, error, loading } = useQueryTopCustomerSalesQuery({
    variables: {
      teamId,
      dateStart,
      dateEnd,
    },
  });
  if (loading === true) {
    return <Spinner />;
  }
  if (error) {
    return <p>Something wrong when retrieving Customer Sales Report</p>;
  }
  if (loading === false && data) {
    const resp = data.QueryTopCustomerSalesReport.response;
    const mappedChartData = resp.dateRange
      .map((dateStr: string) => resp.topCustomerEmails.map((email: string) => {
        const salesNum = resp.topCustomerSalesMap[dateStr]
        && resp.topCustomerSalesMap[dateStr][email]
          ? resp.topCustomerSalesMap[dateStr][email] : 0;

        return {
          target: email,
          x: moment(dateStr, 'DD/MM/YYYY').format('DD/MM'),
          y: roundNumber(parseFloat(salesNum) / 100, 2),
        };
      })).reduce(
        (carry: LineChartDataType[],
          item: LineChartDataType[]) => [...carry, ...item],
      );

    const initchartOptions = resp.topCustomerEmails.map((email: string) => ({
      label: email,
      value: email,
    }));

    return (
      <>
        <h4>Top Customer Sales</h4>
        { mappedChartData && <LineChart data={mappedChartData} prefix="AUD " postfix="" initchartOptions={initchartOptions} />}
      </>
    );
  }
  return <></>;
};

export default TopCustomerSalesChart;
