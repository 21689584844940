import React, { useState } from 'react';
import Button from '@atlaskit/button';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import AppInquiryModal from '../components/AppInquiryModal/AppInquiryModal';
import { useAuth, useSubscription } from '../utils/useAuth';
import { Card } from '../components/CourierIntegrationList/CourierIntegrationList.style';
import PlanUpgrade from '../components/Billing/PlanUpgrade';

export default () => {
  const { state: { currentTeamId: teamID } } = useAuth();
  const isDisabled = useSubscription();
  const [isOpen, setIsOpen] = useState(false);
  const close = () => {
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };

  const [disable, setDisable] = useState(false);
  const closeUpgrade = () => {
    setDisable(false);
  };
  return (
    <>
      <div className="head">
        <Card>
          <Button
            onClick={() => (isDisabled === false ? open() : setDisable(true))}
            appearance="default"
            className="addInquiryBtn"
          >
            App Inquiry
          </Button>
          <div className="addInquiryIcon">
            <EditFilledIcon label="edit" primaryColor="#0052CC" />
          </div>
        </Card>
        <AppInquiryModal isOpen={isOpen} close={close} teamID={teamID} />
        <PlanUpgrade isOpen={disable} close={closeUpgrade} />
      </div>

    </>
  );
};
