import React from 'react';
import Button from '@atlaskit/button';
import PurchaseFormEditor from './PurchaseFormEditor';
import { Footer } from './styles';
import SubmitProgress from './SubmitProgress';
import { PurchaseFormEditorProps, ProgressPayload } from './types';
import CallToConfirmModal from '../CallToConfirmModal';
import SimpleConfirmModal from '../SimpleConfirmModal';

interface Props extends PurchaseFormEditorProps {
  handleReset:()=>void,
  isSubmitable:boolean,
  handleSubmit:() => Promise<void>,
  progressPayload:ProgressPayload | undefined,
}

export default (
  {
    lineItems,
    setLineItems,
    isSubmiting,
    handleReset,
    isSubmitable,
    handleSubmit,
    progressPayload,
  }:Props,
) => {
  const message = `On complete,stock on hand and total inventory value will be updated accordingly.
      Please enter the word "confirm" to continue.`;

  return (
    <>
      <PurchaseFormEditor
        lineItems={lineItems}
        setLineItems={setLineItems}
        isSubmiting={isSubmiting}
      />
      <Footer>
        <SimpleConfirmModal
          heading="Do you wish to continue?"
          message="This action cannot be undone.Are you sure you want to reset the form?"
          onConfirm={handleReset}
          drawToActionComponent={(onClick) => (
            <Button
              appearance="default"
              isDisabled={isSubmiting}
              onClick={onClick}
            >
              Reset
            </Button>
          )}
        />

        <CallToConfirmModal
          heading="Do you wish to continue?"
          message={message}
          helpMessage={'Please enter \'confirm\' to continue'}
          onValidate={(input) => input === 'confirm'}
          onConfirm={handleSubmit}
          drawToActionComponent={(onClick) => (
            <Button
              appearance="primary"
              isDisabled={!isSubmitable}
              isLoading={isSubmiting}
              onClick={onClick}
            >
              Submit
            </Button>
          )}
        />
      </Footer>
      {
        (progressPayload && isSubmiting)
          && (
          <SubmitProgress
            isOpen={isSubmiting}
            payload={progressPayload}
          />
          )
        }
    </>
  );
};
