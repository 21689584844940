import React from 'react';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import Form, {
  Field,
  FormFooter,
  ErrorMessage,
  FormSection,
} from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { useParams, useHistory } from 'react-router-dom';
import Button from '@atlaskit/button';
import { Box, Logo } from '../components';
import {
  useGetUserByTokenQuery,
} from '../graphql/types';
import graphQLErrorsReader from '../utils/graphQLErrorsReader';
import { useAuth } from '../utils/useAuth';

const Center = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

interface ActivateForm {
  password: string;
  passwordConfirmation: string;
  email: string;
}

export default function () {
  const { userid: userId, token } = useParams();
  const { activateUser } = useAuth();
  const [serverResponseError, setServerResponseError] = React.useState('');
  const history = useHistory();
  const { data, loading, error } = useGetUserByTokenQuery({
    variables: {
      userId,
      token,
    },
  });

  const handleActivate = async (formData:ActivateForm) => {
    const err = {
      passwordConfirmation:
        formData.password !== formData.passwordConfirmation
          ? 'Password does not match'
          : undefined,
    };
    if (err.passwordConfirmation) {
      return err;
    }
    await activateUser({
      password: formData.password,
      userId,
      token,
    })
      .then((res) => {
        let defaultTeamChannel = '';
        let onBoarded = false;
        const teamUser = res?.teams;
        if (teamUser && teamUser.length > 0) {
          try {
            const teamChannel = teamUser[0]
              .team
              .teamChannels
              // attempted to filter out 'in-store' type channel
              // in order to avoid give incorrect onboarding queryString
              .filter((t) => t.channel.name === 'eBay')[0];
            defaultTeamChannel = teamChannel.id;
            onBoarded = teamChannel.onBoarded;
            if (!onBoarded) {
              history.push(`/connect/ebay/${defaultTeamChannel}?redirectPath=/config/ebay/${defaultTeamChannel}`);
            } else {
              history.push('/me');
            }
          } catch {
            history.push('/');
          }
        }
      })
      .catch((errors: ApolloError) => {
        setServerResponseError(graphQLErrorsReader(errors));
      });
    return undefined;
  };

  return (
    <Center>
      <Logo />
      <Box style={{ minHeight: 100 }} padding="30px 40px 40px 40px">
        { loading
          && <Center><Spinner size="medium" /></Center>}
        { error
          && <ErrorMessage>{ graphQLErrorsReader(error) }</ErrorMessage>}
        { data && (
          <Form<ActivateForm>
            onSubmit={handleActivate}
          >
            {({ formProps, dirty, submitting }) => (
              <form {...formProps}>
                <FormSection>
                  <Field
                    name="email"
                    label="Email"
                    defaultValue={data.GetUserByToken.user?.email}
                    isDisabled
                  >
                    {({ fieldProps }) => (
                      <>
                        <Textfield testId="email" {...fieldProps} type="email" />
                      </>
                    )}
                  </Field>

                  <Field
                    name="password"
                    label="Password"
                    defaultValue=""
                    isRequired
                  >
                    {({ fieldProps }) => (
                      <Textfield testId="password" type="password" {...fieldProps} />
                    )}
                  </Field>
                  <Field
                    name="passwordConfirmation"
                    label="Password confirmation"
                    defaultValue=""
                    isRequired
                  >
                    {({ fieldProps, error: formError }) => (
                      <>
                        <Textfield testId="passwordConfirmation" type="password" {...fieldProps} />
                        {formError && <ErrorMessage testId="passwordConfirmationErr">{formError}</ErrorMessage>}
                      </>
                    )}
                  </Field>
                </FormSection>
                {serverResponseError
                  && <ErrorMessage testId="serverError">{serverResponseError}</ErrorMessage>}
                <FormFooter>
                  <Button
                    type="submit"
                    appearance="primary"
                    isDisabled={!dirty || submitting}
                    isLoading={submitting}
                    shouldFitContainer
                  >
                    Activate
                  </Button>
                </FormFooter>
              </form>
            )}
          </Form>
        )}
      </Box>
    </Center>
  );
}
