import React from 'react';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import styled from 'styled-components';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import { Box, Logo } from '../components';
import graphQLErrorsReader from '../utils/graphQLErrorsReader';
import { useAuth } from '../utils/useAuth';
import { useValidatePasswordResetLinkQuery } from '../graphql/types';
import ResetPasswordForm, { ResetPasswordFormData } from '../components/Signin/ResetPasswordForm';
import { FormTitle } from '../components/Signin/ForgotPassword.styles';

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export default function () {
  const [serverResponseError, setServerResponseError] = React.useState('');
  const { confirmPasswordReset } = useAuth();
  const { userId, token } = useParams();
  const history = useHistory();
  const { data, loading, error } = useValidatePasswordResetLinkQuery({
    variables: {
      userId,
      token,
    },
  });

  const handleResetPassword = async (formData: ResetPasswordFormData) => {
    const err = {
      passwordConfirmation:
        formData.password !== formData.passwordConfirmation
          ? 'Password does not match'
          : undefined,
    };
    if (err.passwordConfirmation) {
      return err;
    }

    await confirmPasswordReset({
      userId,
      token,
      password: formData.password,
    })
      .then(() => {
        history.push('/me');
      })
      .catch((errors: ApolloError) => {
        setServerResponseError(graphQLErrorsReader(errors));
      });

    return undefined;
  };

  if (error) {
    return (
      <Redirect push to="/signin/forgot/?message=expired_token" />
    );
  }

  return (
    <Wrapper>
      <Logo />
      <Box style={{ padding: '40px', minHeight: 100, marginTop: '1rem' }}>

        {loading
          && (
          <FormTitle>
            <Spinner size="medium" />
          </FormTitle>
          )}
        {data
        && (
        <ResetPasswordForm
          handleResetPassword={handleResetPassword}
          error={serverResponseError}
        />
        )}
      </Box>
    </Wrapper>
  );
}
