import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import { isUndefined } from 'lodash';
import Form, { ErrorMessage, Field, FormSection } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { Grid, GridColumn } from '@atlaskit/page';
import Toggle from '@atlaskit/toggle';
import Select, { ValueType, OptionType } from '@atlaskit/select';
import addNotification from '../../utils/addNotification';
import { Wrapper } from './AuspostIntergration.style';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import {
  Props, AuspostIntergrationFormData, AuspostConfig, Account,
} from './AuspostIntergration.types';
import client from '../../graphql/client';
import couriersQuery from '../../graphql/shipment/queries/couriers';
import getTeamCourier from '../../graphql/teamCourier/queries/getTeamCourier';
import auspostGetAccountsQuery from '../../graphql/auspost/queries/getAccount';
import setTeamCourierMutation from '../../graphql/teamCourier/mutations/setTeamCourier';
import {
  GetCouriersQuery, GetTeamCourierQuery, AuspostGetAccountQuery,
} from '../../graphql/types';
import { submissionVlidation, selectStateOptions, selectCutOffTimesOptions } from './AuspostIntergration.helpers';
import {
  renderFormHeader, renderError, renderTestSucessMsg, renderFormFooter,
} from './AuspostIntergration.widget';

const AuspostIntergration = ({ teamId }: Props) => {
  /**
   * is data loading?
   */
  const [isLoading, setIsloading] = useState(true);
  /**
   * to store API error if any
   */
  const [serverError, setServerError] = useState<string|undefined>();
  /**
   * default form value
   * will only be used for initialize
   */
  // eslint-disable-next-line max-len
  const [defaultFormData, setDefaultFormData] = useState<AuspostIntergrationFormData>();
  /**
   * Auspost couerirID
   * it's not a hard coded value, so we need get it dymaticlly
   * every time enter this page.
   */
  const [courierId, setCourierId] = useState('');
  /**
   * to save and dispaly auspost test connection response payload
   */
  const [auspostAccount, setAuspostAccount] = useState<Account>();

  const [defaultCutOffSelection] = selectCutOffTimesOptions
    .filter((cur) => cur.value === defaultFormData?.cutOffHourUTC?.value);

  /**
   * field level validate
   */
  const validate = (
    value:string|undefined,
    // formState:Object,
    // fieldState:Meta,
  ) => {
    if (!value) {
      return 'This is a required field.';
    }
    return undefined;
  };

  const [active, setActive] = useState<boolean>();

  /**
   * because it will need a chain of promises,
   * so lazy query is perfered.
   */
  useEffect(() => {
    const loadData = async () => {
      await client.query({
        query: couriersQuery,
      })
        .then(({ data }:{data:GetCouriersQuery}) => {
          const [auspost] = data.GetCouriers.couriers!.filter((cur) => cur.code === 'AustraliaPost');
          // need auspost courierid when call setTeamCouerir api.
          setCourierId(auspost.id);
          return auspost;
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then(async (auspost:Extract<GetCouriersQuery['GetCouriers']['couriers'], Array<any>>[number]) => {
          await client.query({
            query: getTeamCourier,
            variables: {
              teamId,
              courierId: auspost.id,
            },
            fetchPolicy: 'no-cache',
          })
            .then(({ data }:{data:GetTeamCourierQuery}) => {
              const { teamCourier: teamCourierRes } = data.GetTeamCourier;
              let defaultActive = true;
              let configuration = '{}';

              if (teamCourierRes) {
                defaultActive = teamCourierRes.active;
                configuration = teamCourierRes.configuration;
              }

              const {
                account = '',
                key = '',
                password = '',
                name = '',
                lines = ['', ''],
                suburb = '',
                state = '',
                postcode = '',
                cutOffHourUTC = 6, // some existing team may mising this value
              } = JSON.parse(configuration) as AuspostConfig;

              const [defaultCutOffHourUTCOptions] = selectCutOffTimesOptions
                .filter((cur) => cur.value === cutOffHourUTC);

              setDefaultFormData({
                key,
                password,
                account,
                name,
                address1: lines[0],
                address2: lines.length > 1 ? lines[1] : '',
                suburb,
                postcode,
                state: state ? { label: state, value: state } : undefined,
                cutOffHourUTC: defaultCutOffHourUTCOptions,
              });
              setActive(defaultActive);
            });
        })
        .catch((err) => {
          setServerError(graphQLErrorsReader(err));
        })
        .finally(() => setIsloading(false));
    };
    loadData();
  }, [teamId]);

  /**
   * handle form submission
   */
  const handleSubmit = async (
    data:AuspostIntergrationFormData,
  ) => {
    /**
     * init
     */
    setServerError(undefined);
    setAuspostAccount(undefined);

    /**
     * 1 form level validation
     */
    const errors = submissionVlidation(data);
    const hasError = Object.values(errors).some((e) => !isUndefined(e));
    if (hasError) return errors;
    /**
     * 2. test connection
     */
    await client.query({
      query: auspostGetAccountsQuery,
      variables: {
        teamId,
        key: data.key,
        password: data.password,
        account: data.account,
      },
      fetchPolicy: 'no-cache',
    })
      .then(({ data: accountData }:{data:AuspostGetAccountQuery}) => {
        const account = accountData.AuspostGetAccounts.response;
        return account;
      })
      .then(async (ausPostAccount:Account) => {
        const {
          key, password, account, name, address1,
          address2, suburb, postcode, state, cutOffHourUTC,
        } = data;

        const configuration:AuspostConfig = {
          key,
          password,
          account,
          name,
          lines: [address1, address2],
          suburb,
          postcode,
          state: state!.value as AuspostConfig['state'],
          cutOffHourUTC: Number(cutOffHourUTC?.value),
        };

        await client.mutate({
          mutation: setTeamCourierMutation,
          variables: {
            teamId,
            courierId,
            configuration: JSON.stringify(configuration),
            active, // this value is from closure scope
          },
        })
          .then(() => {
            setAuspostAccount(ausPostAccount);
            addNotification('Auspost configurations saved!', 'success');
          });
      })
      .catch((error) => {
        setServerError(graphQLErrorsReader(error));
      });

    return undefined;
  };

  const renderApiSection = (submitting:boolean) => (
    <FormSection title="API Credentials">
      <Field
        name="key"
        label="Key(username)"
        isRequired
        defaultValue={defaultFormData?.key}
        validate={validate}
        isDisabled={submitting}
      >
        {({ fieldProps, error }) => (
          <>
            <TextField
              {...fieldProps}
              testId="auspostKey"
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
      <Field
        name="password"
        label="Password(secret)"
        isRequired
        defaultValue={defaultFormData?.password}
        validate={validate}
        isDisabled={submitting}
      >
        {({ fieldProps, error }) => (
          <>
            <TextField {...fieldProps} testId="auspostPassword" />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
      <Field
        name="account"
        label="Account Number"
        isRequired
        defaultValue={defaultFormData?.account}
        validate={validate}
        isDisabled={submitting}
      >
        {({ fieldProps, error }) => (
          <>
            <TextField {...fieldProps} testId="auspostAccount" />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
      <Field<ValueType<OptionType>>
        name="cutOffHourUTC"
        label="Auto dispatch at (local time):"
        isRequired
        defaultValue={defaultCutOffSelection}
        isDisabled={submitting}
      >
        {({ fieldProps: { id, ...rest }, error }) => (
          <>
            <Select<OptionType>
              validationState={error ? 'error' : 'default'}
              inputId={id}
              {...rest}
              options={selectCutOffTimesOptions}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
      <Field
        name="active"
        label="Enable/Disable"
        isRequired
        isDisabled={submitting}
      >
        {({ fieldProps }) => (
          <div>
            <Toggle
              {...fieldProps}
              isDefaultChecked={active}
              onChange={() => setActive(!active)}
              testId="auspostActive"
              size="large"
            />
          </div>
        )}
      </Field>
    </FormSection>
  );

  const renderSendeSection = (submitting:boolean) => (
    <FormSection title="Default sender details">
      <Grid>
        <GridColumn medium={4}>
          <Field
            name="name"
            label="Name"
            defaultValue={defaultFormData?.name}
            isRequired
            validate={validate}
            isDisabled={submitting}
          >
            {({ fieldProps, error }) => (
              <>
                <TextField {...fieldProps} testId="auspostName" maxLength={40} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </GridColumn>
        <GridColumn medium={4} />
        <GridColumn medium={8}>
          <Field
            name="address1"
            label="Address line 1"
            defaultValue={defaultFormData?.address1}
            isRequired
            isDisabled={submitting}
          >
            {({ fieldProps, error }) => (
              <>
                <TextField {...fieldProps} testId="auspostAddress1" maxLength={40} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </GridColumn>
        <GridColumn medium={8}>
          <Field
            name="address2"
            label="Address line 2"
            defaultValue={defaultFormData?.address2}
            isDisabled={submitting}
          >
            {({ fieldProps }) => <TextField {...fieldProps} testId="auspostAddress2" maxLength={40} />}
          </Field>
        </GridColumn>
        <GridColumn medium={4}>
          <Field
            name="suburb"
            label="Suburb"
            defaultValue={defaultFormData?.suburb}
            isRequired
            isDisabled={submitting}
          >
            {({ fieldProps, error }) => (
              <>
                <TextField {...fieldProps} testId="auspostSuburb" maxLength={40} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </GridColumn>
        <GridColumn medium={4}>
          <Field
            name="postcode"
            label="Postcode"
            defaultValue={defaultFormData?.postcode}
            isRequired
            isDisabled={submitting}
          >
            {({ fieldProps, error }) => (
              <>
                <TextField {...fieldProps} testId="auspostPostcode" maxLength={4} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </GridColumn>
        <GridColumn medium={4}>
          <Field<ValueType<OptionType>>
            name="state"
            label="State"
            isRequired
            defaultValue={defaultFormData?.state}
            isDisabled={submitting}
          >
            {({ fieldProps: { id, ...rest }, error }) => (
              <>
                <Select<OptionType>
                  validationState={error ? 'error' : 'default'}
                  inputId={id}
                  {...rest}
                  options={selectStateOptions}
                  testId="auspostState"
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </GridColumn>
      </Grid>
    </FormSection>
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      <div style={{ width: '100%' }}>
        <Form<AuspostIntergrationFormData>
          onSubmit={handleSubmit}
        >
          {({ formProps, submitting }) => (
            <form {...formProps}>
              <Grid>
                <GridColumn medium={12}>
                  {renderFormHeader()}
                  {serverError && renderError(serverError)}
                  {auspostAccount && renderTestSucessMsg(auspostAccount)}
                </GridColumn>
                <GridColumn medium={4}>
                  {renderApiSection(submitting)}
                </GridColumn>
                <GridColumn medium={8}>
                  {renderSendeSection(submitting)}
                </GridColumn>
                <GridColumn medium={12}>
                  { renderFormFooter(submitting) }
                </GridColumn>
              </Grid>
            </form>
          )}
        </Form>
      </div>
    </Wrapper>
  );
};

export default AuspostIntergration;
