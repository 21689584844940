import React, { useState } from 'react';
import Select, { OptionType, ValueType } from '@atlaskit/select';
import styled from 'styled-components';
import { SingleOption } from '../ProductEdit/types';
import FlexSpaceBetweenContainer from '../ProductEdit/styles/FlexSpaceBetweenContainer';

const TaxBaseContainer = styled.div`
  min-width: 130px;
  margin-left:5px;
`;

const OrderTaxBaseSelector = ({ value, onChange }:
{value: string, onChange: (taxBase: string) => void }) => {
  const taxBaseOptions = [
    { label: 'Tax Inclusive', value: 'TAXINCLU' },
    /* { label: 'Tax Exclusive', value: 'TAXEXCLU' }, */
    { label: 'Tax Free', value: 'TAXFREE' },
  ];

  const [defaultValueOption] = taxBaseOptions
    .filter((tb) => tb.value === value);

  const [
    selectedValue,
    setSelectedValue,
  ] = useState<OptionType |undefined>(defaultValueOption);

  const handleOnChange = (selected: ValueType<OptionType>) => {
    if (selected) {
      if (defaultValueOption !== undefined) {
        if (defaultValueOption.value !== (selected as SingleOption).value) {
          onChange((selected as SingleOption).value);
          setSelectedValue(selected as OptionType);
        }
      } else {
        onChange((selected as SingleOption).value);
        setSelectedValue(selected as OptionType);
      }
    }
  };

  return (
    <FlexSpaceBetweenContainer>
      <div>
        {'Amounts are: '}
      </div>
      <TaxBaseContainer>
        <Select
          options={taxBaseOptions}
          value={selectedValue}
          placeholder="Choose a tax base`"
          autoFocus={false}
          onChange={handleOnChange}
        />
      </TaxBaseContainer>
    </FlexSpaceBetweenContainer>
  );
};

export default OrderTaxBaseSelector;
