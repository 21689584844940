/* eslint-disable max-len */
import React, { useState } from 'react';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import EditIcon from '@atlaskit/icon/glyph/edit';
import styled from 'styled-components';
import Form, {
  ErrorMessage,
  Field,
} from '@atlaskit/form';
import { isFinite } from 'lodash';
import { Order } from '../../OrdersListing/types';
import { useUpdateShippingCostMutation } from '../../../graphql/types';
import ErrorSection from '../../ErrorSection/ErrorSection';
import graphQLErrorsReader from '../../../utils/graphQLErrorsReader';
import formatMoneyWithPrefix from '../../../helpers/formatMoney';
import CourierSelector, { Courier } from '../../Couriers/CourierSelector';
import { Footer } from '../../CreateOrder/shares/widget.style';

const UpdateShippingCostContainer = styled.div`
margin-bottom:15px;
`;

interface Props {
  order:Order,
}

interface FormData {
  cost:string,
}

export default ({ order }:Props) => {
  const {
    id: orderId, totalShippingAmtInclTax, channel, status,
  } = order;
  const [shippingCarrierCode, setShippingCarrierCode] = useState<string|undefined>(undefined);
  const [shippingServiceCode, setShippingServiceCode] = useState<string|undefined>(undefined);

  const [updateShippingCost, { error, loading }] = useUpdateShippingCostMutation({
    update: (cache, data) => {
      cache.writeData({ data });
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  const hanldeCourierSelection = (courier:Courier) => {
    if (courier.code.includes('Pickup')) {
      setShippingCarrierCode('');
      setShippingServiceCode('Pickup');
    } else {
      setShippingCarrierCode(courier.name);
      setShippingServiceCode('');
    }
  };

  const validate = (value:unknown) => {
    if (!isFinite(Number(value))) {
      return 'Cost must be a number';
    }
    if (String(value).length === 0) {
      return 'Cost is required.';
    }

    if (Number(value) < 0) {
      return 'Cost must be greater than 0';
    }

    return undefined;
  };

  const onSubmit = async (data: FormData) => {
    try {
      await updateShippingCost({
        variables: {
          orderId,
          shippingCarrierCode,
          shippingServiceCode,
          totalShippingAmtInclTax: Number(data.cost),
        },
      }).then(() => close());
    } catch (err) {
      // leave blank intentional
      // upper scope hook is able to handle the error.
    }
  };

  if (
    status.code === 'UNPAID'
    && channel.name === 'in-store'
  ) {
    // editable shipping cost
    return (
      <>
        <span data-testid="totalShippingAmt">{formatMoneyWithPrefix(totalShippingAmtInclTax)}</span>
        <Button
          onClick={open}
          appearance="subtle-link"
          spacing="compact"
          testId="updateShippingCostButton"
        >
          <EditIcon label="edit" size="small" />
        </Button>

        <ModalTransition>
          {isOpen && (
            <Modal
              onClose={close}
              heading="Update Shipping Cost"
              width={400}
              scrollBehavior="outside"
              shouldCloseOnOverlayClick={false}
            >
              <UpdateShippingCostContainer>
                {error && <ErrorSection>{graphQLErrorsReader(error)}</ErrorSection>}
                <Field label="Perfered Courier" name="courier">
                  {() => (
                    <CourierSelector
                      placeholder="Select a perfered courier"
                      lookupDefaultValue={order.shippingCarrierCode || order.shippingServiceCode}
                      onChange={hanldeCourierSelection}
                    />
                  )}
                </Field>

                <Form onSubmit={onSubmit}>
                  {({ formProps }) => (
                    <form {...formProps} name="shippingCostFrom">
                      <Field
                        label="Cost (Inc.)"
                        name="cost"
                        isRequired
                        validate={validate}
                        defaultValue={totalShippingAmtInclTax / 100}
                      >
                        {({ fieldProps, error: fieldError, meta: { valid } }) => (
                          <>
                            <Textfield
                              placeholder="Enter shipping charge"
                              noValidate={valid}
                              {...fieldProps}
                            />
                            {fieldError && (
                            <ErrorMessage>
                              {fieldError}
                            </ErrorMessage>
                            )}
                          </>
                        )}
                      </Field>
                      <Footer>
                        <Button
                          onClick={close}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          isDisabled={loading}
                          isLoading={loading}
                          appearance="primary"
                          testId="confirmUpdateButton"
                        >
                          Update
                        </Button>
                      </Footer>
                    </form>
                  )}
                </Form>
              </UpdateShippingCostContainer>

            </Modal>
          )}
        </ModalTransition>
      </>
    );
  }

  return <>{formatMoneyWithPrefix(totalShippingAmtInclTax)}</>;
};
