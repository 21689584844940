import React, { useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { Checkbox } from '@atlaskit/checkbox';
import Button from '@atlaskit/button';
import EditorSettingsIcon from '@atlaskit/icon/glyph/editor/settings';
import ColumnsOptionsContainer from '../styles/ColumnsOptionsContainer';
import { defaultColumns } from '../helper/getHeadAndRow';

const ColumnOptions = (
  {
    columns,
    onColumnTick,
  }:{
    columns: typeof defaultColumns,
    onColumnTick: (e: React.ChangeEvent<HTMLInputElement>) => void,
  },
) => {
  const [isOpen, setIsOpen] = useState(false);
  const actions = [
    {
      text: 'Close', onClick: () => (setIsOpen(false)),
    },
  ];

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        appearance="subtle"
        iconAfter={<EditorSettingsIcon size="medium" label="settings" />}
        testId="columnOptionsButton"
      >
        Columns
      </Button>
      <ModalTransition>
        {isOpen && (
          <Modal
            actions={actions}
            onClose={() => setIsOpen(false)}
            heading="Show/Hide Columns "
          >
            <ColumnsOptionsContainer>
              <div>
                <h5>Order</h5>
                {columns.filter((cur) => cur.group === 'Order').map((column) => (
                  <Checkbox
                    defaultChecked={column.isChecked}
                    key={column.key}
                    name={column.key}
                    label={column.name}
                    value={column.name}
                    testId={`cb-${column.key}`}
                    onChange={onColumnTick}
                  />
                ))}
              </div>
              <div>
                <h5>Channel</h5>
                {columns.filter((cur) => cur.group === 'Channel').map((column) => (
                  <Checkbox
                    defaultChecked={column.isChecked}
                    key={column.key}
                    name={column.key}
                    label={column.name}
                    value={column.name}
                    testId={`cb-${column.key}`}
                    onChange={onColumnTick}
                  />
                ))}
              </div>
              <div>
                <h5>Customer</h5>
                {columns.filter((cur) => cur.group === 'Customer').map((column) => (
                  <Checkbox
                    defaultChecked={column.isChecked}
                    key={column.key}
                    name={column.key}
                    label={column.name}
                    value={column.name}
                    testId={`cb-${column.key}`}
                    onChange={onColumnTick}
                  />
                ))}
              </div>
            </ColumnsOptionsContainer>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default ColumnOptions;
