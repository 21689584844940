import React from 'react';
import styled from 'styled-components';
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line';
import MediaServicesDocumentIcon from '@atlaskit/icon/glyph/media-services/document';
import SummaryElement from './SummaryElement';
import { useQuerySummaryReportQuery } from '../../graphql/types';

const Cards = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const SummarySection = ({ teamId }: { teamId: string }) => {
  const { data, error, loading } = useQuerySummaryReportQuery({
    variables: {
      teamId,
    },
  });

  if (error) {
    return <p>Something wrong when retrieving data for summary report.</p>;
  }
  const totalSales = loading === false
  && data?.QuerySummaryReport.response.totalSales
    ? data?.QuerySummaryReport.response.totalSales : 0;
  const thirtyOneDaysSales = loading === false
    && data?.QuerySummaryReport.response.thirtyOneDaysSales
    ? data?.QuerySummaryReport.response.thirtyOneDaysSales : 0;
  const awaitingShippedOrders = loading === false
  && data?.QuerySummaryReport.response.awaitingShippedOrders
    ? data?.QuerySummaryReport.response.awaitingShippedOrders : 0;
  return (
    <>
      <Cards>
        <SummaryElement
          title="Total Sales"
          prefix="AUD $"
          value={totalSales / 100}
          color="darkblue"
          icon={
            <GraphLineIcon size="xlarge" label="Total Sales" />
          }
        />
        <SummaryElement
          title="Last 31 Days Sales"
          prefix="AUD $"
          value={thirtyOneDaysSales / 100}
          color="lightblue"
          icon={
            <GraphLineIcon size="xlarge" label="31 Days Sales" />
          }
        />
        <SummaryElement
          title="Awaiting Postage Orders"
          prefix=""
          value={awaitingShippedOrders}
          color="orange"
          icon={(
            <MediaServicesDocumentIcon
              size="xlarge"
              label="Awaiting Postage Orders"
              secondaryColor="#c3c3c3"
            />
          )}
        />
      </Cards>
    </>
  );
};

export default SummarySection;
