/* eslint-disable max-len */
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import TextArea from '@atlaskit/textarea';
import { PreviewShipmentContainer } from '../Shipping.style';
import { Shipment } from '../types';
import { useShipping } from '../Shipping';

const ConfirmQuote = () => {
  const { state, dispatch } = useShipping();

  const handleNotesChange = (
    e: React.FormEvent<HTMLTextAreaElement>,
  ) => {
    const newShipment:Shipment = {
      ...state.shipment,
      notes: e.currentTarget.value,
    };

    dispatch({ type: 'setShipment', payload: newShipment });
  };

  return (
    <PreviewShipmentContainer>
      <div className="section mt-20">
        <SectionMessage
          title="On click confirm:"
          appearance="warning"
        >
          <div>Special Instructions (printed on label):</div>
          <div>
            <TextArea
              value={state.shipment.notes}
              name="text"
              isDisabled={state.ui.isSubmiting}
              isCompact
              onChange={handleNotesChange}
            />
          </div>
          <p>1. A consignment will be created based on the previous selection.</p>
          <p>2. A fulfillment will be automatically created against this order, and also will update with sales channels if applicable.</p>
        </SectionMessage>
      </div>

    </PreviewShipmentContainer>
  );
};

export default ConfirmQuote;
