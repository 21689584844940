import styled from 'styled-components';

export const AspectsContainer = styled.div`
  min-width: 100%
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
`;

export const AspectContainer = styled.div`
  width: 45%;
  margin-right:20px;
`;

export const LabelContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const CardContainer = styled.div`
  border-radius: 5px;
  padding: 20px 0px;
  margin-bottom: 20px;
`;

export const FloatStickyFooter = styled.div`
  z-index:99;
  // position:sticky;
  bottom:0px;
  background:#fff;
  border-top:5px double #eaeaea;

  .heading{
    padding-top:10px;
    display:flex;
    align-items:center;
    h3 {
      font-size:14px;
    }
  }  
  .pricesGroup {
    display:flex;
    flex-wrap:wrap;
    >div {
      margin-right:10px;
      width:30%;
    }
  }
  .buttons {
    width:100%;
    display:flex;
    justify-content:flex-end;
    padding:10px 0px 10px 0px;
  }
`;

export const ProductDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProductDetailImage = styled.img`
  width: 20%;
  max-height: 150px;
  
`;

export const ProductDetailInfoContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
`;

export const ErrorMsg = styled.p`
  margin-top:10px;
  color: #bf1650;
  &:before {
    content: "⚠ "
  }
`;

export const Label = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  width:100%;
  .labelText {
    white-space: nowrap;
    text-overflow: ellipsis;
    display:block;
    overflow:hidden;
    max-width:100px;
  }
  height:30px;
`;
export const isForSaleLabel = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    display:block;
    overflow:hidden;
    max-width:100px;
    margin-left: 20px;
`;

export const PageMargin = styled.div`
  margin-bottom: 100px;
`;
