import React, { useState } from 'react';
import { get } from 'lodash';
import { InlineEditableTextfield } from '@atlaskit/inline-edit';
import { Checkbox } from '@atlaskit/checkbox';
import { Field } from '@atlaskit/form';
import Button from '@atlaskit/button';

import MerpAvatar from '../MerpAvatar/MerpAvatar';
import Box from '../Box';
import { useAuth, useSubscription } from '../../utils/useAuth';
import { setQuickstartCancelled } from '../../utils/storages';
import addNotification from '../../utils/addNotification';

import { Wrapper, ProfileTitleContainer, InlineEditLikeDiv } from './ProfileEditor.style';
import ChangePasswordForm from './ChangePasswordForm';
import PlanUpgrade from '../Billing/PlanUpgrade';

const ProfileEditor = () => {
  const freeExpired = useSubscription();
  const [disable, setDisable] = React.useState<boolean>(false);
  const closeUpgrade = () => {
    setDisable(false);
  };
  const [isChecked, setIsChecked] = useState(false);
  const { state: { user }, updateProfile } = useAuth();
  const firstName = get(user, 'firstName', '');
  const lastName = get(user, 'lastName', '');

  const hanledUpdateProfile = (
    value:string,
    key:'firstName'|'lastName',
  ) => {
    if (freeExpired === true) {
      setDisable(true);
      return undefined;
    }
    // don't call the server if no changes made
    if (key === 'firstName' && value === firstName) return undefined;
    if (key === 'lastName' && value === lastName) return undefined;

    updateProfile({
      [key]: value.trim(),
    })
      .catch(() => {
        addNotification('Update failed', 'danger');
      });
    return undefined;
  };

  const validate = (value:string) => (
    value.trim().length === 0 ? 'Can\'t be empty!' : undefined
  );

  const onEnableQuickstart = () => {
    setQuickstartCancelled(false);
    window.location.reload();
  };

  return (
    <Wrapper data-testid="profile-editor-wrapper">
      <Box style={{ minWidth: 350 }}>
        <ProfileTitleContainer>
          <div><MerpAvatar size={50} /></div>
          <div className="title"><h2>{`${firstName} ${lastName}`}</h2></div>
        </ProfileTitleContainer>
        <InlineEditableTextfield
          defaultValue={firstName}
          label="First Name"
          readViewFitContainerWidth
          validate={validate}
          onConfirm={(value) => (hanledUpdateProfile(value, 'firstName'))}
          placeholder=""
        />
        <InlineEditableTextfield
          defaultValue={lastName}
          label="Last Name"
          readViewFitContainerWidth
          validate={validate}
          onConfirm={(value) => (hanledUpdateProfile(value, 'lastName'))}
          placeholder=""
        />
        <Field
          name="email"
          label="Email (not editable)"
        >
          {() => (
            <InlineEditLikeDiv>{get(user, 'email', '')}</InlineEditLikeDiv>
          )}
        </Field>
        <Field
          name="security"
          label="Security"
        >
          {() => (
            <InlineEditLikeDiv>
              <Checkbox
                isChecked={isChecked}
                label="Change Password?"
                onChange={() => setIsChecked(!isChecked)}
                isDisabled={freeExpired}
              />
              {isChecked
                  && <ChangePasswordForm />}
            </InlineEditLikeDiv>
          )}
        </Field>
        <Field
          name="onboarding"
          label="Onboarding"
        >
          { () => (
            <div>
              <Button appearance="link" onClick={onEnableQuickstart}>
                Re-enable Quickstart
              </Button>
            </div>
          )}
        </Field>
      </Box>
      <PlanUpgrade isOpen={disable} close={closeUpgrade} />
    </Wrapper>
  );
};

export default ProfileEditor;
