import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TeamChannelSettingsData } from '../../utils/types';
import MarketPlaceCreator from '../MarketPlaceCreator/MarketPlaceCreator';
import { useSubscription } from '../../utils/useAuth';

const LinkWrapper = styled.div`
  margin: 12px 0 0px;
  display: flex;
  justify-content: center;
`;

export interface Step {
  key: string,
  title: string,
  completed: boolean,
  isCurrent: boolean,
  content: JSX.Element,
}

export default (
  settings: TeamChannelSettingsData, teamChannelId: string, selectedKey: string,
) => {
  const freeExpired = useSubscription();
  const connect = {
    key: 'connect',
    title: 'Connect to MarketPlace',
    completed: settings?.access_token?.access_token !== undefined,
    isCurrent: false,
    content: (
      <>
        By connecting to your first store, you can leverage a range of our
        powerful features to manage your business with ease.
        <LinkWrapper>
          <MarketPlaceCreator onComplete={() => { }} freeExpired={freeExpired} config />
        </LinkWrapper>
      </>
    ),
  };

  const configureStore = {
    key: 'configureStore',
    title: 'Configure store policies',
    completed: settings?.paymentPolicyId !== undefined,
    isCurrent: false,
    content: (
      <>
        Set default store policies for more efficient product listing.
        <LinkWrapper>
          <Link to={`/config/ebay/${teamChannelId}`}>Configure store</Link>
        </LinkWrapper>
      </>
    ),
  };

  const configureCompany = {
    key: 'configureCompany',
    title: 'Configure company profile',
    completed: false,
    isCurrent: false,
    content: (
      <>
        Make sure your company&apos;s ABN and tax information are
        entered correctly.
        <LinkWrapper>
          <Link to="/team/profile">Company profile</Link>
        </LinkWrapper>
      </>
    ),
  };

  const importProducts = {
    key: 'importProducts',
    title: 'Import products',
    completed: false,
    isCurrent: false,
    content: (
      <>
        Import products directly from your eBay store to save the hassle of
        data entry.
        <LinkWrapper>
          <Link to={`/import/ebay/${teamChannelId}`}>Import products</Link>
        </LinkWrapper>
      </>
    ),
  };

  const importOrders = {
    key: 'importOrders',
    title: 'Import orders',
    completed: false,
    isCurrent: false,
    content: (
      <>
        Gain insights into your business by visualising your eBay orders.
        <LinkWrapper>
          <Link to={`/import/ebay/${teamChannelId}`}>Import orders</Link>
        </LinkWrapper>
      </>
    ),
  };

  const sequence = [
    connect,
    configureStore,
    configureCompany,
    importProducts,
    importOrders,
  ];

  const calcCurrentKey = ():string => {
    let key = '';
    for (let i = 0; i < sequence.length; i++) {
      if (!sequence[i].completed) {
        key = sequence[i].key;
        break;
      }
    }
    return key;
  };

  const selectionAwareSteps = () => {
    const curKey = selectedKey || calcCurrentKey();
    for (let i = 0; i < sequence.length; i++) {
      if (curKey === sequence[i].key) sequence[i].isCurrent = true;
    }
    return sequence;
  };

  const calcCompletionRate = () => {
    let total = 0;
    sequence.forEach((step) => {
      if (step.completed) total += 1;
    });
    return total / sequence.length;
  };

  return {
    steps: selectionAwareSteps(),
    completionRate: calcCompletionRate(),
  };
};
