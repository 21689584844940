import { ErrorMessage } from '@atlaskit/form';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import Spinner from '@atlaskit/spinner';
import Modal, { ModalTransition, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import { formatNumber } from 'accounting';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import amazon from '../../assets/svg/amazon.svg';
import ebay from '../../assets/svg/ebay.svg';
import magento from '../../assets/svg/magento.svg';
import wooCommerce from '../../assets/svg/wooCommerce.svg';
import shop from '../../assets/svg/shop.svg';
import shopify from '../../assets/svg/shopify.svg';
import google from '../../assets/svg/google.svg';
import facebook from '../../assets/svg/facebook.svg';
import {
  useGetTeamChannelsByTeamIdQuery, useTeamChannelSummaryQuery,
} from '../../graphql/types';
import formatMoneyWithPrefix from '../../helpers/formatMoney';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import { useAuth, useSubscription } from '../../utils/useAuth';
import MarketPlaceCreator from '../MarketPlaceCreator/MarketPlaceCreator';
import { Card, Container } from './TeamChannelListing.style';

export const ChannelLogo = (
  { channelName, testId }: { channelName: string, testId:string},
) => {
  switch (channelName) {
    case 'eBay': {
      return (
        <img className="logo" src={ebay} alt="ebay" data-testid={testId} />
      );
    }
    case 'in-store': {
      return (
        <img className="logo" src={shop} alt="in-store" data-testid={testId} />
      );
    }
    case 'Magento': {
      return (
        <img className="logo" src={magento} alt="magento" data-testid={testId} />
      );
    }
    case 'Amazon': {
      return (
        <img className="logo" src={amazon} alt="amazon" data-testid={testId} />
      );
    }
    case 'Shopify': {
      return (
        <img className="logo" src={shopify} alt="shopify" data-testid={testId} />
      );
    }
    case 'Google': {
      return (
        <img className="logo" src={google} alt="google" data-testid={testId} />
      );
    }
    case 'Facebook': {
      return (
        <img className="logo" src={facebook} alt="facebook" data-testid={testId} />
      );
    }
    case 'WooCommerce': {
      return (
        <img className="logo" src={wooCommerce} alt="wooCommerce" data-testid={testId} />
      );
    }
    default: {
      return (
        <img className="logo" src={shop} alt="in-store" data-testid={testId} />
      );
    }
  }
};

export const TeamChannelCard = (
  {
    id, name, storeName, externalId = '', isEditable, isDisabled,
  }:
  {
    id: string,
    name: string,
    storeName: string,
    externalId: string,
    isEditable: boolean,
    isDisabled: boolean
  },
) => {
  const { data, loading } = useTeamChannelSummaryQuery({
    variables: {
      teamChannelId: id,
    },
  });
  const numberOfProducts = data?.TeamChannelSummary.numberOfProducts ?? 0;
  const numberOfOrders = data?.TeamChannelSummary.numberOfOrders ?? 0;
  const totalRevenue = data?.TeamChannelSummary.totalRevenue ?? 0;
  return (
    <Card data-testid={`${name.replace(' ', '-')}-card`}>
      <div className="head">
        <ChannelLogo
          channelName={name}
          testId={`${name.replace(' ', '-')}-logo`}
        />
        <div className="name">
          <div className="value">{storeName}</div>
          <div className="subtitle">
            {externalId
              ? `ID:${externalId}`
              : ''}
          </div>
        </div>
        {isEditable && (
        <div className="edit">
            {
            isDisabled === false ? (
              <Link to={`/marketplace/${id}`}>
                <EditFilledIcon
                  label="edit"
                  testId={`${name.replace(' ', '-')}-edit-button`}
                  primaryColor="#0052CC"
                />
              </Link>
            )
              : (
                <EditFilledIcon
                  label="edit"
                  testId={`${name.replace(' ', '-')}-edit-button`}
                  primaryColor="grey"
                />
              )
          }
        </div>
        )}
      </div>
      <div className="state">
        <div className="state-item feeedback">
          <div className="state-item-name" data-testid={`${name.replace(' ', '-')}-orders-label`}>Orders</div>
          <div className="state-item-value" data-testid={`${name.replace(' ', '-')}-orders-value`}>
            {!loading && formatNumber(numberOfOrders)}
          </div>
        </div>
        <div className="state-item revenue">
          <div className="state-item-name" data-testid={`${name.replace(' ', '-')}-revenue-label`}>Revenue</div>
          <div className="state-item-value" data-testid={`${name.replace(' ', '-')}-revenue-value`}>
            {!loading && formatMoneyWithPrefix(totalRevenue)}
          </div>
        </div>
        <div className="state-item products">
          <div className="state-item-name" data-testid={`${name.replace(' ', '-')}-products-label`}>Products</div>
          <div className="state-item-value" data-testid={`${name.replace(' ', '-')}-products-value`}>
            {!loading && formatNumber(numberOfProducts)}
          </div>
        </div>
      </div>
    </Card>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (isConnect: any) => {
  const { state: { currentTeamId } } = useAuth();
  const freeExpired = useSubscription();
  const showConnectionPopUp = isConnect.isConnect;
  const [isOpen, setIsOpen] = React.useState(
    showConnectionPopUp !== null && showConnectionPopUp !== undefined,
  );

  const history = useHistory();
  const close = () => {
    setIsOpen(false);
    history.push('/marketplace');
    window.location.reload();
  };

  const {
    data, loading, error, refetch,
  } = useGetTeamChannelsByTeamIdQuery({
    variables: {
      teamId: currentTeamId!,
    },
    fetchPolicy: 'network-only', // to avoid cached data not refresh after delete teamChannel
  });
  const teamChannels = data?.GetTeamChannelsByTeamId.teamChannels;

  const message = showConnectionPopUp === 'true'
    ? 'You have successfully connect with your Store, please allow a bit longer for the full onboarding processes to finish in the background, estimated around 10mins. That means your orders, products from your current Store will be pulled down to MERP. '
    : 'Connect to your store failed, please try again';

  const header = showConnectionPopUp === 'true'
    ? 'Successfully'
    : 'Fail';

  return (
    <>
      <div className="heading">
        <h2>Channels</h2>
      </div>
      <ModalTransition>
        {isOpen && (
        <Modal
          actions={[{ text: 'Close', onClick: close }]}
        >
          <ModalHeader style={{ paddingLeft: 0 }}>
            <ModalTitle>
              Shopify Connect
              {' '}
              {header}
            </ModalTitle>
          </ModalHeader>
          <p>{message}</p>
        </Modal>
        )}
      </ModalTransition>
      {
        loading && (
        <Container>
          <Spinner size="medium" />
        </Container>
        )
      }
      {
        error && (
          <Container>
            <ErrorMessage>{graphQLErrorsReader(error)}</ErrorMessage>
          </Container>
        )
      }
      {
        !loading && !error && (
          <Container>
            {teamChannels!.map((teamChannel) => (
              <TeamChannelCard
                id={teamChannel.id}
                name={teamChannel.channel.name}
                storeName={teamChannel.name}
                externalId={teamChannel.externalId}
                isEditable
                isDisabled={freeExpired}
              />
            ))}
            {/* <TeamChannelCard
              id="mock-shopify-id"
              name="Shopify"
              externalId=""
              isEditable={false}
            />
            <TeamChannelCard
              id="mock-google-id"
              name="Google"
              externalId=""
              isEditable={false}
            />
            <TeamChannelCard
              id="mock-facebook-id"
              name="Facebook"
              externalId=""
              isEditable={false}
            /> */}
            <Card>
              <MarketPlaceCreator onComplete={() => { refetch(); }} freeExpired={freeExpired} config={false} />
            </Card>

          </Container>
        )
      }
    </>
  );
};
