import React from 'react';
import Button from '@atlaskit/button';
import { ProductProps } from './types';
import AspectsList from './AspectsList';

export default (props: ProductProps) => {
  const {
    image, title, epid, aspects, handleProductPreview,
  } = props;
  return (
    <li>
      <div className="product-image">
        <img src={image} alt={title} />
      </div>
      <div className="product-detail">
        <h3>{title}</h3>
        <AspectsList aspects={aspects} limit={4} compact />
        <Button
          testId={title}
          data-testid={title}
          appearance="primary"
          className="select-button"
          onClick={() => handleProductPreview(epid)}
        >
          Select
        </Button>
      </div>
    </li>
  );
};
