import React, { useState, useEffect } from 'react';
import { isInteger, isNaN, cloneDeep } from 'lodash';
import { Prompt } from 'react-router';
import Button from '@atlaskit/button';
import type { LineItem, ProgressPayload, Step } from './types';
import { MainContent, Footer } from './styles';
import { useAdjustInventoryMutation } from '../../graphql/types';
import PostSubmitResult from './PostSubmitResult';
import PurchaseForm from './PurchaseForm';
import withFadeIn from '../Animation/withFadeIn';

const AnimatedPostSubmitResult = withFadeIn(PostSubmitResult);
const AnimatedPurchaseForm = withFadeIn(PurchaseForm);

export default () => {
  const [adjustInventoryMutation] = useAdjustInventoryMutation();
  const [lineItems, setLineItems] = useState<LineItem[]>([]);
  const [step, setStep] = useState<Step>('dataEntry');
  const [
    progressPayload,
    setProgressPayload,
  ] = useState<ProgressPayload>();

  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  const isDataValid = !(lineItems
    .map((lineItem) => (
      isInteger(lineItem.purchaseQty)
      && !isNaN(Number(lineItem.unitCostEx))))
    .includes(false));

  const isSubmitable = isDataValid && lineItems.length > 0;

  const handleReset = () => {
    setLineItems([]);
    setStep('dataEntry');
    setProgressPayload(undefined);
    setIsSubmiting(false);
  };

  const handleSubmit = async () => {
    const mutableLineItems = cloneDeep(lineItems);
    const totalLineItemsCount = mutableLineItems.length;

    setIsSubmiting(true);

    for (let index = 0; index < mutableLineItems.length; index += 1) {
      const lineItem = mutableLineItems[index];
      setProgressPayload({
        totalCount: totalLineItemsCount,
        currentLineItem: lineItem,
        currentPosition: index + 1,
      });

      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await adjustInventoryMutation({
          variables: {
            productId: lineItem.product.id,
            changeInQty: lineItem.purchaseQty,
            costPerUnit: Number(lineItem.unitCostEx),
            isTaxIncluded: false,
          },
        });
        mutableLineItems[index].response = response.data;
        mutableLineItems[index].status = 'Sucess';
      } catch (error) {
        mutableLineItems[index].error = error;
        mutableLineItems[index].status = 'Failed';
      }
    }

    setIsSubmiting(false);
    setLineItems([
      ...mutableLineItems,
    ]);
    setStep('postSubmit');
  };

  const alert = React.useCallback((e:BeforeUnloadEvent) => {
    if (lineItems.length > 0 && step === 'dataEntry') {
      e.preventDefault();
      e.returnValue = '';
    }
  }, [lineItems, step]);

  useEffect(() => {
    window.addEventListener('beforeunload', alert);
    return () => {
      window.removeEventListener('beforeunload', alert);
    };
  }, [lineItems, step, alert]);

  if (step === 'dataEntry') {
    return (
      <>
        <h2>Stock Receiving</h2>
        <MainContent>
          <AnimatedPurchaseForm
            lineItems={lineItems}
            setLineItems={setLineItems}
            isSubmiting={isSubmiting}
            handleReset={handleReset}
            isSubmitable={isSubmitable}
            handleSubmit={handleSubmit}
            progressPayload={progressPayload}
          />
        </MainContent>
        <Prompt
          when={lineItems.length > 0}
          message="Changes that you made may not be saved."
        />
      </>
    );
  }

  return (
    <>
      <h2>Stock Receiving (result)</h2>
      <MainContent>
        <AnimatedPostSubmitResult
          lineItems={lineItems}
        />
        <Footer>
          <Button
            appearance="subtle"
            onClick={() => window.print()}
          >
            Print
          </Button>
          <Button
            appearance="primary"
            onClick={handleReset}
          >
            New Stock Receiving
          </Button>
        </Footer>
      </MainContent>
    </>
  );
};
