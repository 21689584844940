import React from 'react';
import Spinner from '@atlaskit/spinner';
import { Control } from 'react-hook-form';
import { useGetTeamTaxClassesQuery } from '../../graphql/types';
import { ErrorType } from './types';
import SelectWithLabelAndError from './SelectWithLabelAndError';
import { ProductTaxclassType } from '../ProductDetailsContainer/type';

const ProductTaxClassSelector = ({
  teamId, defaultValue, control, errors, setValue, isDisable,
}: {
  teamId : string,
  defaultValue: ProductTaxclassType | undefined,
  control: Control<Record<string, string>>,
  errors: ErrorType,
  setValue: Function
  isDisable: boolean
}) => {
  const { data, loading } = useGetTeamTaxClassesQuery({
    variables: {
      teamId,
    },
  });

  const inputProps = {
    key: 'productTaxClassSelection',
    name: 'productTaxClassSelection',
    label: 'Product Tax Class',
    isRequired: true,
    control,
    errors,
    defaultValue: defaultValue && defaultValue.id ? [defaultValue.id] : '',
    setValue,
  };

  const fieldOptions = data && data?.GetTeamTaxClasses
    && data.GetTeamTaxClasses.taxClasses
    ? data.GetTeamTaxClasses.taxClasses
      .filter((tc) => tc.isForShipping === false && tc.name !== 'GST Excluded Goods')
      .map((tc) => ({
        label: tc.name,
        value: tc.id,
      })) : [];
  const selectProps = {
    isMulti: false,
    optionsArr: fieldOptions,
  };
  return loading === true ? <Spinner /> : (
    <div data-testid="display-product-tax-class-under-team-id">
      <SelectWithLabelAndError
        {...inputProps}
        {...selectProps}
        isDisable={isDisable}
      />
    </div>
  );
};

export default ProductTaxClassSelector;
