import React from 'react';
import Spinner from '@atlaskit/spinner';
import { useQueryEbayCategorySuggestionsQuery } from '../../graphql/types';
import Option from '../SearchProduct/styles/Option';
import OptionContainer from './styles/OptionContainer';
import CenteredContainer from '../ImageUploader/CenteredContainer';
import { CategorySuggestionType } from './types';

const SuggestCategoriesByKeyword = ({ onCategoryChosen, keyword }: {
  onCategoryChosen: Function,
  keyword : string
}) => {
  const { data, error, loading } = useQueryEbayCategorySuggestionsQuery({
    variables: {
      q: keyword,
    },
  });

  if (error) {
    if (error.message.includes('errorId: 62007')) {
      return <p>Please specify a valid set of keywords that best describes your item</p>;
    }
    return <p>Something wrong when getting suggestions</p>;
  }

  if (keyword === '') {
    return <></>;
  }
  return loading === true ? (
    <OptionContainer>
      <CenteredContainer>
        <Spinner />
      </CenteredContainer>
    </OptionContainer>
  ) : (
    <div data-testid="suggest-category-result">
      <OptionContainer>
        {
          (data?.QueryEbay.response.categorySuggestions
            && Array.isArray(data?.QueryEbay.response.categorySuggestions))
            ? data?.QueryEbay.response.categorySuggestions
            .map((sugg: CategorySuggestionType) => (
              <Option
                key={sugg.category.categoryId}
                onClick={() => onCategoryChosen(sugg.category.categoryId)}
              >
                {sugg.categoryTreeNodeAncestors
                  .map((ancestor) => ancestor.categoryName).join(' / ')}
                {' '}
                /
                {' '}
                {sugg.category.categoryName}
              </Option>
            )) : (
              <p>
                No relevant category was found. Please try another keyword.
              </p>
            )
          }
      </OptionContainer>
    </div>
  );
};

export default SuggestCategoriesByKeyword;
