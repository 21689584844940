import { findIndex } from 'lodash';
import {
  getStorageUser, setStorageUser, getStorageTeams,
  setStorageTeams, getStorageCurrentTeam, setStorageCurrentTeam,
  clearStorage,
} from './storages';
import {
  State, StateUser, StateTeam, TeamChannelSettingsData, StateTeamChannel,
} from './types';
import { User } from '../graphql/types';

export const initialState: State = {
  user: getStorageUser(),
  teams: getStorageTeams(),
  currentTeamId: getStorageCurrentTeam(),
};
type ActionType =
// use is to intiallize the app state
  'login'
  // clear all state and localstorage
  | 'logout'
  // update user's name, allow update ui without fetch api
  | 'updateUserProfile'
  // update teamName, allow update ui without featch api
  | 'changeTeamName'
  // switch current workspace(which team is working on)
  | 'switchTeam'
  // hope later we can use this as default value to create product
  | 'saveFulfillmentPolicy'
  // hope later we can use this as default value to create product
  | 'savePaymentPolicy'
  // hope later we can use this as default value to create product
  | 'saveReturnPolicy'
  // hope later we can use this as default value to create product
  | 'saveMerchantLocation'
  // do we need this value in front end when create product?
  | 'togglePushPriceUpdates'
  // do we need this value in front end when create product?
  | 'togglePushStockQty'
  // allow ui has a full list of teamChannels without fetching api
  // current use case is when filter by teamChannel Id on order listing
  | 'addTeamChannel'
  // this is for supplier
  | 'addTeamChannelSupplier'
  // leave here in case later may be useful, current use case unknow
  | 'setTeamChannelOnboarded'
  // allow ui has a updated list of teamChannels without fetching api
  | 'deleteTeamChannel'
  // allow the ui state have the same value of name and externalID after sync with ebay
  | 'updateTeamChannel';

export interface SavePoliciesPayload {
  teamId: string,
  teamChannelId: string,
  policyId: string,
  value: string,
}
export interface Action {
  type: ActionType,
  loginPayload?:User,
  updateUserPayload?:Pick<StateUser, 'firstName' | 'lastName'>,
  changeTeamNamePayLoad?:{
    teamId:string,
    value:string,
  },
  switchTeamPayLoad?:string,
  savePoliciesPayload?: SavePoliciesPayload,
  togglePushPriceUpdatesPayload?:{
    teamChannelId:string,
  },
  togglePushStockQtyPayload?: {
    teamChannelId: string,
  },
  addTeamChannelPayload?:StateTeamChannel,
  addTeamChannelSupplierPayload?: {
    id: string,
    name: string,
    onBoarded:boolean,
    createdAt:string,
    updatedAt:string,
    settings: {
      consumerKey: string,
      consumerSecret: string,
      sourceIdentifier: string,
    },
  },
  setTeamChannelOnboardedPayload?:{
    teamChannelId: string,
    value:boolean,
  },
  deleteTeamChannelPayload?:{
    teamChannelId: string,
  },
  updateTeamChannelPayload?:{
    teamChannelId:string,
    name?:string,
    externalId?:string,
  }
}

/**
 * return a team:StateTeam
 * @param teamId
 * @param teams
 */
export const getTeamById = (
  teamId: string,
  teams: StateTeam[],
): StateTeam => {
  const teamIndex = findIndex(
    teams,
    (team: StateTeam) => team.id === teamId,
  );
  return teams[teamIndex];
};

export const savePolicy = (
  teams:StateTeam[],
  whichPolicy: 'fulfillmentPolicy' | 'paymentPolicy' | 'returnPolicy' |'merchantLocation',
  args: SavePoliciesPayload,
):StateTeam[] => {
  // deep copy
  const newTeams = [
    ...teams,
  ];
  // find team index
  const teamIndex = findIndex(
    teams,
    (team: StateTeam) => team.id === args.teamId,
  );
  // find teamChannel index
  const teamChannelIndex = findIndex(
    teams[teamIndex].teamChannels,
    (teamChannel:StateTeamChannel) => teamChannel.id === args.teamChannelId,
  );
  // update values
  newTeams[teamIndex]
    .teamChannels[teamChannelIndex]
    .settings[whichPolicy].id = args.policyId;
  newTeams[teamIndex]
    .teamChannels[teamChannelIndex]
    .settings[whichPolicy].name = args.value;

  return newTeams;
};

export const reducer = (state:State, action:Action):State => {
  switch (action.type) {
    case 'login': {
      // prepare user
      const user = {
        id: action.loginPayload!.id,
        firstName: action.loginPayload!.firstName,
        lastName: action.loginPayload!.lastName,
        email: action.loginPayload!.email,
      };
      // prepare team
      const teams = action.loginPayload!.teams.map((teamUsers) => {
        let teamSettings;
        try {
          teamSettings = JSON.parse(teamUsers.team.settings);
        } catch (error) {
          teamSettings = {};
        }
        return (
          {
            id: teamUsers.team.id,
            name: teamUsers.team.name,
            settings: teamSettings,
            teamChannels: teamUsers.team.teamChannels.map((teamChannel) => {
              let teamChannelSettings: TeamChannelSettingsData;
              try {
                teamChannelSettings = JSON.parse(teamChannel.settings);
              } catch (error) {
                teamChannelSettings = {};
              }

              return {
                id: teamChannel.id,
                name: teamChannel.name,
                active: teamChannel?.active ?? false,
                externalId: teamChannel?.externalId ?? '',
                channelID: teamChannel.channel.id,
                channelName: teamChannel.channel.name,
                onBoarded: teamChannel.onBoarded,
                settings: {
                  pushStockQty: teamChannelSettings?.pushStockQty ?? false,
                  pushPriceUpdates: teamChannelSettings?.pushPriceUpdates
                    ?? false,
                  refresh_token_expires_at: teamChannelSettings?.access_token?.refresh_token_expires_at ?? '',
                  token: teamChannelSettings?.access_token?.access_token ?? '',
                  country: teamChannelSettings?.country ?? 'AU',
                  currency: teamChannelSettings?.currency ?? 'AUD',
                  fulfillmentPolicy: {
                    id: teamChannelSettings?.fulfillmentPolicyId ?? '',
                    name: teamChannelSettings?.fulfillmentPolicyName ?? '',
                  },
                  paymentPolicy: {
                    id: teamChannelSettings?.paymentPolicyId ?? '',
                    name: teamChannelSettings?.paymentPolicyName ?? '',
                  },
                  returnPolicy: {
                    id: teamChannelSettings?.returnPolicyId ?? '',
                    name: teamChannelSettings?.returnPolicyName ?? '',
                  },
                  merchantLocation: {
                    id: teamChannelSettings?.merchantLocationKey ?? '',
                    name: teamChannelSettings?.merchantLocationName ?? '',
                  },
                },
                createdAt: teamChannel?.createdAt ?? '',
                updatedAt: teamChannel?.updatedAt ?? '',
                createdBy: `${teamChannel?.createdBy?.firstName ?? ''} ${teamChannel?.createdBy?.lastName ?? ''}`,
                updatedBy: `${teamChannel?.updatedBy?.firstName ?? ''} ${teamChannel?.updatedBy?.lastName ?? ''}`,
              };
            }),
          }
        );
      });
      const currentTeamId = action.loginPayload!.teams[0].team.id;// give first team as default on login
      // set the localStorage which will be used for initialState
      setStorageUser(user);
      setStorageTeams(teams);
      setStorageCurrentTeam(currentTeamId);
      return {
        ...state,
        user,
        teams,
        currentTeamId,
      };
    }
    case 'logout':
    {
      clearStorage();
      return {
        ...state,
        user: undefined,
        teams: [],
        currentTeamId: undefined,
      };
    }
    case 'updateUserProfile': {
      const user = {
        ...state.user!,
        firstName: action.updateUserPayload!.firstName,
        lastName: action.updateUserPayload!.lastName,
      };
      setStorageUser(user);
      return {
        ...state,
        user,
      };
    }
    case 'changeTeamName': {
      // find team index
      const teamIndex = findIndex(
        state.teams,
        (team: StateTeam) => team.id === action.changeTeamNamePayLoad!.teamId,
      );
      const teams = [...state.teams];
      // update team name
      teams[teamIndex].name = action.changeTeamNamePayLoad!.value;
      // update local storage
      setStorageTeams(teams);
      // update state
      return {
        ...state,
        teams,
      };
    }
    case 'switchTeam': {
      // update localstorage
      setStorageCurrentTeam(action.switchTeamPayLoad!);
      // update state
      return {
        ...state,
        currentTeamId: action.switchTeamPayLoad,
      };
    }
    case 'saveFulfillmentPolicy': {
      const updatedTeams = savePolicy(
        state.teams,
        'fulfillmentPolicy',
        action.savePoliciesPayload!,
      );
      // update local storeage
      setStorageTeams(updatedTeams);
      // update state
      return {
        ...state,
        teams: updatedTeams,
      };
    }
    case 'savePaymentPolicy': {
      const updatedTeams = savePolicy(
        state.teams,
        'paymentPolicy',
        action.savePoliciesPayload!,
      );
      // update local storeage
      setStorageTeams(updatedTeams);
      // update state
      return {
        ...state,
        teams: updatedTeams,
      };
    }
    case 'saveReturnPolicy': {
      const updatedTeams = savePolicy(
        state.teams,
        'returnPolicy',
        action.savePoliciesPayload!,
      );
      // update local storeage
      setStorageTeams(updatedTeams);
      // update state
      return {
        ...state,
        teams: updatedTeams,
      };
    }
    case 'saveMerchantLocation': {
      const updatedTeams = savePolicy(
        state.teams,
        'merchantLocation',
        action.savePoliciesPayload!,
      );
      // update local storeage
      setStorageTeams(updatedTeams);
      // update state
      return {
        ...state,
        teams: updatedTeams,
      };
    }
    case 'togglePushPriceUpdates': {
      const teamIndex = findIndex(
        state.teams,
        (team) => team.id === state.currentTeamId,
      );
      const teamChannelIndex = findIndex(
        state.teams[teamIndex].teamChannels,
        (teamChannel) => teamChannel.id === action
          .togglePushPriceUpdatesPayload?.teamChannelId,
      );
      const newState = { ...state };
      // toggle the value
      newState.teams[teamIndex].teamChannels[teamChannelIndex]
        .settings.pushPriceUpdates = !newState.teams[teamIndex]
          .teamChannels[teamChannelIndex].settings.pushPriceUpdates;
      // update the updatedBy field
      newState.teams[teamIndex].teamChannels[teamChannelIndex]
        .updatedBy = `${state.user?.firstName} ${state.user?.lastName}`;
      // update the updatedAt field
      newState.teams[teamIndex]
        .teamChannels[teamChannelIndex].updatedAt = Date();
      // update local storeage
      setStorageTeams(newState.teams);
      // update state
      return newState;
    }
    case 'togglePushStockQty': {
      const teamIndex = findIndex(
        state.teams,
        (team) => team.id === state.currentTeamId,
      );
      const teamChannelIndex = findIndex(
        state.teams[teamIndex].teamChannels,
        (teamChannel) => teamChannel.id === action
          .togglePushStockQtyPayload?.teamChannelId,
      );
      const newState = { ...state };
      // toggle the value
      newState.teams[teamIndex].teamChannels[teamChannelIndex]
        .settings.pushStockQty = !newState.teams[teamIndex]
          .teamChannels[teamChannelIndex].settings.pushStockQty;
      // update the updatedBy field
      newState.teams[teamIndex].teamChannels[teamChannelIndex]
        .updatedBy = `${state.user?.firstName} ${state.user?.lastName}`;
      // update the updatedAt field
      newState.teams[teamIndex].teamChannels[teamChannelIndex]
        .updatedAt = Date();
      // update local storeage
      setStorageTeams(newState.teams);
      // update state
      return newState;
    }
    case 'addTeamChannel': {
      const teamIndex = findIndex(
        state.teams,
        (team) => team.id === state.currentTeamId,
      );
      const { teams } = state;
      teams[teamIndex].teamChannels.push(action.addTeamChannelPayload!);
      // update local storeage
      setStorageTeams(teams);
      // update state
      return {
        ...state,
        teams,
      };
    }
    case 'setTeamChannelOnboarded': {
      const teamIndex = findIndex(
        state.teams,
        (team) => team.id === state.currentTeamId,
      );
      const teamChannelIndex = findIndex(
        state.teams[teamIndex].teamChannels,
        (teamChannel) => teamChannel.id === action
          .setTeamChannelOnboardedPayload?.teamChannelId,
      );
      const { teams } = state;
      teams[teamIndex].teamChannels[teamChannelIndex]
        .onBoarded = action.setTeamChannelOnboardedPayload?.value!;
      // update local storeage
      setStorageTeams(teams);
      // update state
      return {
        ...state,
        teams,
      };
    }
    case 'deleteTeamChannel': {
      const teamIndex = findIndex(
        state.teams,
        (team) => team.id === state.currentTeamId,
      );
      const { teams } = state;
      // remove the deleted teamChannel
      teams[teamIndex].teamChannels = teams[teamIndex].teamChannels
        .filter((cur) => cur.id !== action
          .deleteTeamChannelPayload!.teamChannelId);
      // update local storeage
      setStorageTeams(teams);
      // update state
      return {
        ...state,
        teams,
      };
    }
    case 'updateTeamChannel': {
      const teamIndex = findIndex(
        state.teams,
        (team) => team.id === state.currentTeamId,
      );
      const teamChannelIndex = findIndex(
        state.teams[teamIndex].teamChannels,
        (teamChannel) => teamChannel.id === action
          .updateTeamChannelPayload?.teamChannelId,
      );
      const newState = { ...state };
      // update teamChannel name
      if (action?.updateTeamChannelPayload?.name) {
        newState.teams[teamIndex].teamChannels[teamChannelIndex]
          .name = action.updateTeamChannelPayload.name;
      }
      // update externalId
      if (action?.updateTeamChannelPayload?.externalId) {
        newState.teams[teamIndex].teamChannels[teamChannelIndex]
          .externalId = action.updateTeamChannelPayload.externalId;
      }
      setStorageTeams(newState.teams);
      // update state
      return newState;
    }
    default:
      return state;
  }
};

/**
 * helper function to return given team's teamChanel list
 */
export const getTeamChannelLists = (
  teamId:string,
  teams:StateTeam[],
) => {
  const teamIndex = findIndex(
    teams,
    (team: StateTeam) => team.id === teamId,
  );
  if (teamIndex === -1) return [];
  return teams[teamIndex].teamChannels;
};
