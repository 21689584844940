/* eslint-disable max-len */
import React, { useState } from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import EditIcon from '@atlaskit/icon/glyph/edit';
import styled from 'styled-components';
import { Order } from '../../OrdersListing/types';
import { useUpdateAddressMutation } from '../../../graphql/types';
import ErrorSection from '../../ErrorSection/ErrorSection';
import graphQLErrorsReader from '../../../utils/graphQLErrorsReader';
import { Footer } from '../../CreateOrder/shares/widget.style';

const UpdateAddressModalContainer = styled.div`
    padding-bottom:15px;
`;

interface Props {
  address:Order['shippingAddress'] | Order['billingAddress'],
}

export interface FormData {
  name: string,
  company:string,
  email: string,
  street:string,
  suburb:string,
  state:string,
  postcode:string,
  country:string,
  phone:string,
}

export default ({ address }:Props) => {
  const {
    id, name, email, company, phone, street, state, suburb, postcode, country,
  } = address;
  const [updateAddress, { error, loading }] = useUpdateAddressMutation({
    update: (cache, data) => {
      cache.writeData({ data });
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  const onSubmit = async (data: FormData) => {
    try {
      await updateAddress({
        variables: {
          addressId: id,
          addressParams: {
            name: data.name,
            company: data.company,
            email: data.email,
            phone: data.phone,
            street: data.street,
            suburb: data.suburb,
            state: data.state,
            postcode: data.postcode,
            country: data.country,
          },
        },
      }).then(() => close());
    } catch (err) {
      // leave blank intentional
      // upper scope hook is able to handle the error.
    }
  };

  return (
    <>
      <Button
        onClick={open}
        appearance="subtle-link"
        spacing="compact"
        testId="edit-address-button"
      >
        <EditIcon label="edit" size="small" />
      </Button>

      <ModalTransition>
        {isOpen && (
        <Modal
          onClose={close}
          heading="Update Address"
          width={400}
        >
          <UpdateAddressModalContainer>
            {error && <ErrorSection>{graphQLErrorsReader(error)}</ErrorSection>}
            <Form<FormData>
              onSubmit={onSubmit}
            >
              {({ formProps, submitting }) => (
                <form {...formProps}>
                  <Grid spacing="compact">
                    <GridColumn medium={12}>
                      <Field
                        name="name"
                        defaultValue={name}
                        label="Contact"
                        isRequired
                        isDisabled={submitting}
                      >
                        {({ fieldProps }) => <TextField isCompact {...fieldProps} />}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={12}>
                      <Field
                        name="email"
                        defaultValue={email}
                        label="Email"
                        isDisabled={submitting}
                      >
                        {({ fieldProps }) => <TextField type="email" isCompact {...fieldProps} />}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={12}>
                      <Field
                        name="company"
                        defaultValue={company}
                        label="Company"
                        isDisabled={submitting}
                      >
                        {({ fieldProps }) => <TextField isCompact {...fieldProps} />}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={12}>
                      <Field
                        name="phone"
                        defaultValue={phone}
                        label="Phone"
                        isDisabled={submitting}
                      >
                        {({ fieldProps }) => <TextField isCompact {...fieldProps} />}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={12}>
                      <Field
                        name="street"
                        defaultValue={street}
                        label="Street"
                        isDisabled={submitting}
                      >
                        {({ fieldProps }) => (
                          <TextField
                            isCompact
                            placeholder="Line 1"
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={12}>
                      <Field
                        name="suburb"
                        defaultValue={suburb}
                        isDisabled={submitting}
                      >
                        {({ fieldProps }) => (
                          <TextField
                            isCompact
                            placeholder="Suburb"
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={6}>
                      <Field
                        name="state"
                        isDisabled={submitting}
                      >
                        {({ fieldProps }) => (
                          <TextField
                            isCompact
                            defaultValue={state}
                            placeholder="State"
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={6}>
                      <Field
                        name="postcode"
                        defaultValue={postcode}
                        isDisabled={submitting}
                      >
                        {({ fieldProps }) => (
                          <TextField
                            isCompact
                            placeholder="Postcode"
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={12}>
                      <Field
                        name="country"
                        defaultValue={country}
                        isDisabled={submitting}
                      >
                        {({ fieldProps }) => (
                          <TextField
                            isCompact
                            placeholder="Country"
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </GridColumn>
                  </Grid>

                  <Grid>
                    <GridColumn medium={12}>
                      <Footer>
                        <Button
                          onClick={close}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          isDisabled={loading}
                          isLoading={loading}
                          appearance="primary"
                          testId="update-address-button"
                        >
                          Update
                        </Button>
                      </Footer>
                    </GridColumn>
                  </Grid>
                </form>
              )}
            </Form>
          </UpdateAddressModalContainer>
        </Modal>
        )}
      </ModalTransition>
    </>
  );
};
