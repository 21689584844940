import React from 'react';
import Spinner from '@atlaskit/spinner';

interface SpinnerProps {
  appearance?: 'invert' | 'inherit',
  isLoading?: boolean,
  size?: number | 'small' | 'xsmall' | 'medium' | 'large' | 'xlarge' | undefined,
  props?: object,
}

const WithLoading = <P extends object>(Component: React.ComponentType<P>) => ({
  appearance = 'inherit',
  isLoading = false,
  size = 'medium',
  ...props
}: SpinnerProps) => (isLoading
  ? (
    <div data-testid="spinner-container">
      <Spinner data-testid="spinner-item" appearance={appearance} size={size} />
    </div>
  )
  // eslint-disable-next-line react/jsx-props-no-spreading
  : <Component {...props as P} />);

export default WithLoading;
