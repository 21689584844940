import React from 'react';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import styled from 'styled-components';
import Tag from '../../OrdersListing/widget/Tag';
import getColor from '../../OrdersListing/helper/getColor';

const BreadcrumbContainer = styled.div`
    margin-bottom:20px;
    display:flex;
    color: rgb(23, 43, 77);
    font-size:1rem;
    font-weight:500;
    align-items:center;
    a:hover{
      text-decoration:none;
    }
`;

interface Props {
  statusName:string,
  orderNumber:string,
  orderId:string,
}
export default ({ statusName, orderNumber, orderId }:Props) => {
  const { pathname } = window.location;

  return (
    <BreadcrumbContainer>
      <BreadcrumbsStateless>
        {pathname.includes('/order/')
        && <BreadcrumbsItem href="/orders" text="Orders" />}
        <BreadcrumbsItem
          iconBefore={(
            <Tag
              text={statusName.toUpperCase()}
              color={getColor(statusName.toUpperCase())}
              testId={`${orderNumber}-ov-status`}
            />
                  )}
          text={orderNumber}
          href={`/order/${orderId}`}
          target="_blank"
          testId="breadcrums-orderNumber"
        />
      </BreadcrumbsStateless>
    </BreadcrumbContainer>
  );
};
