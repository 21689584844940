import React from 'react';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import styled from 'styled-components';
import { isObject } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import {
  applyMiddleWare, getDiffs, isAmount, SKIPLIST, parseCurrencyAmount, getDesc,
} from './helpers';

const ChangeContainer = styled.div`
  color:rgb(107, 119, 140);
  display:flex;
  justify-content:flex-start;
  align-items:center;
`;

export interface AuditLogMutationDetails {
  action: string,
  model: string,
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  before: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  after: any,
}

const formatSnapshot = (
  // can't really tell the type of inputs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value:any,
) => {
  if (isObject(value)) {
    const keys = Object.keys(value);
    const values = Object.values(value);

    const validKeys:Array<string> = [];
    const validValues:Array<string> = [];

    // remove unwanted columns
    keys.forEach((key, index) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!SKIPLIST.includes(key as any)) {
        validKeys.push(key);
        validValues.push(
          parseCurrencyAmount('', values[index], isAmount(key)),
        );
      }
    });

    return (
      <>
        <table>
          <tbody style={{ border: 'none' }}>
            {validKeys.map((cur, index) => (
              <tr key={uuidV4()}>
                <td>{cur}</td>
                <td>{validValues[index]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
  return value;
};

interface LogLine {
  desc:string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  left:any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  right:any,
}
const LogLine = (
  { desc, left, right }:LogLine,
) => (
  <ChangeContainer>
    {desc && <div className="desc">{desc}</div>}
    <div className="left">{formatSnapshot(left)}</div>
    <div className="icon"><ArrowRightIcon label="" /></div>
    <div className="right">{formatSnapshot(right)}</div>
  </ChangeContainer>
);

const generateChangeLog = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  difference:any,
  isCurrency:boolean,
):{
  desc:string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  left:any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  right:any,
} => {
  const {
    kind, path, rhs, lhs, item,
  } = difference;
  const desc = getDesc(path);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let left:any = lhs;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let right:any = rhs;
  switch (kind) {
    case 'A': {
      // array
      return generateChangeLog(item, false);
    }
    case 'N': {
      // new
      left = 'Add new';
      break;
    }
    case 'E': {
      // edit

      break;
    }
    case 'D': {
      right = 'Removed';
      break;
    }
    default: {
      break;
    }
  }

  left = applyMiddleWare(desc, left, isCurrency);
  right = applyMiddleWare(desc, right, isCurrency);

  return ({
    desc,
    left,
    right,
  });
};

interface Props {
  log:string,
}

const AuditLogCommentCard = (
  { log }:Props,
) => {
  try {
    const { before, after, key } = JSON.parse(log) as AuditLogMutationDetails;
    // breaking changes failover to catch
    if (!key) {
      throw new Error();
    }

    const differences = getDiffs(before, after);

    if (!differences) {
      return <></>;
    }

    const diffs = differences.map((each) => {
      const { desc, left, right } = generateChangeLog(each, isAmount(key));
      return (
        <LogLine
          key={uuidV4()}
          desc={desc}
          left={left}
          right={right}
        />
      );
    });

    return <>{diffs}</>;
  } catch (error) {
    // there are cases that log is not serilized in above format
    // for example, when create a payment agaist a order will have a comment in a format of string
    return <>{log}</>;
  }
};

export default AuditLogCommentCard;
