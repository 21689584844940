import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';
import { HeadType } from '@atlaskit/dynamic-table/types';
import client from '../../graphql/client';
import { GetSeedBySkuDocument } from '../../graphql/types';

const PaymentsContainer = styled.div`
    margin-bottom:20px; 
    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    } 
`;
// const ErrorMsg = styled.p`
//   margin-top:10px;
//   color: #bf1650;
//   &:before {
//     content: "⚠ "
//   }
// `;

type ProductSeedStockType = {
  ETA: String;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormattedStock: any;
  IsCustomerRegion: Boolean;
  ProductCode: String;
  Stock: Number;
  WarehouseCode: String;
  WarehouseName: String;
};

const SupplierInventory = ({ sku } : { sku : String }) => {
  const [isLoading, setLoading] = useState(false);
  // const [errorMessage, setErrorMsg] = useState<string>('');
  const [productSeedStocks, setProductSeedStocks] = useState<ProductSeedStockType[]>([]);

  useEffect(() => {
    setLoading(true);
    client.query({
      query: GetSeedBySkuDocument,
      variables: {
        sku,
      },
    }).then((resp) => {
      const pSeedStocks = JSON.parse(resp.data.GetSeedBySku.productSeed.stocks);
      setProductSeedStocks(pSeedStocks);
    }).catch(() => {
      // console.log('No data from database');
    }).finally(() => { setLoading(false); });
  }, [sku]);

  const stockHead: HeadType = {
    cells: [
      {
        content: 'Warehouse Name',
      },
      {
        content: 'Stock',
      },
      {
        content: 'Is Customer Region',
      },
      {
        content: 'ETA',
      },
    ],
  };

  const getStockRow = () => productSeedStocks.map((stock) => ({
    cells: [
      { content: stock.WarehouseName },
      { content: stock.Stock },
      { content: stock.IsCustomerRegion === true ? 'Yes' : 'No' },
      { content: stock.ETA },
    ],
  }));

  return (
    <>
      <PaymentsContainer>
        <DynamicTable
          head={stockHead}
          rows={getStockRow()}
          isLoading={isLoading}
        />
        {/* {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>} */}
      </PaymentsContainer>
    </>
  );
};

export default SupplierInventory;
